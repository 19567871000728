@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.moshi-vertical-radio-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  justify-content: center;
  align-items: end;

  label {
    &.moshi-vertical-radio {
      height: auto;
    }
  }
}

.moshi-vertical-radio {
  height: auto;

  .radio-wrapper {
    margin-left: -18px;
  }
}

.moshi-horizontal-radio-group {
  display: flex;
  flex-direction: row;

  .moshi-horizontal-radio {
    @include common-border-radius;
    height: 40px;
    border: 1px solid $grey-110;
    padding-left: 15px;
    margin-right: 15px;

    .radio-label {
      color: $dark-blue-font-color;
    }

    &.selected {
      background-color: $blue-200;
    }
  }
}
