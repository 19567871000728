@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.bg-primary {
  background-color: $default-primary !important;
}

.bg-success {
  background-color: $success-green !important;
}

.bg-info {
  background-color: $ocean-blue !important;
}

.bg-warning {
  background-color: $warning-orange !important;
}

.bg-danger {
  background-color: $error-red !important;
}

.bg-white {
  background-color: $white-100 !important;
}
