@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.patient-appointment-event-card {
  @include common-border-radius;

  background-color: $white-100;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
  max-width: 930px;

  &:focus {
    outline: none;
  }

  &:hover {
    @include common-box-shadow;
    cursor: pointer;
  }

  .event-date {
    text-align: center;
    display: inline-block;
    color: $light-font-color;
    margin-right: 30px;

    .day {
      font-size: $font-size-title3;
      letter-spacing: 0.75px;
      margin-bottom: 5px;
    }

    .date {
      font-size: $font-size-sm;
      text-transform: uppercase;
      color: $lighter-font-color;
      letter-spacing: 0.46px;
      line-height: 1.09;
    }
  }

  .details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .category-indicator-container {
      margin-left: 10px;
    }

    .duration-with-doctor {
      color: $light-font-color;
      margin-top: 3px;
    }

    .event-status-tag {
      margin-top: 5px;
    }
  }

  .event-remove-tag {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 100;
  }
}
