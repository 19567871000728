@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-ExtraLightItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-MediumItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-SemiBoldItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-BoldItalic.ttf") format("truetype");
}
