@import "../../abstracts/variables";
@import "../../abstracts/placeholders";
@import "../../abstracts/mixins";

nav {
  &.sidebar {
    z-index: 2;
    height: 100%;
    position: fixed;
    transition: all 0.3s;
    width: $sidebar-size;
    background-color: $white-transparent;
    padding: 0 10px 20px;
    top: 0;
    bottom: 0;
    overflow: auto;
    box-shadow: inset -2px 0 0 0 $pale-lilac;

    > hr {
      width: 33%;
      border-color: $pale-lilac;
      margin: 16px 0 16px -10px;
    }

    div,
    a {
      outline: none;

      &.navigation {
        @include svg-fill($default-font-color);
        @include common-border-radius;
        color: $default-font-color;
        line-height: 40px;
        padding: 0 10px;
        display: block;
        text-decoration: none;
        position: relative;
        white-space: nowrap;

        span {
          vertical-align: middle;
          height: 30px;
        }

        svg {
          width: 30px;
          margin-top: -3px;
        }

        & > .circle {
          top: 17px;
          left: 7px;
          position: absolute;
          width: 7px;
          height: 7px;
          border-radius: 100%;
          background-color: $black-800;
        }

        &.navigation-no-hover {
          @extend %no-hover;
          padding: 0 17px;
          display: flex;
          align-items: center;
          min-height: 20px;
          max-height: 20px;
          transition: all 0.3s;

          .location-name {
            display: flex;
            min-height: 15px;
            max-width: 200px;

            .dropdown-toggle-label {
              max-width: 200px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              .dropdown-value {
                color: $light-font-color;
                letter-spacing: 0.46px;
              }
            }
          }

          .title {
            @include common-navigation-title;
          }
        }

        &.active {
          background-color: $pastel-blue-100;
        }

        &:hover {
          background-color: $reverse-background;
        }
      }
    }

    #locations-dropdown {
      .dropdown-toggle-label {
        min-height: 15px;
      }

      .dropdown-value {
        text-transform: initial;
        color: $default-primary;
        letter-spacing: normal;
        font-size: $font-size-base;
        font-weight: 600;
      }

      .dropdown-caret {
        i {
          &::before {
            border-top-color: $default-primary;
          }

          &::after {
            border-top-color: $default-primary;
          }
        }

        .open {
          &::before {
            border-top-color: transparent;
            border-bottom-color: $default-primary;
          }

          &::after {
            border-top-color: transparent;
            border-bottom-color: $default-primary;
          }
        }
      }
    }

    .sub-navigation {
      @include common-border-radius;

      .navigation-parent {
        @include common-navigation;
        margin: auto;
        color: $default-font-color;

        &:hover {
          background-color: $reverse-background;
        }

        svg {
          width: 30px;
          margin-top: -3px;
        }
      }

      .navigation {
        margin: 0 9px;
        font-weight: 400 !important;

        &.active {
          background-color: $white-100;
        }
      }

      &.active {
        background-color: $pastel-blue-100;
        padding-bottom: 10px;

        .navigation-parent {
          margin-bottom: 10px;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    .collapse-button {
      background-color: $pale-lilac;
      position: absolute;
      right: 0;
      top: 32px;
      width: 21px;
      height: 21px;
      text-align: center;
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
      padding-top: 1px;
      padding-left: 3px;

      i {
        font-size: $font-size-base;
        margin-right: 2px;
      }

      &:hover {
        color: $default-primary;
        cursor: pointer;
      }
    }

    .sidebar-link-text {
      font-size: $font-size-base;
      font-weight: 400;
      transition: all 0.3s;
      opacity: 1;
      white-space: nowrap;
    }

    .logo {
      transition: all 0.3s;
    }
  }

  &.collapsed {
    @include no-scrollbar;
    width: $collapsed-sidebar-size;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;

    .locations-dropdown {
      width: 75px;
    }

    .sidebar-link-text {
      display: none;
    }

    .sidebar-icon {
      height: 19px;
    }

    .navigation {
      padding: 0 9px !important;
      width: 50px;

      &.navigation-no-hover {
        padding: 0 !important;
        border-radius: 0 !important;

        .location-name {
          .dropdown-toggle-label {
            max-width: 50px !important;
          }
        }
      }
    }

    .app-version {
      opacity: 0;
    }

    hr {
      width: 100%;
    }

    .logo {
      margin-left: 5px;
      @media screen and (max-height: 720px) {
        margin-bottom: 0;
      }
    }

    .sub-navigation {
      .navigation {
        text-align: center;
        width: 80%;
        margin: auto;
        text-transform: lowercase;
      }
    }
  }

  &.content-sidebar {
    width: $sidebar-size - 30;
    min-width: $sidebar-size - 30;
    margin-left: -20px;
    position: fixed;
    background-color: transparent;
    padding: 42px 10px 0 20px;
    top: 0;
    bottom: 0;
    overflow: auto;

    header {
      @extend %container-header;
      padding-top: 30px;

      .back {
        &:focus {
          outline: none;
        }
      }
    }

    nav {
      margin: 29px 0 0;

      a {
        outline: none;

        &.navigation {
          @include common-navigation;

          &.active {
            background-color: $white-100;
          }

          &:hover {
            background-color: $reverse-background;
          }
        }
      }
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        svg {
          margin-top: -3px;
        }
      }
    }

    + .custom-container {
      padding-right: 10px;
    }

    hr {
      width: 33%;
      border-color: $pale-lilac;
      margin: 16px 0 16px -20px;
      transition: all 0.3s;
    }

    .title {
      @include common-navigation-title;
      margin-top: 30px;
    }
  }

  .numeric-badge {
    color: $white-100;
    background-color: $red-500;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    padding-top: 1px;
    top: -3px;
    right: -3px;
    font-size: $font-size-base - 5;
    width: 12px;
    height: 12px;
    transform-origin: center center;
    animation-name: bounce-out;
    animation-duration: 0.5s;

    &.font-7 {
      font-size: $font-size-base - 7;
    }

    &.font-9 {
      font-size: $font-size-base - 5;
    }
  }
}

.active-encounters-popover {
  &.moshi-popover {
    .popover {
      top: -5px !important;
    }

    .arrow {
      top: 5px !important;

      &::before {
        border-right-color: transparent;
      }

      &::after {
        border-bottom-color: transparent;
        border-right-color: $white-100;
      }
    }

    .popover-body {
      background-color: $white-100;
      border-radius: 15px;
      padding: 0;

      .active-encounters-container {
        background-color: $white-100;

        .active-encounters-header {
          padding: 10px 0 10px 15px;
          font-size: $font-size-lg;
        }

        .patient-name {
          font-weight: 700;
          font-size: $font-size-base;
        }

        .check-in-at {
          font-weight: 600;
          font-size: $font-size-base - 1;
        }

        .active-encounters-body {
          @include no-scrollbar;
          background-color: $white-100 !important;
          margin-bottom: 10px;
          padding: 0 5px;
          overflow: auto;
          max-height: 380px;

          .card {
            margin-bottom: 5px;
            box-shadow: none !important;
            padding: 10px !important;
            transition: all 0.4s ease-out;

            &:hover {
              background-color: $pastel-blue-100;
            }

            .doctor-circle-icon {
              @include circle(10px);

              margin-right: 5px;
              display: inline-block;
            }
          }
        }
      }
    }

    .active-encounter {
      .icon-chevron-right {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -9px;
        font-size: $font-size-lg;
      }
    }
  }
}

.sidebar-icon {
  margin: 0 7px;
  font-size: $font-size-lg;
  vertical-align: middle;
  padding-bottom: 1px;
}

.sidebar-support-modal {
  .modal-body {
    display: flex;
    justify-content: center;

    a {
      margin-left: 5px;
    }
  }
}

.organization-name {
  margin: 15px 10px 5px 5px;
  padding: 0 10px;
}
