@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.waiting-list-container {
  .waiting-list-filters {
    display: flex;
    max-width: 800px;
    margin: 25px auto;

    .search-field-inverted {
      flex-grow: 1;
      margin-right: 10px;
    }

    .dropdown {
      margin-left: 10px;
    }
  }
}

.waiting-list-event-card {
  @include common-border-radius;

  background-color: $white-300;
  padding: 20px;
  position: relative;
  max-width: 800px;
  margin: 10px auto;

  &:focus {
    outline: none;
  }

  &:hover {
    @include common-box-shadow($grey-600);
    cursor: pointer;
  }

  .grid-wrapper {
    display: grid;
    grid-template-columns: auto max-content max-content auto 1fr max-content;
    grid-template-rows: 1fr;
    grid-template-areas: "event-title category event-time assignees event-priority add-to-calendar";
    align-items: center;

    .event-title {
      grid-area: event-title;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-right: 1px solid $grey-500;
      padding-right: 7px;
      margin-right: 7px;
    }

    .event-time {
      grid-area: event-time;
      border-right: 1px solid $grey-500;
      padding-right: 7px;
      margin-right: 7px;
    }

    .category {
      grid-area: category;
      margin-right: 7px;
      border-right: 1px solid $grey-500;
      padding-right: 7px;
    }

    .event-priority {
      grid-area: event-priority;
      margin-right: 25px;
    }

    .add-to-calendar-btn-container {
      grid-area: add-to-calendar;
    }

    .assignees {
      grid-area: assignees;
      margin-right: 7px;
      border-right: 1px solid $grey-500;
      padding-right: 7px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .note {
    margin-top: 10px;
    width: 100%;
  }
}
