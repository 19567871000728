// ------- COLORS -------
// White colors
$white-100: #fff;
$white-200: #f5f7f8;
$white-300: #f7f9fa;
$white-400: #fcfcf7;

// Grey colors
$grey-100: #f2f2ed;
$grey-101: #ececec;
$grey-110: #e4e4ee;
$grey-200: #e6eded;
$grey-300: #e5e5e1;
$grey-400: #dce3e3;
$grey-410: #ddd;
$grey-500: #c4c4cc;
$grey-600: #b4c2c9;
$grey-700: #8e8e93;
$grey-710: #7ea5b9;
$grey-800: #5c758e;
$grey-900: #406080;

// Black colors
$black-800: #13304d;
$black-900: #171f26;
$black-1000: #000;

// Primary colors
$primary-blue-100: #f3f7fc;
$primary-blue-200: #d2e3f4;
$primary-blue-300: #a9c9ea;
$primary-blue-400: #80b0e0;
$primary-blue-500: #5796d6;
$primary-blue-600: #317dc8;
$primary-blue-700: #2763a0;
$primary-blue-800: #1d4a77;
$primary-blue-900: #13304d;
$primary-blue-1000: #091725;

// Accent colors
$blue-100: #f0fafe;
$blue-200: #caebfc;
$blue-300: #99dafa;
$blue-400: #69c8f7;
$blue-500: #38b6f5;
$blue-600: #0ca3ed;
$blue-700: #0a83bf;
$blue-800: #07608d;
$blue-900: #053f5c;
$blue-1000: #021e2c;

$red-100: #fdf0ed;
$red-200: #f7c9c0;
$red-300: #f1a393;
$red-400: #eb7c66;
$red-500: #e55639;
$red-600: #cf3a1b;
$red-700: #a22d15;
$red-800: #75210f;
$red-900: #481409;

// Pastel colors
$yellow-100: #fffbeb;
$yellow-200: #fef3c8;
$yellow-300: #fee895;
$yellow-400: #fddd63;
$yellow-500: #fdd230;
$yellow-600: #f7c403;
$yellow-700: #c59c02;
$yellow-800: #927402;
$yellow-900: #604c01;

$orange-100: #fff1eb;
$orange-200: #fed7c8;
$orange-300: #fdb396;
$orange-400: #fc8f64;
$orange-500: #fb6b32;
$orange-600: #f54905;
$orange-700: #b43503;
$orange-800: #501801;

$pink-100: #ffebeb;
$pink-200: #fec8c8;
$pink-300: #fd9696;
$pink-400: #fc6464;
$pink-500: #fb3232;
$pink-600: #f50505;
$pink-700: #b40404;
$pink-800: #820303;
$pink-900: #500202;

$green-100: #f1f9f6;
$green-200: #d8eee6;
$green-300: #b5dece;
$green-400: #92ceb7;
$green-500: #6fbea0;
$green-600: #4eac88;
$green-700: #3e896d;
$green-800: #2e6651;
$green-900: #19382d;

$light-blue-100: #ecf8fe;
$light-blue-200: #caebfc;
$light-blue-300: #99dafa;
$light-blue-400: #69c8f7;
$light-blue-500: #38b6f5;
$light-blue-600: #0ca3ed;
$light-blue-700: #0a82bd;
$light-blue-800: #07608d;
$light-blue-900: #04354e;

$pastel-blue-100: #ebf1f5;
$pastel-blue-200: #d2e3f4;
$pastel-blue-300: #a9c9ea;
$pastel-blue-400: #80b0e0;
$pastel-blue-500: #5796d6;
$pastel-blue-600: #317dc8;
$pastel-blue-700: #2763a0;
$pastel-blue-800: #1d4a77;
$pastel-blue-850: #122f4c;
$pastel-blue-900: #102941;

$violet-100: #f1f1f9;
$violet-200: #d8d7ef;
$violet-300: #b5b3e0;
$violet-400: #928fd1;
$violet-500: #6f6bc2;
$violet-600: #4e49b0;
$violet-700: #3e3a8c;
$violet-800: #2e2b68;
$violet-900: #19183a;

// ALIASES
$default-font-color: $black-900;
$light-font-color: $black-800;
$lighter-font-color: $grey-900;
$dark-blue-font-color: $pastel-blue-850;

$default-primary: $blue-700;
$gradient-default-primary: rgba($default-primary, 0.5);
$active-primary: $lighter-font-color;
$disabled-primary: $blue-300;
$light-primary: $blue-200;

$default-success: $green-500;
$active-success: $green-200;
$disabled-success: $green-100;

$default-warning: $orange-500;
$active-warning: $orange-200;
$disabled-warning: $orange-100;

$default-danger: $red-600;
$active-danger: $red-200;
$disabled-danger: $red-100;
$input-danger: rgba($disabled-danger, 0.3);
$no-show-icon-danger: rgba($pink-400, 0.1);

$default-yellow: $yellow-500;
$active-yellow: $yellow-200;
$disabled-yellow: $yellow-100;

$default-purple: $violet-600;
$active-purple: $violet-400;
$disabled-purple: $violet-200;

$default-background: $white-200;
$reverse-background: $white-300;

$default-box-shadow-color: rgba($default-font-color, 0.2);
$light-box-shadow-color: rgba($default-font-color, 0.1);
$image-overlay-background: rgba($default-font-color, 0.6);
$pagination-box-shadow-color: rgba(18, 47, 76, 0.28);
$calendar-popover-box-shadow-color: rgba($black-1000, 0.2);
$filter-box-shadow: 0 0 8 0 rgba($black-900, 0.1);
$working-hours-calendar-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
$common-bg-transitions: background-color 200ms ease-out;

// OLD VARS
$grey3: $violet-200;
$grey4: $grey-500;
$grey5: $grey-700;
$grey6: $grey-400;
$grey7: $grey-200;
$grey8: $grey-300;
$grey9: $red-100;
$grey10: $grey-500;
$grey11: $black-900;

$pale-lilac: $violet-100;
$gradient-pale-lilac: rgba($pale-lilac, 0.3);
$cloudy-blue: $grey-600;

$light-blue-grey: $grey-500;
$light-grey: $grey-200;
$dark-grey: $grey-900;
$secondary-color: $violet-100;

$grey-blue: $grey-200;
$grey-blue-transparent: rgba(235, 241, 245, 0);
$greyer-blue: $grey-400;
$medium-blue: $blue-600;
$dark-blue: $blue-700;

$success-green: $green-300;
$medium-green: $green-600;
$dark-green: $green-700;

$warning-orange: $yellow-300;
$medium-orange: $yellow-600;
$dark-orange: $yellow-700;

$error-red: $red-600;
$medium-red: $red-600;
$dark-red: $red-700;

$white-transparent: rgba($white-100, 0.73);
$dark-white: $white-400;
$light-black: $primary-blue-900;
$ocean-blue: $blue-700;
$modal-footer-border: rgba(0, 0, 0, 0.15);
$header-hover-bg-color: rgba($light-blue-200, 0.3);

// calendar
$active-day-bg-color: rgba(249, 233, 210, 0.3);
$header-day-hover-bg-color: $header-hover-bg-color;
$daypicker-blue: $blue-100;

$font-base-path: "./fonts" !default;
$font-family-ikonate-icons: "Ikonate";
$font-family-ibm-plex-sans: "IBM Plex Sans", "sans-serif";

$font-size-base: 14px;
$font-size-sm: $font-size-base - 3;
$font-size-md: $font-size-base - 2;
$font-size-lg: $font-size-base + 4;
$font-size-xl: $font-size-base + 6;
$font-size-title1: $font-size-base + 22;
$font-size-title2: $font-size-base + 10;
$font-size-title3: $font-size-xl;
$font-size-title4: $font-size-base + 2;
$font-size-label: $font-size-md;
$font-size-tiny: $font-size-base - 6;

// icon sizes
$font-size-icon-sm: $font-size-base - 3;
$font-size-icon-md: $font-size-base + 2;
$font-size-icon-lg: $font-size-base + 8;
$font-size-icon-xl: $font-size-base + 36;

// categories
$category-color-1: $yellow-800;
$category-color-2: $yellow-600;
$category-color-3: $yellow-300;
$category-color-4: $yellow-400;
$category-color-5: $orange-700;
$category-color-6: $orange-500;
$category-color-7: $orange-200;
$category-color-8: $orange-300;
$category-color-9: $red-800;
$category-color-10: $red-600;
$category-color-11: $red-400;
$category-color-12: $pink-700;
$category-color-13: $pink-500;
$category-color-14: $pink-200;
$category-color-15: $pink-300;
$category-color-16: $violet-800;
$category-color-17: $violet-600;
$category-color-18: $violet-300;
$category-color-19: $violet-400;
$category-color-20: $primary-blue-800;
$category-color-21: $pastel-blue-600;
$category-color-22: $pastel-blue-300;
$category-color-23: $pastel-blue-400;
$category-color-24: $light-blue-700;
$category-color-25: $blue-600;
$category-color-26: $blue-300;
$category-color-27: $blue-400;
$category-color-28: $green-800;
$category-color-29: $green-600;
$category-color-30: $green-300;
$category-color-31: $green-400;

$category-colors: $category-color-1, $category-color-2, $category-color-3,
  $category-color-4, $category-color-5, $category-color-6, $category-color-7,
  $category-color-8, $category-color-9, $category-color-10, $category-color-11,
  $category-color-12, $category-color-13, $category-color-14, $category-color-15,
  $category-color-16, $category-color-17, $category-color-18, $category-color-19,
  $category-color-20, $category-color-21, $category-color-22, $category-color-23,
  $category-color-24, $category-color-25, $category-color-26, $category-color-27,
  $category-color-28, $category-color-29, $category-color-30, $category-color-31;

// layout
$sidebar-size: 250px;
$collapsed-sidebar-size: 70px;
$content-sidebar-size: $sidebar-size - 20;
$container-min-width: 900px;
$container-max-width: 1400px;

// forms
$main-border: $ocean-blue;
$input-btn-padding-y: 8px;
$input-btn-padding-x: 10px;

$input-btn-padding-y-sm: 3px;
$input-btn-padding-x-sm: 6px;

$input-btn-padding-y-lg: 7px;
$input-btn-padding-x-lg: 15px;

$btn-font-weight: bold;

$input-color: $black-1000;
$input-border-color: $grey-400;
$input-placeholder-color: $grey5;
$form-group-margin-bottom: 14px;

$nav-link-padding-y: 8px;
$nav-link-padding-x: 16px;

$alert-padding-y: 12px;
$alert-padding-x: 20px;

// other
$box-shadow-color: rgba(0, 0, 0, 0.4);
$box-shadow-color-light: rgba(0, 0, 0, 0.2);
$card-shadow-color: rgba(0, 0, 0, 0.06);
$card-base-padding: 20px;
$popover-shadow-color: rgba(0, 0, 0, 0.36);
$popover-shadow-color: $popover-shadow-color;
$tooltip-shadow-color: $popover-shadow-color;
$header-actions-shadow: 0 0 4px 0 $pale-lilac, 0 8px 30px 0 $light-blue-grey;
$card-small-padding: 14px;
$base-border-radius-size: 14px;
$sm-border-radius-size: 10px;
$pagination-box-shadow: 0 0 10px 0 $pagination-box-shadow-color;
$document-shadow-color: $pastel-blue-850;
$document-box-shadow: 0 8px 30px 0 rgba($document-shadow-color, 0.3);
$document-actions-color: rgba($black-1000, 0.75);

// dropdown
$rs-dropdown-border-color: $grey-110;
$dropdown-shadow-color: $popover-shadow-color;
$dropdown-border-radius-size: 10px;
$dropdown-hover-background: $white-300;

// breakpoints
$breakpoints: (
  "card-with-map-max-width": (
    max-width: 1327px
  ),
  "card-with-location-max-width": (
    max-width: 1200px
  )
);

// dental chart
$active-position-fill: $blue-100;
$active-position-stroke: $blue-700;

$table-hover-color: $white-300;
$expand-list-panel-background-color: $gradient-pale-lilac;

// overlay
$overlay-header-height: 65px;
$overlay-right-side-button-container-height: 88px;

// save bar
$save-bar-min-height: 78px;

// buttons
$primary-button-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.2);
$secondary-button-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.1);
$light-button-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.1);
$danger-button-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.1);
$success-button-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.1);

$button-xs-height: 20px;
$button-xs-font-size: $font-size-base - 4;
$button-xs-padding: 0 15.5px;
$button-xs-border-radius: 10px;

$button-sm-height: 25px;
$button-sm-font-size: $font-size-base - 4;
$button-sm-padding: 0 19.5px;
$button-sm-border-radius: 13px;

$button-md-height: 32px;
$button-md-font-size: $font-size-base - 2;
$button-md-padding: 0 23px;
$button-md-border-radius: 16px;

$button-lg-height: 42px;
$button-lg-font-size: $font-size-base + 1;
$button-lg-padding: 0 20.5px;
$button-lg-border-radius: 10px;
