/* sass-lint:disable class-name-format */

.moshi-multiple-select-container {
  &.is-invalid {
    .moshi-multiple-select__control {
      border-color: $error-red;
      background-image: linear-gradient(
        to bottom,
        $white-100 20%,
        $disabled-danger 100%
      );
    }

    .moshi-multiple-select__indicator-separator {
      background-color: $error-red;
    }

    .moshi-multiple-select__indicator {
      color: $error-red;
    }
  }

  .moshi-multiple-select__indicator {
    color: $grey5;
  }

  .moshi-multiple-select__indicator-separator {
    background-color: $grey5;
  }

  .moshi-multiple-select__control {
    border-radius: $sm-border-radius-size;
    border: 1px solid $grey-400;
    box-shadow: none;

    &:hover {
      border-color: $grey-blue;
      background-color: $grey-blue;
    }
  }

  &:not(.is-invalid) {
    .moshi-multiple-select__control--is-focused {
      border-color: $ocean-blue !important;
    }
  }

  .moshi-multiple-select__control--is-disabled {
    background-color: $white-100;
    border-color: $white-100;

    .moshi-multiple-select__indicators {
      display: none;
    }

    .moshi-multiple-select__value-container {
      padding-left: 0;
    }

    .moshi-multiple-select__multi-value__remove {
      padding-left: 0;
      padding-right: 0;
      color: $default-primary !important;
    }
  }

  .moshi-multiple-select__option {
    border-bottom: 2px solid $grey8;
  }

  .moshi-multiple-select__multi-value {
    background-color: $default-primary;
    border-radius: 4px;

    .moshi-multiple-select__multi-value__remove {
      color: $white-100;

      &:hover {
        border-radius: 4px;
        background-color: $default-primary;
        color: $default-danger;
      }
    }

    .moshi-multiple-select__multi-value__label {
      color: $white-100;
    }
  }
}
