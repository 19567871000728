// sass-lint:disable class-name-format
@import "../../../../../styles/abstracts/variables";

%padded-content {
  padding-left: 6px;
}

.sidebarCollapsible {
  margin: 20px 0;
}

.sidebarCollapsibleTitle {
  @extend %padded-content;

  font-size: $font-size-lg;
  color: $black-1000;
  letter-spacing: 0.46px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    background-color: $white-300;
  }
}

.sidebarCollapsibleSubtitle {
  @extend %padded-content;

  font-style: italic;
  color: $grey-700;
  margin-top: 5px;
  margin-bottom: 6px;
}

.sidebarCollapsibleItem {
  @extend %padded-content;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  border-radius: 14px;

  &:hover {
    background-color: $white-300;

    i {
      visibility: visible;
    }
  }
}

.sidebarCollapsibleItemIcon {
  color: $grey-800;
  opacity: 0.8;
  visibility: hidden;
  margin-right: 9px;

  &:hover {
    cursor: pointer;
    color: $blue-700;
  }
}

.sidebarCollapsibleItemLabel {
  font-size: $font-size-base + 1;
  margin-bottom: 0;

  &:hover {
    color: $black-1000 !important;
  }
}
