@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.save-bar {
  @include common-box-shadow($pagination-box-shadow-color);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: $save-bar-min-height;
  background-color: $white-100;
  z-index: 999;

  .left-action-buttons {
    float: left;
    margin-top: 19px;
  }

  .action-buttons {
    float: right;
    margin-top: 19px;
  }
}
