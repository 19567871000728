@import "../../abstracts/variables";

.moshi-toggle-tabs {
  display: flex;
  overflow: hidden;

  .moshi-toggle-tabs-wrapper {
    display: flex;
    height: 40px;
    border-radius: 10px;
    border: solid 1px $grey-300;
    align-items: center;

    .moshi-toggle-tab {
      color: $black-900;
      font-weight: 400;
      font-size: $font-size-base;
      padding: 0 12px;
      transition: all 0.1s ease-in-out;
      height: 24px;
      margin: 0 8px;
      border-radius: 6px;
      display: flex;
      align-items: center;

      > span {
        line-height: 10px;
      }

      &.active {
        background-color: $default-primary;
        color: $white-100;
      }

      &.moshi-toggle-tab-icon {
        height: 30px;
        width: 30px;
        margin: 0 4px;
        text-align: center;
        padding: 0;

        > span {
          margin: auto;
        }
      }
    }
  }
}
