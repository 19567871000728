@import "../../abstracts/variables";

.overlay-content-container {
  .overlay-content {
    .overlay-left-side-container {
      .event-form-left-side-container {
        max-width: 660px;
        margin: auto;
        padding-bottom: 40px;

        .event-form-container {
          .right-side {
            flex: 1;

            .staff-dropdown-container {
              margin-bottom: 15px;

              .form-group {
                flex: 1;
                margin-bottom: 0;
              }

              i {
                align-self: center;
                margin-left: 7px;
              }
            }
          }

          .left-side {
            margin-bottom: 15px;

            .add-staff-button {
              margin-right: 15px;
            }
          }

          .event-custom-reminder-fields {
            .grey-label {
              margin-right: 10px;
            }

            .moshi-button {
              margin-left: 0;
            }

            .event-custom-reminder-row-container {
              display: flex;

              .reminder-icon {
                @include common-border-radius;
                background-color: $pastel-blue-100;
                width: 40px;
                height: 40px;
                padding: 5px;
                text-align: center;
                line-height: 33px;
                margin-right: 5px;
              }

              .icon-close {
                padding: 13px 5px;

                &:hover {
                  cursor: pointer;
                }
              }

              .num-before-input,
              .time-unit-select {
                flex-grow: 1;
              }

              .before-at {
                padding-top: 10px;
                margin: 0 15px;
              }
            }
          }
        }

        .event-types-container {
          .radio-as-button {
            margin-top: 0;
          }
        }

        .event-date-and-time-text {
          font-size: $font-size-title2;
          display: inline-block;
        }

        .moshi-tag {
          margin-top: 7px;
          margin-left: 15px;
        }

        .event-date-input,
        .event-from-time-dropdown {
          max-width: 160px;
        }

        .event-categories-dropdown {
          flex: 1;
        }

        .event-duration-dropdown {
          width: 125px;
        }

        .doctor-field,
        .category-field,
        .priority-field {
          margin-left: 125px;
        }

        .priority-field {
          .radio {
            margin-top: 0;
          }
        }
      }

      .category-and-doctor-container {
        .category-indicator {
          margin-right: 5px;
        }

        .duration-with-doctor {
          color: $light-font-color;
        }
      }

      hr {
        margin-top: 10px;
        margin-bottom: 20px;
      }

      .event-details-group {
        .detail-title {
          color: $light-font-color;
          font-size: $font-size-sm;
        }

        .column-cell {
          padding-left: 0;
        }

        .empty-notification-text {
          margin-bottom: 10px;
          display: block;
        }
      }
    }

    .overlay-right-side-container {
      &.event-right-side-container {
        min-width: 440px;

        .event-status-container {
          padding: 24px 15px;
          background-color: $pastel-blue-100;
          border-bottom: 1px solid $pale-lilac;

          .form-group {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.event-queue-overlay {
  z-index: 1000;
}

.event-status-tooltip {
  .tooltip-inner {
    min-width: 500px;
  }
}
