@import "../../abstracts/variables";
@import "../../abstracts/mixins";

$default-height: 20px;
$sm-height: 16px;

.moshi-tag {
  @include tag-base;

  &.moshi-tag-primary {
    @include moshi-tag-variant($default-primary);
  }

  &.moshi-tag-primary-outline {
    @include moshi-tag-variant($disabled-primary, $default-primary);
  }

  &.moshi-tag-secondary {
    @include moshi-tag-variant($light-font-color);
  }

  &.moshi-tag-secondary-outline {
    @include moshi-tag-variant($grey-400, $light-font-color);
  }

  &.moshi-tag-info {
    @include moshi-tag-variant($default-font-color);
  }

  &.moshi-tag-info-outline {
    @include moshi-tag-variant($white-300, $light-font-color);
  }

  &.moshi-tag-success {
    @include moshi-tag-variant($green-500, $white-100);
  }

  &.moshi-tag-success-outline {
    @include moshi-tag-variant($green-200, $green-800);
  }

  &.moshi-tag-warning {
    @include moshi-tag-variant($orange-400, $white-100);
  }

  &.moshi-tag-warning-outline {
    @include moshi-tag-variant($orange-200, $orange-700);
  }

  &.moshi-tag-default-warning {
    @include moshi-tag-variant($default-warning);
  }

  &.moshi-tag-default-danger {
    @include moshi-tag-variant($default-danger);
  }

  &.moshi-tag-danger {
    @include moshi-tag-variant($red-500, $white-100);
  }

  &.moshi-tag-danger-outline {
    @include moshi-tag-variant($red-200, $red-700);
  }

  &.moshi-tag-yellow {
    @include moshi-tag-variant($yellow-600, $white-100);
  }

  &.moshi-tag-yellow-outline {
    @include moshi-tag-variant($yellow-200, $yellow-800);
  }

  &.moshi-tag-white {
    @include moshi-tag-variant($white-100, $default-font-color);
    @include common-button-box-shadow;

    border: 1px solid $pale-lilac;
  }

  &.moshi-tag-purple {
    @include moshi-tag-variant($violet-600, $white-100);
  }

  &.moshi-tag-purple-outline {
    @include moshi-tag-variant($violet-200, $violet-700);
  }

  &.moshi-tag-link {
    @include moshi-tag-variant($white-100, $default-primary);
  }

  &.moshi-tag-link-outline {
    @include moshi-tag-variant($default-primary, $white-100);
  }
}
