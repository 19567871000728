@import "../../abstracts/variables";
@import "../../abstracts/placeholders";
@import "../../abstracts/mixins";

%quarter-width-widget-placeholder {
  min-width: 220px;
  max-width: 250px;
  margin-right: 20px;
  display: inline-flex;
}

.widget-group {
  .widget-group-title {
    margin-bottom: 10px;
    font-size: $font-size-title3;

    &::first-letter {
      text-transform: capitalize;
    }

    &.other-group-title {
      margin-bottom: 10px;
    }
  }

  .rjsf {
    &.card {
      @include hover {
        @include common-box-shadow($dropdown-shadow-color);

        &.widget {
          .edit-widget {
            display: block;
          }
        }
      }

      padding: 15px;
      min-height: 100px;

      .card-more-actions {
        position: absolute;
        top: 15px;
        right: 10px;
        width: 30px;
      }

      &.widget {
        max-width: 940px;

        &.form-disabled {
          .fields-wrapper {
            .custom-datepicker {
              height: 16px !important;
            }

            .toggle-switch {
              &.disabled {
                opacity: 1;

                .slider {
                  &:hover {
                    cursor: not-allowed !important;
                  }
                }
              }
            }
          }

          .errors {
            padding-right: 20px;
          }
        }

        &.highlight {
          animation: highlight-fade 1.5s ease-in-out 0s;
        }

        .widget-title-wrapper {
          margin: 0 0 3px;

          .widget-title {
            font-weight: 600;
            color: $grey5;
            margin-left: 0;
            letter-spacing: 0.46px;
          }
        }

        .react-markdown-renderer {
          margin-top: 20px;
        }

        .widget-created-at-label {
          position: absolute;
          left: 15px;
          bottom: 10px;
          font-size: $font-size-base - 5;
        }

        .widget-version-label {
          position: absolute;
          right: 20px;
          bottom: 10px;
          text-transform: lowercase;
          font-size: $font-size-base - 5;
        }

        fieldset {
          display: flex;
          flex-direction: column;
        }

        .fields-wrapper {
          .widget-value {
            display: inline-block;
            font-size: $font-size-title1;
            font-weight: 600;
            margin-right: 5px;

            .custom-numeric-label-widget {
              display: inline-block;
            }

            .measurement {
              margin-left: 5px;
            }

            .big-value {
              font-size: $font-size-title1;
            }
          }

          .measurement {
            font-size: $font-size-base;
            display: inline-block;
            font-weight: 600;
          }

          .custom-react-dropdown {
            height: auto;
          }

          .field-label {
            margin-bottom: 0;
          }

          .input-group {
            border-radius: 14px;
            border: 2px solid $pale-lilac;
            background-color: $reverse-background;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            height: 36px;

            input {
              border: 0;
              background-color: transparent;
              margin-top: -4px;

              &:nth-of-type(1n + 2) {
                height: 15px;
                border-left: 1px solid $pale-lilac;
                margin-left: 1px;
                padding-top: 0;
                padding-bottom: 0;
              }

              &:hover {
                background-color: transparent;
              }
            }

            .input-group-text {
              border: 0;
              font-size: 14px;
              text-align: right;
              color: $light-blue-grey;
              background-color: transparent;
              margin-top: -4px;
              margin-right: 10px;
            }

            .form-control {
              padding-left: 10px;
              padding-right: 10px;
            }
          }

          textarea {
            &.form-control {
              &:disabled {
                font-weight: normal !important;
              }
            }
          }

          .form-control {
            &:disabled {
              margin-top: 3px;

              &:not(textarea),
              &:not(.readonly-look) {
                @extend %edit-mode-toggler-disabled;
              }
            }
          }

          .read-only-widget {
            font-weight: 600;
          }

          .react-date-picker {
            width: 150px;
            height: 40px;

            &.react-date-picker--disabled {
              border: 0;
              padding: 0;

              /* sass-lint:disable class-name-format */
              .react-date-picker__wrapper {
                margin-top: 0;
              }
              /* sass-lint:enable class-name-format */

              input {
                color: $default-font-color;
              }

              &:hover {
                background-color: transparent;
              }
            }
          }
        }

        &.half-width-widget {
          min-width: 480px;
          max-width: 480px;
          margin-right: 20px;
          display: inline-flex;
        }

        &.quarter-width-widget {
          @extend %quarter-width-widget-placeholder;
        }

        .widget-icon-wrapper {
          display: inline-block;
          margin-left: -7px;
          margin-top: -8px;

          .widget-icon {
            @include text-and-icon-color($grey5, $grey5, $grey5);
            margin: 7px 5px 0 7px;
          }
        }

        &.vital-signs-widget {
          @extend %quarter-width-widget-placeholder;
          min-height: 136px;

          .fields-wrapper {
            white-space: nowrap;
          }

          .widget-title {
            margin-left: 0;
          }

          .input-group {
            input {
              &.form-control {
                &:disabled {
                  &:first-child {
                    max-width: 40%;
                    width: 40%;
                  }
                }
              }
            }

            .widget-value-splitter {
              font-size: 30px;
              height: 30px;
              padding: 0;
              margin: 0 0 0 -5px;
              line-height: 1;
            }
          }

          input {
            margin-top: 0;

            &.form-control {
              &:disabled {
                font-size: 36px;
                height: 36px;
                padding: 0;
                font-weight: 600;

                + .input-group-append {
                  span {
                    border: 0;
                    background: none;
                    color: $black-1000;
                  }
                }
              }
            }
          }
        }

        &.admin-data-widget {
          @extend %quarter-width-widget-placeholder;
        }

        &.problem-description-widget {
          padding: 10px 30px 20px 15px;

          div {
            &:first-of-type {
              line-height: 1;
            }
          }

          .widget-title-wrapper {
            text-transform: lowercase;

            &::first-letter {
              text-transform: capitalize;
            }

            .widget-title {
              @extend %header3;
              font-weight: 600;
              color: $light-black;
              letter-spacing: 0.5px;
            }
          }

          .widget-created-at-label {
            left: 15px;
          }
        }

        &.form-enabled {
          .widget-title-wrapper {
            margin-bottom: 5px;
          }

          &.vital-signs-widget {
            padding-top: 17px;
            padding-bottom: 18px;
          }

          .edit-button-toggle {
            margin-top: 12px;
            justify-content: space-between;

            .btn {
              margin: 0;
              height: 30px;
              line-height: 14px;

              &:first-of-type {
                margin-right: 20px;
              }
            }
          }

          &.problem-description-widget {
            .files-dropzone,
            .files-item {
              margin-top: 10px;
            }

            .widget-title-wrapper {
              margin-bottom: 31px;
            }

            .edit-button-toggle {
              justify-content: flex-end;
              margin-top: 14px;

              .btn {
                margin-left: 20px;
              }
            }
          }
        }

        &.draft-widget {
          border: 1px solid $red-600;
          background: $red-100;
        }

        &.multi-index-widget {
          .widget-title-wrapper {
            padding-bottom: 10px;
          }

          .widget-title {
            @extend %header3;
            font-weight: 600;
            color: $light-black;
            text-transform: none;
          }

          .fields-wrapper {
            display: grid;
            grid-template-columns: minmax(240px, 2fr) 5fr;
            margin-top: 4px;
            margin-bottom: 10px;

            & > :first-child {
              grid-row: 1 / span 10000;
            }
          }
        }
      }
    }
  }
}

.add-questionnaire-widget-modal {
  min-width: 700px;

  .widget-title-wrapper {
    margin: 0 0 3px;

    .widget-title {
      font-weight: 600;
      color: $grey5;
      margin-left: 0;
      letter-spacing: 0.46px;
    }
  }

  .modal-footer {
    padding: 15px 0 0;
  }
}

.field-radio-group {
  padding-bottom: 10px;
}

.custom-numeric-label-widget {
  padding: 20px 25px;

  &.widget-label {
    &:not(.widget-label-no-style) {
      display: flex;
      align-items: center;
      min-height: 54px;
      font-size: $font-size-xl;
      font-weight: 600;
      line-height: 1;
      white-space: normal;
      overflow-wrap: anywhere;
    }

    &.include-value {
      flex-direction: column;
      justify-content: center;
      align-items: start;
      font-size: $font-size-title4;

      .value {
        color: $black-1000;
        font-size: $font-size-base * 2;
        margin-bottom: 4px;
      }
    }

    &.colored-background {
      border-radius: $base-border-radius-size;
      box-shadow: 0 0 4px 0 $violet-100;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: calc(100% - 15px);
      margin-right: 20px;
      color: $white-100 !important;
      flex-direction: column-reverse;

      .value {
        margin: 0;
        margin-top: 6px;
        color: $white-100 !important;
      }

      &.color-success {
        background: $default-success;
      }

      &.color-warning {
        background: $default-warning;
      }

      &.color-danger {
        background: $default-danger;
      }

      &.color-default {
        background: $black-900;
      }
    }
  }
}

.widget-icon-label {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 6px;
  padding: 0;
  line-height: 1;
}
