@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.alert {
  font-size: $font-size-base;
  margin: 10px 5px 20px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  color: $white-100;
  min-height: 40px;
  transition: height 0.1s, margin 0.1s, padding 0.1s;
  transition-timing-function: ease-in-out;
  border-radius: 10px;
  border: 0;
  height: auto;

  .close {
    padding: 0 1.25rem 0 0;
    margin-top: 5px;

    &:hover {
      color: $white-100;
    }
  }

  &.alert-primary {
    background-color: $blue-200;
    color: $default-primary;
  }

  &.alert-success {
    background-color: $green-200;
    color: $default-success;
  }

  &.alert-warning {
    background-color: $orange-200;
    color: $default-warning;
  }

  &.alert-danger {
    background-color: $red-200;
    color: $default-danger;
  }
}
