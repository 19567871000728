@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.circular-badge {
  width: 40px;
  height: 40px;
  background-color: $reverse-background;
  color: $light-blue-grey;
  border: 2px solid $pale-lilac;
  font-size: $font-size-base;
  line-height: 1.79;
  text-align: center;
  font-weight: 600;
  border-radius: 50%;
  display: inline-block;

  .initials-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.badge {
  height: 16px;
  font-size: $font-size-base - 6;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  letter-spacing: 0.5px;
  line-height: 1.7;
  color: $white-100;
  font-weight: 700;

  &.badge-primary {
    background-color: $default-primary;
  }

  &.badge-danger {
    background-color: $error-red;
  }

  &.badge-warning {
    background-color: $default-warning;
  }

  &.badge-success {
    background-color: $success-green;
  }
}
