@import "../../../abstracts/variables";
@import "../../../abstracts/mixins";
@import "../daypicker";

.moshi-date-range-popup-calendar {
  @include alternative-box-shadow($box-shadow-color-light);
  @include common-border-radius;
  background: $white-100;
  border: 1px solid $pastel-blue-100;
  position: absolute;
  z-index: 1;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 140px;
  margin-top: 8px;

  .react-calendar {
    @include react-calendar-overrides;
    background: none;
    border: 0;
    padding: 0 25px 5px;
    width: 330px;
    align-self: center;
    flex: 1;

    /* sass-lint:disable class-name-format */
    .react-calendar__navigation {
      margin: 20px 0;
      align-items: center;
      height: 22px;

      button {
        align-self: center;
      }

      .react-calendar__navigation__label {
        font-family: $font-family-ibm-plex-sans;
        font-weight: 500;
        font-size: $font-size-lg;
      }
    }

    .react-calendar__month-view {
      .react-calendar__month-view__weekdays__weekday {
        color: $black-1000;
      }

      .react-calendar__month-view__days__day {
        background: none;
        font-size: $font-size-base;
        font-family: $font-family-ibm-plex-sans;
        font-weight: 500;
        color: $black-1000;
        max-width: 40px;
        height: 40px;
        border-width: 5px;
      }

      .moshi-daypicker-day {
        &.react-calendar__month-view__days__day--weekend {
          background: none;
          color: $black-1000;
        }

        &.react-calendar__month-view__days__day--neighboringMonth {
          background: none;
          color: $grey-500;
        }

        &.react-calendar__tile--now {
          background: $blue-700 !important;
          color: $white-400 !important;
        }
      }
    }

    .react-calendar__year-view__months__month,
    .react-calendar__decade-view__years__year,
    .react-calendar__century-view__decades__decade {
      &.moshi-daypicker-day,
      &.moshi-daypicker-day,
      &.moshi-daypicker-day {
        font-size: $font-size-base;
        padding: 20px 10px;
      }
    }

    .moshi-daypicker-day {
      &.react-calendar__tile--active {
        background: $light-blue-200 !important;
        color: $black-1000 !important;
      }
    }
  }

  .moshi-date-range-calendar-buttons {
    background: transparentize($pastel-blue-100, 0.5);
    border-top: 1px solid $violet-200;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: center;
    grid-column: 1 / -1;

    .moshi-button {
      margin: 0;
    }

    .react-date-picker {
      width: auto;
      height: auto;
    }

    .separator {
      align-self: center;
    }
  }

  .date-range-calendar-shortcuts {
    border-left: 1px solid $violet-200;
    display: grid;
    align-content: flex-start;
    grid-gap: 10px;
  }
}
