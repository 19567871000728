@import "../../abstracts/variables";

.wrapper-aside-banner {
  background-color: $pastel-blue-100;
  flex: 1;
}

.container-aside-banner {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-top: 20px;
  height: 100%;
  overflow-y: auto;
  max-width: 1680px;
  margin: 0 auto;

  .main-aside {
    flex-grow: 1;
    flex-direction: column;
    margin: 0 20px;

    @media (min-width: 768px) {
      margin: 40px 100px 0;
      max-width: 400px;
    }

    .logo {
      max-width: 250px;

      img {
        display: block;
        max-width: 100%;
      }
    }

    .form-main-aside {
      max-width: 340px;

      .btn {
        margin: 0;
      }

      .form-group {
        padding: 0;
        margin-bottom: 25px;

        &:last-of-type {
          margin-bottom: 54px;
        }

        input {
          background-color: $white-100;

          &::placeholder {
            color: $grey5;
          }

          &:hover {
            background-color: $white-100 !important;
            border-color: $pale-lilac !important;
          }
        }
      }
    }

    .greeting-main-aside {
      margin-top: 89px;
      margin-bottom: 66px;
      width: 100%;
      text-align: left;

      h2 {
        margin-bottom: 15px;
        height: auto;
        padding-bottom: 0;
        letter-spacing: normal;
      }

      span {
        &.title {
          font-size: $font-size-base * 2;
          font-weight: 600;
        }

        &.text {
          font-size: $font-size-base + 1;
        }
      }
    }

    .footer-main-aside {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin: 50px 0 20px;

      .footer-link {
        margin-bottom: 10px;

        span {
          margin-right: 10px;
        }
      }

      a {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .banner-aside {
    display: none;
    align-items: center;

    @media (min-width: 1000px) {
      display: flex;
      flex-grow: 1;
      align-items: stretch;
      justify-content: stretch;
      background: url("/img/login/login-aside.svg") no-repeat 50% 5%;
      background-clip: border-box;
      background-size: contain;
      height: 60%;
      margin-top: 40px;
      margin-right: 100px;
      max-height: 75%;
    }
  }
}
