@import "../../abstracts/variables";
@import "../../abstracts/placeholders";
@import "../../abstracts/mixins";

$time-picker-max-width: 115px;

.moshi-timepicker {
  max-width: $time-picker-max-width;

  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  .rc-time-picker-input {
    @extend %form-control-base;
    @include common-border-radius;

    background-color: $reverse-background;
    position: relative;
    height: 40px;
    border-width: 2px;
    border-color: $pale-lilac;
    padding: 11px 15px 10px;

    &:focus {
      outline: none;
    }

    &:disabled {
      @include common-border-radius-important;
      cursor: not-allowed;
    }
  }
}

.moshi-timepicker-popup {
  .rc-time-picker-panel-inner {
    @include common-border-radius;
    overflow: hidden;

    border: 2px solid $main-border;
    box-shadow: none;

    .rc-time-picker-panel-input-wrap {
      background-color: $reverse-background;
      padding-left: 14px;
      padding-top: 10px;

      input {
        background-color: $reverse-background;
        padding: 0;
        font-size: 14px;
        width: 100%;
      }
    }
  }

  &.rc-time-picker-panel-narrow {
    max-width: $time-picker-max-width;
  }
}
