@import "../abstracts/variables";
@import "../abstracts/mixins";

.font-color-primary {
  @include text-and-icon-color($default-primary);
}

.font-color-success {
  @include text-and-icon-color($default-success);
}

.font-color-warning {
  @include text-and-icon-color($default-warning);
}

.font-color-danger {
  @include text-and-icon-color($default-danger);
}

.font-color-default {
  @include text-and-icon-color($black-900);
}

.font-color-yellow {
  @include text-and-icon-color($default-yellow);
}

.font-color-purple {
  @include text-and-icon-color($default-purple);
}

.font-color-grey {
  @include text-and-icon-color($grey-600);
}

// LEGACY
.text-color-blue {
  @include text-and-icon-color($default-primary, $medium-blue, $dark-blue);
}

.text-color-red {
  @include text-and-icon-color($error-red, $medium-red, $dark-red);
}

.text-color-green {
  @include text-and-icon-color($success-green, $medium-green, $dark-green);
}

.text-color-orange {
  @include text-and-icon-color($warning-orange, $medium-orange, $dark-orange);
}

.text-color-grey {
  @include text-and-icon-color($grey5, $grey5, $grey5);
}

.text-color-black {
  @include text-and-icon-color($black-1000, $black-1000, $black-1000);
}

.text-color-black-blue {
  @include text-and-icon-color($black-1000, $default-primary, $default-primary);
}
