@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.moshi-table {
  position: relative;
  padding: 10px 0;

  .table-header,
  .table-row {
    display: grid;
    width: auto;

    > div {
      &:first-child {
        padding-left: 15px;
      }

      &:last-child {
        padding-right: 15px;
      }
    }
  }

  .table-row {
    > div {
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
    }

    &:hover {
      > div {
        border-top: 1px solid $grey-110;
        border-bottom: 1px solid $grey-110;
        background-color: $table-hover-color;
      }
    }

    &.highlight {
      animation: highlight-fade-orange-to-white 5s ease-in-out 0s;
    }

    &.edit-row {
      position: relative;
      display: inherit;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $pale-lilac;
    }

    .card-more-actions {
      position: initial;
      display: flex;
      justify-content: center;
      align-items: center;

      .row-action-btn {
        @extend %cursor-pointer;
        display: inline-block;
        height: inherit;
        min-width: auto;
        margin-right: 5px;
        font-size: $font-size-title4;
        text-decoration: none;
        padding: 0 5px;
        min-height: auto;
        outline: none;

        &:hover {
          text-decoration: none;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .more-toggle {
        &:hover {
          .card-more-actions-icon {
            cursor: pointer;
            border-radius: 50%;
            color: $blue-700;
            background-color: $blue-200;
          }
        }
      }

      .card-more-actions-icon {
        color: $dark-blue-font-color;
        padding: 2px;
      }
    }
  }

  .table-header {
    text-align: left;
    font-size: $font-size-sm;
    border-top: 1px solid $grey-110;
    border-bottom: 1px solid $grey-110;

    .header-cell {
      display: flex;
      align-content: center;
      align-items: center;
      color: $light-font-color;
      cursor: default;

      &.sortable {
        &:hover {
          color: $default-primary;
          background-color: $header-hover-bg-color;
        }
      }

      label {
        font-size: $font-size-base;
        font-weight: 600;
        color: $grey-900;
        margin-bottom: 0;
        width: auto;
      }

      .sortable-icon {
        display: inline-flex;
        flex-direction: column;
        width: 13px;
        margin-left: 10px;

        .chevron {
          &.up {
            @include chevron-variant($grey-710, "bottom", 4px, 2px);

            &.active {
              &::before {
                border-bottom-color: $default-primary;
              }
            }
          }

          &.down {
            @include chevron-variant($grey-710, "top", 4px, 2px);

            &.active {
              &::before {
                border-top-color: $default-primary;
              }
            }
          }
        }
      }
    }
  }

  .header-cell,
  .column-cell {
    padding: 15px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none;

    &.align-right {
      justify-content: flex-end;
      text-align: right;
    }

    &.align-center {
      justify-content: center;
      text-align: center;
    }

    .cell-wrapper {
      color: $default-font-color;
    }

    &.selectable-row-field {
      .checkbox {
        margin: 0;
        padding: 0;
      }
    }

    &.moshi-table-column-container {
      padding-left: 3px;
    }
  }

  .moshi-table-link {
    color: $default-primary;
  }

  .moshi-table-default-link {
    font-weight: 400;
    color: $black-900;

    &:hover {
      color: $medium-blue;
    }
  }

  .moshi-table-date {
    color: $lighter-font-color;
  }

  .bulk-actions-panel {
    position: absolute;
    top: 0;
    z-index: 1;
    background-color: $light-primary;
    width: 100%;
    height: 50px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;

    .checkbox-wrapper {
      width: 150px;

      .checkbox {
        margin-bottom: 0;
        margin-top: 0;

        .checkbox-label {
          color: $blue-700;
        }
      }
    }
  }

  .header-cell,
  .table-row,
  .bulk-actions-panel {
    .checkbox {
      input {
        ~ .checkbox-icon {
          background-color: $white;
          border: 2px solid $grey-110;
        }

        &:checked {
          ~ .checkbox-icon {
            background-color: $default-primary;
            border: 0;
          }
        }
      }
    }
  }

  &:not(.no-header) {
    .table-row {
      &:first-child {
        &:hover {
          .column-cell,
          .card-more-actions {
            border-top-color: transparent;
          }
        }
      }
    }
  }

  &:not(.no-pagination) {
    .table-row {
      &:last-child {
        &:hover {
          .column-cell,
          .card-more-actions {
            border-bottom-color: transparent;
          }
        }
      }
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  position: relative;
  user-select: none;
  padding: 5px 0;
  border-top: 1px solid $grey-110;
  background-color: $white-100;
  height: 49px;

  .navigation-page-number {
    padding-left: 24px;
    align-self: center;
    position: absolute;
    left: 0;
  }

  .navigation-button {
    display: flex;

    .pagination-icon {
      padding: 8px;
      border: 1px solid $grey-110;
      border-radius: 50%;
      font-size: 20px;
      color: $black-1000;
      opacity: 0.9;
      margin: 0 5px;

      &.disabled {
        opacity: 0.7;
        pointer-events: none;
      }

      &:hover {
        cursor: not-allowed;

        &:not(:disabled) {
          background-color: $white-300;
        }
      }
    }
  }

  .pagination-list {
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;

    .pagination-page-placeholder {
      color: $grey-800;
      font-size: 16px;
    }

    .pagination-page {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 0 8px;
      min-width: 27px;
      height: 27px;
      font-size: $font-size-sm;
      border-radius: 14px;

      &.active {
        span {
          color: $blue-700;
        }
      }

      &:hover {
        background-color: $white-300;
      }

      span {
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 5px;
        color: $grey-800;
        outline: none !important;
      }
    }
  }
}

.moshi-table-column-picker {
  .grey-label {
    text-transform: capitalize;
    color: $grey-900;
  }

  &.moshi-popover {
    .popover {
      min-width: unset;

      .popover-body {
        padding-top: 5px !important;
        padding-bottom: 0 !important;
      }
    }
  }
}

.moshi-table-minimal {
  padding: 0;

  .column-cell {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.moshi-table-no-padding {
  > div {
    > div {
      &:first-child {
        padding-left: 0 !important;
      }

      &:last-child {
        padding-right: 0 !important;
      }
    }
  }
}

.common-table-header-padding {
  padding: 10px 12px;
}

.card {
  &.table-card {
    padding: 0;
    margin-bottom: 0;

    .header-cell {
      height: 48px;
    }

    .table-body {
      overflow-y: auto;
      max-height: 100%;
    }

    &:not(.table-only) {
      .bulk-actions-panel {
        top: 10px;
      }
    }

    &.table-only {
      padding: 0;

      &.scrollable-table {
        .bulk-actions-panel {
          border-top-right-radius: 0;
          top: 0;
        }
      }

      .table-header {
        .header-cell {
          &:first-child {
            border-top-left-radius: $base-border-radius-size;
          }

          &:last-child {
            border-top-right-radius: $base-border-radius-size;
          }
        }
      }

      .moshi-table {
        padding-top: 0;
        border-top-left-radius: $base-border-radius-size;
        border-top-right-radius: $base-border-radius-size;

        .bulk-actions-panel {
          border-top-left-radius: $base-border-radius-size;
          border-top-right-radius: $base-border-radius-size;
        }
      }

      :not(.pagination-container) {
        &.moshi-table {
          padding-bottom: 0;
        }
      }
    }

    .pagination-container {
      border-bottom-left-radius: $base-border-radius-size;
      border-bottom-right-radius: $base-border-radius-size;
    }

    .moshi-table {
      overflow: hidden;
      display: flex;
      flex-flow: column;
      height: 100%;

      &:not(.no-pagination) {
        padding-bottom: 0;
      }

      &.no-pagination {
        padding-bottom: 0;

        .table-row {
          &:last-child {
            &:hover {
              .column-cell,
              .card-more-actions {
                border-bottom-color: transparent;
              }

              :first-child {
                &.column-cell {
                  border-bottom-left-radius: $base-border-radius-size;
                }
              }

              :last-child {
                &.column-cell,
                &.card-more-actions {
                  border-bottom-right-radius: $base-border-radius-size;
                }
              }
            }
          }
        }
      }
    }
  }
}
