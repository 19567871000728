.form-builder {
  .react-date-picker {
    border-width: 0;
  }

  .metadata {
    margin-bottom: 25px;

    label {
      font-size: $font-size-base;
      font-weight: 500;
      text-transform: none;
    }
  }

  .navigation {
    :first-child {
      margin-right: 10px;
    }

    :not(.active) {
      color: $black-800;
    }
  }

  .card {
    background-color: $secondary-color;
    padding: 15px;
    border: 2px solid transparent;
    box-shadow: none;

    .card {
      @include common-box-shadow($card-shadow-color);
      background-color: $white-100;
    }

    .title {
      .field-type-label {
        &.form-group-label {
          margin-bottom: 9px;
        }
      }
    }

    .field-title-row {
      height: 40px;
      margin-bottom: 5px;
    }

    .field-type {
      display: flex;
      flex-direction: row;
      align-items: center;

      label {
        white-space: nowrap;
      }
    }

    .field-type-label {
      &.form-group-label {
        margin: 0;
        font-size: $font-size-base;
        height: $font-size-base;
        font-weight: 600;
        color: $black-1000;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    &.field-group {
      margin-top: 20px;
    }

    &.highlight {
      animation: highlight-fade 1.5s ease-in-out 0s;
    }

    &.highlight-odd {
      animation: highlight-fade-odd 1.5s ease-in-out 0s;
    }

    input {
      font-weight: 500;

      &.title-input {
        padding: 20px;
        height: 60px;
        font-size: $font-size-title3;
        font-weight: 600;
      }
    }

    .questionnaire-list-actions {
      padding-left: 20px;
      align-items: center;

      .btn-link,
      .btn-link-danger {
        padding: 0 10px;
        font-size: 12px;
        min-width: 70px;
        width: auto;
      }

      svg {
        &.move-down {
          margin-left: -10px;
        }

        &.remove {
          margin-left: 20px;
        }
      }

      .checkbox {
        input {
          ~ .checkbox-icon {
            background-color: $white;
            border: 1px solid $grey-700;
          }

          &:checked {
            ~ .checkbox-icon {
              background-color: $default-primary;
              border: 0;
            }
          }
        }
      }
    }

    .disabled {
      color: lighten($black-1000, 73) !important;
      background-color: transparent !important;
      border-color: $pale-lilac !important;
    }

    > div {
      &:not(.field-title-row) {
        .btn {
          &.btn-link {
            text-transform: none;
          }
        }
      }
    }

    .option {
      margin: 10px 0;

      .questionnaire-list-actions {
        padding-left: 0;
      }

      .option-icon {
        height: 20px;
        width: 20px;
        border: 2px solid $grey-400;
        background-color: $grey-blue;

        &.circle {
          border-radius: 26px;
        }
      }

      .conditional {
        margin: 10px 0;

        .disabled {
          margin-top: 0;
        }
      }
    }

    .option-number {
      text-align: center;
      line-height: 1.79;
      padding-top: 7px;
    }
  }
}
