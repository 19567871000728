// TODO: Move bootstrap after var import
// sass-lint:disable clean-import-paths
@import "~bootstrap/scss/bootstrap.scss";
@import "~axios-progress-bar/dist/nprogress.css";

/* BASE */
@import "base/fonts";
@import "base/icons";
@import "base/document";
@import "base/base";
@import "base/text-helpers";
@import "base/icon-helpers";

/* ABSTRACTS */
@import "abstracts/variables";
@import "abstracts/placeholders";
@import "abstracts/mixins";
@import "abstracts/input-sugars";

/* COMPONENTS */
@import "partials/components/date-range/index";
@import "partials/components/background";
@import "partials/components/form";
@import "partials/components/card";
@import "partials/components/container";
@import "partials/components/geo-suggest";
@import "partials/components/upload-file";
@import "partials/components/avatar";
@import "partials/components/dropdown";
@import "partials/components/multiple-select";
@import "partials/components/modal";
@import "partials/components/button";
@import "partials/components/badge";
@import "partials/components/alert";
@import "partials/components/toggle-switch";
@import "partials/components/tabs";
@import "partials/components/tooltip";
@import "partials/components/map";
@import "partials/components/table";
@import "partials/components/moshi-table";
@import "partials/components/image";
@import "partials/components/patient-info-mini-card";
@import "partials/components/spinner";
@import "partials/components/card-with-map";
@import "partials/components/auto-suggest";
@import "partials/components/popover";
@import "partials/components/datepicker";
@import "partials/components/files";
@import "partials/components/progress";
@import "partials/components/notification-card";
@import "partials/components/account-logo";
@import "partials/components/signature";
@import "partials/components/rtf-ckeditor";
@import "partials/components/color-picker";
@import "partials/components/full-calendar";
@import "partials/components/daypicker";
@import "partials/components/circular-progress-bar";
@import "partials/components/dental-chart";
@import "partials/components/moshi-tag";
@import "partials/components/moshi-timepicker";
@import "partials/components/patient-event-card";
@import "partials/components/sidepanel";
@import "partials/components/toastify";
@import "partials/components/save-bar";
@import "partials/components/patient-documents";
@import "partials/components/tile-layout";
@import "partials/components/rjsf";
@import "partials/components/feedback-form";
@import "partials/components/overlay";
@import "partials/components/calendar-event";
@import "partials/components/moshi-expand-list-panel";
@import "partials/components/tile";
@import "partials/components/calendar-waiting-list-card";
@import "partials/components/moshi-item-finder";
@import "partials/components/phone-number";
@import "partials/components/patient-appointment-event-card";
@import "partials/components/moshi-toggle-tabs";
@import "partials/components/calendar-waiting-list-overlay";
@import "partials/components/custom-widget-history-modal";
@import "partials/components/select-patient-card";
@import "partials/components/merge-patients-overlay";
@import "partials/components/select-patient-card";
@import "partials/components/merge-patients-overlay";
@import "partials/components/bill-type-icon";
@import "partials/components/radio";
@import "partials/components/draggable-list";
@import "partials/components/remove-icon-button";
@import "partials/components/event-autosuggest-card";
@import "partials/components/charts";
@import "partials/components/sms-modal";
@import "partials/components/event-popover";
@import "partials/components/rs-dropdown";
@import "partials/components/rs-event-status-dropdown";
@import "partials/components/calendar-search-overlay";
@import "partials/components/magic-bell";
@import "partials/components/keyframes";
@import "partials/components/checkbox";
@import "partials/components/patient-notes";
@import "../containers/patient/detail/anamnesis/sidepanel/SidebarCollapsible.module";
@import "partials/components/form-builder";
@import "partials/components/external-document";
@import "partials/components/rtf-decoupled-ckeditor";
@import "partials/components/tags";

/* PAGES */
@import "partials/pages/aside-banner";
@import "partials/pages/version";
@import "partials/pages/sidebar";
@import "partials/pages/logo";
@import "partials/pages/user";
@import "partials/pages/devices";
@import "partials/pages/employee";
@import "partials/pages/patient";
@import "partials/pages/medical-record";
@import "partials/pages/waiting-list";
@import "partials/pages/document";
@import "partials/pages/flow";
@import "partials/pages/encounter-widgets";
@import "partials/pages/automated-check-in";
@import "partials/pages/user-profile";
@import "partials/pages/edit-documents-and-questionnaires";
@import "partials/pages/calendar";
@import "partials/pages/billing";
@import "partials/pages/therapy";
@import "partials/pages/unbilled-items";
@import "partials/pages/analytics";
@import "partials/pages/billing-item";
@import "partials/pages/calendar-settings";
@import "partials/pages/document-inbound";
@import "partials/pages/document-outbound";

// KEYFRAMES
@include highlight-fade("highlight-fade");
@include highlight-fade("highlight-fade-odd");
@include highlight-fade-generic(
  "highlight-fade-orange-to-white",
  $orange-100,
  $white
);
@include highlight-fade-generic(
  "highlight-fade-blue-to-transparent",
  $default-primary,
  transparent
);

// MARGINS
@include margin-x(20);
@include margin-x-y("top", 90);
@include margin-x-y("right", 40);
@include margin-x-y("bottom", 90);
@include margin-x-y("left", 50);

// PADDINGS
@include padding-x(20);
@include padding-x-y("top", 50);
@include padding-x-y("right", 65);
@include padding-x-y("bottom", 40);
@include padding-x-y("left", 50);

// RELATIVE POSITIONING
@include position-x-y("top", 10);
@include position-x-y("right", 10);
@include position-x-y("bottom", 10);
@include position-x-y("left", 10);

// CATEGORY COLOR
@include category-color;
