@import "../../abstracts/variables";

.upload-file-form {
  margin-left: 20px;
  display: flex;
  flex-direction: column;

  .btn {
    margin-bottom: 10px;
  }
}
