@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.analytics {
  .filters-container {
    display: flex;
    align-items: flex-start;
  }

  .select-location {
    min-width: 200px;
  }

  .min-height-400 {
    min-height: 400px;
  }

  .date-container {
    @include common-border-radius;
    border: 0;
    height: 40px;
    margin-right: 10px;
    padding: 6px 12px;
    background-color: $white-300;
    display: flex;
    align-items: center;

    &:hover,
    &:active {
      background-color: $white-100;
      box-shadow: $header-actions-shadow;
    }

    &.danger {
      background-color: $red-300 !important;
      box-shadow: $header-actions-shadow;
    }

    .date-label {
      display: block;
      text-transform: uppercase;
      font-size: $font-size-tiny;
      font-weight: 400;
      color: $grey5;
    }

    .date-value {
      align-items: center;
      display: block;
      font-size: $font-size-base;
      font-weight: 400;

      .date-range {
        display: flex;

        .range-separator {
          margin: 0 3px;
        }
      }

      .date-icon {
        align-self: center;

        &:hover {
          color: $active-position-stroke;
        }
      }
    }

    .invalid-date-range {
      display: inline;
      font-size: $font-size-md;
      font-weight: 400;
      color: $red-500;
    }

    .custom-daypicker {
      margin: 0;
    }

    .date-pick {
      padding: 0;
      height: 20px;
      font-size: $font-size-base;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      cursor: pointer;

      input {
        cursor: pointer;

        &:hover {
          color: $active-position-stroke;
        }
      }

      :first-child {
        :first-child {
          min-width: 0;
        }
      }
    }
  }

  .analytics-chart-container {
    @include common-border-radius;
    padding-top: 20px;
    background-color: $pastel-blue-100;
    height: calc(100% - 20px);
    max-height: 94vh;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
  }

  .table-header {
    label {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .card-height-container {
    height: 100%;
    padding-bottom: 20px;
  }

  .card-height-100 {
    height: 100%;
  }

  .dashboard-date-picker {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .date-text {
      display: flex;
      flex-direction: row;
      align-items: baseline;
    }

    .today {
      &:hover {
        cursor: pointer;
        color: $active-position-stroke;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
