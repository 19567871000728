.table {
  .column-cell {
    .first-last-name-cell {
      > div {
        padding-right: 0;

        &:last-child {
          padding-left: 10px;
        }
      }
    }
  }
}
