@import "../../abstracts/variables";
@import "../../abstracts/placeholders";
@import "../../abstracts/mixins";

.modal {
  .remove-patient-modal,
  .close-encounter-modal,
  .close-encounter-success-modal {
    .modal-content {
      padding: 31px 60px 30px;
      margin: auto;
      max-width: 580px;

      .modal-body {
        &.remove-patient-body {
          padding-top: 0;

          .patient-info-mini-card {
            margin-bottom: 37px;
            padding-bottom: 31px;
            border-bottom: 1px solid $modal-footer-border;
          }

          .doctor-location-time {
            margin-top: 17px;
          }

          .reason-section {
            padding-top: 44px;
            color: $grey5;
          }
        }

        .dropdown {
          button {
            width: 100%;
          }
        }

        &.close-encounter-body {
          padding-bottom: 0;

          .doctor-location-time {
            margin-top: 17px;
            margin-bottom: 44px;
          }

          .radio {
            background: 0;
            border: 0;
          }

          hr {
            margin-top: 34px;
            margin-bottom: 30px;
          }

          .appointment-needed {
            .form-group {
              flex-direction: row;

              .form-group-label {
                flex-basis: 50%;
                color: $black-1000;
                text-transform: none;
                font-weight: 400;
                font-size: 14px;
              }

              > div {
                flex-basis: 50%;

                label {
                  .radio-label {
                    color: $black-1000;
                    text-transform: none;
                    font-weight: 400;
                    font-size: 14px;
                  }

                  &:first-of-type {
                    margin-top: 0;
                  }
                }
              }
            }
          }

          .memo-for-next-appointment {
            &.disabled {
              opacity: 0.2;

              .form-group {
                margin-bottom: 0;

                textarea {
                  border-radius: 14px !important;
                  height: 80px;
                  min-height: 80px;
                  border-width: 2px;
                  border-color: $pale-lilac;
                  background-color: $reverse-background;
                }
              }
            }
          }
        }

        &.close-encounter-success-body {
          margin-top: 20px;

          .patient-info-mini-card {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .close-success-title {
            text-align: center;
            margin-top: 60px;
            margin-bottom: 60px;
          }

          .card {
            padding: 36px 30px 20px;
            background-color: $reverse-background;
            border: 0;
            box-shadow: none;

            h3 {
              margin: 0;
            }

            label {
              @extend %form-group-label;
              margin-top: 29px;
              margin-bottom: 0;
            }

            span {
              white-space: pre;
            }

            .doctor-location-time {
              margin-top: 17px;
            }
          }
        }
      }

      .remove-patient-footer {
        border-top-width: 1px;
      }
    }
  }

  .close-encounter-success-modal {
    .modal-content {
      .modal-footer {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        padding-top: 0;
        border: 0;

        .btn {
          margin: 5px 0;
        }
      }
    }
  }

  .add-patient-modal {
    .card {
      &:not(.notification-card) {
        border: 0;
        padding: 0;
        box-shadow: none;
      }
    }
  }

  .add-patient-preview-modal {
    .user-name {
      margin-top: 10px;
      color: $light-black;
      font-size: $font-size-lg;
      font-weight: 600;
    }

    .card {
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 36px;
      background-color: $reverse-background;
      border: 0;
      box-shadow: none;
      max-width: 520px;
      margin: 60px auto auto;

      .card-title {
        padding-left: 30px;
        margin-bottom: 24px;

        h3 {
          margin-bottom: 17px;
        }
      }

      .card-body {
        padding-left: 30px;

        .form-group {
          label {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.patient-personal-info {
  header {
    > button {
      margin: 3px;
    }

    h1 {
      flex-grow: 0;
      margin-right: 20px;
    }
  }

  .user-profile-title {
    font-weight: 600;
    margin-bottom: 22px;
  }

  .form-group {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0;
    padding-right: 0;
  }

  .horizontal-orientation {
    flex-direction: row;

    > .form-group {
      width: 33.33333%;

      &:nth-child(1) {
        padding-right: 15px;
      }

      &:nth-child(2) {
        padding-left: 15px;
        padding-right: 15px;
      }

      &:nth-child(3) {
        padding-left: 15px;
      }
    }
  }
}

.patient-detail-past-encounters {
  .table {
    margin-top: 0;

    .row {
      .card {
        min-height: 100px;
      }

      &:hover {
        @extend %cursor-pointer;
      }
    }
  }

  .past-encounter-flow-name-cell {
    margin-top: 7px;

    .flow-name {
      h3 {
        line-height: 1.6;
        margin-left: 5px;
      }
    }

    .started-at {
      padding-left: 35px;
    }
  }

  // TODO: Really dont like this, but hopefully this will disappear once we clean the app regarding consistent component usage
  .table-row-indicator-icon {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -10px;
  }
}

.patient-document-list {
  h2 {
    margin-top: 50px;
    margin-bottom: 60px;
  }
}

.patient-detail-encounter {
  margin-bottom: 75px;

  header {
    h1 {
      height: auto;
      padding-right: 15px;
      margin-top: 2px;
    }

    .btn,
    .custom-react-dropdown {
      align-self: start;

      /* sass-lint:disable class-name-format */
      .dropdown---menu-item---1LjoL {
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }

  .comment-section {
    margin-bottom: 45px;

    .card {
      padding: 30px;
      margin-bottom: 10px;
      border: 0;

      h3 {
        margin-top: 8px;
        margin-bottom: 17px;
      }
    }
  }

  .add-comment-button {
    margin-top: 30px;
    margin-bottom: 14px;
  }

  .add-comment-card {
    padding: 30px 30px 20px;
    margin-bottom: 10px;

    h3 {
      margin-top: 7px;
      margin-bottom: 20px;
    }

    .add-comment-actions {
      display: flex;
      justify-content: flex-end;

      .btn-primary {
        margin-right: 0;
      }
    }
  }

  .doctor-location-time {
    margin-top: 20px;
    margin-bottom: 49px;
  }
}

.doctor-location-time {
  font-size: $font-size-base;
  color: $grey5;
}

.patient-detail-dental-chart {
  header {
    margin-bottom: 6px;

    h1 {
      height: auto;
      padding-right: 15px;
    }

    .btn {
      align-self: start;
    }
  }
}

.document-sidepanel-container {
  min-width: auto !important;
  width: 730px;

  .document-sidepanel-form {
    padding: 20px;
  }
}

.add-patient-sidepanel {
  z-index: 1051;

  .sidepanel-container {
    max-width: 770px;
  }
}

.sidepanel-container {
  .cave-sidepanel-header {
    h2 {
      margin-right: 30px !important;
    }
  }
}

$rjsf-field-array-item-width: 0.9;

.rjsf-patient {
  i {
    &.glyphicon {
      display: none;
    }
  }

  .field-array {
    .array-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > div {
        &:first-child {
          padding-left: 15px;
          width: percentage($rjsf-field-array-item-width);

          .form-group {
            > label {
              &:first-child {
                position: absolute;
                left: 0;
                margin-top: 12px;
              }
            }
          }
        }
      }
    }

    .array-item-remove {
      margin-top: 5px;
    }
  }
}

.patient-full-name {
  vertical-align: middle;
}

.patient-header-more-actions {
  display: inline-flex;
  position: unset;
}

.patient-deleted-status {
  @include common-border-radius;
  margin-left: 15px;
  padding: 0 25px;
  background-color: $red-600;
  color: $white;
  font-size: $font-size-xl - 2px;
  vertical-align: middle;
  display: inline-flex;
  text-transform: uppercase;
}
