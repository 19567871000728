// sass-lint:disable class-name-format
@import "../../abstracts/variables";
@import "../../abstracts/placeholders";
@import "../../abstracts/mixins";

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open {
  .react-autosuggest__suggestions-container {
    @include common-border-radius;
    min-width: 100%;
    display: block;
    position: absolute;
    top: 51px;
    border: 2px solid $grey-110;
    background-color: $white-100;
    z-index: 1000;
    font-size: $font-size-base;

    .autosuggest-search-results-container {
      max-height: 500px;
      overflow: auto;
    }

    .autosuggest-show-all-container {
      text-align: center;
      padding: 10px;
      border-top: 1px solid $dropdown-border-color;
    }
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 19px 24px;
  font-weight: 600;

  border-bottom: 1px solid $grey-110;

  &:hover {
    background-color: $dropdown-hover-background;

    .patient-info-mini-card {
      .link-to-profile {
        visibility: visible;
      }
    }
  }

  &:first-child {
    @include common-border-radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    @include common-border-radius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: 0;

    &:first-child {
      @include common-border-radius;
    }
  }

  span {
    display: block;
    font-weight: 400;
  }

  .patient-info-mini-card {
    font-size: $font-size-base - 1;

    .circular-badge {
      width: 32px;
      height: 32px;
    }

    .full-name-line {
      font-weight: 600;
    }

    .one-line-data {
      font-weight: 400;
      font-size: $font-size-md;
    }
  }
}

.react-autosuggest__suggestion--highlighted,
.react-autosuggest__suggestion--focused {
  background-color: $pastel-blue-100;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.autosuggest-prepended-icon {
  position: relative;

  > i {
    position: absolute;
    z-index: 1;
    top: 50%;
    margin-top: -11px;
    left: 6px;
    color: $lighter-font-color;
  }

  .form-control-prepended-icon {
    padding-left: 45px;
  }

  .form-control-clear-button {
    padding-right: 28px;
  }
}

.clear-button-wrapper {
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 12px;
  z-index: 3;
  height: 100%;

  .clear-button {
    line-height: 1.4;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.no-hover-style {
  &:hover {
    background-color: $reverse-background !important;
    border-color: $pale-lilac !important;
  }
}

.calendar-search {
  .react-autosuggest__suggestions-container {
    min-width: 414px;

    .react-autosuggest__suggestion {
      padding: 13px;
    }
  }
}
