@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.select-patient-card {
  @include common-border-radius;

  border: 1px solid $grey-400;
  padding: 20px 50px 20px 15px;
  position: relative;
  width: 300px;
  height: 170px;
  margin: 5px;
  display: flex;
  float: left;
  overflow: hidden;

  &.selected {
    background-color: $blue-100;
  }

  .icon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    color: $grey-700;
    cursor: pointer;
  }

  .card-patient-info-container {
    max-width: 200px;
    margin-left: 20px;

    .patient-name {
      font-weight: 600;
    }

    .patient-basic-info,
    .patient-address,
    .patient-doctor {
      font-size: $font-size-base - 1;
      margin-bottom: 10px;
    }
  }

  .card-button-container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .readonly-radio-btn {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid $grey-400;
      background-color: $white;

      &.selected {
        border: 4px solid $default-primary;
      }
    }
  }

  &:hover {
    @include alternative-box-shadow($pagination-box-shadow-color);
    cursor: pointer;
    background-color: $blue-100;
  }
}
