@import "../../abstracts/variables";

.patient-info-mini-card {
  display: flex;

  .circular-badge {
    margin-right: 10px;
    min-width: 32px;
    min-height: 32px;

    &.horizontal {
      width: 40px;
      height: 40px;
      align-self: center;
    }
  }

  .full-name-line {
    &:not(.horizontal) {
      margin-top: 5px;
      margin-bottom: -5px;
      color: $light-black;
      font-size: $font-size-title3;
      letter-spacing: 0.5px;
    }
  }

  .one-line-data {
    margin-right: 5px;
  }

  .link-to-profile {
    text-align: center;
    margin-left: auto;
    align-self: center;
    line-height: 14px;
    color: $blue-700;
    visibility: hidden;
    white-space: nowrap;

    span {
      font-size: $font-size-sm;
      font-weight: 500;
    }
  }

  &:hover {
    .link-to-profile {
      display: block;
    }
  }
}

.patient-info-mini-tooltip {
  .tooltip {
    opacity: 1;

    .tooltip-inner {
      min-width: 150px;
      max-width: 400px;
      text-align: left;
      min-height: 60px;
      padding: 16px 34px 16px 18px;
      border: 1px solid $grey-110;

      .patient-info-mini-card {
        display: flex;
        align-items: center;

        .circular-badge {
          height: 32px;
          width: 32px;
          margin-right: 15px;
          font-size: $font-size-base - 2;
        }
      }
    }

    .patient-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1px;

      .full-name-line {
        color: $black-1000;
        font-size: 16px;
        font-weight: 400 !important;
        margin-top: 0;
        margin-bottom: 1px;
      }

      .one-line-data {
        font-size: $font-size-md;
        color: $black-1000;
        font-weight: 400 !important;
      }
    }

    .arrow {
      display: none;
    }
  }
}
