.custom-widget-history-modal {
  &.moshi-basic-modal {
    .modal-content {
      .modal-body {
        padding: 15px;
      }
    }
  }

  .empty-state {
    padding: 10px;
  }

  .card {
    margin-bottom: 10px;
    padding: 10px 20px;

    .custom-widget-history-line-title {
      font-size: $font-size-base;
      font-weight: 500;
    }

    .card-body {
      margin-top: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .fields-wrapper {
    .widget-value {
      font-weight: 600;
      font-size: 24px;
      display: inline-block;
    }

    .measurement {
      font-weight: 600;
      margin-left: 8px;
      display: inline-block;
    }

    .custom-numeric-label-widget {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}
