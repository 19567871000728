@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.billing {
  .billing-list-header {
    display: flex;
    padding: 10px 12px;
    margin: 0;
    background: $white-100;
    border-top-left-radius: $base-border-radius-size;
    border-top-right-radius: $base-border-radius-size;

    .billing-list-filters {
      display: flex;
      flex-grow: 1;
      margin-right: 10px;

      .search-field-inverted {
        flex-grow: 1;
      }

      > div {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    > button {
      margin-left: auto;
    }
  }

  .billing-invoice-list {
    //TODO revert once MoreFilters component is introduced
    // min-width: 1180px;
    min-width: 1300px;
  }

  .bill-section {
    max-width: 1250px;
    min-width: 900px;
    margin: 0 auto;
    padding-left: 60px !important;
    padding-right: 60px !important;
  }

  .bill-form {
    .bill-document-left-header-section {
      max-width: 400px;

      .bill-client-name-container {
        .form-group {
          display: inline-block;
          width: calc(100% - 40px);
          vertical-align: top;
        }

        i {
          position: relative;
          top: 36px;
          margin-left: 10px;

          &.disabled {
            color: $grey-600;
            cursor: not-allowed;
          }
        }
      }
    }

    hr {
      margin-top: 6px;
      margin-bottom: 10px;
    }

    .inline-label-inputs-container {
      .custom-daypicker,
      .custom-react-dropdown {
        width: 200px;
      }

      .due-date-container {
        display: flex;
        justify-content: flex-end;

        .due-date-dropdown {
          max-width: 140px;
          margin-right: 20px;

          .dropdown-toggle {
            overflow: visible;
          }
        }
      }
    }

    .number-input-group {
      input {
        width: 200px;
      }
    }

    .array-invalid {
      margin-top: 5px;
      margin-left: 10px;
      color: $default-danger;
      font-size: $font-size-base;
    }

    .bill-item-section {
      .bill-item-list-header {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .array-invalid {
        text-align: center;
      }

      .bill-totals-container {
        margin-top: 50px;
      }
    }

    &.header-text-only {
      header {
        margin-bottom: 15px;
      }
    }
  }

  .bill-preview {
    .bill-items-preview {
      margin-top: 10px;
      margin-bottom: 60px;

      .bill-item-row {
        padding: 20px 0;
        border-top: 1px solid $violet-100;

        .bill-item-description {
          margin-bottom: 10px;
          margin-top: 15px;
          margin-left: 22px;
          color: $grey-800;
        }
      }
    }

    .invoice-signature-text {
      float: right;
      color: $lighter-font-color;
    }

    .invoice-footer {
      margin-top: 40px;
      margin-bottom: 10px;
      color: $lighter-font-color;
      text-align: center;
    }

    .bill-dates {
      .bill-dates-container {
        span {
          text-align: right;
          display: block;
        }
      }
    }

    .bill-detail-fiscalization {
      .not-fiscalized-warning {
        > span {
          display: block;
          color: $red-600;
          text-transform: uppercase;
          font-weight: 600;
        }

        > button {
          margin-top: 10px;
        }
      }

      .qr-code-container {
        display: inline-block;
        border: 1px solid $violet-100;
        padding: 15px;
        padding-bottom: 10px;
      }
    }
  }

  .bill-sidebar {
    .bill-status-section {
      font-size: $font-size-lg;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    .billing-invoice-payments-container {
      .add-payment-container {
        .add-payment-button {
          vertical-align: top;
          width: calc(50% - 5px);
          min-height: 51px;
          margin-bottom: 10px;
          margin-left: 0;
          margin-right: 0;

          &:nth-last-child(1),
          &:nth-last-child(2) {
            margin-bottom: 0;
          }

          > button {
            margin: 0;
            min-height: 51px;
          }
        }
      }

      .back-to-payments-container {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        text-align: center;
      }

      .payment-list-container {
        .payment-list-tag {
          &:not(:empty) {
            &::after {
              content: " - ";
            }
          }
        }

        .amount-due-container {
          display: flex;
          justify-content: space-between;
          border-top: 1px solid $pale-lilac;
          padding-top: 12px;
          font-weight: 600;
          margin-bottom: 5px;
        }

        .payment-hover-container {
          visibility: visible;

          &:not(:hover) {
            .visible-on-hover {
              visibility: hidden !important;
            }
          }
        }
      }
    }

    .advance-usage-section {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
    }
  }

  .bill-referenced-documents {
    margin-top: 50px;

    ul {
      margin-top: 8px;
      padding-inline-start: 20px;

      button {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }

  /* sass-lint:disable class-name-format */
  .invoice-custom-datepicker,
  .invoice-custom-due-datepicker {
    .react-date-picker__calendar--open {
      left: -50px !important;
    }
  }

  .export-bill-document-form {
    width: 468px;
    padding: 15px 10px 5px;
  }

  .billing-sidepanel {
    padding: 32px 40px 40px;
  }

  .overlay-header {
    .bill-type-icon-container {
      margin-right: 14px;
    }

    &.invoice-overlay-header {
      background-color: $green-200;
    }

    &.advance-overlay-header {
      background-color: $red-200;
    }

    &.estimate-overlay-header {
      background-color: $yellow-200;
    }

    &.credit-note-overlay-header {
      background-color: $violet-200;
    }
  }
}

.bill-items {
  .bill-item-list-container {
    margin-bottom: 20px;

    textarea {
      &:hover {
        background-color: $white-100 !important;
      }
    }

    .bill-item {
      width: auto !important;
      margin: 0 -60px;
      padding: 0 60px;

      .remove-cancel-icon {
        margin-left: 8px;
        padding: 8px;
        outline: none;

        i {
          border-radius: 50%;
        }
      }

      .bill-item-name-container {
        display: flex;
        margin-bottom: 1rem;

        .form-group {
          margin-bottom: 0;
          flex-grow: 1;
        }

        i {
          position: relative;
          top: 3px;
          padding: 5px;
          font-size: $font-size-xl;
          cursor: pointer;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .bill-item-description-container {
      .bill-item-description-textarea {
        margin-bottom: 15px;
      }
    }
  }

  .bill-item-buttons {
    text-align: center;
  }
}

.bill-totals-container {
  width: 100%;
  background-color: $white-100;
  font-weight: 400;

  > .row {
    padding: 10px 0;

    &:last-child {
      border-top: 1px solid $pale-lilac;
    }
  }
}

.invoice-sum-placeholder {
  height: 31px;
  background-color: $default-background;
  display: block;
}

.cash-register-general-info {
  margin-bottom: 20px;
  margin-top: 25px;

  > div {
    display: inline-block;
    margin-bottom: 10px;

    &:not(:last-child) {
      margin-right: 40px;
    }

    > .grey-label {
      display: block;
    }
  }
}

.cash-register-create-card {
  padding: 10px !important;

  &:hover {
    @include common-box-shadow($grey-600);
    cursor: pointer;
  }

  .cash-register-create-card-data {
    height: 100%;
    align-items: center;
  }
}

.cash-payments-tag {
  border-radius: 100px;
  padding: 3px 18px;
  font-weight: 600;
  border: 2px solid $violet-100;
}

.cash-register-detail {
  margin: 30px auto;
  max-width: 1200px;

  span,
  div {
    font-size: $font-size-base + 2;
  }
}

.cash-register-title {
  font-size: $font-size-title2;
}

.cash-register-summary {
  margin-bottom: 20px;

  .payment-type {
    padding-right: 0;

    .payment-tags {
      font-size: $font-size-sm;
      color: $lighter-font-color;
      padding-left: 5px;
    }
  }

  .total-revenue {
    padding-right: 0;
    padding-bottom: 16px;

    div {
      display: flex;
      justify-content: space-between;
    }
  }

  .darkened-form-area {
    padding: 18px 20px 20px 23px;

    .row {
      &:first-child {
        margin-bottom: 22px;
      }

      &:not(:first-child) {
        margin-top: 16px;
      }

      > div {
        &:not(:first-child) {
          text-align: right;
        }
      }
    }
  }
}

.billing-unit-detail {
  margin-bottom: $save-bar-min-height;

  .billing-unit-fiscalization {
    width: 830px;

    .card-title {
      h3 {
        font-weight: 400;
      }
    }

    .card-body {
      padding-bottom: 0;
    }

    .fiscalization-status {
      .toggle-switch-container {
        align-items: center;

        .toggle-switch-label {
          font-size: $font-size-title3;
          font-weight: 400;
        }
      }
    }

    .billing-unit-fiscalization-act {
      @include common-border-radius;
      margin-top: 30px;
      border: 1px solid $pale-lilac;
      height: 580px;
      overflow: hidden;

      .billing-unit-fiscalization-act-overflow {
        overflow-y: scroll;
        height: 100%;
      }
    }

    .fiscalization-section-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &.center-align {
        justify-content: center;
      }

      .btn {
        &:first-child {
          margin-right: 10px;
        }

        &.btn-icon {
          display: block;
        }
      }
    }
  }
}

.billing-unit-list {
  .billing-unit-list-item {
    h3 {
      height: initial;
      min-height: 18px;
    }

    .billing-unit-list-item-actions {
      display: flex;

      .btn {
        margin-left: 40px;
        max-height: 35px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.bill-pdf-modal {
  form {
    margin: 30px;
  }
}

.no-log-modal {
  min-width: unset;
  max-width: 650px;
  text-align: center;

  .modal-body {
    padding: 30px !important;
  }
}
