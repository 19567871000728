@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.container-description {
  max-width: 440px;
}

.logo-placeholder {
  @include common-border-radius;
  width: 200px;
  height: 200px;
  border: 1px solid $grey3;
}
