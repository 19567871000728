@import "../../abstracts/variables";

.flow-settings {
  &.custom-container {
    margin-left: 80px;

    h1 {
      margin-top: 19px;
    }

    .navigation {
      margin-bottom: 0;
    }

    .flow-detail-component {
      max-width: 580px;
      display: flex;
      flex-direction: column;

      .flow-detail-description {
        margin-bottom: 40px;
      }

      .card {
        .form-group {
          margin-bottom: 38px;
        }
      }

      .table {
        margin-top: 41px;

        &.smaller-top-margin {
          margin-top: 15px;
        }

        .table-header {
          margin-bottom: 9px;

          label {
            margin-bottom: 5px;
          }
        }

        .row {
          .checkbox {
            margin-top: 0;
          }

          .enable-disable-toggle {
            padding-top: 0;
            margin-top: 0;
            text-transform: uppercase;
          }
        }
      }

      .add-document-button {
        margin-top: 24px;
        margin-bottom: 40px;
      }

      .confirmation-section {
        h2 {
          padding-top: 49px;
          margin-top: 40px;
          height: unset;
          border-top: 1px solid $pale-lilac;
        }

        .confirmation-subtext {
          margin-bottom: 20px;
        }

        .card {
          padding-top: 22px;

          form {
            > div {
              &:first-child {
                .radio {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &.content-sidebar {
    width: 100px;
    min-width: 100px;
    margin-top: 0;

    header {
      margin-top: 22px;
    }
  }
}
