@import "../../abstracts/variables";
@import "../../abstracts/placeholders";

.navigation {
  .tab-container {
    margin-bottom: 20px;
    display: flex;

    .navigation-tab {
      position: relative;
      overflow: hidden;
      margin-right: 5px;
      padding: 8px 15px 8px 14px;
      color: $black-800;
      font-size: $font-size-title4;
      font-weight: 400;
      letter-spacing: normal;
      white-space: nowrap;
      display: flex;
      line-height: 20px;
      border-radius: $base-border-radius-size - 3px;
      transition: $common-bg-transitions;

      i {
        font-size: $font-size-xl;
        align-self: center;
        margin-top: -2px;
        margin-right: 5px;
      }

      &.active {
        @include smaller-box-shadow($light-box-shadow-color);
        opacity: 1;
        color: $default-primary;
        background-color: $white;

        .icon-tab-number {
          background-color: $default-primary;
        }
      }

      &:hover {
        color: $default-primary;
        background-color: $light-primary;
        text-decoration: none;
        opacity: 1;

        .icon-tab-number {
          background-color: $default-primary;
        }
      }

      &:active {
        background-color: $blue-300;
        transition-duration: 100ms;
      }

      .icon-tab-number {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        text-align: center;
        font-size: $font-size-base - 4;
        color: $grey-blue;
        background-color: $default-font-color;
        align-self: center;
        font-style: normal;
        padding-top: 2px;
        padding-right: 0.5px;
      }
    }
  }
}
