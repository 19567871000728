@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.calendar-layout {
  max-height: 100%;
  max-width: 100%;
  padding-bottom: 0;
  font-family: $font-family-ibm-plex-sans;

  .calendar-main-container {
    .calendar-header-container {
      width: 100%;

      .header-actions {
        width: 100%;
        min-width: 1200px;

        .calendar-controls {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-left: 60px !important;

          .calendar-toolbar-left-side {
            display: flex;
            margin-left: 40px;
            min-width: 318px;

            .date-title {
              font-size: $font-size-xl;
              margin-bottom: 0;
              white-space: nowrap;
              line-height: 40px;
              margin-right: 10px;
            }

            .moshi-toggle-tabs {
              min-width: 78px;
            }
          }

          .calendar-toolbar-right-side {
            display: flex;

            .calendar-search {
              margin-right: 10px;

              .calendar-search-input {
                margin-bottom: 0;
                min-width: 150px;
              }
            }

            .calendar-dropdown-filter {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .calendar-sidebar {
      float: left;
      width: 250px;
      padding-right: 20px;

      .checkbox-list-container {
        margin-bottom: 15px;
        border-bottom: 2px solid $grey-110;
        padding-bottom: 10px;
        margin-left: -4px;
        padding-left: 4px;

        .checkbox-list {
          margin-bottom: 11px;
        }

        .list-title {
          font-size: $font-size-lg;
          color: $black-1000;
          letter-spacing: 0.46px;
          margin-bottom: 5px;
          padding-left: 4px;
          padding-top: 10px;
          padding-bottom: 10px;
          align-items: center;
          display: flex;
          justify-content: space-between;

          .add-icon {
            float: left;
            margin-right: 5px;

            &:hover {
              background-color: inherit;
            }
          }

          &:hover {
            background-color: $white-300;
          }
        }

        .list-group-title {
          font-style: italic;
          color: $grey-700;
          margin-bottom: 10px;
          padding-left: 4px;
        }

        .add-icon {
          font-weight: 600;
          border-radius: 50%;
          width: 21px;
          height: 21px;
          text-align: center;
          line-height: 21px;
          font-size: $font-size-base - 1;
          margin-right: 1px;
          color: $black-800;

          &:hover {
            background-color: $white-100;
          }
        }
      }

      .select-text {
        font-size: $font-size-base + 1;
        color: $blue-700;
        padding-left: 4px;

        &:hover {
          cursor: pointer;
          color: $blue-600;
        }
      }
    }

    .calendar-view {
      @include common-border-radius;
      @include alternative-box-shadow($popover-shadow-color);

      margin-left: $sidebar-size;
      overflow: auto;
      max-height: calc(100vh - 115px);
    }

    .calendar-filter-pills-container {
      margin-bottom: 23px;

      .moshi-tag {
        margin: 5px 10px 5px 0;

        &:hover {
          @include smaller-box-shadow($pale-lilac);
        }
      }
    }
  }

  .calendar-waiting-list-container {
    label {
      text-transform: capitalize;
      font-size: 15px;
    }

    .add-to-waiting-list {
      color: $default-primary;
    }

    .calendar-waiting-list-tabs {
      .moshi-toggle-tabs-wrapper {
        width: 100%;
        border: 0;
        align-items: start;
        justify-content: space-between;

        .moshi-toggle-tab {
          text-transform: capitalize;
          margin: 0;
        }
      }
    }
  }
}

// Calendar settings

.category-indicator,
.color-indicator {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;

  &.primary-background {
    background-color: $default-primary;
  }

  &.secondary-background {
    background-color: $light-font-color;
  }

  &.info-background {
    background-color: $default-font-color;
  }

  &.success-background {
    background-color: $active-success;
  }

  &.warning-background {
    background-color: $active-warning;
  }

  &.danger-background {
    background-color: $active-danger;
  }

  &.yellow-background {
    background-color: $active-yellow;
  }

  &.purple-background {
    background-color: $active-purple;
  }
}

.event-notifications {
  margin-bottom: 20px;
  border-top: 1px solid $pale-lilac;
  border-bottom: 1px solid$pale-lilac;

  .sent-row {
    font-family: $font-family-ibm-plex-sans;
    font-weight: 500;
  }

  .scheduled-row {
    .cell-wrapper {
      color: $grey-800 !important;
    }
  }
}

// Datepicker popover

.calendar-date-picker-popover {
  .popover {
    background-color: $white;
    min-width: 460px;

    .popover-body {
      padding: 0;
      display: flex;

      .calendar-daypicker-container {
        .custom-daypicker {
          width: 240px;
          border-right: 1px solid $grey-400;

          .react-calendar {
            box-shadow: none;
          }
        }
      }

      .calendar-popover-jump-to-container {
        font-family: $font-family-ibm-plex-sans;
        padding: 15px;

        .buttons-grid {
          display: grid;
          grid-template-columns: auto auto;
          grid-auto-rows: 30px;
          align-items: center;
          width: 100%;

          .moshi-button {
            margin: 0 3px;
          }
        }
      }
    }
  }
}

.calendar-sidebar-height {
  @include no-scrollbar;
  max-height: calc(100vh - 107px);
  overflow: auto;
}

.calendar-prev-next-container {
  border-radius: $sm-border-radius-size;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 42px;
  margin-left: 10px;
  background-color: $white;
  min-width: 275px;
  border: solid 1px $grey-300;

  .today,
  .calendar-date {
    font-size: $font-size-base + 1;
    font-family: $font-family-ibm-plex-sans;
    font-weight: 400;

    &:hover {
      cursor: pointer;
      color: $active-position-stroke;
    }

    &:focus {
      outline: none;
    }
  }

  .calendar-date {
    margin-left: 15px;
    width: 160px;
    text-align: center;
  }

  .calendar-prev-next-button {
    @include common-border-radius;

    align-items: center;
    display: flex;
    height: 30px;
    width: 30px;
    text-align: center;

    i {
      margin: auto;
    }

    &:hover {
      cursor: pointer;
      color: $active-position-stroke;
    }

    &:focus {
      outline: none;
    }
  }
}
