@import "../abstracts/variables";
@import "../abstracts/placeholders";
@import "../abstracts/mixins";

* {
  outline: none !important;
}

body {
  font-family: $font-family-ibm-plex-sans;
}

header {
  padding-bottom: 15px;
  margin-bottom: 10px;
  display: flex;

  h1,
  h2 {
    flex-grow: 1;
    margin: 0;
  }

  button {
    align-self: flex-end;
    margin-right: 0;
    margin-left: 15px;
  }
}

main {
  flex-grow: 1;
  padding: 20px;
  margin-left: $sidebar-size;
  overflow-y: auto;
  background-color: $pastel-blue-100;
  transition: margin-left 0.3s;

  &.collapsed {
    margin-left: $collapsed-sidebar-size;
  }
}

p {
  line-height: 1.5;
}

ol,
ul {
  li {
    line-height: 1.5;
  }
}

b,
strong {
  font-weight: 600;
}

::placeholder {
  color: $grey-600;
  -webkit-text-fill-color: $grey-600;
}

a {
  @include moshi-btn-link($blue-700, $blue-600, $blue-600);
}

i {
  display: inline-block;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    outline: none;
  }

  .icon-children-wrapper {
    font-family: $font-family-ibm-plex-sans !important;
    font-weight: 600;
  }
}

input {
  &[type="number"] {
    -moz-appearance: textfield !important;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
}

input,
textarea,
select,
.dropdown-toggle {
  &:disabled,
  &.disabled,
  &[disabled] {
    user-select: all;
    pointer-events: none;
    resize: none;
    overflow: hidden;
  }
}

label {
  position: relative;
  width: 100%;
  font-size: $font-size-label;

  input,
  select {
    height: 40px;
  }

  .form-control {
    position: relative;

    :placeholder-shown + * {
      top: 0;
    }
  }

  select {
    .form-control {
      color: $gray-500;

      + span {
        top: 0;
      }

      &.selected {
        color: $black-1000;

        + span {
          top: -20px;
        }
      }
    }
  }

  .invalid-feedback {
    position: absolute;
  }
}

.invalid-feedback {
  color: $error-red;
  font-size: $font-size-base;
}

.full-width {
  @extend %full-width;
}

.full-height {
  @extend %full-height;
}

.w-30 {
  width: 30%;
}

.w-60 {
  width: 60%;
}

.fit-content {
  max-width: fit-content;
}

.font-size-sm {
  font-size: $font-size-sm;
}

.font-size-lg {
  font-size: $font-size-lg;
}

.font-size-xl {
  font-size: $font-size-xl;
}

.font-line-through {
  text-decoration: line-through;
}

.italic {
  font-style: italic;
}

.hidden {
  display: none !important;
}

.hidden-opacity {
  opacity: 0 !important;
}

.invisible {
  visibility: hidden !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.margin-aside-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.margin-aside-negative-5 {
  margin-left: -5px;
  margin-right: -5px;
}

.margin-aside-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.absolute-right {
  position: absolute;
  right: 0;
  top: 50%;
}

.svg-height-offset {
  margin-top: -15px;
}

.transform-uppercase {
  text-transform: uppercase !important;
}

.transform-normal-text {
  text-transform: none !important;
}

.no-break {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pre-wrap {
  white-space: pre-wrap;
}

.text-normal-wrap {
  white-space: normal;
}

.flex-align-middle-row {
  @extend %flex-row;
  align-items: center;
  align-content: center;
}

.flex-align-middle-column {
  @extend %flex-column;
  justify-items: center;
  justify-content: center;
}

.flex-justify-middle-column {
  @extend %flex-column;
  align-items: center;
  align-content: center;
}

.flex-justify-align-middle-row {
  @extend %flex;
  align-items: center;
  justify-content: center;
}

.flex-center-all-row-important {
  @extend %flex;
  align-items: center !important;
  align-content: center !important;
  justify-items: center !important;
  justify-content: center !important;
}

.flex-justify-end-row {
  @extend %flex-row;
  justify-items: flex-end;
  justify-content: flex-end;
}

.flex-justify-start-row {
  @extend %flex-row;
  justify-items: flex-start;
  justify-content: flex-start;
}

.flex-space-between {
  @extend %flex-row;
  justify-content: space-between;
}

.flex-column {
  @extend %flex-column;
}

.flex-row {
  @extend %flex-row;
  align-items: center;
}

.flex-inline-align-middle {
  display: inline-flex;
  align-items: center;
}

.vertical-align-middle {
  vertical-align: middle;
}

.cursor-pointer {
  &:hover {
    @extend %cursor-pointer;
  }
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.no-underline {
  text-decoration: none !important;
}

.clickable-text {
  @include hover {
    @extend %cursor-pointer;
    color: $medium-blue !important;
    text-decoration-color: $medium-blue;
  }

  @include focus {
    color: $dark-blue;
    box-shadow: none;
    text-decoration-color: $dark-blue;
  }

  color: $black-1000;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-semi-bold {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: 600;
}

.font-weight-extra-bold {
  font-weight: 700;
}

.no-text-transform {
  text-transform: none !important;
}

.grey-label {
  @extend %form-group-label;
  font-size: $font-size-base;
}

// TODO: Maybe this one should be used in all card metadata
.metadata-label {
  @extend %metadata-label;
}

.no-hover {
  @extend %no-hover;
}

.no-box-shadow {
  box-shadow: none !important;
}

.no-border {
  border: 0;
}

.small-circle {
  @include circle(10px);
}

.small-rounded-rectangle {
  width: 30px;
  height: 6px;
  border-radius: 10px;
  background-color: $reverse-background;
  margin: 0 5px 0 0;
}

.red-background {
  background-color: $error-red;
}

.orange-background {
  background-color: $warning-orange;
}

.green-background {
  background-color: $success-green;
}

.header-sub-title {
  color: $light-font-color !important;
  text-transform: uppercase;
  font-size: $font-size-sm;
  margin-top: -8px;
  margin-bottom: 5px;
  height: unset;
  line-height: 1;
  font-weight: 600;
}

.read-only {
  opacity: 0.2;
  pointer-events: none;
}

.common-border-radius {
  @include common-border-radius;
}

.no-wrap {
  white-space: nowrap;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.overflow-visible {
  overflow: visible !important;
}

.hover-container {
  &:hover {
    .hidden-on-hover {
      display: none !important;
    }
  }

  &:not(:hover) {
    .visible-on-hover {
      display: none !important;
    }
  }
}

.letter-spacing-normal {
  letter-spacing: normal !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-transform-none {
  text-transform: none !important;
}

.color-black {
  color: $black-1000 !important;
}

.common-border-bottom {
  border-bottom: 1px solid $grey-200;
}

.top-2 {
  top: 2px;
}

.common-filter-box-shadow {
  &:hover {
    box-shadow: $header-actions-shadow;
  }
}
