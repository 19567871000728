@import "../../abstracts/variables";

.toggle-switch-container {
  display: flex;
  justify-content: flex-start;
  padding-top: 35px;

  .toggle-switch-label {
    margin-left: 20px;
    font-size: $font-size-base;
    font-weight: 600;
  }
}

.toggle-switch {
  position: relative;
  display: inline-block;
  min-width: 35px;
  max-width: 35px;

  &.form-control {
    border: 0;
    height: 20px !important;
  }

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $default-success;

      &::before {
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $default-success;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $grey5;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &::before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 2px;
      bottom: 2px;
      background-color: $white-100;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &.round {
      border-radius: 34px;

      &::before {
        border-radius: 50%;
      }
    }
  }
}
