@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.automated-check-in {
  margin-right: 15px;
  min-width: 1000px;

  .navigation {
    margin-bottom: 33px;
  }

  .card {
    margin-top: 17px;
  }

  hr {
    margin-top: 0;
  }

  .greeting-screen {
    .welcome-graphics-card {
      .welcome-graphics-image-card {
        width: 200px;
        height: 200px;
        border: solid 1px $grey3;
        box-shadow: unset;
        margin-bottom: 0;
      }

      .col {
        align-items: center;
        display: flex;
      }

      button {
        width: 100%;
      }
    }

    .title-comment-card {
      label {
        margin-top: 20px;
      }
    }
  }

  .final-screen {
    button {
      margin-top: 17px;
    }
  }

  .sign-in-fields-screen {
    .new-patient {
      .card {
        margin-top: 0;
        margin-bottom: 10px;
      }

      .table {
        margin-top: 17px;
        margin-bottom: 0;

        .row {
          &:last-of-type {
            .card {
              .row {
                .column-cell {
                  &.col-2 {
                    display: block;
                    padding-right: 15px;
                    padding-top: 5px;

                    button {
                      float: right;
                      min-width: inherit;
                      width: auto !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    hr {
      margin-top: 34px;
    }
  }

  .reason-for-visit {
    margin-top: 72px;

    .reason-for-visit-item {
      min-height: 60px;
      border: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 0;
      margin-bottom: 10px;

      &:hover {
        @include common-box-shadow($box-shadow-color);
      }

      input {
        &:disabled {
          margin-top: 5px;
        }
      }

      .btn-primary {
        width: 100%;
      }

      .btn-outline-primary {
        float: right;
      }

      .cell-with-icon {
        padding: 8px 0 0 9px;
        min-width: 35px;
        max-width: 35px;

        i {
          fill: $pale-lilac;
        }

        + .table-row-text {
          flex: 1 0;
          padding-left: 26px;
        }
      }

      .form-group {
        margin-bottom: 0;
      }

      .toggle-container {
        text-align: right;
        margin-top: 10px;

        .toggle-switch {
          float: right;
          margin-left: 15px;
        }
      }
    }

    .card-more-actions {
      top: 10px;
      right: 10px;
    }

    .add-reason-card {
      width: 100%;
      height: 60px;
      border: 0;
      margin-top: 20px;
      background-color: $reverse-background;
      text-align: center;
      font-size: 12px;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;

        &:hover {
          color: $default-primary;
          text-decoration: none;
        }
      }
    }
  }

  .documents-and-questionnaires-screen {
    .flow-dropdown-container {
      hr {
        margin-top: 34px;
        margin-bottom: 37px;
      }

      .dropdown-title {
        font-size: $font-size-lg;
        font-weight: 400;
        margin-bottom: 17px;

        span {
          font-weight: 600;
        }
      }

      .documents-or-questionnaires-table {
        margin-top: 0;

        .table-row {
          margin-top: 0;

          .cell-wrapper {
            padding-top: 0;

            .font-weight-bold {
              .type {
                font-weight: 400;
                font-size: $font-size-base - 3;
                text-transform: uppercase;
                color: $grey5;
              }
            }

            .cell-with-icon {
              padding-top: 15px;
            }

            .version {
              padding-top: 10px;
            }

            .remove-btn {
              font-size: $font-size-base - 2;
              padding-top: 13px;
              color: $red-600;
              border-radius: 0;
            }
          }

          &.highlight {
            animation: highlight-fade 1.5s ease-in-out 0s;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
