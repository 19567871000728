@import "../../abstracts/variables";
@import "../../abstracts/placeholders";
@import "../../abstracts/mixins";

$modal-content-aside-padding: 60px;
$modal-content-aside-padding-negative: -$modal-content-aside-padding + 30px;

.moshi-basic-modal {
  .modal-content {
    padding: 0;
    border-radius: $base-border-radius-size;

    .modal-header {
      padding: 12px 11px 7px 41px;

      .modal-title {
        font-size: $font-size-title3;
        font-weight: 600;
        text-align: center;
        width: 100%;
        height: auto;
      }

      .close-icon {
        float: right;
        font-size: 26px;
        font-weight: 600;
        color: $default-font-color;
      }
    }

    .modal-body {
      padding: 0;

      .action-buttons {
        margin-top: 40px;
        text-align: center;

        > button {
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }

  &.padded-body {
    .modal-body {
      padding: 20px;
    }
  }

  &.common-form-modal {
    form {
      margin: 30px;
    }
  }
}

.loading-modal {
  padding-top: calc(50vh - 100px);
  cursor: wait;

  .modal-content {
    padding: 50px;
    font-size: $font-size-title3;

    font-weight: 400;

    .modal-body {
      text-align: center;

      .spinner {
        display: inline-block;
        width: unset;
        margin-right: 10px;
      }
    }
  }
}

.moshi-modal {
  &.modal {
    &.show {
      display: block;
      overflow-y: auto;
    }

    .modal-dialog {
      max-width: 1200px;
      min-width: 900px;
      width: 80%;

      .modal-content {
        padding: 70px $modal-content-aside-padding 30px;
        border-radius: 1rem;

        .modal-close-button {
          position: absolute;
          top: 8px;
          right: 8px;
          padding: 4px;

          i {
            font-weight: bold;
          }
        }

        .modal-header,
        .modal-footer {
          border: 0;
        }

        h3 {
          margin-top: 37px;
          margin-bottom: 30px;
        }

        .modal-header {
          display: block;
          padding-left: 0;
          padding-right: 0;

          .modal-title {
            height: auto;

            .modal-text-title {
              @extend %header1;
            }
          }

          ol {
            margin: 70px 0 0;
            padding: 0;

            list-style-type: none;
            counter-reset: customlistcounter;

            li {
              display: inline-block;
              font-size: 24px;
              margin-right: 35px;
              counter-increment: customlistcounter;
              cursor: pointer;

              &::before {
                content: counter(customlistcounter) " ";
              }

              &.active {
                color: $black-1000;
              }
            }
          }
        }

        .modal-body {
          padding-left: 0;
          padding-right: 0;
        }

        .modal-footer {
          padding: 40px 0 10px;
          display: block;
          margin: 30px $modal-content-aside-padding-negative 0;

          &.modal-footer-actions-align-right {
            justify-content: flex-end;
            display: flex;
          }

          .btn {
            max-height: 40px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    .medium-width-modal {
      @include modal-width(820px);
    }

    .small-width-modal {
      @include modal-width(580px);

      .modal-content {
        padding-top: 30px;
      }
    }

    .small-padding-modal {
      .modal-content {
        padding: 20px;

        .modal-footer {
          margin: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}
