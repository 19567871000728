@import "../../abstracts/variables";

.user-detail-layout {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  .loading {
    svg {
      height: 100px;
      width: 100px;
    }
  }

  header {
    h1 {
      flex-grow: initial;

      .badge {
        vertical-align: middle;
        margin-left: 15px;
        margin-top: 0;
      }
    }
  }
}

.add-user-modal {
  .card {
    margin-bottom: 0;

    .image-component {
      width: 100px !important;
      height: 100px !important;
      margin-right: 15px;
    }

    .upload-file-form {
      margin: 40px 0 !important;
    }
  }

  .remove-button-container {
    display: flex;
    justify-content: center;

    .btn-link-danger {
      display: flex;
      align-self: center;
      margin-top: 10px;
    }
  }
}

.add-user-success-modal {
  .user-name {
    margin-top: 10px;
    color: $light-black;
    font-size: $font-size-lg;
    font-weight: 600;
  }

  .instructions {
    margin-top: 60px;
    font-weight: 600;
    width: 350px;
    text-align: center;
    margin-bottom: 21px;
  }

  .card {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 36px;
    background-color: $reverse-background;
    border: 0;
    box-shadow: none;
    max-width: 520px;
    margin: auto;

    .card-title {
      padding-left: 30px;
      margin-bottom: 24px;

      h3 {
        margin-bottom: 17px;
      }
    }

    .card-body {
      padding-left: 30px;

      .form-group {
        label {
          margin-bottom: 0;
        }
      }
    }
  }
}
