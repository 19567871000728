@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.document-detail-modal {
  .modal-content {
    .modal-header {
      padding-top: 0;
      padding-bottom: 0;

      .modal-title {
        label {
          @extend %form-group-label;
          display: block;
        }
      }
    }

    .modal-body {
      padding-top: 0;

      .metadata {
        margin-bottom: 29px;
      }

      .field-title-row {
        height: initial;
        margin-bottom: 0;
      }

      .card {
        padding: 0;
        border: 0;
        background: none;
        box-shadow: none;

        .form-group-label {
          color: $grey5;
          margin-bottom: 7px;
          font-size: $font-size-label;
        }
      }
    }
  }
}

.document-questionnaire-submission-modal {
  .modal-content {
    padding-top: 30px !important;

    .content {
      margin-top: 75px;

      .sidebar {
        width: 100%;
        position: relative;
        border: 0;
        box-shadow: none;
        padding: 0;
        margin-bottom: 50px;

        h3 {
          margin-bottom: 17px;

          &:first-of-type {
            margin-top: 14px;
          }
        }

        a {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.navigation {
            margin-top: 0;
            margin-bottom: 5px;
          }

          &.disabled {
            &:hover {
              cursor: not-allowed;
              background-color: initial;

              &.active {
                background-color: $grey-blue;
              }
            }
          }
        }

        svg {
          path {
            fill: none;
            stroke: none;
          }
        }
      }
    }

    .title-row {
      align-items: flex-end;

      h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .btn-link-primary {
        margin-bottom: 6px;
      }
    }

    .required {
      margin: 0 5px;
      color: $error-red;
    }

    .modal-body {
      #root__title {
        display: none;
      }

      .form-group {
        margin-bottom: 0;
      }
    }

    .modal-footer {
      margin-top: 0 !important;
      display: flex !important;
      justify-content: center;
    }
  }
}

.upload-documents-modal {
  .title {
    display: flex !important;
    justify-content: center;
    font-weight: 600;
    line-height: 1.22;
    color: $black-800;
    padding: 0;
  }

  .form-control-file {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon-bin {
      margin-left: auto;
    }
  }

  .files-dropzone {
    display: flex !important;
    justify-content: center;
    background-color: transparent;
    width: 100%;
    min-height: 180px;
  }

  .upload-file-description {
    padding: 0 5px 20px;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
  }
}

.document-icon {
  border-radius: 15%;
  background-color: $blue-700;
  color: $white;

  &.icon-size-lg {
    padding: 5px;
  }

  &.icon-size-md {
    padding: 3px;
  }

  &.icon-size-sm {
    padding: 2px;
  }

  &.inbound-submitted {
    background-color: $green-500;
  }

  &.inbound-request {
    background-color: $yellow-600;
  }

  &.inbound-template {
    background-color: $primary-blue-600;
  }

  &.outbound-template,
  .outbound-document,
  .outbound-document-icon {
    background-color: $violet-500;
  }

  &.external {
    background-color: $orange-500;
  }

  &.pdf {
    background-color: $orange-500;
    color: $white;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    position: relative;
    top: 0;
    font-weight: 500;
    font-size: $font-size-base - 4;
    height: 24px;
    width: 24px;
  }
}

.view-document-container {
  box-shadow: $document-box-shadow;
  width: 770px;
  margin: 0 auto 80px;
  padding: 40px;
}

.new-document-popover {
  .popover {
    min-width: unset;
    max-width: unset;
    max-height: unset;

    .popover-body {
      overflow: visible;
      max-height: unset;
    }
  }

  .document-type-container {
    @include common-border-radius;
    width: 270px;
    border: 1px solid $blue-200;
    padding: 20px 20px 29px;
    text-align: center;

    .document-type-title {
      font-size: $font-size-md;
      font-weight: 600;
      color: $blue-700;
      text-transform: uppercase;
    }

    .document-type-description {
      margin-top: 10px;
      font-size: $font-size-md;
      color: $grey-700;
    }

    .document-type-create-dropdown {
      margin-top: 22px;
    }
  }
}

.form-template-finder {
  .search-box {
    padding-bottom: 8px;
  }

  .template-finder-header {
    .template-finder-create-new-link {
      background-color: $grey-200;
      text-align: center;
      padding-bottom: 8px;
    }

    .template-finder-result {
      padding: 20px 30px 20px 19px;
      display: flex;
      justify-content: space-between;

      > span {
        text-transform: uppercase;
        font-size: $font-size-md;
        color: $grey-800;
      }
    }
  }

  .template-finder-item {
    display: flex;
    justify-content: space-between;
    font-weight: 400;

    .template-finder-item-name {
      color: $black-1000;
    }

    .template-finder-item-date {
      color: $grey-700;
    }
  }
}
