@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.patient-waiting-list-card {
  background-color: $reverse-background;
  margin-bottom: 10px;
  box-shadow: none;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid $pale-lilac;

  &:hover {
    cursor: pointer;
  }

  .event-time {
    font-size: $font-size-sm;
    letter-spacing: 0.46px;
    line-height: 10px;
    margin-left: 7px;
  }

  .event-name {
    font-size: $font-size-sm;
    font-weight: 600;
    padding-left: 29px;
    margin-top: 5px;
  }

  .doctor-name {
    font-size: $font-size-sm;
    padding-left: 29px;
  }

  .event-notes {
    font-size: $font-size-sm;
    padding-left: 29px;
    color: $grey5;
  }
}
