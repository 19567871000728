.notification-card {
  height: 60px !important;
  min-height: 60px !important;
  justify-content: center;
  margin-bottom: 0;
  background-color: $pastel-blue-100;

  .row {
    > div {
      display: flex;
      align-items: center;

      i,
      span {
        font-size: $font-size-base;
      }

      .toggle-switch {
        margin: 0;
      }
    }
  }

  &.inverted {
    box-shadow: none;
    border: 2px solid $pale-lilac;
    background-color: $reverse-background;
    height: 50px !important;
    min-height: 50px !important;
    margin-bottom: 10px !important;
  }
}
