.signature-widget {
  img {
    max-width: 200px;
    display: block;
  }

  .signature-placeholder {
    display: flex;
    align-items: flex-end;

    .signature-placeholder-text {
      border: 1px solid $grey-101;
      border-radius: 10px;
      padding: 5px;
      min-width: 300px;
      min-height: 120px;
      color: $grey-500;
    }
  }
}
