@import "../../abstracts/placeholders";
@import "../../abstracts/mixins";

.card-with-map,
.address-card {
  .card-body {
    > .row {
      .map-fields {
        @extend %flex-column;

        @include respond-to("card-with-map-max-width") {
          .btn-xxl {
            min-width: auto;
            width: 100%;
            margin-right: 0;
          }
        }

        justify-content: space-between;
      }
    }
  }
}
