@import "../../abstracts/variables";

.avatar {
  img {
    border-radius: 100%;
  }

  .upload-file-form {
    .btn {
      &.btn-link {
        height: 25px;
        justify-content: flex-start;
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    .avatar-upload {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
}
