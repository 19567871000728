@import "../../abstracts/variables";
@import "../../abstracts/placeholders";
@import "../../abstracts/mixins";

.therapy-detail-form,
.new-patient-active-therapy-form {
  padding-bottom: 100px;
  width: 550px;
}

.therapy-widget {
  min-width: 900px;

  &.past-therapy {
    color: $light-font-color !important;
    background-color: $reverse-background;

    .intake-progress {
      .progress-bar {
        background-color: $gradient-default-primary !important;
      }

      .intake-target {
        .current-intake-number {
          color: $grey5;
        }
      }
    }
  }

  hr {
    margin-top: 15px;
    margin-bottom: 0;
  }

  .metadata {
    @extend %flex;
    justify-content: space-between;
    color: $lighter-font-color;
    font-size: $font-size-sm;
    text-transform: uppercase;

    .card-more-actions {
      top: 12px;
      right: 18px;
    }
  }

  .therapy-preview {
    font-size: $font-size-xl;
  }

  .therapy-notification-preview {
    .therapy-info {
      font-weight: 500;
      font-size: $font-size-base -1;
    }

    .therapy-instructions {
      font-weight: 500;
      font-size: $font-size-base -1;

      .simple-gray-blue-card {
        padding: 10px 20px;

        p {
          margin-bottom: 0;
          white-space: pre-wrap;
        }
      }
    }
  }

  .duration-info {
    font-weight: 600;
    font-size: $font-size-sm;
    color: $light-font-color;
    letter-spacing: 0.46px;
  }

  .intake-progress {
    height: 44px;
    margin-top: 10px;
    align-items: center;

    .intake-target {
      color: $grey5;
      font-size: $font-size-sm;
      letter-spacing: 0.46px;
      font-weight: 500;
      margin-left: 30px;

      .current-intake-number {
        letter-spacing: 0.46px;
        color: $default-primary;
      }
    }
  }

  .therapy-actions {
    .moshi-tag-button {
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .intake-table {
    .collapsible-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 5px;

      .intake-info {
        color: $black-1000;
        font-size: $font-size-base;
      }

      .duration-info {
        margin: 0;
        color: $grey-800;
        font-size: $font-size-sm;
      }
    }

    h3 {
      font-size: $font-size-base;
      display: flex;
      height: $font-size-icon-lg;

      i {
        color: $dark-blue-font-color;
      }
    }
  }

  .moshi-expand-list-panel {
    margin: -10px -20px -20px;
    width: calc(100% + 40px);
  }
}

.past-therapy-list {
  h3 {
    font-size: $font-size-xl;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
