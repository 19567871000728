@import "../../abstracts/variables";

.document-editor {
  display: flex;
  flex-flow: column nowrap;
  min-width: calc(210mm + 40px);

  .document-editor-editable-container {
    background-color: $grey-500;

    &.header,
    &.footer {
      .ck-content {
        width: auto;
        min-height: 30mm;
        box-sizing: border-box;
        margin: 0;
        padding: 0 20px;
      }
    }

    .ck-content {
      /* A4 size */
      width: calc(210mm + 2px);
      padding: 20mm 12mm;
      box-sizing: border-box;
      min-height: 297mm;

      border: 1px solid $grey-600;
      background: $white-100;
      margin: 40px auto;
      overflow: hidden;
    }
  }

  /* sass-lint:disable class-name-format */
  .ck-dropdown {
    .smartcode-dropdown ~ .ck-dropdown__panel {
      max-height: 300px;
      overflow: auto;
    }
  }
}
