@import "../../abstracts/variables";
@import "../../abstracts/placeholders";
@import "../../abstracts/mixins";

.ck-editor {
  .ck-content {
    min-height: 100px;
    border-radius: 0 0 14px 14px !important;
  }

  button {
    border-radius: 6px !important;
  }

  // sass-lint:disable-block class-name-format
  .ck-dropdown__panel {
    button {
      border-radius: 0 !important;
    }
  }

  .ck-toolbar {
    border-radius: 14px 14px 0 0 !important;
  }
}

.react-markdown-renderer,
.ck-editor {
  h2 {
    font-size: 17px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 15px;
    font-weight: 600;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    height: auto;
    // force margins to prevent intervention from .moshi-modal
    margin: 10px 0 !important;
  }
}

.document-editor {
  display: flex;
  flex-flow: column nowrap;
  min-width: calc(210mm + 40px);

  .document-editor-toolbar {
    &.sticky-toolbar {
      position: sticky;
      top: -26px;
      z-index: 10;
    }
  }

  .document-editor-editable-container {
    background-color: $grey-500;

    .ck-content {
      /* A4 size */
      width: calc(210mm + 2px);
      padding: 20mm 12mm;
      box-sizing: border-box;
      min-height: 297mm;

      border: 1px solid $grey-600;
      background: $white-100;
      margin: 40px auto;
      overflow: hidden;
    }
  }
}
