@import "../../abstracts/mixins";

.color-picker {
  height: 40px;

  .color-picker-hidden-input {
    visibility: hidden !important;
    height: 0;
    width: 0;
    margin-left: 90px;
  }

  .color-picker-button {
    width: 180px !important;
    height: 40px !important;

    &.btn {
      margin-left: 0;
    }

    &:disabled {
      opacity: 1;
    }
  }
}

.category-colors-container {
  max-width: 300px;

  .color-circle,
  .color-picker {
    @include circle(25px);
    position: relative;
    float: left;
    margin: 0 10px 10px 0;

    &:hover {
      @include tiny-border-box-shadow-without-blur($grey10, 2px);
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    .icon-ok {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -7px;
      margin-left: -7px;
      font-weight: 600;
    }

    &.color-picker {
      border: 1px solid $grey10;
      text-align: center;
      color: $grey10;
      font-weight: 600;

      &:hover {
        box-shadow: none !important;
        background-color: $grey-blue;
      }

      .custom-color-circle {
        &:focus {
          outline: none;
        }
      }
    }
  }
}
