@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.files-list {
  .files-item {
    @include common-border-radius;
    background-color: $reverse-background;
    height: 60px;
    align-items: center;
    margin-bottom: 10px;
    display: flex;
    margin-right: 15px;
    margin-left: 15px;

    .files-item-name {
      font-weight: 600;

      i {
        margin-top: -3px;
        margin-right: 5px;
      }
    }

    > div {
      align-items: center;
      display: flex;

      &:last-child {
        justify-content: flex-end;

        button {
          text-align: right;
        }
      }
    }
  }
}

.moshi-image-preview {
  position: relative;
  border-radius: 15px;
  border: 2px $pale-lilac solid;
  width: 350px;
  height: 250px;

  img {
    @include common-border-radius;

    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.no-src {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .moshi-file-preview-overlay {
    width: 100%;
    max-width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $image-overlay-background;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden;

    .row {
      height: 100%;

      > * {
        border-top: 1px solid $pale-lilac;
        height: 100%;

        &:first-child {
          border-right: 1px solid $pale-lilac;
        }
      }
    }

    .btn {
      color: $white-100;
      border-radius: unset;
      background-color: transparent;
      font-size: $font-size-sm;
      width: 100%;
      height: 100%;
      margin: 0;
      border: 0;
      display: block;

      &:hover {
        color: $light-font-color;
        background-color: $white-100;
      }
    }
  }
}

.moshi-files {
  .form-group & {
    .files-dropzone {
      margin-bottom: 0;
    }
  }

  .files-dropzone {
    @include common-border-radius;
    margin-bottom: 10px;
    background-color: $reverse-background;
    height: 100px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    display: flex;
    border: 2px dashed $pale-lilac;
    color: $grey4;

    &:hover {
      cursor: pointer;
    }

    &.files-dropzone-active {
      display: flex;
      border-color: $ocean-blue;
    }
  }

  .upload-progressbar {
    margin-top: 20px;
    margin-bottom: 10px;

    .upload-status-text {
      margin-bottom: 15px;
    }
  }

  > .btn {
    margin-left: 0;
  }
}
