@import "../../../abstracts/variables";

.moshi-date-range-field {
  @include common-border-radius;
  display: flex;
  align-items: stretch;
  font-size: $font-size-md;
  font-weight: 400;
  border: 1px solid $grey-400;
  background: $white-300;
  height: 40px;

  &.focused {
    border-color: $pastel-blue-600;
    background: $white-100;

    & > * {
      border-right: 1px solid $grey-400;
    }
  }

  & > * {
    border-radius: 0;
    border: 0;
  }

  & > :last-child {
    border-right: 0;
  }

  .separator-icon {
    display: flex;
    align-items: center;
    padding: 0 12px;
    margin: 0 -1px;
  }

  .react-date-picker {
    background: none;
    font-size: $font-size-base;
    height: auto;
    padding-right: 8px;
    display: flex;
    align-items: center;
  }

  /* sass-lint:disable class-name-format */
  .react-date-picker__wrapper {
    border: 0;

    .react-date-picker__inputGroup__input {
      &:invalid {
        background: none;
      }
    }
  }
}

.moshi-hook-form-date-range-picker {
  .moshi-date-range-field {
    .form-control {
      .react-date-picker__wrapper {
        margin-top: 0;
      }
    }
  }
}
