@import "../../abstracts/variables";

.sidebar-icon-relative-position {
  position: relative;
}

.delete-animation {
  opacity: 0;
}

.bounce-in {
  transform-origin: center center;
  animation-name: bounce-in;
  animation-duration: 0.2s;
}

.magicbell-popover {
  &.moshi-popover {
    .arrow {
      &::before {
        border-right-color: transparent;
      }

      &::after {
        border-bottom-color: transparent;
        border-right-color: $white-100;
      }
    }

    .popover-body {
      background-color: $white-100;
      border-radius: 12px;
      padding: 0;

      .magicbell-inbox-container {
        height: 415px;

        .magicbell-inbox-header {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          padding: 10px 25px 10px 20px;

          .magicbell-inbox-title {
            cursor: default;
            font-size: $font-size-lg;
          }

          .magicbell-inbox-mark-all-read {
            font-size: $font-size-base;
            color: $blue-700;
            font-weight: 400;

            &:hover {
              color: $blue-800;
            }
          }
        }

        .infinite-scroll-component {
          @include no-scrollbar;
        }

        .magicbell-notification-container {
          display: flex;
          justify-content: space-between;
          margin: 0 5px 5px;
          padding: 0 5px;
          border: transparent 1px solid;
          border-radius: 10px;
          transition: all 0.4s ease-out;

          &.unread {
            background-color: $white-200;
            border-color: $grey-110;
          }

          &:hover {
            background-color: $pastel-blue-100;
            border-color: $grey-110;
          }

          .magicbell-notification {
            display: flex;
            flex-direction: column;
            min-height: 50px;
            padding: 10px;
            width: 360px;

            .magicbell-notification-title {
              font-size: $font-size-base + 1;
              margin-bottom: 5px;
            }

            .magicbell-notification-content {
              font-size: $font-size-base;
              color: $grey-800;
              line-height: 1.4;
            }
          }

          .magicbell-notification-sent-at {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 30px;
            font-size: $font-size-base;
          }

          .magicbell-notification-actions-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;

            .magicbell-notification-actions {
              position: relative;

              > div {
                right: 0;
                top: -7px;
              }
            }
          }
        }
      }
    }
  }
}
