@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.event-details-popover {
  max-width: 340px;
  min-width: auto;

  .popover {
    @include calendar-popover-box-shadow;
    background-color: $white;
    overflow: auto;
    min-height: 325px;
    max-height: calc(100% - 70px);
    min-width: 520px;

    .popover-body {
      padding: 0;
      overflow: visible;
      max-height: none;

      .event-status-container {
        .form-group {
          margin-bottom: 0;
          padding-left: 0;
        }
      }

      .moshi-fc-event-popover-content {
        .event-notifications {
          border-top: 0;
          border-bottom: 0;

          span {
            font-weight: $font-weight-base;
          }
        }

        .event-popover-actions-container {
          height: 38px;

          .icon-wrapper {
            i {
              font-size: 20px;
            }
          }

          .event-popover-actions {
            position: inherit;
            display: inline-block;
            height: 20px;
            font-family: $font-family-ibm-plex-sans;

            i {
              height: revert;
            }

            .icon-ellypsis {
              color: $default-font-color;
              margin: 0 5px;

              &:hover {
                color: $default-primary;
              }
            }
          }
        }

        .status-container {
          .form-group {
            padding: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
