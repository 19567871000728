@import "../../abstracts/variables";
@import "../../abstracts/mixins";

// CUSTOM FULL CALENDAR CSS

.custom-calendar {
  .fc {
    background-color: $white-100;

    .fc-timegrid-body,
    .fc-col-header,
    .fc-timegrid-body {
      width: 100% !important;
    }

    .fc-timegrid-slots,
    .fc-timegrid-cols {
      table {
        width: 100% !important;
      }
    }

    .fc-event {
      &.fc-bg-event {
        .moshi-fc-title {
          margin: 5px;
        }
      }
    }

    .fc-timegrid,
    .fc-resource-timeline {
      .fc-col-header {
        .fc-timegrid-axis {
          border-right: 0;
        }

        .fc-col-header-cell {
          font-size: $font-size-base - 3;
          text-transform: uppercase;
          color: $grey5;
          letter-spacing: 0.46px;
          font-weight: 400;
          padding-bottom: 10px;
          padding-top: 10px;
          border-right: 0;
          border-left: 0;

          .fc-col-header-cell-cushion {
            width: 100%;

            &:hover {
              text-decoration: none;
            }
          }

          &:hover {
            background-color: $header-day-hover-bg-color;
          }

          a {
            text-align: center;
            padding: 0;

            span {
              font-size: $font-size-base - 4;
              color: $black-900;
            }

            .moshi-fc-header-day {
              text-transform: capitalize;
              font-size: $font-size-base - 2;
              font-weight: 500;
            }

            .moshi-fc-header-day-number {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 36px;
              height: 36px;
              margin: auto;
              line-height: 30px;
              text-align: center;
              border-radius: 50%;
              font-size: $font-size-title2;
              color: $black-900;
            }
          }
        }

        .fc-day-today {
          background-color: $white-100;

          a {
            span {
              color: $default-primary;
            }

            .moshi-fc-header-day-number {
              background-color: $default-primary;
              color: $white-100;
              font-size: $font-size-title3;
            }
          }
        }
      }

      .moshi-resource-header-cell {
        text-align: center;
        font-size: $font-size-base;

        .resource-name {
          color: $black-1000;
          text-transform: none;
          margin-bottom: 3px;
          letter-spacing: 0;
        }

        .resource-badge {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          text-transform: uppercase;
          display: inline-block;
          line-height: 30px;
          font-weight: 600;
        }
      }

      .moshi-now-indicator {
        position: absolute;
        background-color: $default-primary;
        height: 2px;
        border: 0;

        &.fc-now-indicator-arrow {
          border-width: 1px 0;
        }
      }

      .fc-timegrid-slots {
        .fc-timegrid-slot-label {
          border-top: 0;
          padding-top: 0;
          vertical-align: top;
          font-size: $font-size-md;
          color: $grey-900;
          padding-bottom: 0;
        }

        .moshi-fc-lane {
          height: 25px;
          opacity: 0.5;

          .moshi-fc-lane-time {
            font-size: $font-size-md;
            color: $blue-700;
            visibility: hidden;
          }

          &:hover {
            background-color: $grey-100;

            .moshi-fc-lane-time {
              visibility: visible;
            }
          }
        }
      }

      .fc-timeline-slot-lane {
        height: 25px;

        .moshi-fc-lane-time {
          display: none;
        }
      }

      .fc-timegrid-event,
      .fc-timeline-event {
        min-height: 26px;
        border-width: 4px;
        border-radius: 0 6px 6px 0;
        border-top-width: 0;
        border-right-width: 0;
        border-bottom-width: 0;
        box-shadow: 0 0 0 1px $white-100;
        margin-right: 10px;

        &.fc-timegrid-event-condensed {
          .moshi-fc-event-status {
            display: none;
          }

          .moshi-fc-title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .moshi-fc-event-time {
            overflow: hidden;
          }

          .moshi-fc-event-note {
            display: none;
          }
        }

        &.moshi-fc-dim-event {
          opacity: 0.5;
        }

        &.fc-event-today {
          opacity: 1;
        }

        .fc-event-main {
          .moshi-fc-event-content {
            overflow: hidden;
            padding: 5px;
            font-weight: 500;
            font-size: $font-size-md;
            white-space: nowrap;

            * {
              line-height: 15px;
            }

            .moshi-fc-title {
              margin-right: 5px;
              width: 100%;
              flex-grow: 0;
            }

            .moshi-fc-event-time {
              width: 100%;
            }

            .moshi-fc-event-status {
              font-style: italic;
            }

            .moshi-fc-event-note {
              font-size: $font-size-md;
              overflow: hidden;
            }
          }
        }

        &.event-status-no-show {
          background-image: repeating-linear-gradient(
            135deg,
            transparent,
            transparent 10px,
            $box-shadow-color 11px,
            $box-shadow-color 12px
          );
        }

        &.event-status-reserved,
        &.outlined-event {
          border-top: 1px solid;
          border-right: 1px solid;
          border-bottom: 1px solid;
        }

        &:hover {
          @include common-box-shadow($box-shadow-color);
        }

        &.filtered-event {
          opacity: 0.18;

          .moshi-fc-title,
          .moshi-fc-event-time,
          .moshi-fc-event-note {
            display: none;
          }
        }
      }

      .fc-timeline-event {
        overflow: hidden;
      }
    }

    // sass-lint:disable class-name-format
    .fc-resourceTimeGridDay-view {
      .fc-scrollgrid-section {
        td {
          &:first-child {
            .fc-scroller-harness {
              .fc-scrollgrid-sync-table {
                border-bottom-style: hidden;
              }
            }
          }
        }
      }

      .fc-timegrid-axis-chunk {
        .fc-timegrid-slot-label {
          border-top-color: transparent;
          padding-top: 0;
          vertical-align: top;
          font-size: $font-size-md;
          color: $grey-900;
          padding-bottom: 0;
          line-height: 25px;
        }
      }

      .moshi-fc-lane {
        .moshi-fc-lane-time {
          font-size: unset !important;
          color: $blue-700;
          visibility: hidden;
        }

        &:hover {
          background-color: $grey-100;

          .moshi-fc-lane-time {
            visibility: visible;
          }
        }
      }
    }

    .fc-resource-timeline {
      .fc-datagrid-body {
        border-bottom: 1px solid $grey-410;
      }

      .moshi-resource-timeline-header {
        .fc-datagrid-cell-main {
          visibility: hidden;
        }
      }

      .fc-timeline-lane {
        .moshi-fc-event-content {
          flex-direction: column;
        }
      }

      .fc-timeline-header {
        .fc-timeline-header-row {
          .fc-timeline-slot {
            &.fc-day-mon,
            &.fc-day-wed,
            &.fc-day-fri {
              .fc-timeline-slot-frame {
                background-color: $grey-101;
              }
            }

            &.fc-day-sat,
            &.fc-day-sun {
              .fc-timeline-slot-frame {
                background-color: $red-100;
              }
            }

            .fc-timeline-slot-frame {
              .fc-timeline-slot-cushion {
                font-size: $font-size-md;
                color: $grey-900;
                font-weight: 500;
                pointer-events: none;
              }
            }
          }
        }
      }

      .fc-timeline-slot {
        &.fc-slot-mon,
        &.fc-slot-wed,
        &.fc-slot-fri {
          .fc-timeline-slot-frame {
            background-color: $grey-101 !important;
          }
        }

        &.fc-slot-sat,
        &.fc-slot-sun {
          .fc-timeline-slot-frame {
            background-color: $red-100 !important;
          }
        }
      }

      .moshi-now-indicator {
        position: relative;
        height: 100%;
        width: 1px;

        &.fc-timeline-now-indicator-arrow {
          display: none;
        }
      }

      .moshi-resource-header-cell {
        text-align: left;

        .resource-name {
          color: $black-1000;
          text-transform: none;
          display: inline-block;
        }

        .resource-badge {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          float: left;
          color: transparent !important;
          margin-top: 2px;
          margin-right: 8px;
        }
      }
    }
  }
}

.working-hours-calendar-wrapper,
.booking-calendar-wrapper {
  box-shadow: $working-hours-calendar-shadow;

  .working-hours-calendar,
  .booking-calendar {
    .working-hours-fc-wrapper {
      max-height: calc(100vh - 270px);
      overflow: auto;
    }

    .fc {
      .fc-scrollgrid {
        @include common-border-radius;
        background-color: $white-100;
        overflow: hidden;
        border: 1px solid $grey-410;
      }

      .fc-resource-timeline-divider {
        width: 1px;
        border: 0;
      }

      .fc-resourceTimeline-view {
        .fc-timeline-header {
          .fc-timeline-header-row {
            .fc-timeline-slot {
              background-color: $white-100;
              font-size: $font-size-base - 3;
              text-transform: uppercase;
              color: $grey5;
              letter-spacing: 0.46px;
              font-weight: 400;
              padding-bottom: 10px;
              padding-top: 10px;
              border-right: 0;
              border-left: 0;

              .fc-col-header-cell-cushion {
                width: 100%;

                &:hover {
                  text-decoration: none;
                }
              }

              a {
                text-align: center;
                padding: 0;

                span {
                  font-size: $font-size-base - 4;
                  color: $black-900;
                }

                .moshi-fc-header-day {
                  text-transform: capitalize;
                  font-size: $font-size-base - 2;
                  font-weight: 500;
                }

                .moshi-fc-header-day-number {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 36px;
                  height: 36px;
                  margin: auto;
                  line-height: 30px;
                  text-align: center;
                  border-radius: 50%;
                  font-size: $font-size-title2;
                  color: $black-900;
                }
              }
            }

            .fc-day-today {
              background-color: $white-100;

              a {
                span {
                  color: $default-primary;
                }

                .moshi-fc-header-day-number {
                  background-color: $default-primary;
                  color: $white-100;
                  font-size: $font-size-title3;
                }
              }
            }
          }
        }
      }

      .fc-resource {
        .fc-datagrid-cell-frame {
          display: flex;
          align-items: center;

          .fc-datagrid-cell-cushion {
            .fc-icon,
            .fc-datagrid-expander {
              display: none;
            }

            .moshi-resource-header-cell {
              cursor: default !important;
              display: flex;
              align-items: center;
              flex-direction: row-reverse;

              .resource-name {
                color: $black-1000;
                text-transform: none;
                display: inline-block;
                margin-bottom: 0;
              }

              .resource-badge {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                float: left;
                color: transparent !important;
                margin-right: 8px;
              }
            }
          }
        }
      }

      .fc-timeline-lane-frame,
      .fc-datagrid-cell-frame {
        min-height: 3rem;
      }

      .fc-resource-group {
        .fc-datagrid-cell-frame {
          min-height: 37px;
        }
      }

      .fc-scrollgrid-section-header {
        td {
          &:first-child {
            border-bottom: 1px solid $grey-410;
          }
        }
      }

      .moshi-wh-calendar-event {
        padding: 0.3rem 0.85rem;
        margin: 0.3rem;
        min-height: 45px;
        font-size: $font-size-base - 1;
        border-radius: 0 6px 6px 0;

        &.fc-bg-event {
          margin: 38px 0 0;
          border-radius: 0;

          .moshi-fc-title {
            margin: 5px;
          }
        }
      }
    }
  }
}
