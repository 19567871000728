@import "../../abstracts/variables";
@import "../../abstracts/placeholders";

.content-sidebar {
  + .custom-container {
    margin-left: $content-sidebar-size;
    min-width: $container-min-width - 200;
    max-width: $container-max-width - 300;
  }
}

.custom-container {
  padding-bottom: 30px;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  min-width: $container-min-width;
  max-width: $container-max-width;
  flex-grow: 1;

  &.full-width-layout {
    max-height: 100%;
    max-width: 100%;
    padding-bottom: 0;
  }

  header {
    @extend %container-header;

    .container-header-title {
      min-height: 42px;
      line-height: 30px;
    }

    .header-actions {
      display: flex;
      align-items: center;

      label {
        color: $grey5;
        width: initial;
        margin: 0;
      }

      > * {
        float: right;
        margin-left: 10px !important;
      }

      .header-filters > * {
        display: inline-block;

        &:not(:first-child) {
          margin-left: 10px;
        }

        .dropdown-toggle {
          min-width: 150px;
        }
      }

      .search-field-inverted {
        min-width: 350px;
      }
    }

    &.sibling-actions {
      display: inline-block;

      + button {
        float: right;
      }
    }

    .image-component {
      margin-right: 20px;
    }

    .btn {
      align-self: center;
    }

    .spinner {
      width: auto;
      display: inline-block;

      svg {
        height: 46px;
      }
    }
  }

  .container-section-title {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .container-description {
    display: inline-flex;
    margin-bottom: 40px;
    font-size: $font-size-base;
  }

  .navigation {
    display: flex;
    justify-content: space-between;

    a {
      display: inline-block;
      margin-right: 30px;
      padding-bottom: 20px;
      color: $grey4;

      &.active {
        color: $black-1000;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .btn-primary {
      margin-left: 10px;
      margin-right: 10px;
    }

    .btn-outline-primary {
      margin-left: 10px;
      margin-right: 10px;
      height: 40px;
    }
  }

  .edit-button-toggle {
    display: flex;
    flex-direction: row;

    .btn {
      margin-left: 0;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .btn-link {
    padding-left: 5px;
    padding-right: 10px;
    border: 0;

    &:last-child {
      padding-right: 2px;
    }
  }

  .btn-image {
    margin: 30px 10px;
  }

  .row {
    &.buttons {
      margin-top: 20px;
    }
  }

  .bigger-font {
    font-size: $font-size-lg;
  }

  hr {
    margin-top: 40px;
    margin-bottom: 30px;
  }
}
