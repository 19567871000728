.tile-layout {
  > header {
    min-height: 57px;

    > h1 {
      height: auto;
    }
  }

  .tile-column-layout {
    display: flex;
    flex-direction: row;

    &.default-layout {
      min-width: $container-min-width;
      max-width: $container-max-width;
    }

    .tile-column-center {
      flex-grow: 1;
    }

    .tile-column-left {
      min-width: 220px;
      max-width: 220px;
      margin-right: 20px;
    }

    .tile-column-right {
      min-width: 220px;
      max-width: 220px;
      margin-left: 20px;
    }
  }
}
