@import "../../abstracts/variables";
@import "../../abstracts/placeholders";
@import "../../abstracts/mixins";

// TODO: Move someplace logical
.form-group {
  .row & {
    padding-left: 0;
    padding-right: 0;
  }

  &.form-group-inline {
    flex-direction: row;
    align-items: center;

    label {
      text-align: right;
      margin-bottom: 0;
      margin-right: 15px;
      width: auto;
      flex-grow: 1;
    }

    .error-first {
      margin-right: 20px;
      text-align: right;
    }
  }
}

.search-field-inverted {
  .input-group {
    border-bottom: 2px solid $pale-lilac;

    .input-group-prepend,
    .input-group-append {
      span {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        color: $grey5;
      }
    }

    .form-control {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      border-radius: 0;
      padding-top: 0;
      padding-right: 35px;

      &::placeholder {
        color: $grey4;
      }
    }
  }
}

.search-field {
  .input-group {
    @include common-border-radius;

    background-color: $white-100;
    border: 1px solid $pale-lilac;

    .input-group-prepend,
    .input-group-append {
      align-items: center;

      .icon-search {
        font-size: 16px;
      }
    }

    .form-control {
      background-color: transparent;
      border: 0;
      box-shadow: none;
      border-radius: 0;
      padding-right: 35px;

      &::placeholder {
        color: $grey4;
      }
    }
  }
}

.darkened-form-area {
  @include common-border-radius;
  background-color: $grey-blue;
}

.moshi-form,
form {
  label {
    &.checkbox {
      @extend %checkbox;
    }

    &.radio {
      @extend %radio;
    }

    &.disabled {
      cursor: default !important;
    }

    input {
      width: 0;
      height: 0;
    }
  }

  .edit-mode-toggler-disabled {
    &:not(.checkbox) {
      @extend %edit-mode-toggler-disabled;
    }
  }

  .column-layout {
    display: flex;
    flex-direction: row;
  }

  .form-group-link {
    position: absolute;
    padding-top: 0;
    padding-bottom: 0;
    right: 15px;
    top: 0;
    z-index: 1;
    font-size: $font-size-base - 2 !important;
    height: 12px !important;
    min-height: 12px !important;
  }

  .input-group {
    .input-group-text {
      @include common-border-radius;
      background-color: $reverse-background;
      border-width: 2px;
      padding-left: 5px;
      padding-right: 5px;
    }

    .input-group-prepend {
      .form-control,
      .input-group-text {
        margin-right: 1px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 1px;

        &:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left-width: 1px;
        }
      }
    }

    > .form-control {
      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-width: 1px;
      }

      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 1px;
      }
    }

    .input-group-append {
      margin-left: 0;

      .form-control,
      .input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-width: 1px;

        &:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right-width: 1px;
        }
      }
    }
  }

  .field-group {
    .invalid-feedback {
      display: block;
      color: $default-danger;
      margin-left: 15px;
      font-size: $font-size-base;

      &.display {
        display: block;
      }
    }
  }

  .form-group {
    display: flex;
    padding-right: 5px;
    padding-left: 5px;
    flex-direction: column;

    &.form-group-no-margin {
      margin: 0;
    }

    &.is-invalid {
      .invalid-feedback {
        display: block;
        position: absolute;
        top: 41px;
        padding-left: 2px;
      }
    }

    .invalid-feedback {
      display: block;
      color: $default-danger;
      margin-left: 15px;
      font-size: $font-size-base;

      &.display {
        display: block;
      }
    }

    &.is-warning {
      .warning-feedback {
        display: block;
        position: absolute;
        top: 41px;
        padding-left: 2px;
        font-size: 80%;
        color: $warning-orange;
      }
    }

    .row & {
      padding-left: 0;
      padding-right: 0;
    }

    &.form-group-row {
      padding: 10px 0;
      margin: 0;

      label {
        display: block;
        padding-top: 0.2em;
        margin: 5px;
      }
    }

    label {
      &:not(.btn) {
        @extend %form-group-label;
      }

      span {
        &.required {
          margin: 0 5px;
          color: $error-red;
        }
      }
    }

    .form-control-date-field {
      .date-field-dropdowns {
        display: flex;
        flex-direction: row;
      }
    }

    .btn {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .form-group-label {
    @extend %form-group-label;

    .required-red-dot {
      @include circle-dot($error-red);

      width: 7px;
      height: 7px;
      position: initial;
      display: inline-block;
      margin-right: 5px;
    }

    .tooltip-icon {
      position: absolute;
      bottom: 2px;
      margin-left: 3px;
    }
  }

  .btn {
    margin-right: 5px;
    margin-left: 5px;
  }

  .form-control-text {
    height: 40px;
    padding: 11px 15px 10px;
  }

  .form-control {
    @extend %form-control-base;
    border-radius: $sm-border-radius-size;
    background-color: $white-100;
    position: relative;
    height: 42px;
    border-width: 1px;
    border-color: $grey-400;
    padding: 10px 15px 10px 11px;
    box-shadow: none !important;

    &::placeholder {
      color: $cloudy-blue;
    }

    &.form-control-no-style {
      box-shadow: none;
      border: 0;
      background: none;
      padding: 0;
      margin: 0;
    }

    &.form-control-label {
      background: none !important;
      box-shadow: none;
      border: 0;
      padding-left: 0;
      padding-right: 0;
    }

    &.checkbox,
    &.radio {
      width: auto;
      border: 0;
      height: 20px;
      background: none !important;
    }

    &.is-warning {
      box-shadow: none;
      border-color: $default-danger;
      background-image: none;
    }

    &.is-invalid {
      box-shadow: none;
      border-color: $default-danger !important;
      background-image: none;
      background-color: $input-danger;
    }

    &:hover {
      &:not(:focus) {
        &:not(.no-hover-effect) {
          &:not(.edit-mode-toggler-disabled) {
            &:not(.disabled) {
              &:not(:disabled) {
                background-color: $pastel-blue-100;
                border-color: $pastel-blue-100;
              }
            }
          }
        }
      }
    }

    &:active,
    &:focus,
    &:focus-within {
      color: $black-1000;
      -webkit-text-fill-color: $black-1000;

      &:not(.is-invalid) {
        &:not(.is-warning) {
          &:not(.edit-mode-toggler-disabled) {
            &:not(.disabled) {
              &:not(:disabled) {
                &:not(:read-only) {
                  background-color: $white-100;
                  border-color: $main-border;
                  outline: 0;
                }
              }
            }
          }
        }
      }
    }

    &:disabled,
    &.disabled,
    &.readonly {
      &:not(:read-only) {
        @extend %edit-mode-toggler-disabled;
      }
    }

    &:disabled {
      &:not(.react-date-picker) {
        &:not(.checkbox) {
          color: $black-1000;
          -webkit-text-fill-color: $black-1000;
          background-color: transparent;
          cursor: initial;
        }
      }
    }
  }

  textarea {
    &.form-control {
      padding: 8px 15px;

      &:read-only {
        opacity: 0.2;
        pointer-events: none;
      }

      &:disabled {
        opacity: 1;
        resize: none;
        pointer-events: none;
      }
    }
  }

  .react-date-picker--disabled {
    &.form-control {
      // prettier-ignore
      .react-date-picker__wrapper { // sass-lint:disable-line class-name-format
        margin-top: -11px;
        width: unset;

        // prettier-ignore
        .react-date-picker__inputGroup { // sass-lint:disable-line class-name-format
          padding-left: 0;
        }
      }

      // prettier-ignore
      .react-date-picker__calendar-button { // sass-lint:disable-line class-name-format
        display: none;
      }
    }
  }

  .form-inline-container {
    width: 100%;

    span {
      margin-right: 0;
    }

    .label {
      display: inline-block;
      top: 0;
      position: relative;
      text-transform: none;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      color: $black-1000;
      transition: none;
    }
  }

  .invalid-feedback,
  .invalid-tooltip {
    text-align: left;

    &.array-invalid {
      @extend %block;
    }
  }
}
