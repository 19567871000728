@import "../../abstracts/variables";

.circle-container {
  position: absolute;
  top: -2px;
  left: -2px;

  .circle-background,
  .circle-progress {
    fill: none;
  }

  .circle-background {
    stroke: transparent;
  }

  .circle-progress {
    stroke: $black-1000;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}
