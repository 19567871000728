@import "variables";

%edit-mode-toggler-disabled {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: $black-1000;

  + label {
    margin: 0;
  }

  &::placeholder {
    color: $white-100;
  }

  &.form-control {
    @extend %edit-mode-toggler-disabled;

    input {
      @extend %edit-mode-toggler-disabled;
    }
  }

  &.form-group {
    &.disabled-no-style {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

%header {
  color: $black-1000;
}

%header1 {
  @extend %header;
  line-height: 40px;
  font-size: $font-size-title1;
  height: $font-size-title1;
  font-weight: 600;
}

%header3 {
  font-size: $font-size-title3;
  height: $font-size-title3;
}

%header4 {
  font-size: $font-size-title4;
  height: $font-size-title4;
}

%container-header {
  font-weight: 600;
  font-size: $font-size-title1;
  align-items: center;
}

%button-primary-width {
  width: 220px !important;
}

%button-disabled {
  &:disabled {
    opacity: 0.1;
  }
}

%full-width {
  width: 100% !important;
}

%full-height {
  height: 100% !important;
}

%block {
  display: block;
}

%flex {
  display: flex !important;
}

%flex-column {
  @extend %flex;
  flex-direction: column;
}

%flex-row {
  @extend %flex;
  flex-direction: row;
}

%flex-center {
  @extend %flex;
  align-items: center;
  align-content: center;
}

%cursor-pointer {
  cursor: pointer !important;
}

%form-control-base {
  color: $black-1000;
  font-weight: 400;
  font-size: $font-size-base + 1;
}

%form-group-label {
  text-transform: none;
  color: $grey5;
  font-size: $font-size-label;
  font-weight: 500;

  &.form-group-label-disabled {
    margin-bottom: 0;
  }
}

%metadata-label {
  text-transform: none;
  color: $grey-900;
  font-size: $font-size-label;
  font-weight: 400;
}

%no-hover {
  cursor: initial !important;

  &:hover {
    background-color: initial !important;
    color: initial !important;
  }
}

%checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:disabled {
      cursor: not-allowed;

      ~ .checkbox-label {
        cursor: not-allowed;
      }

      ~ .checkbox-icon {
        background-color: $grey7 !important;
        cursor: not-allowed;
      }

      &:checked {
        ~ .checkbox-icon {
          background-color: $default-primary !important;
        }
      }
    }

    &:checked {
      ~ .checkbox-icon {
        background-color: $default-primary;

        &::after {
          display: block;
          border-width: 0 2px 2px 0;
        }
      }
    }
  }

  .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: darken($pale-lilac, 5);
    border-radius: 4px;

    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid $white-100;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  .checkbox-label {
    text-transform: none;
    font-size: $font-size-base;
    color: $black-1000;
  }

  &:hover {
    input {
      &:not(:disabled) {
        ~ .checkbox-icon {
          background-color: darken($pale-lilac, 10);
        }

        &:checked {
          ~ .checkbox-icon {
            background-color: darken($default-primary, 10);
          }
        }
      }
    }
  }
}

%radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px;

  &.disabled {
    cursor: not-allowed !important;
  }

  + .radio-note {
    color: $primary-blue-400;
    font-weight: bold;
    margin-left: 30px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:disabled {
      ~ .radio-icon {
        background-color: $grey7;
      }
    }

    &:checked {
      ~ .radio-icon {
        background-color: $default-primary;

        &::after {
          display: block;
        }
      }
    }
  }

  .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: darken($pale-lilac, 5);
    border-radius: 50%;

    &::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $white-100;
    }
  }

  .radio-label {
    text-transform: uppercase;
    font-size: $font-size-sm;
    color: $grey5;
  }

  &:hover {
    input {
      &:not(:disabled) {
        ~ .radio-icon {
          background-color: darken($pale-lilac, 10);
        }

        &:checked {
          ~ .radio-icon {
            background-color: darken($default-primary, 10);
          }
        }
      }
    }
  }

  &.radio-as-button {
    height: 40px;
    border: 1px solid $grey-300 !important;
    margin-right: 10px;
    padding-left: 40px;

    .radio-label {
      color: $default-font-color;
      text-transform: lowercase;
      font-weight: 400;
      font-size: $font-size-base;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    .radio-icon {
      background-color: $white;
      border: 2px solid $grey-400;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px;

      &::after {
        background: transparent;
      }
    }

    input {
      &:checked {
        ~ .radio-icon {
          background-color: $white;
          border: 5px solid $default-primary !important;
        }
      }
    }

    &.checked {
      background-color: darken($light-blue-100, 2) !important;
    }

    &:hover {
      background-color: darken($light-blue-100, 2) !important;

      input {
        ~ .radio-icon {
          background-color: $white;
        }

        &:checked {
          ~ .radio-icon {
            background-color: $white;
          }
        }
      }
    }
  }
}

%icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $font-family-ikonate-icons !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
