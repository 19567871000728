@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.logo {
  a {
    height: 50px;

    img {
      max-width: 100%;
    }
  }

  nav & {
    margin: 16px 15px 50px 18px;

    svg {
      height: 30px;
    }
  }

  svg {
    overflow: visible;
  }
}
