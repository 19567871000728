@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.moshi-rs-dropdown {
  .moshi-rs-dropdown-toggle {
    @include dropdown-border-radius;
    background-color: $white-100;
    border: 1px solid $rs-dropdown-border-color;
    color: $black-1000;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    padding: 8px 24px 8px 13px;
    min-width: 100%;
    text-align: left;
    text-transform: none;
    font-size: $font-size-base;
    height: 42px;

    &:active {
      background-color: $white-100 !important;
      color: $default-font-color !important;
      border: 1px solid $primary-blue-600 !important;
      box-shadow: none;
    }

    &:hover {
      background-color: $dropdown-hover-background;
      border: 1px solid $primary-blue-600 !important;
    }

    &:focus {
      box-shadow: none !important;
    }

    &::after {
      display: inline-block;
      position: absolute;
      right: 17px;
      top: calc(50% - 2px);
      -ms-transform: translateY(-50%) rotate(45deg);
      transform: translateY(-50%) rotate(45deg);

      padding: 3px;
      border: solid $grey5;
      border-width: 0 2px 2px 0;
    }
  }

  &.show {
    .moshi-rs-dropdown-toggle {
      background-color: $white !important;
      color: $default-font-color !important;
      border: 1px solid $primary-blue-600 !important;
      box-shadow: none;

      &:focus {
        box-shadow: none !important;
      }

      &::after {
        top: calc(50% + 2px);
        transform: translateY(-50%) rotate(-135deg);
        -webkit-transform: translateY(-50%) rotate(-135deg);
      }
    }
  }

  .moshi-rs-dropdown-menu {
    @include common-border-radius;
    @include smaller-box-shadow(rgba(0, 0, 0, 0.2));
    margin: 5px 0;
    padding: 0;
    overflow: hidden;
    border: 1px solid $rs-dropdown-border-color;
    max-height: 300px;
    overflow-y: auto;
    min-width: 100%;
    font-size: $font-size-base;

    .dropdown-item {
      padding: 0;
    }

    .moshi-rs-dropdown-item {
      font-size: $font-size-md;
      font-weight: 400;
      height: 42px;
      text-decoration: none;
      margin-left: 0;
      padding: 10px 15px;
      border-bottom: 1px solid transparent;
      border-top: 1px solid transparent;

      .selected {
        position: relative;
        padding-right: 30px;
        font-weight: 600;

        span {
          color: $default-primary !important;
        }

        .checkmark {
          font-weight: 800;
          position: absolute;
          right: -5px;
          top: 1px;
          color: $default-primary;

          path {
            fill: $default-primary;
          }
        }
      }

      > li {
        > i {
          &:first-child {
            position: relative;
            top: 2px;
            margin-right: 7px;
          }
        }
      }

      &:hover,
      &:active {
        background-color: $dropdown-hover-background;
        border-bottom: 1px solid $rs-dropdown-border-color;
        border-top: 1px solid $rs-dropdown-border-color;
      }

      &:first-child {
        border-top: 1px solid transparent;
      }

      &:last-child {
        border-bottom: 1px solid transparent;
      }

      &:disabled {
        background-color: $pastel-blue-100;
        color: $grey-710;
      }

      &.dropdown-header {
        padding-bottom: 0;
        height: 25px;
        color: $grey-800;
        font-style: italic;

        &:hover {
          background-color: transparent;
          border-bottom: 0;
          border-top: 0;
        }
      }
    }
  }
}

.rs-more-actions-container {
  .rs-more-actions-toggle {
    cursor: pointer;
  }

  .rs-more-actions-menu {
    max-height: unset !important;
    min-width: unset !important;
  }

  .rs-more-actions-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;

    i {
      margin-right: 5px;
      color: inherit !important;
      font-size: $font-size-base !important;
    }

    &:active {
      color: $black-1000;
    }
  }
}
