@import "../../abstracts/variables";
@import "../../abstracts/placeholders";
@import "../../abstracts/mixins";

label {
  &.btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.icon {
  min-height: 30px;
  min-width: 30px;

  &[disabled] {
    path {
      opacity: 0.3;
    }
  }

  &:hover {
    &:not([disabled]) {
      @extend %cursor-pointer;
      @include svg-fill($default-primary);
    }
  }
}

.btn-group {
  &:not(.moshi-dropdown-button) {
    .btn {
      @include hover {
        z-index: initial;
      }

      margin: 0;

      &:last-of-type {
        border-top-right-radius: $button-lg-border-radius !important;
        border-bottom-right-radius: $button-lg-border-radius !important;
      }
    }

    > * {
      &:first-child {
        border-top-left-radius: $button-lg-border-radius !important;
        border-bottom-left-radius: $button-lg-border-radius !important;

        > .btn {
          border-top-left-radius: $button-lg-border-radius !important;
          border-bottom-left-radius: $button-lg-border-radius !important;
        }
      }

      &:last-child {
        border-top-right-radius: $button-lg-border-radius !important;
        border-bottom-right-radius: $button-lg-border-radius !important;

        > .btn {
          border-top-right-radius: $button-lg-border-radius;
          border-bottom-right-radius: $button-lg-border-radius;
        }

        &:not(:first-child) {
          > .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}

.moshi-link {
  background-color: transparent;
  font-weight: 600;
  border: 0 !important;

  &.btn-link,
  &.btn-link-primary {
    @include moshi-btn-link($blue-700, $blue-600, $blue-600);
  }

  &.btn-link-danger {
    @include moshi-btn-link($red-600, $red-500, $red-500);
  }

  &.btn-link-black {
    @include moshi-btn-link($black-1000);
  }

  &.btn-link-white {
    @include moshi-btn-link($white-100);
  }

  &.btn-link-lg {
    font-size: $font-size-base - 2;
  }

  &.btn-link-xl {
    font-size: $font-size-base;
  }

  &.btn-link-xxl {
    font-size: $font-size-base + 1;
  }

  &.btn-link-sm {
    font-size: $font-size-base - 4;
  }
}

.moshi-button {
  @include button-base($black-1000);
  text-transform: none;
  width: auto;
  min-height: 30px;
  min-width: 140px;

  &:focus {
    outline: none !important;
  }

  &.icon {
    padding: 0;
  }

  .no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  &.btn-box-shadow {
    @include common-button-box-shadow;
  }

  &.btn-primary {
    @include moshi-button-variant(
      $blue-700,
      $blue-700,
      $blue-700,
      $white-100,
      $primary-button-shadow
    );
  }

  &.btn-secondary {
    @include moshi-button-variant(
      $blue-200,
      $blue-200,
      $blue-200,
      $blue-700,
      $secondary-button-shadow
    );
  }

  &.btn-light {
    @include moshi-button-variant(
      $white-100,
      $white-100,
      $white-100,
      $blue-700,
      $light-button-shadow
    );
    border: solid 1px $primary-blue-100 !important;
  }

  &.btn-danger {
    @include moshi-button-variant(
      $red-200,
      $red-200,
      $red-200,
      $red-600,
      $danger-button-shadow
    );
  }

  &.btn-gray {
    @include button-variant(
      $reverse-background,
      $reverse-background,
      $white-100,
      $white-100,
      $default-background,
      $default-background
    );
  }

  &.btn-transparent {
    @include moshi-button-variant(
      transparent,
      transparent,
      transparent,
      $black-1000
    );

    &:hover {
      color: $blue-700;
    }
  }

  &.btn-xs {
    @include moshi-button-size(
      $button-xs-height,
      $button-xs-font-size,
      $button-xs-padding,
      $button-xs-border-radius
    );
  }

  &.btn-sm {
    @include moshi-button-size(
      $button-sm-height,
      $button-sm-font-size,
      $button-sm-padding,
      $button-sm-border-radius
    );
  }

  &.btn-md {
    @include moshi-button-size(
      $button-md-height,
      $button-md-font-size,
      $button-md-padding,
      $button-md-border-radius
    );
  }

  &.btn-lg {
    @include moshi-button-size(
      $button-lg-height,
      $button-lg-font-size,
      $button-lg-padding,
      $button-lg-border-radius
    );
  }

  &.btn-capital-first {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &.btn-with-shadow {
    &:hover {
      box-shadow: $header-actions-shadow !important;
    }
  }

  &.btn-regular-font {
    font-weight: 400;
  }
}

.moshi-dropdown-button {
  .dropdown-toggle {
    // Caret
    &::after {
      float: right;
      width: 6px;
      height: 6px;
      margin-left: 15px;
      vertical-align: 0.1em;
      transform: rotate(135deg);

      border-top-width: 1px;
      border-top-style: solid;
      border-top-color: inherit;

      border-right-width: 1px;
      border-right-style: solid;
      border-right-color: inherit;

      border-left: 0;
      margin-top: 3px;
    }

    &.btn-light {
      &::after {
        // Fixes 'light' button's caret being too bright
        border-top-color: $blue-700;
        border-right-color: $blue-700;
      }
    }
  }

  &.split {
    > .btn {
      &:not(:first-child) {
        margin-left: 0;
        border-left: 1px solid $white-100 !important;

        // Caret when button split
        &::after {
          margin-top: 0;
          float: unset;
          margin-left: 0;
        }
      }

      &:first-child {
        margin-right: 0;
      }
    }
  }

  .btn-dropdown-menu {
    @include common-border-radius;
    @include smaller-box-shadow(rgba(0, 0, 0, 0.2));
    margin: 5px 0;
    padding: 0;
    overflow: hidden;
    border: 1px solid $violet-100;
    max-height: 300px;
    overflow-y: auto;

    .btn-dropdown-item {
      height: 40px;
      margin-left: 0;
      padding: 5px 14px;
      font-size: $font-size-base;
      font-weight: 400;
      text-decoration: none;
      border-bottom: 1px solid $violet-100;
      background-color: $white-300;

      &.danger {
        color: $default-danger;

        path {
          fill: $default-danger;
        }
      }

      &:active {
        background-color: initial;
        color: initial;
      }

      &:hover {
        background-color: $pastel-blue-100;
      }

      &:last-child {
        border-bottom: 0;
      }

      i {
        margin-right: 7px;
      }
    }
  }
}

.moshi-more-actions-button {
  .rs-more-actions-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white-100;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    border: 1px solid $grey-110;
    transition: $common-bg-transitions;

    &:hover {
      background-color: $blue-200;
    }
  }

  .card-more-actions-icon {
    color: $blue-700;
    margin: 0 !important;
  }
}
