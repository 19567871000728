@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.sidepanel-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  background-color: $box-shadow-color;
  z-index: 999;

  .sidepanel-container {
    height: 100%;
    max-width: 90%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: $white-100;

    .sidepanel-common-padding {
      padding: 24px 40px;
    }

    &.sidepanel-xl {
      min-width: 1200px;

      &.fixed-width {
        max-width: 1200px;
      }
    }

    &.sidepanel-lg {
      min-width: 1100px;

      &.fixed-width {
        max-width: 1100px;
      }
    }

    &.sidepanel-md {
      min-width: 770px;

      &.fixed-width {
        max-width: 770px;
      }
    }

    &.sidepanel-sm {
      width: 550px;

      &.fixed-width {
        max-width: 550px;
      }
    }

    .sidepanel-content {
      overflow: auto;
      height: 100%;
      position: relative;

      .sidepanel-close-btn-container {
        @extend %cursor-pointer;
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1000;

        .sidepanel-close-btn {
          padding: 4px;

          i {
            font-weight: bold;
          }
        }
      }

      header {
        margin-bottom: 40px;
        padding-bottom: 5px;

        h2 {
          margin-right: 70px;
        }

        > div {
          white-space: nowrap;
          float: right;

          .btn {
            margin-right: 20px;

            &:last-child {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }

      .sidepanel-group-title {
        margin-bottom: 25px;
      }
    }
  }
}
