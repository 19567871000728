/* sass-lint:disable no-duplicate-properties class-name-format force-pseudo-nesting */
@import "../abstracts/variables";
@import "../abstracts/placeholders";

@font-face {
  font-family: $font-family-ikonate-icons;
  src: url("#{$font-base-path}/Ikonate/fonts/ikonate.eot?z7b5kh");
  src: url("#{$font-base-path}/Ikonate/fonts/ikonate.eot?z7b5kh#iefix")
      format("embedded-opentype"),
    url("#{$font-base-path}/Ikonate/fonts/ikonate.ttf?z7b5kh")
      format("truetype"),
    url("#{$font-base-path}/Ikonate/fonts/ikonate.woff?z7b5kh") format("woff"),
    url("#{$font-base-path}/Ikonate/fonts/ikonate.svg?z7b5kh#icomoon")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  @extend %icon;
}

.icon-arrow-right-circle::before {
  content: "\e900";
}

.icon-arrow-left-circle::before {
  content: "\e901";
}

.icon-arrow-down-circle::before {
  content: "\e902";
}

.icon-arrow-up::before {
  content: "\e903";
}

.icon-arrow-right::before {
  content: "\e904";
}

.icon-arrow-right-top::before {
  content: "\e905";
}

.icon-arrow-right-bottom::before {
  content: "\e906";
}

.icon-arrow-left::before {
  content: "\e907";
}

.icon-arrow-left-top::before {
  content: "\e908";
}

.icon-arrow-left-bottom::before {
  content: "\e909";
}

.icon-arrow-down::before {
  content: "\e90a";
}

.icon-apps-alt::before {
  content: "\e90b";
}

.icon-apps::before {
  content: "\e90c";
}

.icon-aperture::before {
  content: "\e90d";
}

.icon-anchor::before {
  content: "\e90e";
}

.icon-align-right::before {
  content: "\e90f";
}

.icon-align-left::before {
  content: "\e910";
}

.icon-arrow-up-circle::before {
  content: "\e911";
}

.icon-align-center::before {
  content: "\e912";
}

.icon-alarm::before {
  content: "\e913";
}

.icon-add-to-list::before {
  content: "\e914";
}

.icon-add::before {
  content: "\e915";
}

.icon-activity::before {
  content: "\e916";
}

.icon-accessibility-human::before {
  content: "\e917";
}

.icon-accessibility::before {
  content: "\e918";
}

.icon-brightness::before {
  content: "\e919";
}

.icon-box-alt2::before {
  content: "\e91a";
}

.icon-box-alt::before {
  content: "\e91b";
}

.icon-box::before {
  content: "\e91c";
}

.icon-bookmark::before {
  content: "\e91d";
}

.icon-book::before {
  content: "\e91e";
}

.icon-book-opened::before {
  content: "\e91f";
}

.icon-bolt::before {
  content: "\e920";
}

.icon-bluetooth::before {
  content: "\e921";
}

.icon-bin::before {
  content: "\e922";
}

.icon-bike::before {
  content: "\e923";
}

.icon-bell::before {
  content: "\e924";
}

.icon-battery::before {
  content: "\e925";
}

.icon-battery-medium::before {
  content: "\e926";
}

.icon-battery-low::before {
  content: "\e927";
}

.icon-battery-full::before {
  content: "\e928";
}

.icon-battery-charging::before {
  content: "\e929";
}

.icon-bug::before {
  content: "\e92a";
}

.icon-battery-alt::before {
  content: "\e92b";
}

.icon-basketball::before {
  content: "\e92c";
}

.icon-basket::before {
  content: "\e92d";
}

.icon-bag::before {
  content: "\e92e";
}

.icon-back::before {
  content: "\e92f";
}

.icon-back-right::before {
  content: "\e930";
}

.icon-back-left::before {
  content: "\e931";
}

.icon-back-alt::before {
  content: "\e932";
}

.icon-code::before {
  content: "\e933";
}

.icon-cloud-crossed::before {
  content: "\e934";
}

.icon-cloud::before {
  content: "\e935";
}

.icon-cloud-upload::before {
  content: "\e936";
}

.icon-close::before {
  content: "\e937";
}

.icon-clipboard::before {
  content: "\e938";
}

.icon-clip::before {
  content: "\e939";
}

.icon-circle::before {
  content: "\e93a";
}

.icon-chevrons-up::before {
  content: "\e93b";
}

.icon-chevrons-right::before {
  content: "\e93c";
}

.icon-chevrons-left::before {
  content: "\e93d";
}

.icon-chevrons-down::before {
  content: "\e93e";
}

.icon-chevron-up::before {
  content: "\e93f";
}

.icon-chevron-right::before {
  content: "\e940";
}

.icon-chevron-left::before {
  content: "\e941";
}

.icon-chevron-down::before {
  content: "\e942";
}

.icon-checkbox::before {
  content: "\e943";
}

.icon-checkbox-intermediate::before {
  content: "\e944";
}

.icon-chat-warning::before {
  content: "\e945";
}

.icon-chat-remove::before {
  content: "\e946";
}

.icon-chat-add::before {
  content: "\e947";
}

.icon-chat::before {
  content: "\e948";
}

.icon-chat-alt::before {
  content: "\e949";
}

.icon-chart::before {
  content: "\e94a";
}

.icon-celluar::before {
  content: "\e94b";
}

.icon-cart-add::before {
  content: "\e94c";
}

.icon-cart::before {
  content: "\e94d";
}

.icon-cards::before {
  content: "\e94e";
}

.icon-car-alt::before {
  content: "\e94f";
}

.icon-car::before {
  content: "\e950";
}

.icon-cancel::before {
  content: "\e951";
}

.icon-camera::before {
  content: "\e952";
}

.icon-camera-rear::before {
  content: "\e953";
}

.icon-calendar-event::before {
  content: "\e954";
}

.icon-calendar-add::before {
  content: "\e955";
}

.icon-calendar-decline::before {
  content: "\e956";
}

.icon-calendar::before {
  content: "\e957";
}

.icon-code-alt::before {
  content: "\e958";
}

.icon-coffee::before {
  content: "\e959";
}

.icon-drop::before {
  content: "\e95a";
}

.icon-download::before {
  content: "\e95b";
}

.icon-dolar::before {
  content: "\e95c";
}

.icon-disc::before {
  content: "\e95d";
}

.icon-directions-right::before {
  content: "\e95e";
}

.icon-directions-left::before {
  content: "\e95f";
}

.icon-direction-right::before {
  content: "\e960";
}

.icon-direction-left::before {
  content: "\e961";
}

.icon-dialpad::before {
  content: "\e962";
}

.icon-diamond::before {
  content: "\e963";
}

.icon-delete::before {
  content: "\e964";
}

.icon-dashboard::before {
  content: "\e965";
}

.icon-danger::before {
  content: "\e966";
}

.icon-cut::before {
  content: "\e967";
}

.icon-cursor::before {
  content: "\e968";
}

.icon-cup::before {
  content: "\e969";
}

.icon-crossing::before {
  content: "\e96a";
}

.icon-crop::before {
  content: "\e96b";
}

.icon-credit-card::before {
  content: "\e96c";
}

.icon-copy::before {
  content: "\e96d";
}

.icon-controls-vertical-alt::before {
  content: "\e96e";
}

.icon-controls-alt::before {
  content: "\e96f";
}

.icon-controls::before {
  content: "\e970";
}

.icon-control-centre::before {
  content: "\e971";
}

.icon-contrast::before {
  content: "\e972";
}

.icon-contact-book::before {
  content: "\e973";
}

.icon-component::before {
  content: "\e974";
}

.icon-colours::before {
  content: "\e975";
}

.icon-eye-crossed::before {
  content: "\e976";
}

.icon-eye::before {
  content: "\e977";
}

.icon-extension::before {
  content: "\e978";
}

.icon-explore::before {
  content: "\e979";
}

.icon-exit::before {
  content: "\e97a";
}

.icon-error::before {
  content: "\e97b";
}

.icon-envelope::before {
  content: "\e97c";
}

.icon-envelope-alt::before {
  content: "\e97d";
}

.icon-entrance::before {
  content: "\e97e";
}

.icon-ellypsis::before {
  content: "\e97f";
}

.icon-ellypsis-vertical::before {
  content: "\e980";
}

.icon-edit::before {
  content: "\e981";
}

.icon-ear::before {
  content: "\e982";
}

.icon-eye-closed::before {
  content: "\e983";
}

.icon-grid::before {
  content: "\e984";
}

.icon-grid-small::before {
  content: "\e985";
}

.icon-glasses::before {
  content: "\e986";
}

.icon-forward::before {
  content: "\e987";
}

.icon-folder-warning::before {
  content: "\e988";
}

.icon-folder-remove::before {
  content: "\e989";
}

.icon-folder-add::before {
  content: "\e98a";
}

.icon-folder::before {
  content: "\e98b";
}

.icon-flower::before {
  content: "\e98c";
}

.icon-flag::before {
  content: "\e98d";
}

.icon-fit::before {
  content: "\e98e";
}

.icon-filter::before {
  content: "\e98f";
}

.icon-film::before {
  content: "\e990";
}

.icon-file::before {
  content: "\e991";
}

.icon-feed::before {
  content: "\e992";
}

.icon-grid-alt::before {
  content: "\e993";
}

.icon-feather::before {
  content: "\e994";
}

.icon-favourite::before {
  content: "\e995";
}

.icon-justify::before {
  content: "\e996";
}

.icon-iphone::before {
  content: "\e997";
}

.icon-info::before {
  content: "\e998";
}

.icon-inbox-alt::before {
  content: "\e999";
}

.icon-inbox::before {
  content: "\e99a";
}

.icon-image::before {
  content: "\e99b";
}

.icon-hourglass::before {
  content: "\e99c";
}

.icon-horn::before {
  content: "\e99d";
}

.icon-home-alt2::before {
  content: "\e99e";
}

.icon-home-alt::before {
  content: "\e99f";
}

.icon-home::before {
  content: "\e9a0";
}

.icon-history::before {
  content: "\e9a1";
}

.icon-help::before {
  content: "\e9a2";
}

.icon-hearing-disability::before {
  content: "\e9a3";
}

.icon-headphones::before {
  content: "\e9a4";
}

.icon-hdr::before {
  content: "\e9a5";
}

.icon-hash::before {
  content: "\e9a6";
}

.icon-key::before {
  content: "\e9a7";
}

.icon-happy-face::before {
  content: "\e9a8";
}

.icon-hamburger::before {
  content: "\e9a9";
}

.icon-lock-open::before {
  content: "\e9aa";
}

.icon-lock-alt::before {
  content: "\e9ab";
}

.icon-lock-alt-open::before {
  content: "\e9ac";
}

.icon-location::before {
  content: "\e9ad";
}

.icon-list-view::before {
  content: "\e9ae";
}

.icon-list::before {
  content: "\e9af";
}

.icon-list-alt::before {
  content: "\e9b0";
}

.icon-link::before {
  content: "\e9b1";
}

.icon-line-chart::before {
  content: "\e9b2";
}

.icon-lightbulb::before {
  content: "\e9b3";
}

.icon-layout-right::before {
  content: "\e9b4";
}

.icon-column::before {
  content: "\e9b5";
}

.icon-layout-left::before {
  content: "\e9b6";
}

.icon-layers::before {
  content: "\e9b7";
}

.icon-laptop::before {
  content: "\e9b8";
}

.icon-language::before {
  content: "\e9b9";
}

.icon-lock::before {
  content: "\e9ba";
}

.icon-music::before {
  content: "\e9bb";
}

.icon-mouse::before {
  content: "\e9bc";
}

.icon-moon::before {
  content: "\e9bd";
}

.icon-minus::before {
  content: "\e9be";
}

.icon-minimise::before {
  content: "\e9bf";
}

.icon-microphone::before {
  content: "\e9c0";
}

.icon-maximise::before {
  content: "\e9c1";
}

.icon-mask::before {
  content: "\e9c2";
}

.icon-marker::before {
  content: "\e9c3";
}

.icon-map::before {
  content: "\e9c4";
}

.icon-mute::before {
  content: "\e9c5";
}

.icon-ok-circle::before {
  content: "\e9c6";
}

.icon-ok::before {
  content: "\e9c7";
}

.icon-octagon::before {
  content: "\e9c8";
}

.icon-notebook::before {
  content: "\e9c9";
}

.icon-not-allowed::before {
  content: "\e9ca";
}

.icon-no-entry::before {
  content: "\e9cb";
}

.icon-next-alt::before {
  content: "\e9cc";
}

.icon-night-mode::before {
  content: "\e9cd";
}

.icon-next::before {
  content: "\e9ce";
}

.icon-news::before {
  content: "\e9cf";
}

.icon-new::before {
  content: "\e9d0";
}

.icon-neutral-face::before {
  content: "\e9d1";
}

.icon-origin::before {
  content: "\e9d2";
}

.icon-previous-alt::before {
  content: "\e9d3";
}

.icon-previous::before {
  content: "\e9d4";
}

.icon-presentation::before {
  content: "\e9d5";
}

.icon-power::before {
  content: "\e9d6";
}

.icon-poll::before {
  content: "\e9d7";
}

.icon-plus::before {
  content: "\e9d8";
}

.icon-play::before {
  content: "\e9d9";
}

.icon-plane::before {
  content: "\e9da";
}

.icon-placeholder::before {
  content: "\e9db";
}

.icon-pizza::before {
  content: "\e9dc";
}

.icon-pie-chart::before {
  content: "\e9dd";
}

.icon-phone::before {
  content: "\e9de";
}

.icon-person-add::before {
  content: "\e9df";
}

.icon-person::before {
  content: "\e9e0";
}

.icon-people::before {
  content: "\e9e1";
}

.icon-pen::before {
  content: "\e9e2";
}

.icon-pause-circle::before {
  content: "\e9e3";
}

.icon-print::before {
  content: "\e9e4";
}

.icon-pause::before {
  content: "\e9e5";
}

.icon-paperclip::before {
  content: "\e9e6";
}

.icon-pan::before {
  content: "\e9e7";
}

.icon-rotate::before {
  content: "\e9e8";
}

.icon-rocket::before {
  content: "\e9e9";
}

.icon-retweet::before {
  content: "\e9ea";
}

.icon-return::before {
  content: "\e9eb";
}

.icon-restaurant::before {
  content: "\e9ec";
}

.icon-repeat::before {
  content: "\e9ed";
}

.icon-remove::before {
  content: "\e9ee";
}

.icon-refresh::before {
  content: "\e9ef";
}

.icon-redo::before {
  content: "\e9f0";
}

.icon-rectangle::before {
  content: "\e9f1";
}

.icon-rain::before {
  content: "\e9f2";
}

.icon-radio-button-selected::before {
  content: "\e9f3";
}

.icon-radio-button::before {
  content: "\e9f4";
}

.icon-radio::before {
  content: "\e9f5";
}

.icon-rss::before {
  content: "\e9f6";
}

.icon-switch-off::before {
  content: "\e9f7";
}

.icon-swap-horizontal::before {
  content: "\e9f8";
}

.icon-swap-vertical::before {
  content: "\e9f9";
}

.icon-support-alt::before {
  content: "\e9fa";
}

.icon-support::before {
  content: "\e9fb";
}

.icon-sunset::before {
  content: "\e9fc";
}

.icon-sun::before {
  content: "\e9fd";
}

.icon-sun-cloud::before {
  content: "\e9fe";
}

.icon-suitcase::before {
  content: "\e9ff";
}

.icon-suitcase-alt::before {
  content: "\ea00";
}

.icon-stopwatch::before {
  content: "\ea01";
}

.icon-stop::before {
  content: "\ea02";
}

.icon-sticker::before {
  content: "\ea03";
}

.icon-stats::before {
  content: "\ea04";
}

.icon-stats-alt::before {
  content: "\ea05";
}

.icon-star::before {
  content: "\ea06";
}

.icon-stack::before {
  content: "\ea07";
}

.icon-spam::before {
  content: "\ea08";
}

.icon-sounds::before {
  content: "\ea09";
}

.icon-sort-up::before {
  content: "\ea0a";
}

.icon-sort-down::before {
  content: "\ea0b";
}

.icon-sorting::before {
  content: "\ea0c";
}

.icon-snow::before {
  content: "\ea0d";
}

.icon-smartphone::before {
  content: "\ea0e";
}

.icon-sign-language::before {
  content: "\ea0f";
}

.icon-signal::before {
  content: "\ea10";
}

.icon-signal-alt::before {
  content: "\ea11";
}

.icon-skull::before {
  content: "\ea12";
}

.icon-shuffle::before {
  content: "\ea13";
}

.icon-shift::before {
  content: "\ea14";
}

.icon-shield::before {
  content: "\ea15";
}

.icon-share::before {
  content: "\ea16";
}

.icon-share-ios::before {
  content: "\ea17";
}

.icon-share-android::before {
  content: "\ea18";
}

.icon-settings::before {
  content: "\ea19";
}

.icon-send::before {
  content: "\ea1a";
}

.icon-search::before {
  content: "\ea1b";
}

.icon-save::before {
  content: "\ea1c";
}

.icon-sad-face::before {
  content: "\ea1d";
}

.icon-switch-on::before {
  content: "\ea1e";
}

.icon-tv::before {
  content: "\ea1f";
}

.icon-turn-right::before {
  content: "\ea20";
}

.icon-turn-left::before {
  content: "\ea21";
}

.icon-trending-up::before {
  content: "\ea22";
}

.icon-trending-down::before {
  content: "\ea23";
}

.icon-train::before {
  content: "\ea24";
}

.icon-transport::before {
  content: "\ea25";
}

.icon-tool::before {
  content: "\ea26";
}

.icon-timer::before {
  content: "\ea27";
}

.icon-time::before {
  content: "\ea28";
}

.icon-thunder::before {
  content: "\ea29";
}

.icon-thumb-up::before {
  content: "\ea2a";
}

.icon-thumb-down::before {
  content: "\ea2b";
}

.icon-thermometer::before {
  content: "\ea2c";
}

.icon-text::before {
  content: "\ea2d";
}

.icon-tag::before {
  content: "\ea2e";
}

.icon-tag-alt::before {
  content: "\ea2f";
}

.icon-typography::before {
  content: "\ea30";
}

.icon-table-vertical::before {
  content: "\ea31";
}

.icon-table-horizontal::before {
  content: "\ea32";
}

.icon-upload::before {
  content: "\ea33";
}

.icon-undo::before {
  content: "\ea34";
}

.icon-umbrella::before {
  content: "\ea35";
}

.icon-user::before {
  content: "\ea36";
}

.icon-volume-off::before {
  content: "\ea37";
}

.icon-volume-loud::before {
  content: "\ea38";
}

.icon-voicemail::before {
  content: "\ea39";
}

.icon-video::before {
  content: "\ea3a";
}

.icon-vertical::before {
  content: "\ea3b";
}

.icon-verified::before {
  content: "\ea3c";
}

.icon-volume-quiet::before {
  content: "\ea3d";
}

.icon-watch::before {
  content: "\ea3e";
}

.icon-wallpaper::before {
  content: "\ea3f";
}

.icon-walking::before {
  content: "\ea40";
}

.icon-wheelchair::before {
  content: "\ea41";
}

.icon-wine::before {
  content: "\ea42";
}

.icon-window::before {
  content: "\ea43";
}

.icon-wifi::before {
  content: "\ea44";
}

.icon-qr::before {
  content: "\ea45";
}

.icon-zoom-out::before {
  content: "\ea46";
}

.icon-zoom-in::before {
  content: "\ea47";
}
