// sass-lint:disable class-name-format
@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.Toastify__toast-container {
  z-index: 9999999999;

  .Toastify__toast {
    border-radius: $base-border-radius-size - 6px;
    font-family: $font-family-ibm-plex-sans;
    min-height: 40px;
    margin-bottom: 10px;

    &.toast-unclickable {
      cursor: unset;
    }
  }

  .Toastify__toast-body {
    font-weight: 400;
    margin-left: 10px;
  }

  .icon-close {
    display: flex;
    align-items: center;
  }

  .Toastify__toast--info {
    background-color: $pastel-blue-850;
  }

  .Toastify__toast--success {
    background-color: $green-600;
  }

  .Toastify__toast--warning {
    background-color: $default-warning;
  }

  .Toastify__toast--error {
    background-color: $red-500;
  }

  .Toastify__toast--default {
    background-color: $white-100;
    color: $default-primary;
  }

  .Toastify__progress-bar--default {
    background: $default-primary;
  }

  &.Toastify__toast-container--bottom-center {
    &.moshi-full-width-toast-container {
      bottom: 15px;
    }
  }

  &.Toastify__toast-container--top-center {
    &.moshi-full-width-toast-container {
      top: 0;
    }
  }

  &.moshi-full-width-toast-container {
    width: 100%;
    padding: 0;

    &.default-z-index {
      z-index: 100;
    }

    .moshi-full-width-toast {
      border-radius: 0;
      margin-bottom: 0;
      min-height: auto;
      padding: 10px !important;
      text-align: center;
      font-size: $font-size-title3;
      font-weight: 600;

      .Toastify__toast-body {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
// sass-lint:enable class-name-format
