@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.simple-gray-blue-card {
  @include common-border-radius;
  background-color: $pastel-blue-100;
  padding: 20px;
}

.card {
  @include common-border-radius;
  @include common-box-shadow($card-shadow-color);
  margin-bottom: 20px;
  padding: $card-base-padding;
  border-width: 0;

  .card-title {
    margin-bottom: 0;
  }

  .card-body {
    padding: 0;
  }

  &.selected-card {
    border: 2px solid $main-border;
  }

  &.highlight {
    animation: highlight-fade-orange-to-white 5s ease-in-out 0s;
  }

  .card-vertical-layout {
    flex-direction: row;
    display: flex;
  }

  form {
    height: 100%;
  }

  .bottom-actions {
    margin-right: 0;

    .edit-button-toggle {
      display: inline-block;
    }

    .right-align {
      float: right;
      margin-top: 3px;

      .btn {
        text-align: right;
      }

      @include respond-to("card-with-location-max-width") {
        float: left;

        .btn {
          text-align: left;
        }
      }
    }
  }

  &.no-border {
    border: 0;
    margin: 0;
    padding: 0;

    .card-body {
      margin: 0;
      padding: 0;
    }
  }

  &.no-body-border {
    border: 0;
  }

  &.card-no-style {
    border: 0;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    margin-left: 0;

    .row {
      > div {
        padding-left: 5px;
      }
    }
  }

  &.no-box-shadow {
    box-shadow: none;
  }
}

.card-two-column-layout {
  &.card {
    flex-direction: row;
  }

  .card-body {
    width: 50%;
  }
}

.card-small-padding {
  padding: $card-small-padding;
}

.card-more-actions {
  position: absolute;
  right: 15px;
  top: 25px;

  .more-toggle {
    i {
      font-size: $font-size-lg;
      margin-top: 0;
      margin-left: 3px;
      color: $grey4;
    }
  }
}
