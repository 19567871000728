@import "../../abstracts/variables";
@import "../../abstracts/placeholders";
@import "../../abstracts/mixins";

.checkbox-list {
  .filter-checkbox-row {
    .checkbox-wrapper {
      input {
        &[type="checkbox"] {
          display: none;
        }
      }
    }

    .checkbox-label {
      font-size: $font-size-base + 1;

      &:hover {
        color: $black-1000 !important;
      }
    }

    .moshi-tag {
      &:hover {
        @include smaller-box-shadow($pale-lilac);
      }
    }

    .checkbox-row-icon {
      color: $grey-800;
      opacity: 0.8;
      visibility: hidden;
      margin-right: 3px;

      &:hover {
        cursor: pointer;
        color: $blue-700;
      }
    }

    &:hover {
      background-color: $white-300;

      .checkbox-row-icon {
        visibility: visible;
      }
    }
  }
}
