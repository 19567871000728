@import "../../abstracts/variables";

$device-card-width: 340px;

.device-content {
  padding: 20px 0 40px;
}

.dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  padding: 5px;
  margin-right: 5px;

  &.online {
    background-color: $success-green;
  }

  &.offline {
    background-color: $error-red;
  }
}

.device-card {
  height: auto;
  width: $device-card-width;
  padding: 20px;
  border: 0;
  min-height: 316px;

  .card-title {
    margin-bottom: 20px;

    .device-card-title {
      font-size: $font-size-lg;
      margin-bottom: 5px;
      margin-top: 17px;
      color: $light-black;
      letter-spacing: 0.5px;
      height: auto;
    }
  }

  .card-body {
    padding: 0;
    line-height: 1.43;
  }

  .card-footer {
    background-color: $white-100;
    border: 0;
    padding: 0;

    .btn-link-danger {
      margin-bottom: -10px;
    }
  }

  &.add-device-card {
    background-color: $reverse-background;
    box-shadow: unset;
    padding: 0;
    height: 316px;

    .card-body {
      // safari needs this properties to center the button
      width: 100%;
      height: 100%;
    }

    button {
      height: 100%;
      width: 100%;
      text-align: center;
      padding: 0 !important;
      font-size: $font-size-base - 2;
    }
  }

  @media screen and (max-width: $container-max-width) {
    width: $device-card-width - 20;
  }
}

.modal-dialog {
  &.confirm-remove-device-modal,
  &.add-device-success-modal,
  &.device-modal {
    h5 {
      .modal-text-title {
        font-size: $font-size-lg !important;
      }

      &.modal-title {
        font-size: $font-size-base * 2;
        letter-spacing: 0.3px;
        text-align: center;

        img {
          width: 200px;
        }
      }
    }

    .card {
      background-color: $reverse-background;
      border-color: $grey-blue;
      padding: 55px 0 45px 40px;

      .property {
        font-size: $font-size-sm;
        line-height: 1.09;
        letter-spacing: 0.5px;
        color: $grey5;
      }

      .value {
        font-size: $font-size-base;
        line-height: 1.79;
        color: $black-1000;
      }
    }

    .modal-footer {
      .justify-content-center {
        .btn {
          width: 100%;
          height: 40px;
        }
      }
    }
  }

  &.add-device-modal {
    min-width: 960px;

    h5 {
      &.modal-title {
        font-size: $font-size-base * 2;
        letter-spacing: 0.3px;
      }
    }

    .modal-content {
      .modal-body {
        h5 {
          font-size: $font-size-lg;
          line-height: 1;
          letter-spacing: 0.5px;

          &.step-one-title {
            margin-top: 45px;
          }
        }
      }

      .row {
        margin-top: 40px;
        margin-bottom: 40px;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .enter-code-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 35px;

        .device-code {
          margin-top: auto;
          margin-bottom: 0;

          input {
            width: 100%;
            height: 100px;
            border-radius: 14px;
            background-color: $reverse-background;
            padding: 11px 15px;

            font-size: $font-size-title2;
            letter-spacing: 18px;
            text-align: center;
            color: $black-1000;

            &::placeholder {
              letter-spacing: 0.3px;
              color: $grey5;
            }
          }

          .invalid-feedback {
            position: absolute;
            bottom: -25px;
          }
        }
      }

      .centered-image {
        display: flex;
        justify-content: center;

        &.custom-drop-shadow-image {
          img {
            box-shadow: 30px 30px 40px -25px $box-shadow-color;
            border-radius: 11px;
          }
        }
      }

      .step-three-container {
        margin-top: 37px;
      }
    }

    .modal-footer {
      margin-top: 0 !important;
    }
  }
}
