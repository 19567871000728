.user-detail-layout {
  .navigation {
    margin-bottom: 0;
  }

  .user-detail {
    .user-profile-title {
      margin: 57px 0 17px;
    }

    .card {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 100px;

      .form-group {
        margin: 18px 10px;
      }

      &.identification-wrapper-card {
        padding-left: 0;

        > .row {
          margin-left: 0;
          margin-right: 0;

          .card-no-style {
            margin-bottom: 0;
          }
        }
      }

      &.avatar {
        padding-left: 20px;
        padding-right: 20px;

        .image-component {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }

    .change-password-button {
      height: 56px;
    }

    .user-rights {
      padding-bottom: 20px;

      .label-row-columns {
        .form-group-label {
          margin-left: 10px;
          margin-top: 14px;
          margin-bottom: 0;
        }
      }

      // TODO: Maybe a bit ugly
      .remove-button-container {
        display: flex;
        justify-content: center;

        .btn-link-danger {
          display: flex;
          align-self: center;
          margin-top: 20px;
        }
      }

      .btn-link {
        &:not(.btn-link-danger) {
          margin-left: 10px;
        }
      }
    }
  }
}

.user-detail {
  .card {
    &.color-picker-card {
      min-height: auto;
      padding-bottom: 20px;

      .color-picker-form-group {
        margin-bottom: 0;
      }
    }
  }
}
