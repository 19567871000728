@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.event-auto-suggest-result-card {
  display: flex;

  .icon-container {
    display: flex;
    align-items: center;
    margin-right: 18px;
    margin-left: 10px;
  }

  .details-container {
    display: flex;
    flex-direction: column;
    font-size: $font-size-md;

    .date-time-location {
      font-weight: 400;
    }

    .name-title {
      font-weight: 500;
    }
  }
}
