// sass-lint:disable force-attribute-nesting

@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.moshi-tooltip {
  font-weight: 400;
  transition: opacity 0s;

  .tooltip {
    pointer-events: none;
  }

  &.large {
    .tooltip-inner {
      max-width: 50vw;
    }
  }

  &.medium {
    .tooltip-inner {
      max-width: 30vw;
    }
  }

  .tooltip-inner {
    @include common-border-radius;
    font-size: $font-size-base;
    padding: 10px 13px;
  }

  &.show {
    opacity: 1;
  }

  &.tooltip-danger {
    @include tooltip-variant($error-red);
  }

  &.tooltip-warning {
    @include tooltip-variant($warning-orange);
  }

  &.tooltip-success {
    @include tooltip-variant($success-green);
  }

  &.tooltip-info {
    @include tooltip-variant($default-font-color);
  }

  &.tooltip-white {
    @include tooltip-variant($white);
  }
}
