@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.table-actions {
  margin-top: 30px;
  align-items: center;
  margin-left: 0;
  margin-right: 0;

  &:has(.align-right) {
    align-items: flex-end;
  }

  label {
    color: $grey5;
    width: initial;
    margin: 0;
    font-weight: 600;
    font-size: $font-size-sm;
  }

  .btn {
    margin: 0 10px;
  }

  > div {
    &:last-of-type {
      margin-left: auto;
    }
  }

  .align-right {
    .dropdown-toggle {
      margin-left: 5px;
      color: $grey5 !important;
      text-transform: uppercase;

      &:hover,
      &:focus,
      &:active {
        color: $grey5 !important;
      }
    }
  }
}

.table-form-buttons {
  position: absolute;
  right: 15px;
  top: 15px;
}

.table {
  margin-top: 45px;

  > .row {
    margin-left: 0;
    margin-right: 0;
  }

  .row {
    border: 0;
    border-bottom: 1px solid $grey-blue;

    .table-row-icon {
      margin-top: -5px;
    }

    .card {
      min-height: 80px;
      justify-content: flex-start;
      border: 0;
      padding-top: 20px;
      padding-left: 15px;
      padding-bottom: 20px;
      margin-bottom: 10px;

      &.table-row {
        @include hover {
          @include common-box-shadow($dropdown-shadow-color);
        }
      }

      &.small-table-card {
        min-height: 60px;
        padding-top: 10px;
        padding-bottom: 10px;

        .card-more-actions {
          top: 15px;
        }
      }

      .column-cell {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 0;
        padding-right: 0;

        .cell-with-icon {
          padding: 0;
          min-width: 35px;
          max-width: 35px;

          + .col-11 {
            flex: 1 0;
          }
        }

        &:first-child {
          padding-left: 15px;
          padding-right: 15px;
        }

        > a {
          display: table;
        }

        .badge {
          position: absolute;
          margin-top: 0;
        }

        .cell-wrapper {
          padding-top: 10px;

          .row {
            margin-left: 0;
            margin-right: 0;
            border: 0;
          }

          &:last-child {
            padding-right: 15px;
          }
        }
      }
    }

    &.table-header {
      border-bottom: 0;

      .column-header {
        padding: 0;
      }

      label {
        text-transform: none;
        opacity: 0.3;
        font-size: $font-size-base;
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    form {
      .row {
        border: 0;

        .no-left-padding {
          padding-left: 0;
        }
      }
    }
  }
}
