.bill-type-icon-container {
  display: inline-block;
  border-radius: 10px;
  padding-top: 3px;
  text-align: center;
  width: 38px;
  height: 38px;

  .bill-type-icon {
    color: $white-100;
  }

  &.invoice {
    background-color: $green-600;
  }

  &.estimate {
    background-color: $yellow-600;
  }

  &.advance {
    background-color: $orange-500;
  }

  &.credit-note {
    background-color: $violet-600;
  }

  &.bill-type-cell {
    width: 23px;
    height: 23px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-icon-md;
    border-radius: 5px;
    margin-right: 10px;
    padding: 0;
    vertical-align: bottom;
  }
}
