.remove-icon-button {
  padding: 0 !important;
  font-size: $font-size-md !important;
}

.remove-icon-link {
  padding: 2px;
  color: $blue-700;

  &:hover {
    border-radius: 50%;
    background-color: $blue-200;
  }
}
