@import "../../abstracts/variables";
@import "../../abstracts/mixins";

/* sass-lint:disable class-name-format */
%dropdown-item-radius-reset {
  .dropdown---menu-item---1LjoL {
    border-radius: 0;
  }
}

%dropdown-item-radius-top {
  .dropdown---menu-item---1LjoL {
    border-radius: $base-border-radius-size $base-border-radius-size 0 0;
  }
}

%dropdown-item-radius-only-child {
  .dropdown---menu-item---1LjoL {
    border-radius: $base-border-radius-size;
  }
}

%dropdown-item-radius-bottom {
  .dropdown---menu-item---1LjoL {
    border-bottom-left-radius: $base-border-radius-size;
    border-bottom-right-radius: $base-border-radius-size;
    border: 0;
  }
}

.dropdown---dropdown---1yvIZ {
  .flip-lr {
    text-align: left;
    transform: scale(-1, 1);
    min-width: 100%;

    .dropdown-toggle-label {
      margin-right: 0 !important;
    }

    .flipped-submenu-container {
      text-align: left;
    }

    .flipped-submenu-content,
    .dropdown-item-null,
    .dropdown-filter {
      transform: scale(-1, 1);
    }
  }

  &.empty {
    .form-look {
      .dropdown-toggle-label {
        color: $cloudy-blue;
      }
    }
  }

  .dropdown-toggle {
    font-weight: 500;
    font-size: $font-size-base;
    color: $black-1000;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
    text-transform: initial;
    width: 100%;

    .dropdown-toggle-label {
      display: inline-block;
      letter-spacing: inherit;
      font-weight: 400;
    }

    .dropdown-caret {
      float: right;

      i {
        display: inline-block;
        position: absolute;
        top: calc(50% - 2px);
        -ms-transform: translateY(-50%) rotate(45deg);
        transform: translateY(-50%) rotate(45deg);

        padding: 3px;
        border: solid $grey5;
        border-width: 0 2px 2px 0;

        &.open {
          top: calc(50% + 2px);
          transform: translateY(-50%) rotate(-135deg);
          -webkit-transform: translateY(-50%) rotate(-135deg);
        }
      }
    }

    &::after {
      display: none;
    }

    &.no-style-look {
      padding: unset;
      border: unset;
      text-align: unset;
      margin: unset;
      color: unset;
      background-color: unset;

      ~ .dropdown-menu {
        .dropdown-menu-content {
          top: 100%;
        }
      }
    }

    &.form-look {
      @include common-border-radius;
      background-image: linear-gradient(to bottom, $white-100, $grey7);
      font-weight: 400;
      height: 42px;
      border: 1px solid $grey-400;

      &:disabled {
        opacity: 1;
        border-radius: $base-border-radius-size !important;
        background-color: $pastel-blue-100;
        background-image: none;
        border-color: $reverse-background;

        .dropdown-toggle-label {
          color: $lighter-font-color;
        }

        .dropdown-caret {
          i {
            border-color: $lighter-font-color;
          }
        }
      }

      &.open,
      &:active,
      &:focus {
        color: $black-1000;
        box-shadow: 0 2px 10px 0 $light-blue-grey !important;
        border: 1px transparent;
      }

      &:hover {
        background-image: linear-gradient(
          to bottom,
          $default-background,
          $grey6
        );
        color: $black-1000;
      }
    }

    &.button-look {
      @include moshi-button-variant(
        $blue-700,
        $blue-700,
        $blue-700,
        $white-100,
        $primary-button-shadow
      );

      .dropdown-caret {
        i {
          border-color: $white-100;
        }
      }
    }

    &.filter-look {
      height: 42px;
      border-radius: $sm-border-radius-size;
      border: 0;
      background-color: $reverse-background;
      padding: 6px 25px 6px 12px;

      &.no-caret {
        padding-right: 12px;
      }

      .dropdown-value {
        color: $default-font-color;
      }

      .dropdown-toggle-label {
        &:not(.has-prefix) {
          margin-top: 2px;
        }
      }

      .has-prefix {
        &.dropdown-toggle-label {
          margin-top: 0;
        }

        .dropdown-prefix {
          display: block;
          letter-spacing: normal;
          margin-top: -4px;
          font-size: $font-size-base - 2;
          font-weight: 500;
          color: $grey5;
        }

        .dropdown-value {
          display: block;
          margin-top: -6px;
          font-size: $font-size-base;
        }
      }

      &.open,
      &:hover,
      &:active {
        background-color: $white-100;
        box-shadow: $header-actions-shadow;
      }
    }

    &.tiny-look {
      @include common-navigation-title;
      height: auto;
      border: 0;
      padding-left: 0;
      background-color: unset;

      &.open {
        color: $black-1000;
      }

      &.link {
        color: $default-primary !important;
      }

      ~ .dropdown-menu {
        .dropdown-menu-content {
          top: calc(100% + 5px);
        }
      }

      .dropdown-toggle-label {
        margin-right: 0 !important;
      }

      .dropdown-value,
      .dropdown-prefix {
        font-size: inherit;
        color: inherit;
        height: inherit;
        letter-spacing: inherit;
        font-weight: 600;
      }

      .dropdown-caret {
        position: relative;
        top: 4px;
        margin-left: 0;

        i {
          @include chevron-variant(
            $grey5,
            $rotation: "top",
            $size: 4px,
            $thickness: 1px,
            $transparent-color: transparent
          );
          border: 0;
          transform: none !important;
          -webkit-transform: none !important;

          &.open {
            @include chevron-variant(
              $black-1000,
              $rotation: "bottom",
              $size: 4px,
              $thickness: 1px,
              $transparent-color: transparent
            );
            top: -5px;
          }
        }
      }
    }

    &.center-text-look {
      background-color: $white-100;
      text-align: center;
      padding: 7px !important;
      border: 1px solid $pale-lilac;
      box-shadow: 0 2px 2px 0 $pagination-box-shadow-color;
      border-radius: $base-border-radius-size;
      text-transform: uppercase;
      font-size: $font-size-md;

      .dropdown-caret {
        margin-right: 20px;
      }
    }

    &:not(.no-caret) {
      padding-right: 25px;

      .dropdown-toggle-label {
        margin-right: 14px;
      }
    }
  }

  &.font-size-md {
    .dropdown-toggle-label {
      .dropdown-value {
        font-size: $font-size-md;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    border: 0;
    min-width: 100%;

    div {
      &[role="menu"] {
        @include common-border-radius;
        @include alternative-box-shadow($popover-shadow-color);

        border: 1px solid $pale-lilac !important;
        background-color: $reverse-background;
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 30px;

        &.dropdown-menu-content {
          min-width: 100%;
          position: absolute;
          top: calc(100% + 9px);

          .menu-item-wrapper {
            max-height: 340px;
            overflow-y: scroll;
          }

          .danger {
            color: $default-danger;

            path {
              fill: $default-danger;
            }
          }
        }
      }
    }

    .dropdown-filter {
      padding: 7px;
      height: 55px;
      border-top-left-radius: $base-border-radius-size;
      border-top-right-radius: $base-border-radius-size;
      border: 1px solid $pale-lilac !important;

      background-color: $reverse-background;
      position: absolute;
      top: 9px;
      z-index: 1001;

      ~ .dropdown-menu-content {
        min-width: 200px !important;
        position: absolute;
        top: calc(100% + 64px) !important;
        max-height: 340px;
        overflow-y: hidden;
        border-radius: unset;
      }

      &.no-results {
        border-bottom-left-radius: $base-border-radius-size;
        border-bottom-right-radius: $base-border-radius-size;

        ~ .dropdown-menu-content {
          display: none;
        }

        ~ .shadow-caster {
          border-bottom-right-radius: $base-border-radius-size !important;
          border-bottom-left-radius: $base-border-radius-size !important;
        }
      }

      &.show-scrollbar {
        ~ .dropdown-menu-content {
          overflow-y: scroll;
        }
      }

      > input {
        &[name="filter"] {
          @include tiny-border-box-shadow($pale-lilac);
          border: 0;
          padding-left: 36px;
          padding-right: 30px;
        }
      }

      .filter-search-icon {
        position: absolute;
        top: 19px;
        left: 17px;
        z-index: 1;
      }

      .clear-button-wrapper {
        height: 42px;
      }

      .clear-button-icon {
        float: right;
        position: relative;
        margin-top: -2px;
        margin-left: 5px;
        width: 16px;
        height: 16px;
        top: auto;
      }

      ~ .dropdown---dropdown-menu---1fkH0 {
        @extend %dropdown-item-radius-reset;

        border-bottom-left-radius: $base-border-radius-size;
        border-bottom-right-radius: $base-border-radius-size;

        .dropdown---menu-item-wrapper---3uAM0 {
          &:first-child {
            @extend %dropdown-item-radius-reset;

            &:hover {
              @extend %dropdown-item-radius-reset;
            }
          }
        }
      }
    }

    .shadow-caster {
      width: 100%;
      height: 55px;
      position: absolute;
      top: 9px;
      box-shadow: 0 8px 30px 0 $grey4 !important;
      border-top-right-radius: $base-border-radius-size !important;
      border-top-left-radius: $base-border-radius-size !important;
    }

    .dropdown---menu-item-wrapper---3uAM0 {
      @extend %dropdown-item-radius-reset;

      &:first-child {
        @extend %dropdown-item-radius-top;

        .dropdown---menu-item-wrapper---3uAM0 {
          @extend %dropdown-item-radius-reset;

          &:first-child {
            @extend %dropdown-item-radius-top;
          }

          &:only-child {
            @extend %dropdown-item-radius-only-child;
          }

          &:last-child {
            @extend %dropdown-item-radius-bottom;
          }
        }
      }

      &:only-child {
        @extend %dropdown-item-radius-only-child;
      }

      &:last-child {
        @extend %dropdown-item-radius-bottom;

        .dropdown---menu-item-wrapper---3uAM0 {
          @extend %dropdown-item-radius-reset;

          &:first-child {
            @extend %dropdown-item-radius-top;
          }

          &:only-child {
            @extend %dropdown-item-radius-only-child;
          }

          &:last-child {
            @extend %dropdown-item-radius-bottom;
          }
        }
      }
    }

    .dropdown---menu-item---1LjoL {
      font-size: $font-size-base;
      font-weight: 400;
      height: 42px;
      text-decoration: none;
      margin-left: 0;
      border-bottom: 2px solid $grey8;
      padding: 10px 15px;
      background-color: $reverse-background;

      .selected {
        position: relative;
        padding-right: 30px;
        font-weight: 600;

        span {
          color: $default-primary !important;
        }

        .checkmark {
          font-weight: 800;
          position: absolute;
          right: -5px;
          top: 1px;
          color: $default-primary;

          path {
            fill: $default-primary;
          }
        }
      }

      > li {
        > i {
          &:first-child {
            position: relative;
            top: 2px;
            margin-right: 7px;
          }
        }
      }

      &:hover,
      &:active {
        background-color: $pastel-blue-100;
      }

      svg {
        width: 30px;
        height: 30px;
        margin-top: -8px;
        margin-left: -8px;
        position: relative;
        top: 2px;
      }
    }
  }

  &.is-invalid {
    .dropdown-toggle {
      border-color: $error-red !important;
      background-image: linear-gradient(
        to bottom,
        $white-100 20%,
        $disabled-danger 100%
      );

      .dropdown-toggle-label {
        color: $error-red;
      }

      .dropdown-caret {
        i {
          border-color: $error-red;
        }
      }
    }
  }
}
