// sass-lint:disable class-name-format
@import "../../abstracts/variables";
@import "../../abstracts/placeholders";
@import "../../abstracts/mixins";

.geosuggest {
  position: relative;
  text-align: left;
  padding: 0;

  &.edit-mode-toggler-disabled {
    padding: 0;
  }
}

.geosuggest__input-wrapper {
  height: $font-size-base;
}

.geosuggest__input {
  @extend %form-control-base;
  border: 0;
  background-color: transparent;
  width: 100%;
  height: $font-size-base + 1;
  display: block;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
  outline: none;
}

.geosuggest__suggests {
  @include common-border-radius;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: 10px;
  margin-left: -2px;
  margin-right: -2px;
  background-color: $white-100;
  border: 2px solid $main-border;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 9999;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $grey-blue;
  }
}

.geosuggest__item--active {
  background-color: $grey-blue;
}

.geosuggest__item__matched-text {
  font-weight: 600;
}
