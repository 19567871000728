// sass-lint:disable class-name-format,placeholder-in-extend
@import "../../../../../styles/abstracts/variables";

.cell {
  height: 45px;
  width: 54px;
  background-color: $white-300;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-base + 1;
  color: $black-1000;
}

.boldText {
  font-size: $font-size-lg;
  font-weight: 500;
  color: $black-1000;
}

.totalCell {
  @extend .boldText;
  height: 45px;
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.totalLabel {
  @extend .boldText;
  display: flex;
  justify-content: left;
  align-items: center;
}

.normalLabel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  span {
    font-size: $font-size-base;
    color: $black-1000;
  }
}

.specialLabel {
  font-size: $font-size-base;
  color: $grey-900;
  font-weight: 500;
}

.rightBorder {
  border-right: 1px solid $grey-200;
}

.topBorder {
  border-top: 1px solid $grey-200;
}

.overlayContainer {
  min-width: 1300px;
}

.tableWrapper {
  padding-bottom: 100px;
}

.homunculusWrapper {
  padding: 0 40px 80px;
  align-items: center;
  display: flex;
  flex-direction: column;

  &:first-child {
    @extend .rightBorder;
  }
}
