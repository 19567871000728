@import "../../abstracts/variables";

.calendar-view-settings-container {
  .form-title {
    font-size: $font-size-lg;
    color: $default-font-color;
  }

  .trigger-label,
  .checkbox-label,
  .radio-label {
    text-transform: none !important;
    color: $default-font-color !important;
    font-size: $font-size-title4 !important;
    font-weight: normal !important;
  }

  .day-of-week {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid $grey-110;
    color: $grey-700;
    font-size: $font-size-md;
    justify-content: center;
    align-items: center;

    &.active {
      background-color: $blue-700;
      color: $white-100;
      border: 1px solid transparent;
    }
  }

  .event-date-input {
    .invalid-feedback {
      margin-left: 0;
    }
  }
}
