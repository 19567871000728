.moshi-phone-input {
  @include common-border-radius;
  border: 1px solid $pale-lilac;
  background-color: $white-100;
  display: flex;
  cursor: text;

  input {
    font-size: $font-size-base;
    border: 0;
    background: none;
    padding-left: 0;
    height: 30px;
    color: $black-1000;

    &:focus {
      outline: none;
    }
  }

  // sass-lint:disable class-name-format
  &.PhoneInput--focus {
    background-color: $white-100 !important;
    border: 1px solid $ocean-blue !important;
    outline: 0;
  }

  &.disabled {
    cursor: default;
    padding-left: 4px !important;

    .PhoneInputCountrySelectArrow {
      display: none;
    }

    input {
      margin-top: 2px;
    }
  }

  .PhoneInputCountrySelect {
    outline: none;
  }
}
