@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.progress {
  .progress-bar {
    @include common-border-radius;
  }

  &.xs {
    height: 6px;
  }
}
