.external-documents {
  .overlay-header {
    &.external {
      background-color: $red-200;

      .external-document-header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        font-size: $font-size-title2;
        font-weight: 400;

        .external-document-icon {
          background-color: $orange-500;
          color: $white-100;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          margin-right: 15px;
          text-align: center;
          width: 38px;
          height: 38px;

          .pdf {
            font-size: $font-size-base;
          }
        }
      }
    }
  }

  .external-documents-right-side {
    height: 100%;

    .external-document-edit-form {
      .document-form-field {
        padding: 15px 15px 0;

        .form-group-label {
          color: $default-primary;
          font-size: $font-size-sm;
          font-weight: 600;
        }
      }
    }
  }
}

.view-image-modal {
  max-width: unset;

  .modal-header {
    display: none;
  }

  .modal-content {
    justify-content: center;
    align-items: center;
    background: transparent;
    border: unset;

    img {
      max-width: 90vw;
      max-height: 90vh;
      transition: all 0.2s ease-in-out;
      cursor: zoom-in;
      object-fit: contain;

      &.zoom {
        transform: scale(2);
        cursor: zoom-out;
      }
    }
  }
}

.overlay-content-container {
  display: flex;
  justify-content: space-between;

  .overlay-documents-left {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    overflow: auto;
    border-right: 1px solid $grey-200;

    .view-pdf-container {
      .view-pdf-page {
        margin-bottom: 100px;
      }
    }

    .view-dicom-container {
      @include common-border-radius;
      display: flex;
      width: 80%;
      height: 80%;
      object-fit: contain;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .view-dicom-toolbar {
        margin: 10px 0;
      }

      .viewport-wrapper {
        object-fit: contain;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .view-image-container {
      @include common-border-radius;
      display: flex;
      width: 900px;
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
      justify-content: center;
      align-items: center;

      img {
        object-fit: contain;
        cursor: zoom-in;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 100%;
      }
    }

    .view-xlsx-container {
      box-shadow: $document-box-shadow;
      min-width: 700px;
      margin-bottom: 100px;
      padding: 40px;

      table {
        width: 100%;
      }

      td {
        min-width: 90px;
        text-align: center;
        border: 1px solid $grey-500;
        height: 17px;

        &:hover {
          border: 1px double $primary-blue-600;
          background-color: $primary-blue-200;
        }
      }
    }

    .view-docx-container {
      box-shadow: $document-box-shadow;
      min-width: 700px;
      max-width: 900px;
      margin-bottom: 100px;
      padding: 40px;

      .view-docx {
        overflow: hidden;

        h1,
        h2,
        h3,
        h4,
        h5 {
          height: auto;
        }

        td {
          min-width: 90px;
          text-align: center;
          border: 1px solid $grey-500;
          height: 17px;
        }

        img {
          max-height: 350px;
        }
      }
    }

    .action-buttons-container {
      position: fixed;
      bottom: 0;
      left: 0;
      width: calc(100% - 440px);
      height: 250px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      transition: all 200ms ease-out;
      opacity: 0;

      &:hover {
        opacity: 1;
      }

      .action-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        height: 100px;

        .action-group {
          justify-content: center;
          display: flex;
          padding: 10px;
          color: $grey-400;
          border-radius: 4px;
          background-color: $document-actions-color;
        }

        i {
          &.disabled {
            color: $grey-700;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .external-documents-right-side {
    .overlay-right-side-container {
      height: 100%;

      .document-overlay-description {
        padding: 15px;
        border-bottom: 1px solid $pale-lilac;

        .title {
          text-transform: uppercase;
          color: $default-primary;
          font-size: $font-size-sm;
          font-weight: 600;
        }

        .content {
          margin-bottom: 3px;
          margin-top: 15px;
          display: block;
        }
      }
    }
  }
}
