@import "../../abstracts/variables";

.tile {
  width: 100%;
  background-color: $white-100;
  font-weight: 400;

  &.collapsable {
    background-color: transparent;

    .open-tile {
      padding-bottom: 10px;
    }

    .collapsible-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: $font-size-lg;
      color: $black-1000;
      margin-bottom: 14px;
    }

    &:hover {
      background-color: transparent;
    }

    .select-button {
      font-size: $font-size-base;
    }
  }

  .grey-label {
    margin-bottom: 7px;
    margin-top: 14px;
  }

  .tile-header {
    margin-bottom: 5px;
    font-weight: 400;
  }

  .tile-hover-edit {
    position: absolute;
    top: 11px;
    right: 11px;
    padding: 3px 10px;
    border-radius: 100px;
    font-size: $font-size-md;
    font-weight: 600;
    color: $default-primary;
    background-color: $light-primary;
    display: none;

    &.inline {
      position: initial;
    }

    &.icon-container {
      display: none;
      background-color: transparent;
      font-size: $font-size-xl;
      border: 0;
      padding-right: 0;
    }
  }

  &:hover {
    .tile-hover-edit {
      display: block;
      outline: none;
      cursor: pointer;

      &.icon-container {
        display: flex;
      }
    }
  }

  .tile-send-sms {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 11px;
    left: 11px;
    padding: 3px 10px;
    border-radius: 100px;

    i {
      margin-top: -1px;
      margin-right: 7px;
    }
  }

  &.tile-cave {
    min-height: 300px;

    .tile-cave-no-file-text {
      display: block;
      margin-top: 16px;
      color: $grey-700;
      font-size: $font-size-base - 1;
    }

    &.tile-multiline {
      white-space: pre-line;
    }
  }

  &.tile-basic-info {
    margin-bottom: 10px;
  }

  &.tile-reminder {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    margin-bottom: 10px;

    .reminder-button {
      flex-basis: 49%;
      padding: 0 13.5px;
    }
  }

  .tile-row {
    padding: 2px;
    border-radius: $sm-border-radius-size;

    &:hover {
      background-color: $white-200;
    }

    .tile-text {
      font-size: $font-size-base + 1;
      flex-grow: 1;
    }
  }
}
