@import "../../abstracts/variables";
@import "../../abstracts/mixins";

$encounter-border-width: 3px;
$header-height: 65px;
$bucket-height: calc(100vh - #{$header-height} - 105px);
$bucket-width: 359px;
$bucket-card-width: $bucket-width - 19;
$visitors-card-padding: 15px;

.comment-red-circle {
  @include circle-dot($error-red);
}

.waiting-list {
  width: 100%;
  margin-top: 0;
  padding-bottom: 0;

  header {
    padding-bottom: 15px;
    margin-bottom: 5px;
  }

  > .waiting-list-columns {
    width: max-content;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;

    .bucket-container {
      > .row {
        margin-left: 0;
        margin-right: 0;
      }

      .bucket-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        padding-left: 10px;
        margin-bottom: 32px;
        letter-spacing: normal;

        h3 {
          margin-bottom: 0;
          margin-right: 5px;
          padding-bottom: 0;

          .list-position-indicator {
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border-radius: 50%;
            background-color: $reverse-background;
            display: inline-block;
            font-weight: 500;
            font-size: 16px;
          }
        }
      }

      .bucket-main-action {
        text-transform: initial;
        font-size: 12px;
        padding: 0;
        height: 12px;
        margin-bottom: -15px;
        width: auto;
        min-width: 40px;
      }

      &:last-child {
        .bucket {
          border-right: 0;
        }
      }
    }

    .bucket {
      height: $bucket-height;
      overflow-y: auto;
      overflow-x: hidden;
      border-right: 2px solid $white-100;

      .scrollbar-container {
        width: $bucket-width !important;
      }

      .bucket-row {
        padding-top: 17px;
        height: 100%;
      }

      .bucket-droppable-area {
        padding: 0;
        height: 100%;
        display: table;
        align-self: start;
        overflow-x: hidden;

        .drop-bucket-indicator {
          position: absolute;
          width: 340px;
          height: 110%;
          left: 10px;
          top: -17px;
          background-color: $greyer-blue;
        }

        &.dragging-over-column {
          .drop-item-placeholder {
            display: none;
          }
        }
      }

      .bucket-title {
        display: flex;
        flex-direction: row;
        margin-left: 15px;
      }

      .drop-item-placeholder {
        @include common-border-radius;
        width: $bucket-card-width;
        height: 140px;
        border: dashed 2px $grey4;
        color: $grey4;
        font-weight: 400;
        text-transform: uppercase;
        font-size: $font-size-base - 2;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .card {
        &:not(.notification-card) {
          margin: 0 auto 20px;
          width: $bucket-card-width;
          border-top-width: 0;
          border-right-width: 0;
          border-bottom-width: 0;
          border-left-width: $encounter-border-width;
          height: 140px;
          padding-right: 10px;
          padding-left: $visitors-card-padding;
          padding-top: $visitors-card-padding;
          box-shadow: none;

          &.highlight {
            animation: highlight-fade-orange-to-white 5s ease-in-out 0s;
          }

          &:focus {
            outline: $default-primary dashed;

            &:not(.focus-visible) {
              outline: none;
            }
          }
        }

        .btn {
          &.btn-link {
            width: initial;
          }
        }

        &:hover,
        &.dragging {
          @include visitors-card-box-shadow($black-1000);
          background-color: darken($white-100, 2%);
        }

        &.full-border {
          border-width: $encounter-border-width;
          padding-top: calc(
            #{$visitors-card-padding} - #{$encounter-border-width}
          );
          padding-right: calc(
            #{$visitors-card-padding} - #{$encounter-border-width}
          );
        }

        &.dragging {
          background-color: darken($white-100, 1);
        }

        .patient-actions,
        .document-actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 1px;
        }

        .patient-actions {
          margin-top: 0;
          padding-right: 8px;
          height: fit-content;
          position: absolute;
          top: 8px;
          right: 1px;

          .no-comments {
            svg {
              path {
                fill: $grey4;
              }
            }
          }

          > .btn-group {
            background-color: $primary-blue-100;
            display: flex;
            align-items: center;
            border-radius: 50px;
            height: 32px;
            padding: 5px;

            .finish-wrapper {
              display: flex;
              align-items: center;
              padding-left: 5px;
            }

            .btn-primary {
              margin-right: 5px;
            }

            .btn-transparent {
              min-width: 30px;
              width: 30px;
              height: 30px;
            }

            .dropdown {
              .dropdown-menu {
                top: 37px;
              }
            }
          }
        }

        .card-title {
          margin-bottom: 0;
          margin-top: -5px;
          flex-grow: 1;

          .patient-info {
            display: flex;
            flex-direction: column;

            .patient-name {
              font-weight: 600;
              font-size: $font-size-base + 2;
              text-transform: none;
              width: auto;
              padding-top: 0;
              padding-bottom: 0;
              height: 21px;
              min-height: 21px;
              margin-top: 5px;
            }

            .check-in-at {
              font-weight: 500;
              font-size: $font-size-base;
              line-height: 1.25;
              align-items: flex-start;
              display: flex;
              margin-top: 5px;
            }

            .flow-name {
              font-weight: 500;
              font-size: $font-size-base;
              line-height: 1.25;
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              justify-content: flex-end;
            }

            .status-container {
              display: flex;
              flex-grow: 1;
              flex-direction: column;
              margin-bottom: 10px;
              white-space: nowrap;
              font-size: $font-size-base;
              font-weight: 500;
            }
          }
        }

        .card-footer {
          background-color: transparent;
          padding: 0;
          border: 0;
          display: flex;
          align-items: flex-end;
          height: 20px;

          .doctor-name {
            display: flex;
            align-items: center;
            margin-top: 6px;
            color: lighten($grey5, 0.5);
            font-size: $font-size-base;

            .category-name {
              max-width: 130px;
            }

            .doctor-circle-icon {
              @include circle(10px);
              margin-right: 5px;
            }

            .event-category {
              margin-right: 10px;
            }
          }

          .choose-doctor {
            font-size: $font-size-base;
            padding: 0;
          }

          &.footer-buttons {
            padding: 0 15px;

            :first-child {
              margin-right: 10px;
            }
          }
        }

        &.assist-request {
          background-color: $black-800;
          color: $white-100;
          border: 0;
          height: 70px;

          .transparent-actions-background {
            &.btn-group {
              background-color: transparent;
            }
          }

          .patient-name {
            font-size: $font-size-base !important;
          }
        }
      }

      .upcoming-events-container {
        width: 340px;
        margin: auto;
        border-top: 2px solid $white-100;
        margin-top: 19px;
        padding-top: 16px;
        padding-bottom: 70px;

        h3 {
          margin-bottom: 13px;
        }

        .upcoming-event-container {
          @include common-border-radius;
          @include tiny-border-box-shadow($pale-lilac);
          background-color: $reverse-background;
          padding: 15px;
          margin-bottom: 10px;
          color: $lighter-font-color;

          .upcoming-event-row {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .category-name {
              max-width: 130px;
            }

            &:first-child {
              margin-bottom: 3px;
            }

            &.align-left {
              justify-content: flex-start;
            }

            .event-category {
              margin-right: 10px;
            }

            .hover-actions {
              height: 21px;
              display: flex;
              flex-direction: row;

              .card-more-actions {
                position: initial;
                right: initial;
                top: initial;
                margin-left: 10px;

                .more-toggle {
                  i {
                    color: $black-1000;
                    margin: 0;
                  }

                  .rs-more-actions-menu {
                    i {
                      margin-right: 5px;
                    }
                  }
                }
              }
            }
          }

          .patient-name {
            max-width: 180px;
            color: $light-font-color;
          }

          .doctor-circle {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            margin-right: 5px;
            display: inline-block;
          }

          &:hover {
            background-color: $white-100;
          }
        }
      }
    }
  }
}

.comment-list-popover {
  &.moshi-popover {
    .popover {
      overflow: hidden;
    }

    .popover-body {
      background-color: $white-100;
      padding: 0;

      .comment-list {
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-height: 260px;
        padding: 10px;

        .comment-item {
          display: inline-flex;
          flex-direction: column;
          padding: 6px 16px;
          background-color: $grey4;
          border-radius: 14px;
          margin-bottom: 5px;

          .comment-title {
            font-weight: 600;
            font-size: $font-size-base;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;

            .card-more-actions {
              position: static;
              margin-left: 12px;

              .dropdown-toggle {
                i {
                  color: $default-font-color;
                }
              }
            }
          }

          &.my-comments {
            background-color: $blue-200;
            align-self: flex-end;

            &.editable {
              background-color: $white-100;
              border: 1px solid $grey-110;
              padding: 7px 0;
              min-width: 300px;

              .form-buttons {
                display: flex;
                justify-content: flex-end;
              }

              .moshi-button {
                height: 34px;
              }

              textarea {
                resize: none;
                border: 0;

                &:hover {
                  background: $white-100;
                }
              }
            }
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          .form-group {
            margin-bottom: 0;
            padding: 0;

            textarea {
              width: 220px;
            }
          }
        }
      }

      p {
        white-space: pre-wrap;
      }

      hr {
        clear: both;
        margin: 0 0 5px;
      }

      textarea {
        font-weight: 400 !important;
        min-height: 42px !important;
      }

      .comment-inline-form {
        padding: 16px 10px 0;

        form {
          display: flex;
          flex-grow: 1;

          .form-group {
            flex-grow: 1;

            textarea {
              &::placeholder {
                color: $grey5;
              }
            }
          }

          .moshi-button {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

.modal-body {
  .custom-radio-label-container {
    margin-top: 15px;

    .form-group {
      flex-direction: row;
      margin-bottom: 0;

      .form-group-label {
        flex-basis: 30%;
        display: inline-block;
        text-transform: none;
        font-size: $font-size-base;
        color: $black-1000;
        font-weight: 400;
      }

      > div {
        flex-basis: 70%;

        label {
          margin-top: 0;
          margin-bottom: 20px;
          font-weight: 600;

          &:last-of-type {
            margin-bottom: 0;
          }

          span {
            text-transform: none;
            font-size: $font-size-base;
            color: $black-1000;
          }
        }
      }
    }
  }
}

.custom-remove-encounter-modal {
  .modal-content {
    h3 {
      margin-bottom: 1px !important;
    }

    .modal-body {
      margin-bottom: 90px;
    }
  }
}
