@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.mosh-rs-event-status-dropdown {
  .moshi-rs-dropdown-toggle {
    @include common-button-box-shadow;
    @include dropdown-border-radius;

    border-color: $black-900;
    text-align: center;
  }

  .moshi-rs-dropdown-menu {
    .moshi-rs-dropdown-item {
      padding-left: 50px;

      .status-indicator {
        margin-top: 1px;
      }
    }
  }
}
