@import "../../abstracts/variables";

.moshi-expand-list-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $expand-list-panel-background-color;
  height: 50px;
}
