@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.moshi-item-finder {
  height: 85vh;

  .search-box {
    padding: 19px 15px 25px 19px;
    background-color: $grey-blue;
  }

  .moshi-item-finder-results {
    max-height: calc(85vh - 105px);
    border-radius: 0 0 $base-border-radius-size $base-border-radius-size;
    position: relative;
    overflow-y: auto;

    .moshi-item-finder-category,
    .moshi-item-finder-row {
      min-height: 62px;
      border-top: 1px solid $pale-lilac;
      background-color: $white-100;

      &:hover,
      &:active,
      &:focus {
        cursor: pointer;
        text-decoration: none !important;
      }

      .disabled {
        color: $grey-800;

        &:hover,
        &:active,
        &:focus {
          cursor: not-allowed;
          color: $grey-800;
          text-decoration: none !important;
        }
      }

      &.no-hover {
        color: $default-font-color;
      }
    }

    .moshi-item-finder-category {
      .moshi-item-finder-row {
        padding-left: 40px;
      }
    }

    .moshi-item-finder-category-name {
      font-weight: 400;
      font-size: $font-size-title3;
      display: flex;
      align-items: center;
    }

    .moshi-item-finder-row {
      color: $default-primary;
      font-weight: 500;
      font-size: $font-size-base;
    }

    .moshi-item-finder-row,
    .moshi-item-finder-category-name {
      padding: 20px;
      background-color: $white-100;
      outline: none !important;

      &:hover {
        background-color: $default-background;
      }
    }
  }
}
