@import "../../abstracts/mixins";
@import "../../abstracts/variables";

.form-group {
  label {
    .required-red-dot {
      @include circle-dot($red-400);

      width: 7px;
      height: 7px;
      position: initial;
      display: inline-block;
      margin-right: 5px;
    }
  }

  .rsjf-invalid-feedback {
    margin-top: 0.25rem;

    ul {
      list-style: none;
      padding-left: 15px;
      margin-bottom: 0;

      li {
        color: $red-600 !important;
        font-size: $font-size-base;
        font-weight: 400;
      }
    }
  }
}

.rjsf {
  p {
    white-space: pre-wrap;
  }

  .control-label,
  .radio-label {
    color: $light-font-color !important;
  }

  .control-label {
    font-size: $font-size-base !important;
    padding: 10px 0;
  }

  > .field-object {
    > fieldset {
      @extend %flex-column;

      .react-date-picker--disabled {
        // prettier-ignore
        .react-date-picker__wrapper { // sass-lint:disable-line class-name-format
          margin-top: -3px;
          margin-left: 2px;
        }
      }

      p {
        margin-top: 20px;
      }

      > .field {
        border-bottom: 2px solid $grey-blue;

        &:last-child {
          border-bottom: 0;
        }

        &.form-group {
          &.field-object {
            &.field-section {
              @include common-border-radius;
              background-color: $violet-100;
              padding: 15px;
            }
          }
        }
      }

      .field-string,
      .field-array,
      .field-number {
        padding: 25px 0;

        .custom-daypicker {
          width: 25%;
        }

        > div {
          > div {
            @extend %flex-column;

            .form-control {
              width: initial;
              margin-left: 40px;
            }

            input,
            textarea {
              box-shadow: none;
            }

            .field-radio-group,
            .checkboxes {
              padding-left: 40px;

              .radio,
              .checkbox {
                label {
                  > span {
                    display: flex;
                    justify-content: flex-start;

                    span {
                      padding: 10px;
                      color: $black-1000;
                      font-size: $font-size-base;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
