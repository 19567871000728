@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.custom-datepicker {
  &:disabled {
    padding: 0 !important;
  }
}

.moshi-popover {
  &.date-picker-popover {
    .row {
      margin-bottom: 15px;

      > div {
        > button {
          margin: auto;
          display: flex;
          justify-content: center;
        }
      }
    }

    .date-picker-actions {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .btn-icon {
        svg {
          g,
          path {
            fill: $default-primary;
          }
        }
      }
    }

    .date-picker-values {
      button {
        @include moshi-button-outline-variant(
          $black-1000,
          $grey-blue,
          $greyer-blue
        );
        background-color: transparent;
        border: 0;
        padding-top: 6px;
      }
    }

    .search-field {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
}
