@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.moshi-popover {
  .popover {
    @include common-border-radius;
    @include common-box-shadow($popover-shadow-color);
    font-family: $font-family-ibm-plex-sans;
    min-width: 460px;
    max-height: 460px;
    background-color: $reverse-background;
    border-width: 0;
    outline: none;

    > .arrow {
      &::before {
        border-bottom-color: transparent;
      }

      &::after {
        border-bottom-color: $reverse-background;
      }
    }
  }

  .popover-body {
    padding: 15px;
    overflow: auto;
    max-height: 460px;

    h4 {
      font-weight: 600;
      font-size: $font-size-base;
    }
  }
}
