@import "./variables";

.input-affix-wrapper {
  position: relative;

  .input-prefix {
    padding-left: 25px;
  }

  .input-suffix {
    padding-right: 25px;
  }

  .prefix-container {
    position: absolute;
    z-index: 1;
    left: 15px;
    top: 10px;
    font-weight: 600;
  }

  .suffix-container {
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 10px;
    color: $lighter-font-color;
  }
}
