@import "./variables";
@import "./placeholders";

@import "~bootstrap/scss/mixins/buttons";
@import "~bootstrap/scss/mixins/gradients";
@import "~bootstrap/scss/functions";

@mixin focus {
  &.focus,
  &:focus {
    @content;
  }
}

@mixin hover {
  &.hover,
  &:hover {
    @content;
  }
}

@mixin active {
  &.active,
  &:active {
    @content;
  }
}

@mixin fixed-width($width) {
  width: $width;
  min-width: $width;
  max-width: $width;
}

@mixin common-border-radius {
  border-radius: $base-border-radius-size;
}

@mixin dropdown-border-radius {
  border-radius: $dropdown-border-radius-size;
}

@mixin common-border-radius-important {
  border-radius: $base-border-radius-size !important;
}

@mixin common-box-shadow($shadow-color: $card-shadow-color) {
  box-shadow: 0 0 30px 0 $shadow-color;
}

@mixin smaller-box-shadow($shadow-color) {
  box-shadow: 0 4px 15px 0 $shadow-color;
}

@mixin visitors-card-box-shadow($shadow-color) {
  box-shadow: 0 0 22px -15px $shadow-color;
}

@mixin alternative-box-shadow($shadow-color) {
  box-shadow: 0 8px 30px 0 $shadow-color !important;
}

@mixin tiny-border-box-shadow($shadow-color) {
  box-shadow: 0 0 4px 0 $shadow-color !important;
}

@mixin tiny-border-box-shadow-without-blur($shadow-color, $shadow-size) {
  box-shadow: 0 0 0 $shadow-size $shadow-color !important;
}

@mixin inset-box-shadow($shadow-color) {
  box-shadow: inset 0 0 0 5px $shadow-color !important;
}

@mixin border-inset($shadow-color) {
  box-shadow: inset 0 0 0 1px $shadow-color;
}

@mixin common-button-box-shadow($shadow-color: $pagination-box-shadow-color) {
  box-shadow: 0 2px 2px 0 $shadow-color !important;
}

@mixin calendar-popover-box-shadow(
  $shadow-color: $calendar-popover-box-shadow-color
) {
  box-shadow: 0 10px 40px 0 $shadow-color;
}

@mixin svg-fill($color) {
  svg {
    g,
    circle,
    path {
      fill: $color;
    }
  }
}

@mixin moshi-button-size($height, $font-size, $padding, $border-radius) {
  min-height: 0;
  height: $height;
  min-width: $height;
  font-size: $font-size;
  line-height: 1;
  padding: $padding;
  border-radius: $border-radius;
}

@mixin button-base($color: $white-100) {
  @extend %button-disabled;
  border-radius: $sm-border-radius-size;
  font-weight: 600;
  color: $color !important;
  border: 0 !important;
}

@mixin tag-base {
  @include common-border-radius;
  font-size: $font-size-base - 4;
  font-weight: 600;
  padding: 0 10px;
  text-transform: uppercase;
  height: 20px;
  line-height: 10px;
  display: inline-flex;
  align-items: center;
  align-content: center;
  cursor: default;

  &.moshi-tag-sm {
    height: 16px;
  }

  &:focus {
    outline: none;
  }

  &.moshi-tag-clickable {
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 5px 0 $default-box-shadow-color;
    }
  }
}

@mixin outline-active($color, $active-background, $active-border) {
  &:not(:disabled) {
    &:not(.disabled) {
      @include active {
        color: $color;
        background-color: $active-background;
        border-color: $active-border;
      }
    }
  }

  .show > &.dropdown-toggle {
    color: $color;
    background-color: $active-background;
    border-color: $active-border;
  }
}

@mixin moshi-button-variant(
  $background,
  $hover-background,
  $active-background,
  $color: $white-100,
  $hover-shadow: none,
  $shadow: none
) {
  @include button-base($color);
  @include svg-fill($color);

  @include focus {
    background-color: $active-background !important;
    box-shadow: $hover-shadow !important;
    color: $color !important;
  }

  @include hover {
    background-color: $hover-background !important;
    box-shadow: $hover-shadow !important;
    color: $color !important;
  }

  @include active {
    background-color: $active-background !important;
    box-shadow: $hover-shadow !important;
    color: $color !important;
  }

  background-color: $background !important;
  box-shadow: $shadow;
  outline: none !important;

  &:disabled {
    opacity: 0.3;

    &:hover {
      box-shadow: none !important;
      color: $color;
    }
  }
}

@mixin moshi-button-outline-variant(
  $color,
  $hover-background,
  $active-background
) {
  @include button-outline-variant($color, $color, $active-background, $color);

  @include outline-active($color, $active-background, $color);

  @include hover {
    color: $color;
    background-color: darken($hover-background, 5%);
    border-color: $color;
  }

  @include focus {
    color: $color;
    background-color: $active-background;
  }
  @include button-base($color);
  background-color: transparent;
  color: $color;
}

@mixin moshi-btn-link($color, $hover-color: $color, $active-color: $color) {
  @extend %button-disabled;

  @include hover {
    @extend %cursor-pointer;

    &:not(.no-hover) {
      @include svg-fill($hover-color);
      color: $hover-color;
      text-decoration: none;
    }
  }

  @include focus {
    @extend %cursor-pointer;

    &:not(.no-hover) {
      @include svg-fill($active-color);
      color: $active-color;
      text-decoration: none;
      box-shadow: none;
    }
  }

  font-size: $font-size-base;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  font-weight: 600;
  color: $color;
  border: 0;
}

@mixin moshi-tag-variant($background, $font-color: $white-100) {
  @include tag-base;

  background-color: $background;
  color: $font-color;
}

@mixin moshi-calendar-event-variant(
  $background,
  $font-color: $default-font-color,
  $opacity: 0%
) {
  background-color: $background;
  border-color: $background;
  color: $font-color;
}

@mixin common-navigation {
  @include common-border-radius;
  @include svg-fill($default-font-color);
  color: $default-font-color;
  line-height: 40px;
  padding: 0 0 0 10px;
  display: block;
  font-weight: 500;
  text-decoration: none;

  span {
    vertical-align: middle;
  }
}

@mixin common-navigation-title {
  color: $light-font-color;
  font-size: $font-size-md;
  font-weight: 500;
  height: $font-size-sm;
  min-height: $font-size-sm;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 9px;
}

@mixin modal-width($width) {
  .modal-content {
    margin: auto;
    max-width: $width;
  }
}

@mixin text-and-icon-color(
  $color,
  $hover-color: $color,
  $active-color: $color
) {
  color: $color;

  path {
    fill: $color !important;
  }

  @include hover {
    color: $hover-color !important;

    path {
      fill: $hover-color !important;
    }
  }

  @include focus {
    color: $color !important;

    path {
      fill: $active-color !important;
    }
  }
}

@mixin circle($size) {
  height: $size;
  width: $size;
  border-radius: 50%;
}

@mixin square($size, $color, $hover-color: $color) {
  height: $size;
  width: $size;
  border-radius: 4px;
  background-color: $color;

  &:hover {
    background-color: $hover-color;
  }
}

@mixin circle-dot($color) {
  @include circle(10px);

  background-color: $color;
  position: absolute;
  right: 1px;
  top: 3px;
}

@mixin highlight-fade($name) {
  @keyframes #{$name} {
    from {
      background: $greyer-blue;
      border-color: $default-primary;
      border-width: 2px;
    }

    to {
      background: $secondary-color;
      border-color: transparent;
    }
  }
}

@mixin highlight-fade-generic(
  $name,
  $background-from,
  $background-to: transparent
) {
  @keyframes #{$name} {
    0% {
      background-color: $background-from;
    }

    75% {
      background-color: $background-to;
    }
  }
}

@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == "string",
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error "No value found for `#{$breakpoint}`. "
    + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin chevron-variant(
  $color,
  $rotation: "top",
  $size: 8px,
  $thickness: 10px,
  $transparent-color: $white-100
) {
  position: relative;
  display: block;
  height: $size * 2;
  margin: 1px 3px;

  &::before,
  &::after {
    position: absolute;
    display: block;
    content: "";
    border: $size solid transparent;
  }

  &::before {
    #{$rotation}: 0;
    border-#{$rotation}-color: $color;
  }

  &::after {
    #{$rotation}: -$thickness;
    border-#{$rotation}-color: $transparent-color;
  }
}

@mixin margin-x-y($from, $count) {
  @for $i from 0 through $count {
    .margin-#{$from}-#{$i} {
      margin-#{$from}: #{$i}px;
    }

    .margin-#{$from}-#{$i}-important {
      margin-#{$from}: #{$i}px !important;
    }
  }
}

@mixin margin-x($count) {
  @for $i from 0 through $count {
    .margin-#{$i} {
      margin: #{$i}px;
    }

    .margin-#{$i}-important {
      margin: #{$i}px !important;
    }
  }
}

@mixin padding-x($count) {
  @for $i from 0 through $count {
    .padding-#{$i} {
      padding: #{$i}px;
    }

    .padding-#{$i}-important {
      padding: #{$i}px !important;
    }
  }
}

@mixin padding-x-y($from, $count) {
  @for $i from 0 through $count {
    .padding-#{$from}-#{$i} {
      padding-#{$from}: #{$i}px;
    }

    .padding-#{$from}-#{$i}-important {
      padding-#{$from}: #{$i}px !important;
    }
  }
}

@mixin position-x-y($from, $count) {
  @for $i from 0 through $count {
    .position-#{$from}-#{$i} {
      position: relative;
      #{$from}: #{$i}px;
    }
  }
}

@mixin category-color {
  @for $i from 1 through length($category-colors) {
    .category-color-#{$i} {
      background-color: nth($category-colors, $i);
    }
  }
}

@mixin tooltip-arrow-position-color($color, $position) {
  &.bs-tooltip-#{$position},
  &.bs-tooltip-auto[x-placement^="#{$position}"] /* sass-lint:disable-line force-attribute-nesting */ {
    .arrow {
      &::before {
        border-#{$position}-color: $color;
      }
    }
  }
}

@mixin tooltip-arrow-color($color) {
  @include tooltip-arrow-position-color($color, "top");
  @include tooltip-arrow-position-color($color, "bottom");
  @include tooltip-arrow-position-color($color, "right");
  @include tooltip-arrow-position-color($color, "left");
}

@mixin tooltip-variant($color) {
  @include tooltip-arrow-color($color);

  .tooltip-inner {
    background-color: $color;
    box-shadow: 0 8px 30px 0 $tooltip-shadow-color !important;
  }
}

@mixin no-scrollbar {
  // sass-lint:disable no-misspelled-properties
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
