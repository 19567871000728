@import "../../abstracts/variables";

.feedback-form-container {
  flex-direction: column;
  width: 100%;

  .feedback-form-header {
    width: 100%;
    height: 94px;
    align-items: center;
    justify-content: center;
    border-bottom: 4px solid $default-background;
    padding-bottom: 0;

    img,
    svg {
      max-width: 110px;
    }
  }

  .feedback-form-content {
    width: 100%;
    padding: 25px;

    .rjsf {
      max-width: 400px;
      margin: auto;

      .form-group {
        margin-bottom: 0;

        label {
          color: $black-1000;
        }
      }

      .submit-button-container {
        display: flex;
        justify-content: center;
      }
    }
  }
}
