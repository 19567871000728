@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.calendar-search-overlay-container {
  font-size: $font-size-base;
  color: $black-1000;
  font-weight: initial;

  .overlay-content {
    display: grid !important;
    grid-template-columns: auto max-content;
  }

  .overlay-header {
    justify-content: space-between;

    .calendar-search {
      width: 645px;
      margin-left: 50px;
    }
  }

  .overlay-content-container {
    .overlay-content {
      max-width: 100%;
      min-width: 1400px;

      .calendar-search-list-container {
        padding: 20px 50px 20px 75px;

        .results-text {
          font-size: $font-size-base - 1;
          font-weight: 500;
          margin-bottom: 17px;
        }

        .search-results-container {
          max-width: 100%;

          .day-group {
            padding: 18px 7px;
            border-top: 1px solid $grey-110;
            display: flex;

            .date-container {
              min-width: 140px;

              .day-number {
                width: 34px;
                height: 34px;
                border-radius: 50%;
                line-height: 34px;
                text-align: center;
                font-size: $font-size-lg;
                font-weight: 500;
                display: inline-block;
                margin-right: 10px;
                align-self: center;

                &.today {
                  background-color: $blue-700;
                  color: $white-100;
                }
              }

              .month-and-date {
                text-transform: uppercase;
                font-size: $font-size-sm;
                font-weight: 500;
                align-self: center;
                margin-top: 1px;
              }

              &:hover {
                cursor: pointer;

                .day-number {
                  background-color: $blue-200;

                  &.today {
                    background-color: $blue-700;
                    opacity: 0.7;
                  }
                }
              }
            }

            .events-list {
              flex: 1;

              .search-result-container {
                @include common-border-radius;
                height: 37px;
                padding: 0 11px;
                display: grid;
                grid-template-columns: max-content max-content 1fr 1fr max-content max-content;
                grid-template-rows: 1fr;
                grid-template-areas: "category-indicator from-to-time event-title duration-with-doctor status-and-upcoming-container more-actions-container";
                align-items: center;

                .category-indicator {
                  grid-area: category-indicator;
                  width: 15px;
                  height: 15px;
                  border-radius: 50%;
                  display: inline-block;
                }

                .from-to-time {
                  grid-area: from-to-time;
                  margin-right: 28px;
                }

                .event-title {
                  grid-area: event-title;
                  font-weight: 500;
                  margin-right: 28px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .duration-with-doctor {
                  grid-area: duration-with-doctor;
                  color: $grey-800;
                  margin-right: 30px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }

                .status-and-upcoming-container {
                  grid-area: status-and-upcoming-container;
                  display: flex;
                  justify-content: center;
                  margin-right: 10px;

                  .upcoming-passed,
                  .event-status-tag {
                    margin-left: 15px;
                    margin-right: 15px;
                  }

                  .tag-container {
                    min-width: 130px;
                    display: flex;
                    justify-content: center;
                  }
                }

                .more-actions-container {
                  grid-area: more-actions-container;

                  .event-search-result-actions {
                    position: relative;
                    right: auto;
                    top: 3px;

                    .card-more-actions-icon {
                      color: $black-1000;

                      &:hover {
                        color: $default-primary;
                      }
                    }
                  }
                }

                &:hover {
                  box-shadow: $document-box-shadow;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .calendar-search-filters-container {
        min-width: 400px;
        padding: 20px 30px;
        border-left: 1px solid $grey-blue;

        h3 {
          margin-bottom: 30px;
        }

        .filters-container {
          display: block;

          .calendar-search-filter-container {
            margin-bottom: 30px;

            .list-title {
              font-size: $font-size-md;
              margin-bottom: 14px;
              text-transform: uppercase;
              letter-spacing: 0.46px;
              color: $grey-710;
            }

            .checkbox-list {
              margin-bottom: 15px;

              .checkbox-label {
                color: $black-1000;
                text-transform: none;
                font-size: $font-size-base + 1;
                font-weight: 400;
              }
            }

            .select-text {
              font-size: $font-size-base + 1;
              color: $blue-700;

              &:hover {
                cursor: pointer;
                color: $blue-600;
              }
            }

            .calendar-search-filter-datepicker {
              .moshi-date-range-popup-calendar {
                right: 15px;
              }
            }
          }
        }
      }
    }
  }
}
