@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.dental-chart-entry-sidepanel-container {
  min-width: auto !important;
  width: 830px;

  .dental-chart-form-container {
    padding: 30px;

    .dental-chart-entry-form-title {
      font-size: $font-size-xl;
    }

    .dental-chart-grids-container {
      display: flex;

      .dental-chart-grid-container {
        margin-right: 20px;
      }
    }

    .bill-items {
      margin-top: 0;
      padding: 15px;
    }
  }
}

.dental-position-grid-wrapper {
  @include common-border-radius;
  flex-grow: 1;
  margin: 5px;

  &:hover {
    @include alternative-box-shadow($pagination-box-shadow-color);
    background-color: $white-100;
    cursor: pointer;

    &.basic-grid {
      background-color: unset;
    }
  }

  &.active {
    background-color: $pastel-blue-100;
    box-shadow: inset 0 0 2px $pale-lilac;

    &:hover {
      @include alternative-box-shadow(transparent);
    }
  }

  &:focus {
    outline: none;
  }

  .dental-position-grid-container {
    height: 63px;
    width: 37px;
    position: relative;
    margin: auto;

    &.bottom-grid {
      margin-top: 5px;
    }

    .tooth-number-position {
      text-align: center;
      width: 21px;
      height: 21px;
      margin-bottom: 2px;
      line-height: 20px;
      margin-left: 1px;
      font-size: $font-size-sm;
      border: 1px solid transparent;

      &.active {
        border-radius: 50px;
        background-color: $active-position-fill;
        border: 1px solid $active-position-stroke;
      }

      &.bottom-number {
        position: absolute;
        bottom: 0;
        margin-bottom: 0;
      }
    }

    .tooth-wrapper {
      display: inline-grid;
      position: absolute;

      .servical-buccal-position,
      .servical-palatal-position {
        display: inline-block;
        width: 25px;
        height: 7px;
        position: relative;
        margin-left: -1px;

        svg {
          position: absolute;
          left: 0;
        }
      }

      .tooth-container {
        width: 24px;
        height: 26px;
        position: relative;

        svg {
          position: absolute;
          z-index: 2;
        }

        .buccal-position {
          position: absolute;
          left: 50%;
          margin-left: -9px;
          width: 18px;
          height: 10px;
          transform: rotate(180deg);
          z-index: 2;
        }

        .mesial-position {
          position: absolute;
          left: -5px;
          top: 8px;
          width: 18px;
          height: 10px;
          transform: rotate(90deg);
          z-index: 2;
        }

        .distal-position {
          width: 18px;
          height: 10px;
          position: absolute;
          transform: rotate(-90deg);
          right: -5px;
          top: 8px;
          z-index: 2;
        }

        .palatal-position {
          position: absolute;
          left: 50%;
          margin-left: -9px;
          bottom: 0;
          width: 18px;
          height: 10px;
          z-index: 2;
        }

        .incisal-position {
          position: absolute;
          width: 14px;
          height: 14px;
          top: 50%;
          left: 50%;
          margin-top: -7px;
          margin-left: -7px;
          background-color: $white-100;
          z-index: 1;

          &.active {
            background-color: $active-position-fill;
            border: 1px solid $active-position-stroke;
            border-radius: 50%;
            z-index: 4 !important;
          }
        }
      }
    }

    .roots-container {
      float: right;
      width: 8px;
      height: 30px;
      margin-top: 5px;

      .root-position {
        width: 8px;
        height: 8px;
        position: relative;
        margin-bottom: 3px;

        svg {
          position: absolute;
        }
      }
    }

    .active {
      z-index: 3 !important;

      svg {
        g {
          fill: $active-position-fill;
          stroke: $active-position-stroke;
        }
      }
    }
  }

  &.basic-grid {
    margin: 5px 0;

    .dental-position-grid-container {
      height: 41px;
      width: 37px;
      margin: 0;
    }

    &:hover {
      box-shadow: none !important;
      cursor: default;
    }
  }
}

.dental-chart-grid-container {
  @include common-border-radius;
  @include tiny-border-box-shadow($pale-lilac);
  display: inline-grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  width: 1170px;
  height: 161px;
  background-color: $reverse-background;

  &.basic-grid {
    background-color: $white-100;
    box-shadow: none !important;
    width: 472px;
    height: 68px;
  }

  .dental-chart-clear-button {
    @include common-border-radius;
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 3px 10px;
    height: unset;
    line-height: 1.4;
    font-size: $font-size-md;
    display: none;
  }

  &:hover {
    .dental-chart-clear-button {
      display: block;
      outline: none;
      cursor: pointer;
    }
  }

  .teeth-container {
    display: flex;
    width: 585px;
    height: 80px;
    justify-content: space-around;
  }

  .small-teeth-container {
    width: 232px;
    height: 30px;
    justify-content: normal;
    border: 0 !important;

    .small-tooth-number-container {
      width: 29px;
      height: 30px;
      text-align: center;
      font-size: $font-size-sm;
      color: $lighter-font-color;
      line-height: 30px;
      border: 1px solid $pale-lilac;
      border-left: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: $white-100;

      cursor: pointer;

      &:first-child {
        border-left: 1px solid $pale-lilac;

        &.active {
          border-left: 0;
        }
      }

      &.bottom-row {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:hover {
        background-color: $pale-lilac;
      }

      &.active {
        border-color: $default-primary;
        color: $default-primary;
        background-color: $pale-lilac;
        box-shadow: inset 1px 0 0 0 $default-primary;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .top-left {
    border-right: 1px solid $grey-blue;
    border-bottom: 1px solid $grey-blue;
  }

  .top-right {
    border-bottom: 1px solid $grey-blue;
  }

  .bottom-left {
    border-right: 1px solid $grey-blue;
  }

  &.small-basic-teeth-grid {
    background-color: unset;
    width: 370px;
    height: 52px;
    cursor: default;
    pointer-events: none;

    .small-teeth-container {
      width: 182px;
      height: 23px;

      .small-tooth-number-container {
        height: 23px;
        line-height: 23px;
        font-size: $small-font-size;
      }
    }
  }
}

.advanced-tooth-position-grid-container {
  width: 235px;
  display: flex;
  justify-content: space-between;

  .tooth-position-wrapper {
    .tooth-container {
      position: relative;
      width: 102px;
      height: 170px;

      .servical-buccal-position {
        position: absolute;
        top: 0;
      }

      .tooth-positions-container {
        position: absolute;
        top: 35px;
        width: 102px;
        height: 102px;

        .buccal-position {
          position: absolute;
          left: 50%;
          margin-left: -35px;
        }

        .mesial-position {
          position: absolute;
          left: 1px;
          width: 34px;
          height: 72px;
          top: 14px;
        }

        .incisal-position {
          width: 47px;
          height: 46px;
          position: absolute;
          top: 28px;
          left: 50%;
          margin-left: -23px;
          z-index: 2;
        }

        .distal-position {
          position: absolute;
          right: 0;
          width: 34px;
          height: 72px;
          top: 14px;
        }

        .palatal-position {
          position: absolute;
          bottom: 2px;
          left: 50%;
          margin-left: -35px;
        }
      }

      .servical-palatal-position {
        position: absolute;
        bottom: 0;
      }
    }
  }

  .roots-position-container {
    .root-position {
      position: relative;
      margin-bottom: 8px;
    }
  }

  .tooth-position-wrapper,
  .roots-position-container {
    .position {
      &.active {
        color: $active-position-stroke;
        z-index: 1;

        svg {
          g {
            fill: $active-position-fill;
            stroke: $active-position-stroke;
          }
        }
      }

      &:focus {
        outline: none;
      }

      .position-text {
        position: absolute;
        text-transform: uppercase;
        font-size: $font-size-base - 6;

        &.root-text-position {
          font-size: $font-size-sm;
          position: absolute;
          width: 100%;
          text-align: center;
          top: 7px;
        }
      }

      &:hover {
        cursor: pointer;

        svg {
          g {
            fill: $pale-lilac;
          }
        }
      }
    }
  }
}

.patient-dental-chart-list {
  margin-top: 12px;
  min-width: 1130px;
}

.dental-chart-list-container {
  .services-list {
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 83px;
  }

  .dental-chart-notes {
    > span {
      white-space: normal;
      max-height: 100px;
    }
  }
}
