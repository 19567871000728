@import "../../abstracts/variables";

.sidepanel-overlay {
  .sidepanel-document-container {
    min-width: auto !important;
    width: 700px !important;

    @media print {
      -webkit-print-color-adjust: exact !important;
      width: 100% !important;
      max-width: 100% !important;

      button,
      .icon-close {
        display: none;
      }
    }

    .sidepanel-document-form {
      & > button {
        &:not(:first-child) {
          margin-right: 16px;
        }
      }

      .document-edit-button,
      .document-save-button {
        float: right;
      }

      .document-save-button {
        margin-right: 60px;
        margin-bottom: 27px;
      }
    }
  }
}

.document-list-file-name {
  margin: 1px 0 0 5px;
  font-size: $font-size-base;
}
