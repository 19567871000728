.patient-notes-tile-container {
  .patient-notes-form-card {
    margin-bottom: 0;

    form {
      display: flex;
      align-items: flex-end;

      .form-group {
        margin: 0;
      }

      .note-field {
        .ck-content {
          transition: min-height 0.1s ease-out;
        }

        &.filled-field {
          &:not(.hide-toolbar) {
            .ck-sticky-panel {
              display: none;
            }

            .ck-content {
              &:not(.ck-focused) {
                min-height: 42px;
                border-radius: 10px !important;
              }
            }
          }
        }
      }

      .moshi-button {
        min-width: auto;
        height: 48px;
      }
    }
  }

  .patient-note-card {
    border: 1px solid $grey-110;

    &.pinned {
      background-color: $yellow-100;
    }

    .patient-note-header {
      font-size: $font-size-md;
      color: $grey-900;
      min-height: 19px;

      .note-action-buttons {
        .pin {
          height: 16px;
          width: 16px;
          vertical-align: text-top;
        }

        .card-more-actions {
          .card-more-actions-icon {
            color: $grey-900;
          }
        }
      }
    }

    form {
      margin: 0 -5px;

      .note-field {
        margin-top: -2px;

        &.disabled {
          .form-group {
            margin-bottom: 0;
          }

          .ck-sticky-panel {
            display: none;
          }

          .ck-content {
            background-color: transparent;
            min-height: 42px;
            padding: 0;
            border: 0;
          }
        }
      }
    }
  }

  .note-field {
    &.focused {
      .ck-toolbar {
        border: 1px solid $blue-700;
        border-bottom-color: $grey-500 !important;
      }

      .ck-content {
        border: 1px solid $blue-700 !important;
        border-top-color: $grey-500 !important;
      }
    }
  }

  .ck-toolbar {
    border-radius: 10px 10px 0 0 !important;
  }

  .ck-content {
    border-radius: 0 0 10px 10px !important;
    font-size: $font-size-base + 1;

    .ck-placeholder {
      font-size: $font-size-base;
    }
  }
}
