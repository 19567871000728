@import "../../abstracts/variables";

.billing-item-edit {
  .overlay-section {
    > * {
      margin: 0 auto;
      min-width: 800px;
      max-width: 1000px;
    }
  }

  label {
    font-weight: 600;
    color: $grey-800 !important;
  }

  .item-edit-translations {
    margin-bottom: 90px !important;

    .required-red-dot,
    .invalid-feedback {
      display: none;
    }
  }
}
