@import "../../abstracts/variables";
@import "../../abstracts/mixins";

@mixin react-calendar-overrides {
  /* sass-lint:disable class-name-format */
  .react-calendar__navigation {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;

    button {
      min-width: auto;

      &:hover {
        background-color: transparent;
      }
    }

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation__label {
      font-family: $font-family-ibm-plex-sans;
      font-weight: 600;
      font-size: $font-size-lg;
      min-width: auto;
      flex-grow: unset !important;
    }

    .react-calendar__navigation__arrow {
      font-size: $font-size-lg;
    }

    .react-calendar__navigation__label,
    .react-calendar__navigation__arrow {
      &:hover {
        color: $default-primary;
      }

      &:focus {
        background-color: transparent;
      }
    }
  }

  .react-calendar__tile {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
  }

  .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    font-size: $font-size-base - 3;
    color: $grey5;
    font-family: $font-family-ibm-plex-sans;
    font-weight: 400;

    abbr {
      border-bottom: 0 !important;
      cursor: inherit !important;
      text-decoration: none !important;
    }
  }

  .react-calendar__month-view {
    .moshi-daypicker-day {
      border-radius: 50%;
      background-color: $daypicker-blue;
      font-size: $font-size-base - 4;
      height: 31px;
      color: $grey5;
      border: 3px solid $white-100;
      padding: 0;

      position: relative;
      overflow: visible !important;
      font-family: $font-family-ibm-plex-sans;
      font-weight: 600;
      margin-bottom: 5px;

      &:hover {
        @include smaller-box-shadow($pale-lilac);
        border: 0;
      }

      &.react-calendar__month-view__days__day--neighboringMonth,
      &.react-calendar__month-view__days__day--weekend {
        color: $grey10;
        background-color: $reverse-background;
      }

      &.react-calendar__tile--now {
        @include border-inset($default-primary);
        background-color: $white-100;
        color: $default-primary;
      }

      &.react-calendar__tile--rangeBothEnds {
        color: $white-100;
        background-color: $default-primary;
      }

      &:disabled {
        color: $grey10;
        background-color: $reverse-background;
        cursor: not-allowed;
      }
    }
  }

  button {
    background-color: transparent;
    border: 0;
    text-align: center;

    &:focus {
      outline: none !important;
    }
  }
}

.custom-daypicker {
  .react-calendar {
    @include common-box-shadow($card-shadow-color);
    @include common-border-radius;

    overflow: hidden;
    background-color: $white-100;
    padding: 0 10px 5px;
    min-height: 261px;
    border: 0;

    &.moshi-custom-date-picker {
      max-width: 255px;
    }

    @include react-calendar-overrides;
  }

  .react-date-picker__wrapper {
    width: 100%;
    border: 0;
    margin-top: -4px;
  }

  .react-date-picker__button {
    padding: 2px 6px;

    &:focus {
      outline: none;
    }
  }
}

.react-date-picker__calendar {
  width: 100% !important;
  z-index: 2 !important;

  .moshi-custom-date-picker {
    border: 1px solid $grey3;
  }
}
