.merge-patients-overlay {
  font-size: initial;
  font-weight: initial;

  .merge-patients-container {
    margin-left: 10%;

    .merge-patients-title {
      margin-bottom: 40px;
    }

    .merge-form-container {
      max-width: 460px;
      margin: auto;
      padding-bottom: 10%;
    }
  }
}
