.modal {
  .reminder-modal {
    hr {
      margin: 2rem 0;
    }

    .reminder-header {
      font-size: $font-size-title3;
      font-weight: 500;
      color: $black;
    }

    .reminder-radio-container {
      label {
        font-size: $font-size-title3;
        font-weight: 500;
        text-transform: none;
        color: $black;
      }

      .reminder-radio-options {
        margin-left: 25%;
      }
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
