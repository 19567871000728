@import "../abstracts/variables";

.icon-size-sm {
  font-size: $font-size-icon-sm;

  .icon-wrapper & {
    height: $font-size-icon-sm;
  }
}

.icon-size-md {
  font-size: $font-size-icon-md;

  .icon-wrapper & {
    height: $font-size-icon-md;
  }
}

.icon-size-lg {
  font-size: $font-size-icon-lg;

  .icon-wrapper & {
    height: $font-size-icon-lg;
  }
}

.icon-size-xl {
  font-size: $font-size-icon-xl;

  .icon-wrapper & {
    height: $font-size-icon-xl;
  }
}

.icon-color-gray {
  color: $lighter-font-color;
}

.icon-color-danger {
  color: $default-danger;
}

.icon-color-warning {
  color: $default-warning;
}

.icon-color-info {
  color: $default-primary;
}

.icon-color-success {
  color: $default-success;
}

.icon-empty {
  margin-left: 30px;
}

.icon-sidebar-active {
  position: relative;
  margin-top: -3px;
  vertical-align: middle;
  margin-left: 6px;
  margin-right: 10px;
}

.icon-weight-bold {
  font-weight: bold;
}

.icon-hover-blue :hover {
  color: $default-primary;
}

.hoverable-icon {
  height: 19px;
  width: 19px;
  padding: 1px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $blue-700;
    background-color: $blue-200;
  }

  .more-toggle {
    height: 19px;
    width: 19px;

    .card-more-actions-icon {
      margin: 0;
    }
  }
}
