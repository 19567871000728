@import "../../abstracts/variables";

.tags-popover {
  .popover {
    min-width: unset;
    max-width: unset;
    max-height: unset;

    .popover-body {
      padding: 0;
      border-radius: $sm-border-radius-size;
      overflow: visible;
      max-height: unset;
      background-color: $white;
    }
  }
}
