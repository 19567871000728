@import "../abstracts/variables";

html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  display: flex;
  text-align: left;
  font-weight: 400;
  font-size: $font-size-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-grow: 1;
  }
}
