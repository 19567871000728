.draggable-list {
  margin-bottom: 28px;
  min-height: 50px;

  .draggable-list-title {
    font-size: $font-size-xl;
    font-weight: 500;
    margin-bottom: 11px;

    &.medium-list-title {
      font-size: $font-size-title4;
      font-weight: 400;
    }
  }

  .draggable-card {
    display: flex;
    flex-direction: row;
    padding: 14px;
    margin-bottom: 10px;

    .draggable-handle {
      flex-grow: 0;
      font-size: $font-size-lg;
      height: 22px;
      margin-right: 20px;

      i {
        position: relative;
        top: -1px;
      }
    }

    .draggable-content {
      flex-grow: 1;
    }

    .actions {
      flex-grow: 0;
      display: inline-flex;
    }
  }
}
