@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.outbound-documents {
  .overlay-header {
    background-color: $violet-200;

    .outbound-document-header {
      margin: 0 auto;

      .title {
        position: relative;
        bottom: 2px;

        font-size: $font-size-title2;
        font-weight: 600;
      }
    }
  }

  .overlay-content-container {
    .overlay-content {
      display: grid !important;
      grid-template-columns: auto max-content;
    }

    .form-preview-left-side {
      .view-document-wrapper {
        min-width: 830px;
      }
    }

    .form-preview-toggle {
      margin-bottom: 20px;

      .toggle-switch-container {
        .toggle-switch {
          margin-left: 10px;
        }

        .toggle-switch-label {
          font-weight: 400;
        }
      }
    }

    .outbound-document-pdf-preview-wrapper {
      .outbound-document-pdf-preview {
        padding: 60px 60px 15px;
        min-width: 1050px;

        .form-preview-toggle {
          max-width: 920px;
          margin: 0 auto 20px;
        }
      }
    }

    .view-pdf-container {
      text-align: center;
      min-width: 1050px;

      .view-pdf-page {
        margin-bottom: 50px;

        // sass-lint:disable class-name-format
        .react-pdf__Page__canvas {
          box-shadow: $document-box-shadow;
          margin: auto;
        }
      }
    }

    .outbound-overlay-action-buttons {
      .pdf-action-button {
        vertical-align: top;
        width: calc(50% - 5px);
        min-height: 51px;
        margin-bottom: 10px;
        margin-left: 0;
        margin-right: 10px;

        &:nth-child(even) {
          margin-right: 0;
        }

        &:nth-last-child(1),
        &:nth-last-child(2) {
          margin-bottom: 0;
        }

        > button {
          margin: 0;
          min-height: 51px;
        }
      }
    }

    .outbound-new-container {
      max-width: 1200px;
      margin: 20px auto 100px;
      padding-bottom: 100px;
    }
  }
}

.outbound-document-settings {
  .form-container,
  legend-container {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .toggle-switch-label {
    margin-right: 5px;
    font-weight: 400;
  }
}

.collapsed {
  .outbound-document-settings {
    @media (min-width: 1300px) {
      .form-container {
        flex: 0 0 75%;
        max-width: 75%;
      }

      .legend-container {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
}

:not(.collapsed) {
  .outbound-document-settings {
    @media (min-width: 1475px) {
      .form-container {
        flex: 0 0 75%;
        max-width: 75%;
      }

      .legend-container {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
}
