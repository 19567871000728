@import "../../../abstracts/variables";
@import "../../../abstracts/placeholders";
@import "../../../abstracts/mixins";

.moshi-date-range-picker-empty-state {
  @extend %flex-center;
  @include common-border-radius;
  background: $white-100;
  border: 1px solid $violet-200;
  font-size: $font-size-base;
  line-height: $font-size-xl;
  font-weight: 400;
  padding: 9px 35px 9px 15px;
  white-space: nowrap;
  position: relative;

  .icon-chevron-down {
    position: absolute;
    right: 10px;
  }
}
