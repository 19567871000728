@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.patient-event-card {
  cursor: pointer;
  background-color: $reverse-background;
  margin-bottom: 10px;
  box-shadow: none;
  margin-right: 10px;

  &.active {
    background-color: $white-100;
  }

  .event-date {
    font-size: $font-size-base - 3;
    color: $grey5;
    text-transform: uppercase;
    line-height: 24px;
    margin-bottom: 15px;
    letter-spacing: 0.46px;

    .event-day {
      background-color: $grey3;
      font-weight: 600;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: inline-block;
      text-align: center;
      margin-right: 8px;
    }
  }

  .event-time {
    font-size: $font-size-base - 3;
    letter-spacing: 0.46px;
    line-height: 10px;
    margin-left: 7px;

    .category-indicator {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      float: left;
      margin-top: -1px;
      margin-right: 14px;

      &.moshi-calendar-event-primary {
        @include moshi-calendar-event-variant($default-primary);
      }

      &.moshi-calendar-event-secondary {
        @include moshi-calendar-event-variant($light-font-color);
      }

      &.moshi-calendar-event-info {
        @include moshi-calendar-event-variant($default-font-color);
      }

      &.moshi-calendar-event-success {
        @include moshi-calendar-event-variant($active-success);
      }

      &.moshi-calendar-event-warning {
        @include moshi-calendar-event-variant($active-warning);
      }

      &.moshi-calendar-event-danger {
        @include moshi-calendar-event-variant($active-danger);
      }

      &.moshi-calendar-event-yellow {
        @include moshi-calendar-event-variant($active-yellow);
      }

      &.moshi-calendar-event-purple {
        @include moshi-calendar-event-variant($active-purple);
      }
    }
  }

  .event-name {
    font-size: $font-size-base - 3;

    font-weight: 600;
    padding-left: 31px;
    margin-top: 5px;
  }

  .doctor-name {
    font-size: $font-size-base - 3;
    padding-left: 31px;
  }
}
