@import "../../abstracts/variables";
@import "../../abstracts/mixins";

.inbound-documents {
  .inbound-document-list-header-wrapper {
    .inbound-document-list-header {
      display: flex;
      margin: 10px;

      > * {
        margin-right: 10px;
      }
    }

    .inbound-document-list-patient-header {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $grey-110;
      margin-bottom: 10px;
      padding: 10px;
    }

    .view-dicom-toolbar {
      margin-top: 10px;
      padding: 10px;
      border-radius: 10px;
      background-color: $white-300;
      border: solid 1px $grey-110;

      button {
        font-size: 16px;
        padding: 4px 8px;
        margin: 0 5px;

        &.btn-light {
          color: $blue-700;
          border: solid 1px $grey-110;
          background-color: $white-100;

          &:hover {
            background-color: $blue-200;
            border-color: $blue-700;
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .inbound-document-tabs {
      .active {
        &.inbound-document-submission-tab {
          background-color: $green-600;
        }

        &.inbound-document-request-tab {
          background-color: $yellow-500;
        }

        &.inbound-document-template-tab {
          background-color: $primary-blue-600;
        }
      }
    }

    .inbound-date-range-filter {
      .moshi-date-range-picker-empty-state,
      .moshi-date-range-field {
        min-width: 297px;
      }
    }

    .inbound-creator-filter {
      min-width: 140px;
    }
  }

  .inbound-documents-overlay {
    .overlay-header {
      background-color: $blue-200;

      &.request {
        background-color: $yellow-200;
      }

      &.submitted {
        background-color: $green-200;
      }

      .inbound-document-header {
        margin: 0 auto;

        .title {
          position: relative;
          bottom: 2px;

          font-size: $font-size-title2;
          font-weight: 600;
        }
      }
    }

    .overlay-left-side-container {
      .form-preview-toggle {
        margin-bottom: 20px;

        .toggle-switch-container {
          display: flex;
          flex-direction: row-reverse;
          padding-top: 0;

          .toggle-switch {
            margin-left: 10px;
          }

          .toggle-switch-label {
            font-weight: 400;
          }
        }
      }

      form {
        height: unset;
      }
    }

    .overlay-right-side-container {
      overflow-y: auto;
    }

    .form-builder-left-side {
      .form-builder {
        margin: 0 auto;
        max-width: 700px;
        padding-bottom: 78px;
      }
    }

    .form-builder-right-side {
      min-width: 360px;
      height: calc(100% - 75px);
      margin-bottom: 75px;

      .overlay-section {
        padding: 16px 24px !important;
        font-size: $font-size-title4;
      }

      .add-form-field-section {
        color: $blue-700;

        &:hover {
          color: $blue-600;
        }

        i {
          margin-right: 20px;
          position: relative;
          top: 1px;
        }
      }

      .document-collapsible {
        :nth-child(2) {
          margin-top: 0;
        }
      }

      .document-is-clinical-field {
        display: flex;

        .is-clinical-label {
          flex-grow: 1;
        }
      }
    }

    .form-preview-right-side {
      width: 440px;

      .form-status-section {
        font-size: $font-size-md;

        .form-status {
          border-radius: 6px;
          padding: 4px 8px;
          margin-right: 9px;
          color: $white;

          &.submitted {
            background-color: $green-600;
          }

          &.request {
            background-color: $yellow-600;
          }
        }
      }
    }
  }
}
