// sass-lint:disable class-name-format
@import "../../abstracts/variables";

.overlay-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $white-100;
  z-index: 999;

  .overlay-header {
    justify-content: flex-end;
    padding: 10px 15px;
    border-bottom: 1px solid $grey-blue;
    margin-bottom: 0;
    height: $overlay-header-height;

    #overlay-title {
      font-size: $font-size-title2;
      font-weight: 400;
      text-align: center;
      width: 100%;

      > * {
        vertical-align: middle;
      }
    }

    .close-button {
      cursor: pointer;
      display: flex;

      &:focus {
        outline: none;
      }

      i {
        font-size: $font-size-title1;
        color: $default-font-color;

        &:hover {
          color: $blue-700;
        }
      }
    }
  }

  .overlay-content-container {
    height: calc(100% - #{$overlay-header-height});
    overflow-x: auto;

    .overlay-content {
      display: flex;
      height: 100%;
      flex: 1;

      > form {
        display: flex;
        flex: 1;
      }

      .overlay-left-side-container {
        flex-grow: 1;
        border-right: 1px solid $grey-blue;
        padding: 25px;
        overflow: auto;

        .overlay-section {
          padding: 25px;
          border-bottom: 1px solid $violet-100;
        }

        &.no-padding {
          padding: 0;
        }
      }

      .overlay-right-side-container {
        display: flex;
        flex-direction: column;
        position: relative;

        .overlay-section {
          padding: 20px 34px;
          border-bottom: 1px solid $violet-100;

          .form-group {
            padding-left: 0;
            padding-right: 0;
          }
        }

        .overlay-right-bottom-container {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: $overlay-right-side-button-container-height;

          .view-in-calendar-link {
            position: absolute;
            margin-top: -30px;
            top: 0;
            width: 100%;
            text-align: center;
            left: 0;
          }
        }
      }

      .button-container {
        background-color: $pastel-blue-100;
        padding: 24px 14px;
        text-align: center;
        border-top: 1px solid $grey-400;
        border-bottom: 1px solid $grey-400;

        > *:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

.overlay-right-side-patient-details {
  width: 440px;

  &.patient-details-scrollable {
    height: calc(
      100vh - #{$overlay-header-height +
        $overlay-right-side-button-container-height}
    );
    overflow: auto;
  }

  .selected-patient-container {
    .patient-card-container {
      position: relative;
      padding: 18px 15px 15px;
      border-bottom: 1px solid $violet-100;

      .patient-name {
        margin-bottom: 0;
        margin-left: 15px;
        color: $black-900;

        > a {
          font-size: $font-size-xl;
        }
      }

      .patient-basic-info {
        color: $black-900;
        margin-bottom: 20px;
        margin-left: 15px;
      }

      .view-profile {
        text-align: center;
      }
    }
  }

  .patient-stats-container {
    display: flex;
    padding: 10px;
    min-height: 88px;
    margin-bottom: 10px;

    .totals-container {
      flex-grow: 1;
      text-align: center;

      h3 {
        margin-bottom: 7px;
      }

      p {
        margin-bottom: 5px;
        font-size: $font-size-sm;
        color: $cloudy-blue;
        text-transform: uppercase;
      }
    }
  }

  .patient-tabs-container {
    padding: 15px;
    border-bottom: 1px solid $pale-lilac;

    .tabs {
      .tab {
        text-transform: uppercase;
        color: $default-primary;
        font-size: $font-size-sm;
        font-weight: 600;
      }
    }

    .tab-content {
      .patient-contact {
        .grey-label {
          margin-bottom: 0;
          margin-top: 10px;
          display: block;
        }
      }
    }
  }

  .patient-autosuggest-container {
    padding: 15px;
    background-color: $pastel-blue-100;

    .form-group {
      padding: 0;
    }

    .react-autosuggest__suggestions-container--open {
      top: 84px;
      width: 440px;
      height: calc(100vh - 278px);
      left: -15px;
      border-radius: 0;
      border: 0;
      overflow: auto;

      .react-autosuggest__suggestion {
        border: 0;
        border-bottom: 1px solid $grey-300;
        border-radius: 0;
        padding: 8px 15px 7px;

        .patient-suggestion {
          .patient-name {
            font-weight: 600;
          }

          .patient-info {
            font-size: $font-size-md;
            color: $grey-700;

            > span {
              display: inline;

              &:not(:last-child) {
                &::after {
                  margin-left: 5px;
                  content: "\2022";
                  margin-right: 5px;
                }
              }
            }
          }
        }

        &:hover {
          color: $default-primary;
          background-color: $blue-100;
          cursor: pointer;

          .patient-info {
            color: $default-primary;
          }
        }
      }
    }

    .create-patient-btn-container {
      text-align: center;

      .btn-link {
        font-size: $font-size-base - 2;
        display: inline-block;
      }
    }
  }

  .empty-patient-container {
    width: 100%;
    background-color: $pastel-blue-100;
    height: 129px;
  }

  .no-patient-msg {
    font-size: $font-size-title3;
    text-align: center;
    color: $cloudy-blue;
    line-height: 1.22;
  }
}

.overlay-doctor-container {
  padding-top: 20px !important;
  padding-bottom: 3px !important;
}

.overlay-patient-more-actions-container {
  position: absolute;
  right: 16px;
  top: 16px;
}
