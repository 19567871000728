/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url(~axios-progress-bar/dist/nprogress.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* BASE */
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-Thin.ttf") format("truetype"); }

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-ThinItalic.ttf") format("truetype"); }

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-ExtraLight.ttf") format("truetype"); }

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-ExtraLightItalic.ttf") format("truetype"); }

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf") format("truetype"); }

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-LightItalic.ttf") format("truetype"); }

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-Italic.ttf") format("truetype"); }

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-MediumItalic.ttf") format("truetype"); }

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf") format("truetype"); }

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-SemiBoldItalic.ttf") format("truetype"); }

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/IBM_Plex_Sans/IBMPlexSans-BoldItalic.ttf") format("truetype"); }

/* sass-lint:disable no-duplicate-properties class-name-format force-pseudo-nesting */
.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@font-face {
  font-family: "Ikonate";
  src: url("./fonts/Ikonate/fonts/ikonate.eot?z7b5kh");
  src: url("./fonts/Ikonate/fonts/ikonate.eot?z7b5kh#iefix") format("embedded-opentype"), url("./fonts/Ikonate/fonts/ikonate.ttf?z7b5kh") format("truetype"), url("./fonts/Ikonate/fonts/ikonate.woff?z7b5kh") format("woff"), url("./fonts/Ikonate/fonts/ikonate.svg?z7b5kh#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.icon-arrow-right-circle::before {
  content: "\e900"; }

.icon-arrow-left-circle::before {
  content: "\e901"; }

.icon-arrow-down-circle::before {
  content: "\e902"; }

.icon-arrow-up::before {
  content: "\e903"; }

.icon-arrow-right::before {
  content: "\e904"; }

.icon-arrow-right-top::before {
  content: "\e905"; }

.icon-arrow-right-bottom::before {
  content: "\e906"; }

.icon-arrow-left::before {
  content: "\e907"; }

.icon-arrow-left-top::before {
  content: "\e908"; }

.icon-arrow-left-bottom::before {
  content: "\e909"; }

.icon-arrow-down::before {
  content: "\e90a"; }

.icon-apps-alt::before {
  content: "\e90b"; }

.icon-apps::before {
  content: "\e90c"; }

.icon-aperture::before {
  content: "\e90d"; }

.icon-anchor::before {
  content: "\e90e"; }

.icon-align-right::before {
  content: "\e90f"; }

.icon-align-left::before {
  content: "\e910"; }

.icon-arrow-up-circle::before {
  content: "\e911"; }

.icon-align-center::before {
  content: "\e912"; }

.icon-alarm::before {
  content: "\e913"; }

.icon-add-to-list::before {
  content: "\e914"; }

.icon-add::before {
  content: "\e915"; }

.icon-activity::before {
  content: "\e916"; }

.icon-accessibility-human::before {
  content: "\e917"; }

.icon-accessibility::before {
  content: "\e918"; }

.icon-brightness::before {
  content: "\e919"; }

.icon-box-alt2::before {
  content: "\e91a"; }

.icon-box-alt::before {
  content: "\e91b"; }

.icon-box::before {
  content: "\e91c"; }

.icon-bookmark::before {
  content: "\e91d"; }

.icon-book::before {
  content: "\e91e"; }

.icon-book-opened::before {
  content: "\e91f"; }

.icon-bolt::before {
  content: "\e920"; }

.icon-bluetooth::before {
  content: "\e921"; }

.icon-bin::before {
  content: "\e922"; }

.icon-bike::before {
  content: "\e923"; }

.icon-bell::before {
  content: "\e924"; }

.icon-battery::before {
  content: "\e925"; }

.icon-battery-medium::before {
  content: "\e926"; }

.icon-battery-low::before {
  content: "\e927"; }

.icon-battery-full::before {
  content: "\e928"; }

.icon-battery-charging::before {
  content: "\e929"; }

.icon-bug::before {
  content: "\e92a"; }

.icon-battery-alt::before {
  content: "\e92b"; }

.icon-basketball::before {
  content: "\e92c"; }

.icon-basket::before {
  content: "\e92d"; }

.icon-bag::before {
  content: "\e92e"; }

.icon-back::before {
  content: "\e92f"; }

.icon-back-right::before {
  content: "\e930"; }

.icon-back-left::before {
  content: "\e931"; }

.icon-back-alt::before {
  content: "\e932"; }

.icon-code::before {
  content: "\e933"; }

.icon-cloud-crossed::before {
  content: "\e934"; }

.icon-cloud::before {
  content: "\e935"; }

.icon-cloud-upload::before {
  content: "\e936"; }

.icon-close::before {
  content: "\e937"; }

.icon-clipboard::before {
  content: "\e938"; }

.icon-clip::before {
  content: "\e939"; }

.icon-circle::before {
  content: "\e93a"; }

.icon-chevrons-up::before {
  content: "\e93b"; }

.icon-chevrons-right::before {
  content: "\e93c"; }

.icon-chevrons-left::before {
  content: "\e93d"; }

.icon-chevrons-down::before {
  content: "\e93e"; }

.icon-chevron-up::before {
  content: "\e93f"; }

.icon-chevron-right::before {
  content: "\e940"; }

.icon-chevron-left::before {
  content: "\e941"; }

.icon-chevron-down::before {
  content: "\e942"; }

.icon-checkbox::before {
  content: "\e943"; }

.icon-checkbox-intermediate::before {
  content: "\e944"; }

.icon-chat-warning::before {
  content: "\e945"; }

.icon-chat-remove::before {
  content: "\e946"; }

.icon-chat-add::before {
  content: "\e947"; }

.icon-chat::before {
  content: "\e948"; }

.icon-chat-alt::before {
  content: "\e949"; }

.icon-chart::before {
  content: "\e94a"; }

.icon-celluar::before {
  content: "\e94b"; }

.icon-cart-add::before {
  content: "\e94c"; }

.icon-cart::before {
  content: "\e94d"; }

.icon-cards::before {
  content: "\e94e"; }

.icon-car-alt::before {
  content: "\e94f"; }

.icon-car::before {
  content: "\e950"; }

.icon-cancel::before {
  content: "\e951"; }

.icon-camera::before {
  content: "\e952"; }

.icon-camera-rear::before {
  content: "\e953"; }

.icon-calendar-event::before {
  content: "\e954"; }

.icon-calendar-add::before {
  content: "\e955"; }

.icon-calendar-decline::before {
  content: "\e956"; }

.icon-calendar::before {
  content: "\e957"; }

.icon-code-alt::before {
  content: "\e958"; }

.icon-coffee::before {
  content: "\e959"; }

.icon-drop::before {
  content: "\e95a"; }

.icon-download::before {
  content: "\e95b"; }

.icon-dolar::before {
  content: "\e95c"; }

.icon-disc::before {
  content: "\e95d"; }

.icon-directions-right::before {
  content: "\e95e"; }

.icon-directions-left::before {
  content: "\e95f"; }

.icon-direction-right::before {
  content: "\e960"; }

.icon-direction-left::before {
  content: "\e961"; }

.icon-dialpad::before {
  content: "\e962"; }

.icon-diamond::before {
  content: "\e963"; }

.icon-delete::before {
  content: "\e964"; }

.icon-dashboard::before {
  content: "\e965"; }

.icon-danger::before {
  content: "\e966"; }

.icon-cut::before {
  content: "\e967"; }

.icon-cursor::before {
  content: "\e968"; }

.icon-cup::before {
  content: "\e969"; }

.icon-crossing::before {
  content: "\e96a"; }

.icon-crop::before {
  content: "\e96b"; }

.icon-credit-card::before {
  content: "\e96c"; }

.icon-copy::before {
  content: "\e96d"; }

.icon-controls-vertical-alt::before {
  content: "\e96e"; }

.icon-controls-alt::before {
  content: "\e96f"; }

.icon-controls::before {
  content: "\e970"; }

.icon-control-centre::before {
  content: "\e971"; }

.icon-contrast::before {
  content: "\e972"; }

.icon-contact-book::before {
  content: "\e973"; }

.icon-component::before {
  content: "\e974"; }

.icon-colours::before {
  content: "\e975"; }

.icon-eye-crossed::before {
  content: "\e976"; }

.icon-eye::before {
  content: "\e977"; }

.icon-extension::before {
  content: "\e978"; }

.icon-explore::before {
  content: "\e979"; }

.icon-exit::before {
  content: "\e97a"; }

.icon-error::before {
  content: "\e97b"; }

.icon-envelope::before {
  content: "\e97c"; }

.icon-envelope-alt::before {
  content: "\e97d"; }

.icon-entrance::before {
  content: "\e97e"; }

.icon-ellypsis::before {
  content: "\e97f"; }

.icon-ellypsis-vertical::before {
  content: "\e980"; }

.icon-edit::before {
  content: "\e981"; }

.icon-ear::before {
  content: "\e982"; }

.icon-eye-closed::before {
  content: "\e983"; }

.icon-grid::before {
  content: "\e984"; }

.icon-grid-small::before {
  content: "\e985"; }

.icon-glasses::before {
  content: "\e986"; }

.icon-forward::before {
  content: "\e987"; }

.icon-folder-warning::before {
  content: "\e988"; }

.icon-folder-remove::before {
  content: "\e989"; }

.icon-folder-add::before {
  content: "\e98a"; }

.icon-folder::before {
  content: "\e98b"; }

.icon-flower::before {
  content: "\e98c"; }

.icon-flag::before {
  content: "\e98d"; }

.icon-fit::before {
  content: "\e98e"; }

.icon-filter::before {
  content: "\e98f"; }

.icon-film::before {
  content: "\e990"; }

.icon-file::before {
  content: "\e991"; }

.icon-feed::before {
  content: "\e992"; }

.icon-grid-alt::before {
  content: "\e993"; }

.icon-feather::before {
  content: "\e994"; }

.icon-favourite::before {
  content: "\e995"; }

.icon-justify::before {
  content: "\e996"; }

.icon-iphone::before {
  content: "\e997"; }

.icon-info::before {
  content: "\e998"; }

.icon-inbox-alt::before {
  content: "\e999"; }

.icon-inbox::before {
  content: "\e99a"; }

.icon-image::before {
  content: "\e99b"; }

.icon-hourglass::before {
  content: "\e99c"; }

.icon-horn::before {
  content: "\e99d"; }

.icon-home-alt2::before {
  content: "\e99e"; }

.icon-home-alt::before {
  content: "\e99f"; }

.icon-home::before {
  content: "\e9a0"; }

.icon-history::before {
  content: "\e9a1"; }

.icon-help::before {
  content: "\e9a2"; }

.icon-hearing-disability::before {
  content: "\e9a3"; }

.icon-headphones::before {
  content: "\e9a4"; }

.icon-hdr::before {
  content: "\e9a5"; }

.icon-hash::before {
  content: "\e9a6"; }

.icon-key::before {
  content: "\e9a7"; }

.icon-happy-face::before {
  content: "\e9a8"; }

.icon-hamburger::before {
  content: "\e9a9"; }

.icon-lock-open::before {
  content: "\e9aa"; }

.icon-lock-alt::before {
  content: "\e9ab"; }

.icon-lock-alt-open::before {
  content: "\e9ac"; }

.icon-location::before {
  content: "\e9ad"; }

.icon-list-view::before {
  content: "\e9ae"; }

.icon-list::before {
  content: "\e9af"; }

.icon-list-alt::before {
  content: "\e9b0"; }

.icon-link::before {
  content: "\e9b1"; }

.icon-line-chart::before {
  content: "\e9b2"; }

.icon-lightbulb::before {
  content: "\e9b3"; }

.icon-layout-right::before {
  content: "\e9b4"; }

.icon-column::before {
  content: "\e9b5"; }

.icon-layout-left::before {
  content: "\e9b6"; }

.icon-layers::before {
  content: "\e9b7"; }

.icon-laptop::before {
  content: "\e9b8"; }

.icon-language::before {
  content: "\e9b9"; }

.icon-lock::before {
  content: "\e9ba"; }

.icon-music::before {
  content: "\e9bb"; }

.icon-mouse::before {
  content: "\e9bc"; }

.icon-moon::before {
  content: "\e9bd"; }

.icon-minus::before {
  content: "\e9be"; }

.icon-minimise::before {
  content: "\e9bf"; }

.icon-microphone::before {
  content: "\e9c0"; }

.icon-maximise::before {
  content: "\e9c1"; }

.icon-mask::before {
  content: "\e9c2"; }

.icon-marker::before {
  content: "\e9c3"; }

.icon-map::before {
  content: "\e9c4"; }

.icon-mute::before {
  content: "\e9c5"; }

.icon-ok-circle::before {
  content: "\e9c6"; }

.icon-ok::before {
  content: "\e9c7"; }

.icon-octagon::before {
  content: "\e9c8"; }

.icon-notebook::before {
  content: "\e9c9"; }

.icon-not-allowed::before {
  content: "\e9ca"; }

.icon-no-entry::before {
  content: "\e9cb"; }

.icon-next-alt::before {
  content: "\e9cc"; }

.icon-night-mode::before {
  content: "\e9cd"; }

.icon-next::before {
  content: "\e9ce"; }

.icon-news::before {
  content: "\e9cf"; }

.icon-new::before {
  content: "\e9d0"; }

.icon-neutral-face::before {
  content: "\e9d1"; }

.icon-origin::before {
  content: "\e9d2"; }

.icon-previous-alt::before {
  content: "\e9d3"; }

.icon-previous::before {
  content: "\e9d4"; }

.icon-presentation::before {
  content: "\e9d5"; }

.icon-power::before {
  content: "\e9d6"; }

.icon-poll::before {
  content: "\e9d7"; }

.icon-plus::before {
  content: "\e9d8"; }

.icon-play::before {
  content: "\e9d9"; }

.icon-plane::before {
  content: "\e9da"; }

.icon-placeholder::before {
  content: "\e9db"; }

.icon-pizza::before {
  content: "\e9dc"; }

.icon-pie-chart::before {
  content: "\e9dd"; }

.icon-phone::before {
  content: "\e9de"; }

.icon-person-add::before {
  content: "\e9df"; }

.icon-person::before {
  content: "\e9e0"; }

.icon-people::before {
  content: "\e9e1"; }

.icon-pen::before {
  content: "\e9e2"; }

.icon-pause-circle::before {
  content: "\e9e3"; }

.icon-print::before {
  content: "\e9e4"; }

.icon-pause::before {
  content: "\e9e5"; }

.icon-paperclip::before {
  content: "\e9e6"; }

.icon-pan::before {
  content: "\e9e7"; }

.icon-rotate::before {
  content: "\e9e8"; }

.icon-rocket::before {
  content: "\e9e9"; }

.icon-retweet::before {
  content: "\e9ea"; }

.icon-return::before {
  content: "\e9eb"; }

.icon-restaurant::before {
  content: "\e9ec"; }

.icon-repeat::before {
  content: "\e9ed"; }

.icon-remove::before {
  content: "\e9ee"; }

.icon-refresh::before {
  content: "\e9ef"; }

.icon-redo::before {
  content: "\e9f0"; }

.icon-rectangle::before {
  content: "\e9f1"; }

.icon-rain::before {
  content: "\e9f2"; }

.icon-radio-button-selected::before {
  content: "\e9f3"; }

.icon-radio-button::before {
  content: "\e9f4"; }

.icon-radio::before {
  content: "\e9f5"; }

.icon-rss::before {
  content: "\e9f6"; }

.icon-switch-off::before {
  content: "\e9f7"; }

.icon-swap-horizontal::before {
  content: "\e9f8"; }

.icon-swap-vertical::before {
  content: "\e9f9"; }

.icon-support-alt::before {
  content: "\e9fa"; }

.icon-support::before {
  content: "\e9fb"; }

.icon-sunset::before {
  content: "\e9fc"; }

.icon-sun::before {
  content: "\e9fd"; }

.icon-sun-cloud::before {
  content: "\e9fe"; }

.icon-suitcase::before {
  content: "\e9ff"; }

.icon-suitcase-alt::before {
  content: "\ea00"; }

.icon-stopwatch::before {
  content: "\ea01"; }

.icon-stop::before {
  content: "\ea02"; }

.icon-sticker::before {
  content: "\ea03"; }

.icon-stats::before {
  content: "\ea04"; }

.icon-stats-alt::before {
  content: "\ea05"; }

.icon-star::before {
  content: "\ea06"; }

.icon-stack::before {
  content: "\ea07"; }

.icon-spam::before {
  content: "\ea08"; }

.icon-sounds::before {
  content: "\ea09"; }

.icon-sort-up::before {
  content: "\ea0a"; }

.icon-sort-down::before {
  content: "\ea0b"; }

.icon-sorting::before {
  content: "\ea0c"; }

.icon-snow::before {
  content: "\ea0d"; }

.icon-smartphone::before {
  content: "\ea0e"; }

.icon-sign-language::before {
  content: "\ea0f"; }

.icon-signal::before {
  content: "\ea10"; }

.icon-signal-alt::before {
  content: "\ea11"; }

.icon-skull::before {
  content: "\ea12"; }

.icon-shuffle::before {
  content: "\ea13"; }

.icon-shift::before {
  content: "\ea14"; }

.icon-shield::before {
  content: "\ea15"; }

.icon-share::before {
  content: "\ea16"; }

.icon-share-ios::before {
  content: "\ea17"; }

.icon-share-android::before {
  content: "\ea18"; }

.icon-settings::before {
  content: "\ea19"; }

.icon-send::before {
  content: "\ea1a"; }

.icon-search::before {
  content: "\ea1b"; }

.icon-save::before {
  content: "\ea1c"; }

.icon-sad-face::before {
  content: "\ea1d"; }

.icon-switch-on::before {
  content: "\ea1e"; }

.icon-tv::before {
  content: "\ea1f"; }

.icon-turn-right::before {
  content: "\ea20"; }

.icon-turn-left::before {
  content: "\ea21"; }

.icon-trending-up::before {
  content: "\ea22"; }

.icon-trending-down::before {
  content: "\ea23"; }

.icon-train::before {
  content: "\ea24"; }

.icon-transport::before {
  content: "\ea25"; }

.icon-tool::before {
  content: "\ea26"; }

.icon-timer::before {
  content: "\ea27"; }

.icon-time::before {
  content: "\ea28"; }

.icon-thunder::before {
  content: "\ea29"; }

.icon-thumb-up::before {
  content: "\ea2a"; }

.icon-thumb-down::before {
  content: "\ea2b"; }

.icon-thermometer::before {
  content: "\ea2c"; }

.icon-text::before {
  content: "\ea2d"; }

.icon-tag::before {
  content: "\ea2e"; }

.icon-tag-alt::before {
  content: "\ea2f"; }

.icon-typography::before {
  content: "\ea30"; }

.icon-table-vertical::before {
  content: "\ea31"; }

.icon-table-horizontal::before {
  content: "\ea32"; }

.icon-upload::before {
  content: "\ea33"; }

.icon-undo::before {
  content: "\ea34"; }

.icon-umbrella::before {
  content: "\ea35"; }

.icon-user::before {
  content: "\ea36"; }

.icon-volume-off::before {
  content: "\ea37"; }

.icon-volume-loud::before {
  content: "\ea38"; }

.icon-voicemail::before {
  content: "\ea39"; }

.icon-video::before {
  content: "\ea3a"; }

.icon-vertical::before {
  content: "\ea3b"; }

.icon-verified::before {
  content: "\ea3c"; }

.icon-volume-quiet::before {
  content: "\ea3d"; }

.icon-watch::before {
  content: "\ea3e"; }

.icon-wallpaper::before {
  content: "\ea3f"; }

.icon-walking::before {
  content: "\ea40"; }

.icon-wheelchair::before {
  content: "\ea41"; }

.icon-wine::before {
  content: "\ea42"; }

.icon-window::before {
  content: "\ea43"; }

.icon-wifi::before {
  content: "\ea44"; }

.icon-qr::before {
  content: "\ea45"; }

.icon-zoom-out::before {
  content: "\ea46"; }

.icon-zoom-in::before {
  content: "\ea47"; }

html {
  height: 100%;
  overflow: hidden; }

body {
  height: 100%;
  display: flex;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body #root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-grow: 1; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

* {
  outline: none !important; }

body {
  font-family: "IBM Plex Sans", "sans-serif"; }

header {
  padding-bottom: 15px;
  margin-bottom: 10px;
  display: flex; }
  header h1,
  header h2 {
    flex-grow: 1;
    margin: 0; }
  header button {
    align-self: flex-end;
    margin-right: 0;
    margin-left: 15px; }

main {
  flex-grow: 1;
  padding: 20px;
  margin-left: 250px;
  overflow-y: auto;
  background-color: #ebf1f5;
  transition: margin-left 0.3s; }
  main.collapsed {
    margin-left: 70px; }

p {
  line-height: 1.5; }

ol li,
ul li {
  line-height: 1.5; }

b,
strong {
  font-weight: 600; }

::placeholder {
  color: #b4c2c9;
  -webkit-text-fill-color: #b4c2c9; }

a {
  font-size: 14px;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  font-weight: 600;
  color: #0a83bf;
  border: 0; }
  a.hover:not(.no-hover), a:hover:not(.no-hover) {
    color: #0ca3ed;
    text-decoration: none; }
    a.hover:not(.no-hover) svg g,
    a.hover:not(.no-hover) svg circle,
    a.hover:not(.no-hover) svg path, a:hover:not(.no-hover) svg g,
    a:hover:not(.no-hover) svg circle,
    a:hover:not(.no-hover) svg path {
      fill: #0ca3ed; }
  a.focus:not(.no-hover), a:focus:not(.no-hover) {
    color: #0ca3ed;
    text-decoration: none;
    box-shadow: none; }
    a.focus:not(.no-hover) svg g,
    a.focus:not(.no-hover) svg circle,
    a.focus:not(.no-hover) svg path, a:focus:not(.no-hover) svg g,
    a:focus:not(.no-hover) svg circle,
    a:focus:not(.no-hover) svg path {
      fill: #0ca3ed; }

i {
  display: inline-block;
  text-decoration: none; }
  i:active, i:focus, i:hover {
    outline: none; }
  i .icon-children-wrapper {
    font-family: "IBM Plex Sans", "sans-serif" !important;
    font-weight: 600; }

input[type="number"] {
  -moz-appearance: textfield !important; }

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important; }

input:disabled, input.disabled, input[disabled],
textarea:disabled,
textarea.disabled,
textarea[disabled],
select:disabled,
select.disabled,
select[disabled],
.dropdown-toggle:disabled,
.dropdown-toggle.disabled,
.dropdown-toggle[disabled] {
  user-select: all;
  pointer-events: none;
  resize: none;
  overflow: hidden; }

label {
  position: relative;
  width: 100%;
  font-size: 12px; }
  label input,
  label select {
    height: 40px; }
  label .form-control {
    position: relative; }
    label .form-control :placeholder-shown + * {
      top: 0; }
  label select .form-control {
    color: #adb5bd; }
    label select .form-control + span {
      top: 0; }
    label select .form-control.selected {
      color: #000; }
      label select .form-control.selected + span {
        top: -20px; }
  label .invalid-feedback {
    position: absolute; }

.invalid-feedback {
  color: #cf3a1b;
  font-size: 14px; }

.w-30 {
  width: 30%; }

.w-60 {
  width: 60%; }

.fit-content {
  max-width: fit-content; }

.font-size-sm {
  font-size: 11px; }

.font-size-lg {
  font-size: 18px; }

.font-size-xl {
  font-size: 20px; }

.font-line-through {
  text-decoration: line-through; }

.italic {
  font-style: italic; }

.hidden {
  display: none !important; }

.hidden-opacity {
  opacity: 0 !important; }

.invisible {
  visibility: hidden !important; }

.no-padding {
  padding: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-left {
  padding-left: 0 !important; }

.no-margin {
  margin: 0 !important; }

.no-margin-bottom {
  margin-bottom: 0 !important; }

.margin-aside-5 {
  margin-left: 5px;
  margin-right: 5px; }

.margin-aside-negative-5 {
  margin-left: -5px;
  margin-right: -5px; }

.margin-aside-10 {
  margin-left: 10px;
  margin-right: 10px; }

.absolute-right {
  position: absolute;
  right: 0;
  top: 50%; }

.svg-height-offset {
  margin-top: -15px; }

.transform-uppercase {
  text-transform: uppercase !important; }

.transform-normal-text {
  text-transform: none !important; }

.no-break {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.pre-wrap {
  white-space: pre-wrap; }

.text-normal-wrap {
  white-space: normal; }

.flex-align-middle-row {
  align-items: center;
  align-content: center; }

.flex-align-middle-column {
  justify-items: center;
  justify-content: center; }

.flex-justify-middle-column {
  align-items: center;
  align-content: center; }

.flex-justify-align-middle-row {
  align-items: center;
  justify-content: center; }

.flex-center-all-row-important {
  align-items: center !important;
  align-content: center !important;
  justify-items: center !important;
  justify-content: center !important; }

.flex-justify-end-row {
  justify-items: flex-end;
  justify-content: flex-end; }

.flex-justify-start-row {
  justify-items: flex-start;
  justify-content: flex-start; }

.flex-space-between {
  justify-content: space-between; }

.flex-row {
  align-items: center; }

.flex-inline-align-middle {
  display: inline-flex;
  align-items: center; }

.vertical-align-middle {
  vertical-align: middle; }

.cursor-not-allowed {
  cursor: not-allowed !important; }

.no-underline {
  text-decoration: none !important; }

.clickable-text {
  color: #000; }
  .clickable-text.hover, .clickable-text:hover {
    color: #0ca3ed !important;
    text-decoration-color: #0ca3ed; }
  .clickable-text.focus, .clickable-text:focus {
    color: #0a83bf;
    box-shadow: none;
    text-decoration-color: #0a83bf; }

.font-weight-normal {
  font-weight: 400; }

.font-weight-semi-bold {
  font-weight: 500; }

.font-weight-bold {
  font-weight: 600; }

.font-weight-extra-bold {
  font-weight: 700; }

.no-text-transform {
  text-transform: none !important; }

.grey-label {
  font-size: 14px; }

.no-box-shadow {
  box-shadow: none !important; }

.no-border {
  border: 0; }

.small-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%; }

.small-rounded-rectangle {
  width: 30px;
  height: 6px;
  border-radius: 10px;
  background-color: #f7f9fa;
  margin: 0 5px 0 0; }

.red-background {
  background-color: #cf3a1b; }

.orange-background {
  background-color: #fee895; }

.green-background {
  background-color: #b5dece; }

.header-sub-title {
  color: #13304d !important;
  text-transform: uppercase;
  font-size: 11px;
  margin-top: -8px;
  margin-bottom: 5px;
  height: unset;
  line-height: 1;
  font-weight: 600; }

.read-only {
  opacity: 0.2;
  pointer-events: none; }

.common-border-radius {
  border-radius: 14px; }

.no-wrap {
  white-space: nowrap; }

.text-align-right {
  text-align: right; }

.text-align-center {
  text-align: center; }

.text-align-left {
  text-align: left; }

.overflow-visible {
  overflow: visible !important; }

.hover-container:hover .hidden-on-hover {
  display: none !important; }

.hover-container:not(:hover) .visible-on-hover {
  display: none !important; }

.letter-spacing-normal {
  letter-spacing: normal !important; }

.capitalize {
  text-transform: capitalize !important; }

.text-transform-none {
  text-transform: none !important; }

.color-black {
  color: #000 !important; }

.common-border-bottom {
  border-bottom: 1px solid #e6eded; }

.top-2 {
  top: 2px; }

.common-filter-box-shadow:hover {
  box-shadow: 0 0 4px 0 #f1f1f9, 0 8px 30px 0 #c4c4cc; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.font-color-primary {
  color: #0a83bf; }
  .font-color-primary path {
    fill: #0a83bf !important; }
  .font-color-primary.hover, .font-color-primary:hover {
    color: #0a83bf !important; }
    .font-color-primary.hover path, .font-color-primary:hover path {
      fill: #0a83bf !important; }
  .font-color-primary.focus, .font-color-primary:focus {
    color: #0a83bf !important; }
    .font-color-primary.focus path, .font-color-primary:focus path {
      fill: #0a83bf !important; }

.font-color-success {
  color: #6fbea0; }
  .font-color-success path {
    fill: #6fbea0 !important; }
  .font-color-success.hover, .font-color-success:hover {
    color: #6fbea0 !important; }
    .font-color-success.hover path, .font-color-success:hover path {
      fill: #6fbea0 !important; }
  .font-color-success.focus, .font-color-success:focus {
    color: #6fbea0 !important; }
    .font-color-success.focus path, .font-color-success:focus path {
      fill: #6fbea0 !important; }

.font-color-warning {
  color: #fb6b32; }
  .font-color-warning path {
    fill: #fb6b32 !important; }
  .font-color-warning.hover, .font-color-warning:hover {
    color: #fb6b32 !important; }
    .font-color-warning.hover path, .font-color-warning:hover path {
      fill: #fb6b32 !important; }
  .font-color-warning.focus, .font-color-warning:focus {
    color: #fb6b32 !important; }
    .font-color-warning.focus path, .font-color-warning:focus path {
      fill: #fb6b32 !important; }

.font-color-danger {
  color: #cf3a1b; }
  .font-color-danger path {
    fill: #cf3a1b !important; }
  .font-color-danger.hover, .font-color-danger:hover {
    color: #cf3a1b !important; }
    .font-color-danger.hover path, .font-color-danger:hover path {
      fill: #cf3a1b !important; }
  .font-color-danger.focus, .font-color-danger:focus {
    color: #cf3a1b !important; }
    .font-color-danger.focus path, .font-color-danger:focus path {
      fill: #cf3a1b !important; }

.font-color-default {
  color: #171f26; }
  .font-color-default path {
    fill: #171f26 !important; }
  .font-color-default.hover, .font-color-default:hover {
    color: #171f26 !important; }
    .font-color-default.hover path, .font-color-default:hover path {
      fill: #171f26 !important; }
  .font-color-default.focus, .font-color-default:focus {
    color: #171f26 !important; }
    .font-color-default.focus path, .font-color-default:focus path {
      fill: #171f26 !important; }

.font-color-yellow {
  color: #fdd230; }
  .font-color-yellow path {
    fill: #fdd230 !important; }
  .font-color-yellow.hover, .font-color-yellow:hover {
    color: #fdd230 !important; }
    .font-color-yellow.hover path, .font-color-yellow:hover path {
      fill: #fdd230 !important; }
  .font-color-yellow.focus, .font-color-yellow:focus {
    color: #fdd230 !important; }
    .font-color-yellow.focus path, .font-color-yellow:focus path {
      fill: #fdd230 !important; }

.font-color-purple {
  color: #4e49b0; }
  .font-color-purple path {
    fill: #4e49b0 !important; }
  .font-color-purple.hover, .font-color-purple:hover {
    color: #4e49b0 !important; }
    .font-color-purple.hover path, .font-color-purple:hover path {
      fill: #4e49b0 !important; }
  .font-color-purple.focus, .font-color-purple:focus {
    color: #4e49b0 !important; }
    .font-color-purple.focus path, .font-color-purple:focus path {
      fill: #4e49b0 !important; }

.font-color-grey {
  color: #b4c2c9; }
  .font-color-grey path {
    fill: #b4c2c9 !important; }
  .font-color-grey.hover, .font-color-grey:hover {
    color: #b4c2c9 !important; }
    .font-color-grey.hover path, .font-color-grey:hover path {
      fill: #b4c2c9 !important; }
  .font-color-grey.focus, .font-color-grey:focus {
    color: #b4c2c9 !important; }
    .font-color-grey.focus path, .font-color-grey:focus path {
      fill: #b4c2c9 !important; }

.text-color-blue {
  color: #0a83bf; }
  .text-color-blue path {
    fill: #0a83bf !important; }
  .text-color-blue.hover, .text-color-blue:hover {
    color: #0ca3ed !important; }
    .text-color-blue.hover path, .text-color-blue:hover path {
      fill: #0ca3ed !important; }
  .text-color-blue.focus, .text-color-blue:focus {
    color: #0a83bf !important; }
    .text-color-blue.focus path, .text-color-blue:focus path {
      fill: #0a83bf !important; }

.text-color-red {
  color: #cf3a1b; }
  .text-color-red path {
    fill: #cf3a1b !important; }
  .text-color-red.hover, .text-color-red:hover {
    color: #cf3a1b !important; }
    .text-color-red.hover path, .text-color-red:hover path {
      fill: #cf3a1b !important; }
  .text-color-red.focus, .text-color-red:focus {
    color: #cf3a1b !important; }
    .text-color-red.focus path, .text-color-red:focus path {
      fill: #a22d15 !important; }

.text-color-green {
  color: #b5dece; }
  .text-color-green path {
    fill: #b5dece !important; }
  .text-color-green.hover, .text-color-green:hover {
    color: #4eac88 !important; }
    .text-color-green.hover path, .text-color-green:hover path {
      fill: #4eac88 !important; }
  .text-color-green.focus, .text-color-green:focus {
    color: #b5dece !important; }
    .text-color-green.focus path, .text-color-green:focus path {
      fill: #3e896d !important; }

.text-color-orange {
  color: #fee895; }
  .text-color-orange path {
    fill: #fee895 !important; }
  .text-color-orange.hover, .text-color-orange:hover {
    color: #f7c403 !important; }
    .text-color-orange.hover path, .text-color-orange:hover path {
      fill: #f7c403 !important; }
  .text-color-orange.focus, .text-color-orange:focus {
    color: #fee895 !important; }
    .text-color-orange.focus path, .text-color-orange:focus path {
      fill: #c59c02 !important; }

.text-color-grey {
  color: #8e8e93; }
  .text-color-grey path {
    fill: #8e8e93 !important; }
  .text-color-grey.hover, .text-color-grey:hover {
    color: #8e8e93 !important; }
    .text-color-grey.hover path, .text-color-grey:hover path {
      fill: #8e8e93 !important; }
  .text-color-grey.focus, .text-color-grey:focus {
    color: #8e8e93 !important; }
    .text-color-grey.focus path, .text-color-grey:focus path {
      fill: #8e8e93 !important; }

.text-color-black {
  color: #000; }
  .text-color-black path {
    fill: #000 !important; }
  .text-color-black.hover, .text-color-black:hover {
    color: #000 !important; }
    .text-color-black.hover path, .text-color-black:hover path {
      fill: #000 !important; }
  .text-color-black.focus, .text-color-black:focus {
    color: #000 !important; }
    .text-color-black.focus path, .text-color-black:focus path {
      fill: #000 !important; }

.text-color-black-blue {
  color: #000; }
  .text-color-black-blue path {
    fill: #000 !important; }
  .text-color-black-blue.hover, .text-color-black-blue:hover {
    color: #0a83bf !important; }
    .text-color-black-blue.hover path, .text-color-black-blue:hover path {
      fill: #0a83bf !important; }
  .text-color-black-blue.focus, .text-color-black-blue:focus {
    color: #000 !important; }
    .text-color-black-blue.focus path, .text-color-black-blue:focus path {
      fill: #0a83bf !important; }

.icon-size-sm {
  font-size: 11px; }
  .icon-wrapper .icon-size-sm {
    height: 11px; }

.icon-size-md {
  font-size: 16px; }
  .icon-wrapper .icon-size-md {
    height: 16px; }

.icon-size-lg {
  font-size: 22px; }
  .icon-wrapper .icon-size-lg {
    height: 22px; }

.icon-size-xl {
  font-size: 50px; }
  .icon-wrapper .icon-size-xl {
    height: 50px; }

.icon-color-gray {
  color: #406080; }

.icon-color-danger {
  color: #cf3a1b; }

.icon-color-warning {
  color: #fb6b32; }

.icon-color-info {
  color: #0a83bf; }

.icon-color-success {
  color: #6fbea0; }

.icon-empty {
  margin-left: 30px; }

.icon-sidebar-active {
  position: relative;
  margin-top: -3px;
  vertical-align: middle;
  margin-left: 6px;
  margin-right: 10px; }

.icon-weight-bold {
  font-weight: bold; }

.icon-hover-blue :hover {
  color: #0a83bf; }

.hoverable-icon {
  height: 19px;
  width: 19px;
  padding: 1px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .hoverable-icon:hover {
    color: #0a83bf;
    background-color: #caebfc; }
  .hoverable-icon .more-toggle {
    height: 19px;
    width: 19px; }
    .hoverable-icon .more-toggle .card-more-actions-icon {
      margin: 0; }

/* ABSTRACTS */
.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.input-affix-wrapper {
  position: relative; }
  .input-affix-wrapper .input-prefix {
    padding-left: 25px; }
  .input-affix-wrapper .input-suffix {
    padding-right: 25px; }
  .input-affix-wrapper .prefix-container {
    position: absolute;
    z-index: 1;
    left: 15px;
    top: 10px;
    font-weight: 600; }
  .input-affix-wrapper .suffix-container {
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 10px;
    color: #406080; }

/* COMPONENTS */
.moshi-date-range-field {
  border-radius: 14px;
  display: flex;
  align-items: stretch;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #dce3e3;
  background: #f7f9fa;
  height: 40px;
  /* sass-lint:disable class-name-format */ }
  .moshi-date-range-field.focused {
    border-color: #317dc8;
    background: #fff; }
    .moshi-date-range-field.focused > * {
      border-right: 1px solid #dce3e3; }
  .moshi-date-range-field > * {
    border-radius: 0;
    border: 0; }
  .moshi-date-range-field > :last-child {
    border-right: 0; }
  .moshi-date-range-field .separator-icon {
    display: flex;
    align-items: center;
    padding: 0 12px;
    margin: 0 -1px; }
  .moshi-date-range-field .react-date-picker {
    background: none;
    font-size: 14px;
    height: auto;
    padding-right: 8px;
    display: flex;
    align-items: center; }
  .moshi-date-range-field .react-date-picker__wrapper {
    border: 0; }
    .moshi-date-range-field .react-date-picker__wrapper .react-date-picker__inputGroup__input:invalid {
      background: none; }

.moshi-hook-form-date-range-picker .moshi-date-range-field .form-control .react-date-picker__wrapper {
  margin-top: 0; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.custom-daypicker .react-calendar {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  overflow: hidden;
  background-color: #fff;
  padding: 0 10px 5px;
  min-height: 261px;
  border: 0;
  /* sass-lint:disable class-name-format */ }
  .custom-daypicker .react-calendar.moshi-custom-date-picker {
    max-width: 255px; }
  .custom-daypicker .react-calendar .react-calendar__navigation {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between; }
    .custom-daypicker .react-calendar .react-calendar__navigation button {
      min-width: auto; }
      .custom-daypicker .react-calendar .react-calendar__navigation button:hover {
        background-color: transparent; }
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__prev2-button,
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__next2-button {
      display: none; }
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__label {
      font-family: "IBM Plex Sans", "sans-serif";
      font-weight: 600;
      font-size: 18px;
      min-width: auto;
      flex-grow: unset !important; }
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow {
      font-size: 18px; }
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__label:hover,
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow:hover {
      color: #0a83bf; }
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__label:focus,
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow:focus {
      background-color: transparent; }
  .custom-daypicker .react-calendar .react-calendar__tile {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1; }
  .custom-daypicker .react-calendar .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    font-size: 11px;
    color: #8e8e93;
    font-family: "IBM Plex Sans", "sans-serif";
    font-weight: 400; }
    .custom-daypicker .react-calendar .react-calendar__month-view__weekdays__weekday abbr {
      border-bottom: 0 !important;
      cursor: inherit !important;
      text-decoration: none !important; }
  .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day {
    border-radius: 50%;
    background-color: #f0fafe;
    font-size: 10px;
    height: 31px;
    color: #8e8e93;
    border: 3px solid #fff;
    padding: 0;
    position: relative;
    overflow: visible !important;
    font-family: "IBM Plex Sans", "sans-serif";
    font-weight: 600;
    margin-bottom: 5px; }
    .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day:hover {
      box-shadow: 0 4px 15px 0 #f1f1f9;
      border: 0; }
    .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__month-view__days__day--neighboringMonth, .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__month-view__days__day--weekend {
      color: #c4c4cc;
      background-color: #f7f9fa; }
    .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__tile--now {
      box-shadow: inset 0 0 0 1px #0a83bf;
      background-color: #fff;
      color: #0a83bf; }
    .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__tile--rangeBothEnds {
      color: #fff;
      background-color: #0a83bf; }
    .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day:disabled {
      color: #c4c4cc;
      background-color: #f7f9fa;
      cursor: not-allowed; }
  .custom-daypicker .react-calendar button {
    background-color: transparent;
    border: 0;
    text-align: center; }
    .custom-daypicker .react-calendar button:focus {
      outline: none !important; }

.custom-daypicker .react-date-picker__wrapper {
  width: 100%;
  border: 0;
  margin-top: -4px; }

.custom-daypicker .react-date-picker__button {
  padding: 2px 6px; }
  .custom-daypicker .react-date-picker__button:focus {
    outline: none; }

.react-date-picker__calendar {
  width: 100% !important;
  z-index: 2 !important; }
  .react-date-picker__calendar .moshi-custom-date-picker {
    border: 1px solid #d8d7ef; }

.moshi-date-range-popup-calendar {
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 14px;
  background: #fff;
  border: 1px solid #ebf1f5;
  position: absolute;
  z-index: 1;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto 140px;
  margin-top: 8px; }
  .moshi-date-range-popup-calendar .react-calendar {
    /* sass-lint:disable class-name-format */
    background: none;
    border: 0;
    padding: 0 25px 5px;
    width: 330px;
    align-self: center;
    flex: 1;
    /* sass-lint:disable class-name-format */ }
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation button {
        min-width: auto; }
        .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation button:hover {
          background-color: transparent; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__prev2-button,
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__next2-button {
        display: none; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__label {
        font-family: "IBM Plex Sans", "sans-serif";
        font-weight: 600;
        font-size: 18px;
        min-width: auto;
        flex-grow: unset !important; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow {
        font-size: 18px; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__label:hover,
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow:hover {
        color: #0a83bf; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__label:focus,
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow:focus {
        background-color: transparent; }
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__tile {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1; }
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view__weekdays__weekday {
      text-align: center;
      font-size: 11px;
      color: #8e8e93;
      font-family: "IBM Plex Sans", "sans-serif";
      font-weight: 400; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view__weekdays__weekday abbr {
        border-bottom: 0 !important;
        cursor: inherit !important;
        text-decoration: none !important; }
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view .moshi-daypicker-day {
      border-radius: 50%;
      background-color: #f0fafe;
      font-size: 10px;
      height: 31px;
      color: #8e8e93;
      border: 3px solid #fff;
      padding: 0;
      position: relative;
      overflow: visible !important;
      font-family: "IBM Plex Sans", "sans-serif";
      font-weight: 600;
      margin-bottom: 5px; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view .moshi-daypicker-day:hover {
        box-shadow: 0 4px 15px 0 #f1f1f9;
        border: 0; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__month-view__days__day--neighboringMonth, .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__month-view__days__day--weekend {
        color: #c4c4cc;
        background-color: #f7f9fa; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__tile--now {
        box-shadow: inset 0 0 0 1px #0a83bf;
        background-color: #fff;
        color: #0a83bf; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__tile--rangeBothEnds {
        color: #fff;
        background-color: #0a83bf; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view .moshi-daypicker-day:disabled {
        color: #c4c4cc;
        background-color: #f7f9fa;
        cursor: not-allowed; }
    .moshi-date-range-popup-calendar .react-calendar button {
      background-color: transparent;
      border: 0;
      text-align: center; }
      .moshi-date-range-popup-calendar .react-calendar button:focus {
        outline: none !important; }
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation {
      margin: 20px 0;
      align-items: center;
      height: 22px; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation button {
        align-self: center; }
      .moshi-date-range-popup-calendar .react-calendar .react-calendar__navigation .react-calendar__navigation__label {
        font-family: "IBM Plex Sans", "sans-serif";
        font-weight: 500;
        font-size: 18px; }
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view .react-calendar__month-view__weekdays__weekday {
      color: #000; }
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view .react-calendar__month-view__days__day {
      background: none;
      font-size: 14px;
      font-family: "IBM Plex Sans", "sans-serif";
      font-weight: 500;
      color: #000;
      max-width: 40px;
      height: 40px;
      border-width: 5px; }
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__month-view__days__day--weekend {
      background: none;
      color: #000; }
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__month-view__days__day--neighboringMonth {
      background: none;
      color: #c4c4cc; }
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__tile--now {
      background: #0a83bf !important;
      color: #fcfcf7 !important; }
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__year-view__months__month.moshi-daypicker-day, .moshi-date-range-popup-calendar .react-calendar .react-calendar__year-view__months__month.moshi-daypicker-day, .moshi-date-range-popup-calendar .react-calendar .react-calendar__year-view__months__month.moshi-daypicker-day,
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__decade-view__years__year.moshi-daypicker-day,
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__decade-view__years__year.moshi-daypicker-day,
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__decade-view__years__year.moshi-daypicker-day,
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__century-view__decades__decade.moshi-daypicker-day,
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__century-view__decades__decade.moshi-daypicker-day,
    .moshi-date-range-popup-calendar .react-calendar .react-calendar__century-view__decades__decade.moshi-daypicker-day {
      font-size: 14px;
      padding: 20px 10px; }
    .moshi-date-range-popup-calendar .react-calendar .moshi-daypicker-day.react-calendar__tile--active {
      background: #caebfc !important;
      color: #000 !important; }
  .moshi-date-range-popup-calendar .moshi-date-range-calendar-buttons {
    background: rgba(235, 241, 245, 0.5);
    border-top: 1px solid #d8d7ef;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: center;
    grid-column: 1 / -1; }
    .moshi-date-range-popup-calendar .moshi-date-range-calendar-buttons .moshi-button {
      margin: 0; }
    .moshi-date-range-popup-calendar .moshi-date-range-calendar-buttons .react-date-picker {
      width: auto;
      height: auto; }
    .moshi-date-range-popup-calendar .moshi-date-range-calendar-buttons .separator {
      align-self: center; }
  .moshi-date-range-popup-calendar .date-range-calendar-shortcuts {
    border-left: 1px solid #d8d7ef;
    display: grid;
    align-content: flex-start;
    grid-gap: 10px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-date-range-picker-empty-state {
  border-radius: 14px;
  background: #fff;
  border: 1px solid #d8d7ef;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 9px 35px 9px 15px;
  white-space: nowrap;
  position: relative; }
  .moshi-date-range-picker-empty-state .icon-chevron-down {
    position: absolute;
    right: 10px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.bg-primary {
  background-color: #0a83bf !important; }

.bg-success {
  background-color: #b5dece !important; }

.bg-info {
  background-color: #0a83bf !important; }

.bg-warning {
  background-color: #fee895 !important; }

.bg-danger {
  background-color: #cf3a1b !important; }

.bg-white {
  background-color: #fff !important; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.row .form-group {
  padding-left: 0;
  padding-right: 0; }

.form-group.form-group-inline {
  flex-direction: row;
  align-items: center; }
  .form-group.form-group-inline label {
    text-align: right;
    margin-bottom: 0;
    margin-right: 15px;
    width: auto;
    flex-grow: 1; }
  .form-group.form-group-inline .error-first {
    margin-right: 20px;
    text-align: right; }

.search-field-inverted .input-group {
  border-bottom: 2px solid #f1f1f9; }
  .search-field-inverted .input-group .input-group-prepend span,
  .search-field-inverted .input-group .input-group-append span {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: #8e8e93; }
  .search-field-inverted .input-group .form-control {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    padding-top: 0;
    padding-right: 35px; }
    .search-field-inverted .input-group .form-control::placeholder {
      color: #c4c4cc; }

.search-field .input-group {
  border-radius: 14px;
  background-color: #fff;
  border: 1px solid #f1f1f9; }
  .search-field .input-group .input-group-prepend,
  .search-field .input-group .input-group-append {
    align-items: center; }
    .search-field .input-group .input-group-prepend .icon-search,
    .search-field .input-group .input-group-append .icon-search {
      font-size: 16px; }
  .search-field .input-group .form-control {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    padding-right: 35px; }
    .search-field .input-group .form-control::placeholder {
      color: #c4c4cc; }

.darkened-form-area {
  border-radius: 14px;
  background-color: #e6eded; }

.moshi-form label.disabled,
form label.disabled {
  cursor: default !important; }

.moshi-form label input,
form label input {
  width: 0;
  height: 0; }

.moshi-form .column-layout,
form .column-layout {
  display: flex;
  flex-direction: row; }

.moshi-form .form-group-link,
form .form-group-link {
  position: absolute;
  padding-top: 0;
  padding-bottom: 0;
  right: 15px;
  top: 0;
  z-index: 1;
  font-size: 12px !important;
  height: 12px !important;
  min-height: 12px !important; }

.moshi-form .input-group .input-group-text,
form .input-group .input-group-text {
  border-radius: 14px;
  background-color: #f7f9fa;
  border-width: 2px;
  padding-left: 5px;
  padding-right: 5px; }

.moshi-form .input-group .input-group-prepend .form-control,
.moshi-form .input-group .input-group-prepend .input-group-text,
form .input-group .input-group-prepend .form-control,
form .input-group .input-group-prepend .input-group-text {
  margin-right: 1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 1px; }
  .moshi-form .input-group .input-group-prepend .form-control:not(:first-child),
  .moshi-form .input-group .input-group-prepend .input-group-text:not(:first-child),
  form .input-group .input-group-prepend .form-control:not(:first-child),
  form .input-group .input-group-prepend .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 1px; }

.moshi-form .input-group > .form-control:not(:first-child),
form .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 1px; }

.moshi-form .input-group > .form-control:not(:last-child),
form .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 1px; }

.moshi-form .input-group .input-group-append,
form .input-group .input-group-append {
  margin-left: 0; }
  .moshi-form .input-group .input-group-append .form-control,
  .moshi-form .input-group .input-group-append .input-group-text,
  form .input-group .input-group-append .form-control,
  form .input-group .input-group-append .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 1px; }
    .moshi-form .input-group .input-group-append .form-control:not(:last-child),
    .moshi-form .input-group .input-group-append .input-group-text:not(:last-child),
    form .input-group .input-group-append .form-control:not(:last-child),
    form .input-group .input-group-append .input-group-text:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 1px; }

.moshi-form .field-group .invalid-feedback,
form .field-group .invalid-feedback {
  display: block;
  color: #cf3a1b;
  margin-left: 15px;
  font-size: 14px; }
  .moshi-form .field-group .invalid-feedback.display,
  form .field-group .invalid-feedback.display {
    display: block; }

.moshi-form .form-group,
form .form-group {
  display: flex;
  padding-right: 5px;
  padding-left: 5px;
  flex-direction: column; }
  .moshi-form .form-group.form-group-no-margin,
  form .form-group.form-group-no-margin {
    margin: 0; }
  .moshi-form .form-group.is-invalid .invalid-feedback,
  form .form-group.is-invalid .invalid-feedback {
    display: block;
    position: absolute;
    top: 41px;
    padding-left: 2px; }
  .moshi-form .form-group .invalid-feedback,
  form .form-group .invalid-feedback {
    display: block;
    color: #cf3a1b;
    margin-left: 15px;
    font-size: 14px; }
    .moshi-form .form-group .invalid-feedback.display,
    form .form-group .invalid-feedback.display {
      display: block; }
  .moshi-form .form-group.is-warning .warning-feedback,
  form .form-group.is-warning .warning-feedback {
    display: block;
    position: absolute;
    top: 41px;
    padding-left: 2px;
    font-size: 80%;
    color: #fee895; }
  .row .moshi-form .form-group, .row
  form .form-group {
    padding-left: 0;
    padding-right: 0; }
  .moshi-form .form-group.form-group-row,
  form .form-group.form-group-row {
    padding: 10px 0;
    margin: 0; }
    .moshi-form .form-group.form-group-row label,
    form .form-group.form-group-row label {
      display: block;
      padding-top: 0.2em;
      margin: 5px; }
  .moshi-form .form-group label span.required,
  form .form-group label span.required {
    margin: 0 5px;
    color: #cf3a1b; }
  .moshi-form .form-group .form-control-date-field .date-field-dropdowns,
  form .form-group .form-control-date-field .date-field-dropdowns {
    display: flex;
    flex-direction: row; }
  .moshi-form .form-group .btn,
  form .form-group .btn {
    margin-left: 0;
    margin-right: 0; }

.moshi-form .form-group-label .required-red-dot,
form .form-group-label .required-red-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #cf3a1b;
  position: absolute;
  right: 1px;
  top: 3px;
  width: 7px;
  height: 7px;
  position: initial;
  display: inline-block;
  margin-right: 5px; }

.moshi-form .form-group-label .tooltip-icon,
form .form-group-label .tooltip-icon {
  position: absolute;
  bottom: 2px;
  margin-left: 3px; }

.moshi-form .btn,
form .btn {
  margin-right: 5px;
  margin-left: 5px; }

.moshi-form .form-control-text,
form .form-control-text {
  height: 40px;
  padding: 11px 15px 10px; }

.moshi-form .form-control,
form .form-control {
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  height: 42px;
  border-width: 1px;
  border-color: #dce3e3;
  padding: 10px 15px 10px 11px;
  box-shadow: none !important; }
  .moshi-form .form-control::placeholder,
  form .form-control::placeholder {
    color: #b4c2c9; }
  .moshi-form .form-control.form-control-no-style,
  form .form-control.form-control-no-style {
    box-shadow: none;
    border: 0;
    background: none;
    padding: 0;
    margin: 0; }
  .moshi-form .form-control.form-control-label,
  form .form-control.form-control-label {
    background: none !important;
    box-shadow: none;
    border: 0;
    padding-left: 0;
    padding-right: 0; }
  .moshi-form .form-control.checkbox, .moshi-form .form-control.radio,
  form .form-control.checkbox,
  form .form-control.radio {
    width: auto;
    border: 0;
    height: 20px;
    background: none !important; }
  .moshi-form .form-control.is-warning,
  form .form-control.is-warning {
    box-shadow: none;
    border-color: #cf3a1b;
    background-image: none; }
  .moshi-form .form-control.is-invalid,
  form .form-control.is-invalid {
    box-shadow: none;
    border-color: #cf3a1b !important;
    background-image: none;
    background-color: rgba(253, 240, 237, 0.3); }
  .moshi-form .form-control:hover:not(:focus):not(.no-hover-effect):not(.edit-mode-toggler-disabled):not(.disabled):not(:disabled),
  form .form-control:hover:not(:focus):not(.no-hover-effect):not(.edit-mode-toggler-disabled):not(.disabled):not(:disabled) {
    background-color: #ebf1f5;
    border-color: #ebf1f5; }
  .moshi-form .form-control:active, .moshi-form .form-control:focus, .moshi-form .form-control:focus-within,
  form .form-control:active,
  form .form-control:focus,
  form .form-control:focus-within {
    color: #000;
    -webkit-text-fill-color: #000; }
    .moshi-form .form-control:active:not(.is-invalid):not(.is-warning):not(.edit-mode-toggler-disabled):not(.disabled):not(:disabled):not(:read-only), .moshi-form .form-control:focus:not(.is-invalid):not(.is-warning):not(.edit-mode-toggler-disabled):not(.disabled):not(:disabled):not(:read-only), .moshi-form .form-control:focus-within:not(.is-invalid):not(.is-warning):not(.edit-mode-toggler-disabled):not(.disabled):not(:disabled):not(:read-only),
    form .form-control:active:not(.is-invalid):not(.is-warning):not(.edit-mode-toggler-disabled):not(.disabled):not(:disabled):not(:read-only),
    form .form-control:focus:not(.is-invalid):not(.is-warning):not(.edit-mode-toggler-disabled):not(.disabled):not(:disabled):not(:read-only),
    form .form-control:focus-within:not(.is-invalid):not(.is-warning):not(.edit-mode-toggler-disabled):not(.disabled):not(:disabled):not(:read-only) {
      background-color: #fff;
      border-color: #0a83bf;
      outline: 0; }
  .moshi-form .form-control:disabled:not(.react-date-picker):not(.checkbox),
  form .form-control:disabled:not(.react-date-picker):not(.checkbox) {
    color: #000;
    -webkit-text-fill-color: #000;
    background-color: transparent;
    cursor: initial; }

.moshi-form textarea.form-control,
form textarea.form-control {
  padding: 8px 15px; }
  .moshi-form textarea.form-control:read-only,
  form textarea.form-control:read-only {
    opacity: 0.2;
    pointer-events: none; }
  .moshi-form textarea.form-control:disabled,
  form textarea.form-control:disabled {
    opacity: 1;
    resize: none;
    pointer-events: none; }

.moshi-form .react-date-picker--disabled.form-control .react-date-picker__wrapper,
form .react-date-picker--disabled.form-control .react-date-picker__wrapper {
  margin-top: -11px;
  width: unset; }
  .moshi-form .react-date-picker--disabled.form-control .react-date-picker__wrapper .react-date-picker__inputGroup,
  form .react-date-picker--disabled.form-control .react-date-picker__wrapper .react-date-picker__inputGroup {
    padding-left: 0; }

.moshi-form .react-date-picker--disabled.form-control .react-date-picker__calendar-button,
form .react-date-picker--disabled.form-control .react-date-picker__calendar-button {
  display: none; }

.moshi-form .form-inline-container,
form .form-inline-container {
  width: 100%; }
  .moshi-form .form-inline-container span,
  form .form-inline-container span {
    margin-right: 0; }
  .moshi-form .form-inline-container .label,
  form .form-inline-container .label {
    display: inline-block;
    top: 0;
    position: relative;
    text-transform: none;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #000;
    transition: none; }

.moshi-form .invalid-feedback,
.moshi-form .invalid-tooltip,
form .invalid-feedback,
form .invalid-tooltip {
  text-align: left; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.simple-gray-blue-card {
  border-radius: 14px;
  background-color: #ebf1f5;
  padding: 20px; }

.card {
  border-radius: 14px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 20px;
  padding: 20px;
  border-width: 0; }
  .card .card-title {
    margin-bottom: 0; }
  .card .card-body {
    padding: 0; }
  .card.selected-card {
    border: 2px solid #0a83bf; }
  .card.highlight {
    animation: highlight-fade-orange-to-white 5s ease-in-out 0s; }
  .card .card-vertical-layout {
    flex-direction: row;
    display: flex; }
  .card form {
    height: 100%; }
  .card .bottom-actions {
    margin-right: 0; }
    .card .bottom-actions .edit-button-toggle {
      display: inline-block; }
    .card .bottom-actions .right-align {
      float: right;
      margin-top: 3px; }
      .card .bottom-actions .right-align .btn {
        text-align: right; }
      @media (max-width: 1200px) {
        .card .bottom-actions .right-align {
          float: left; }
          .card .bottom-actions .right-align .btn {
            text-align: left; } }
  .card.no-border {
    border: 0;
    margin: 0;
    padding: 0; }
    .card.no-border .card-body {
      margin: 0;
      padding: 0; }
  .card.no-body-border {
    border: 0; }
  .card.card-no-style {
    border: 0;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    margin-left: 0; }
    .card.card-no-style .row > div {
      padding-left: 5px; }
  .card.no-box-shadow {
    box-shadow: none; }

.card-two-column-layout.card {
  flex-direction: row; }

.card-two-column-layout .card-body {
  width: 50%; }

.card-small-padding {
  padding: 14px; }

.card-more-actions {
  position: absolute;
  right: 15px;
  top: 25px; }
  .card-more-actions .more-toggle i {
    font-size: 18px;
    margin-top: 0;
    margin-left: 3px;
    color: #c4c4cc; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.content-sidebar + .custom-container {
  margin-left: 230px;
  min-width: 700px;
  max-width: 1100px; }

.custom-container {
  padding-bottom: 30px;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  min-width: 900px;
  max-width: 1400px;
  flex-grow: 1; }
  .custom-container.full-width-layout {
    max-height: 100%;
    max-width: 100%;
    padding-bottom: 0; }
  .custom-container header .container-header-title {
    min-height: 42px;
    line-height: 30px; }
  .custom-container header .header-actions {
    display: flex;
    align-items: center; }
    .custom-container header .header-actions label {
      color: #8e8e93;
      width: initial;
      margin: 0; }
    .custom-container header .header-actions > * {
      float: right;
      margin-left: 10px !important; }
    .custom-container header .header-actions .header-filters > * {
      display: inline-block; }
      .custom-container header .header-actions .header-filters > *:not(:first-child) {
        margin-left: 10px; }
      .custom-container header .header-actions .header-filters > * .dropdown-toggle {
        min-width: 150px; }
    .custom-container header .header-actions .search-field-inverted {
      min-width: 350px; }
  .custom-container header.sibling-actions {
    display: inline-block; }
    .custom-container header.sibling-actions + button {
      float: right; }
  .custom-container header .image-component {
    margin-right: 20px; }
  .custom-container header .btn {
    align-self: center; }
  .custom-container header .spinner {
    width: auto;
    display: inline-block; }
    .custom-container header .spinner svg {
      height: 46px; }
  .custom-container .container-section-title {
    margin-top: 25px;
    margin-bottom: 10px; }
  .custom-container .container-description {
    display: inline-flex;
    margin-bottom: 40px;
    font-size: 14px; }
  .custom-container .navigation {
    display: flex;
    justify-content: space-between; }
    .custom-container .navigation a {
      display: inline-block;
      margin-right: 30px;
      padding-bottom: 20px;
      color: #c4c4cc; }
      .custom-container .navigation a.active {
        color: #000; }
      .custom-container .navigation a:hover {
        text-decoration: none; }
    .custom-container .navigation .btn-primary {
      margin-left: 10px;
      margin-right: 10px; }
    .custom-container .navigation .btn-outline-primary {
      margin-left: 10px;
      margin-right: 10px;
      height: 40px; }
  .custom-container .edit-button-toggle {
    display: flex;
    flex-direction: row; }
    .custom-container .edit-button-toggle .btn {
      margin-left: 0; }
      .custom-container .edit-button-toggle .btn:first-child {
        margin-right: 20px; }
  .custom-container .btn-link {
    padding-left: 5px;
    padding-right: 10px;
    border: 0; }
    .custom-container .btn-link:last-child {
      padding-right: 2px; }
  .custom-container .btn-image {
    margin: 30px 10px; }
  .custom-container .row.buttons {
    margin-top: 20px; }
  .custom-container .bigger-font {
    font-size: 18px; }
  .custom-container hr {
    margin-top: 40px;
    margin-bottom: 30px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.geosuggest {
  position: relative;
  text-align: left;
  padding: 0; }
  .geosuggest.edit-mode-toggler-disabled {
    padding: 0; }

.geosuggest__input-wrapper {
  height: 14px; }

.geosuggest__input {
  border: 0;
  background-color: transparent;
  width: 100%;
  height: 15px;
  display: block;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
  outline: none; }

.geosuggest__suggests {
  border-radius: 14px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: 10px;
  margin-left: -2px;
  margin-right: -2px;
  background-color: #fff;
  border: 2px solid #0a83bf;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 9999;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s; }

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0; }

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer; }
  .geosuggest__item:hover, .geosuggest__item:focus {
    background-color: #e6eded; }

.geosuggest__item--active {
  background-color: #e6eded; }

.geosuggest__item__matched-text {
  font-weight: 600; }

.upload-file-form {
  margin-left: 20px;
  display: flex;
  flex-direction: column; }
  .upload-file-form .btn {
    margin-bottom: 10px; }

.avatar img {
  border-radius: 100%; }

.avatar .upload-file-form .btn.btn-link {
  height: 25px;
  justify-content: flex-start;
  margin-bottom: 0;
  margin-top: 0; }

.avatar .upload-file-form .avatar-upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* sass-lint:disable class-name-format */
.dropdown---dropdown---1yvIZ .dropdown-menu .dropdown-filter ~ .dropdown---dropdown-menu---1fkH0 .dropdown---menu-item---1LjoL, .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown-filter ~ .dropdown---dropdown-menu---1fkH0 .dropdown---menu-item-wrapper---3uAM0:first-child .dropdown---menu-item---1LjoL, .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item-wrapper---3uAM0 .dropdown---menu-item---1LjoL, .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item-wrapper---3uAM0:first-child .dropdown---menu-item-wrapper---3uAM0 .dropdown---menu-item---1LjoL, .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item-wrapper---3uAM0:last-child .dropdown---menu-item-wrapper---3uAM0 .dropdown---menu-item---1LjoL {
  border-radius: 0; }

.dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item-wrapper---3uAM0:first-child .dropdown---menu-item---1LjoL, .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item-wrapper---3uAM0:first-child .dropdown---menu-item-wrapper---3uAM0:first-child .dropdown---menu-item---1LjoL, .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item-wrapper---3uAM0:last-child .dropdown---menu-item-wrapper---3uAM0:first-child .dropdown---menu-item---1LjoL {
  border-radius: 14px 14px 0 0; }

.dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item-wrapper---3uAM0:first-child .dropdown---menu-item-wrapper---3uAM0:only-child .dropdown---menu-item---1LjoL, .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item-wrapper---3uAM0:only-child .dropdown---menu-item---1LjoL, .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item-wrapper---3uAM0:last-child .dropdown---menu-item-wrapper---3uAM0:only-child .dropdown---menu-item---1LjoL {
  border-radius: 14px; }

.dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item-wrapper---3uAM0:first-child .dropdown---menu-item-wrapper---3uAM0:last-child .dropdown---menu-item---1LjoL, .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item-wrapper---3uAM0:last-child .dropdown---menu-item---1LjoL, .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item-wrapper---3uAM0:last-child .dropdown---menu-item-wrapper---3uAM0:last-child .dropdown---menu-item---1LjoL {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  border: 0; }

.dropdown---dropdown---1yvIZ .flip-lr {
  text-align: left;
  transform: scale(-1, 1);
  min-width: 100%; }
  .dropdown---dropdown---1yvIZ .flip-lr .dropdown-toggle-label {
    margin-right: 0 !important; }
  .dropdown---dropdown---1yvIZ .flip-lr .flipped-submenu-container {
    text-align: left; }
  .dropdown---dropdown---1yvIZ .flip-lr .flipped-submenu-content,
  .dropdown---dropdown---1yvIZ .flip-lr .dropdown-item-null,
  .dropdown---dropdown---1yvIZ .flip-lr .dropdown-filter {
    transform: scale(-1, 1); }

.dropdown---dropdown---1yvIZ.empty .form-look .dropdown-toggle-label {
  color: #b4c2c9; }

.dropdown---dropdown---1yvIZ .dropdown-toggle {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  text-align: left;
  margin-left: 0;
  margin-right: 0;
  text-transform: initial;
  width: 100%; }
  .dropdown---dropdown---1yvIZ .dropdown-toggle .dropdown-toggle-label {
    display: inline-block;
    letter-spacing: inherit;
    font-weight: 400; }
  .dropdown---dropdown---1yvIZ .dropdown-toggle .dropdown-caret {
    float: right; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle .dropdown-caret i {
      display: inline-block;
      position: absolute;
      top: calc(50% - 2px);
      -ms-transform: translateY(-50%) rotate(45deg);
      transform: translateY(-50%) rotate(45deg);
      padding: 3px;
      border: solid #8e8e93;
      border-width: 0 2px 2px 0; }
      .dropdown---dropdown---1yvIZ .dropdown-toggle .dropdown-caret i.open {
        top: calc(50% + 2px);
        transform: translateY(-50%) rotate(-135deg);
        -webkit-transform: translateY(-50%) rotate(-135deg); }
  .dropdown---dropdown---1yvIZ .dropdown-toggle::after {
    display: none; }
  .dropdown---dropdown---1yvIZ .dropdown-toggle.no-style-look {
    padding: unset;
    border: unset;
    text-align: unset;
    margin: unset;
    color: unset;
    background-color: unset; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.no-style-look ~ .dropdown-menu .dropdown-menu-content {
      top: 100%; }
  .dropdown---dropdown---1yvIZ .dropdown-toggle.form-look {
    border-radius: 14px;
    background-image: linear-gradient(to bottom, #fff, #e6eded);
    font-weight: 400;
    height: 42px;
    border: 1px solid #dce3e3; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.form-look:disabled {
      opacity: 1;
      border-radius: 14px !important;
      background-color: #ebf1f5;
      background-image: none;
      border-color: #f7f9fa; }
      .dropdown---dropdown---1yvIZ .dropdown-toggle.form-look:disabled .dropdown-toggle-label {
        color: #406080; }
      .dropdown---dropdown---1yvIZ .dropdown-toggle.form-look:disabled .dropdown-caret i {
        border-color: #406080; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.form-look.open, .dropdown---dropdown---1yvIZ .dropdown-toggle.form-look:active, .dropdown---dropdown---1yvIZ .dropdown-toggle.form-look:focus {
      color: #000;
      box-shadow: 0 2px 10px 0 #c4c4cc !important;
      border: 1px transparent; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.form-look:hover {
      background-image: linear-gradient(to bottom, #f5f7f8, #dce3e3);
      color: #000; }
  .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look {
    border-radius: 10px;
    font-weight: 600;
    color: #fff !important;
    border: 0 !important;
    background-color: #0a83bf !important;
    box-shadow: none;
    outline: none !important; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look svg g,
    .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look svg circle,
    .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look svg path {
      fill: #fff; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look.focus, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:focus {
      background-color: #0a83bf !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.2) !important;
      color: #fff !important; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look.hover, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:hover {
      background-color: #0a83bf !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.2) !important;
      color: #fff !important; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look.active, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:active {
      background-color: #0a83bf !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.2) !important;
      color: #fff !important; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled {
      opacity: 0.3; }
      .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled:hover {
        box-shadow: none !important;
        color: #fff; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look .dropdown-caret i {
      border-color: #fff; }
  .dropdown---dropdown---1yvIZ .dropdown-toggle.filter-look {
    height: 42px;
    border-radius: 10px;
    border: 0;
    background-color: #f7f9fa;
    padding: 6px 25px 6px 12px; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.filter-look.no-caret {
      padding-right: 12px; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.filter-look .dropdown-value {
      color: #171f26; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.filter-look .dropdown-toggle-label:not(.has-prefix) {
      margin-top: 2px; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.filter-look .has-prefix.dropdown-toggle-label {
      margin-top: 0; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.filter-look .has-prefix .dropdown-prefix {
      display: block;
      letter-spacing: normal;
      margin-top: -4px;
      font-size: 12px;
      font-weight: 500;
      color: #8e8e93; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.filter-look .has-prefix .dropdown-value {
      display: block;
      margin-top: -6px;
      font-size: 14px; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.filter-look.open, .dropdown---dropdown---1yvIZ .dropdown-toggle.filter-look:hover, .dropdown---dropdown---1yvIZ .dropdown-toggle.filter-look:active {
      background-color: #fff;
      box-shadow: 0 0 4px 0 #f1f1f9, 0 8px 30px 0 #c4c4cc; }
  .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look {
    color: #13304d;
    font-size: 12px;
    font-weight: 500;
    height: 11px;
    min-height: 11px;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 9px;
    height: auto;
    border: 0;
    padding-left: 0;
    background-color: unset; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look.open {
      color: #000; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look.link {
      color: #0a83bf !important; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look ~ .dropdown-menu .dropdown-menu-content {
      top: calc(100% + 5px); }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-toggle-label {
      margin-right: 0 !important; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-value,
    .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-prefix {
      font-size: inherit;
      color: inherit;
      height: inherit;
      letter-spacing: inherit;
      font-weight: 600; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-caret {
      position: relative;
      top: 4px;
      margin-left: 0; }
      .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-caret i {
        position: relative;
        display: block;
        height: 8px;
        margin: 1px 3px;
        border: 0;
        transform: none !important;
        -webkit-transform: none !important; }
        .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-caret i::before, .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-caret i::after {
          position: absolute;
          display: block;
          content: "";
          border: 4px solid transparent; }
        .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-caret i::before {
          top: 0;
          border-top-color: #8e8e93; }
        .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-caret i::after {
          top: -1px;
          border-top-color: transparent; }
        .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-caret i.open {
          position: relative;
          display: block;
          height: 8px;
          margin: 1px 3px;
          top: -5px; }
          .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-caret i.open::before, .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-caret i.open::after {
            position: absolute;
            display: block;
            content: "";
            border: 4px solid transparent; }
          .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-caret i.open::before {
            bottom: 0;
            border-bottom-color: #000; }
          .dropdown---dropdown---1yvIZ .dropdown-toggle.tiny-look .dropdown-caret i.open::after {
            bottom: -1px;
            border-bottom-color: transparent; }
  .dropdown---dropdown---1yvIZ .dropdown-toggle.center-text-look {
    background-color: #fff;
    text-align: center;
    padding: 7px !important;
    border: 1px solid #f1f1f9;
    box-shadow: 0 2px 2px 0 rgba(18, 47, 76, 0.28);
    border-radius: 14px;
    text-transform: uppercase;
    font-size: 12px; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle.center-text-look .dropdown-caret {
      margin-right: 20px; }
  .dropdown---dropdown---1yvIZ .dropdown-toggle:not(.no-caret) {
    padding-right: 25px; }
    .dropdown---dropdown---1yvIZ .dropdown-toggle:not(.no-caret) .dropdown-toggle-label {
      margin-right: 14px; }

.dropdown---dropdown---1yvIZ.font-size-md .dropdown-toggle-label .dropdown-value {
  font-size: 12px; }

.dropdown---dropdown---1yvIZ .dropdown-menu {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 100%; }
  .dropdown---dropdown---1yvIZ .dropdown-menu div[role="menu"] {
    border-radius: 14px;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.36) !important;
    border: 1px solid #f1f1f9 !important;
    background-color: #f7f9fa;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 30px; }
    .dropdown---dropdown---1yvIZ .dropdown-menu div[role="menu"].dropdown-menu-content {
      min-width: 100%;
      position: absolute;
      top: calc(100% + 9px); }
      .dropdown---dropdown---1yvIZ .dropdown-menu div[role="menu"].dropdown-menu-content .menu-item-wrapper {
        max-height: 340px;
        overflow-y: scroll; }
      .dropdown---dropdown---1yvIZ .dropdown-menu div[role="menu"].dropdown-menu-content .danger {
        color: #cf3a1b; }
        .dropdown---dropdown---1yvIZ .dropdown-menu div[role="menu"].dropdown-menu-content .danger path {
          fill: #cf3a1b; }
  .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown-filter {
    padding: 7px;
    height: 55px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    border: 1px solid #f1f1f9 !important;
    background-color: #f7f9fa;
    position: absolute;
    top: 9px;
    z-index: 1001; }
    .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown-filter ~ .dropdown-menu-content {
      min-width: 200px !important;
      position: absolute;
      top: calc(100% + 64px) !important;
      max-height: 340px;
      overflow-y: hidden;
      border-radius: unset; }
    .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown-filter.no-results {
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px; }
      .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown-filter.no-results ~ .dropdown-menu-content {
        display: none; }
      .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown-filter.no-results ~ .shadow-caster {
        border-bottom-right-radius: 14px !important;
        border-bottom-left-radius: 14px !important; }
    .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown-filter.show-scrollbar ~ .dropdown-menu-content {
      overflow-y: scroll; }
    .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown-filter > input[name="filter"] {
      box-shadow: 0 0 4px 0 #f1f1f9 !important;
      border: 0;
      padding-left: 36px;
      padding-right: 30px; }
    .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown-filter .filter-search-icon {
      position: absolute;
      top: 19px;
      left: 17px;
      z-index: 1; }
    .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown-filter .clear-button-wrapper {
      height: 42px; }
    .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown-filter .clear-button-icon {
      float: right;
      position: relative;
      margin-top: -2px;
      margin-left: 5px;
      width: 16px;
      height: 16px;
      top: auto; }
    .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown-filter ~ .dropdown---dropdown-menu---1fkH0 {
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px; }
  .dropdown---dropdown---1yvIZ .dropdown-menu .shadow-caster {
    width: 100%;
    height: 55px;
    position: absolute;
    top: 9px;
    box-shadow: 0 8px 30px 0 #c4c4cc !important;
    border-top-right-radius: 14px !important;
    border-top-left-radius: 14px !important; }
  .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item---1LjoL {
    font-size: 14px;
    font-weight: 400;
    height: 42px;
    text-decoration: none;
    margin-left: 0;
    border-bottom: 2px solid #e5e5e1;
    padding: 10px 15px;
    background-color: #f7f9fa; }
    .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item---1LjoL .selected {
      position: relative;
      padding-right: 30px;
      font-weight: 600; }
      .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item---1LjoL .selected span {
        color: #0a83bf !important; }
      .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item---1LjoL .selected .checkmark {
        font-weight: 800;
        position: absolute;
        right: -5px;
        top: 1px;
        color: #0a83bf; }
        .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item---1LjoL .selected .checkmark path {
          fill: #0a83bf; }
    .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item---1LjoL > li > i:first-child {
      position: relative;
      top: 2px;
      margin-right: 7px; }
    .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item---1LjoL:hover, .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item---1LjoL:active {
      background-color: #ebf1f5; }
    .dropdown---dropdown---1yvIZ .dropdown-menu .dropdown---menu-item---1LjoL svg {
      width: 30px;
      height: 30px;
      margin-top: -8px;
      margin-left: -8px;
      position: relative;
      top: 2px; }

.dropdown---dropdown---1yvIZ.is-invalid .dropdown-toggle {
  border-color: #cf3a1b !important;
  background-image: linear-gradient(to bottom, #fff 20%, #fdf0ed 100%); }
  .dropdown---dropdown---1yvIZ.is-invalid .dropdown-toggle .dropdown-toggle-label {
    color: #cf3a1b; }
  .dropdown---dropdown---1yvIZ.is-invalid .dropdown-toggle .dropdown-caret i {
    border-color: #cf3a1b; }

/* sass-lint:disable class-name-format */
.moshi-multiple-select-container.is-invalid .moshi-multiple-select__control {
  border-color: #cf3a1b;
  background-image: linear-gradient(to bottom, #fff 20%, #fdf0ed 100%); }

.moshi-multiple-select-container.is-invalid .moshi-multiple-select__indicator-separator {
  background-color: #cf3a1b; }

.moshi-multiple-select-container.is-invalid .moshi-multiple-select__indicator {
  color: #cf3a1b; }

.moshi-multiple-select-container .moshi-multiple-select__indicator {
  color: #8e8e93; }

.moshi-multiple-select-container .moshi-multiple-select__indicator-separator {
  background-color: #8e8e93; }

.moshi-multiple-select-container .moshi-multiple-select__control {
  border-radius: 10px;
  border: 1px solid #dce3e3;
  box-shadow: none; }
  .moshi-multiple-select-container .moshi-multiple-select__control:hover {
    border-color: #e6eded;
    background-color: #e6eded; }

.moshi-multiple-select-container:not(.is-invalid) .moshi-multiple-select__control--is-focused {
  border-color: #0a83bf !important; }

.moshi-multiple-select-container .moshi-multiple-select__control--is-disabled {
  background-color: #fff;
  border-color: #fff; }
  .moshi-multiple-select-container .moshi-multiple-select__control--is-disabled .moshi-multiple-select__indicators {
    display: none; }
  .moshi-multiple-select-container .moshi-multiple-select__control--is-disabled .moshi-multiple-select__value-container {
    padding-left: 0; }
  .moshi-multiple-select-container .moshi-multiple-select__control--is-disabled .moshi-multiple-select__multi-value__remove {
    padding-left: 0;
    padding-right: 0;
    color: #0a83bf !important; }

.moshi-multiple-select-container .moshi-multiple-select__option {
  border-bottom: 2px solid #e5e5e1; }

.moshi-multiple-select-container .moshi-multiple-select__multi-value {
  background-color: #0a83bf;
  border-radius: 4px; }
  .moshi-multiple-select-container .moshi-multiple-select__multi-value .moshi-multiple-select__multi-value__remove {
    color: #fff; }
    .moshi-multiple-select-container .moshi-multiple-select__multi-value .moshi-multiple-select__multi-value__remove:hover {
      border-radius: 4px;
      background-color: #0a83bf;
      color: #cf3a1b; }
  .moshi-multiple-select-container .moshi-multiple-select__multi-value .moshi-multiple-select__multi-value__label {
    color: #fff; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-basic-modal .modal-content {
  padding: 0;
  border-radius: 14px; }
  .moshi-basic-modal .modal-content .modal-header {
    padding: 12px 11px 7px 41px; }
    .moshi-basic-modal .modal-content .modal-header .modal-title {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      width: 100%;
      height: auto; }
    .moshi-basic-modal .modal-content .modal-header .close-icon {
      float: right;
      font-size: 26px;
      font-weight: 600;
      color: #171f26; }
  .moshi-basic-modal .modal-content .modal-body {
    padding: 0; }
    .moshi-basic-modal .modal-content .modal-body .action-buttons {
      margin-top: 40px;
      text-align: center; }
      .moshi-basic-modal .modal-content .modal-body .action-buttons > button:not(:last-child) {
        margin-right: 10px; }

.moshi-basic-modal.padded-body .modal-body {
  padding: 20px; }

.moshi-basic-modal.common-form-modal form {
  margin: 30px; }

.loading-modal {
  padding-top: calc(50vh - 100px);
  cursor: wait; }
  .loading-modal .modal-content {
    padding: 50px;
    font-size: 20px;
    font-weight: 400; }
    .loading-modal .modal-content .modal-body {
      text-align: center; }
      .loading-modal .modal-content .modal-body .spinner {
        display: inline-block;
        width: unset;
        margin-right: 10px; }

.moshi-modal.modal.show {
  display: block;
  overflow-y: auto; }

.moshi-modal.modal .modal-dialog {
  max-width: 1200px;
  min-width: 900px;
  width: 80%; }
  .moshi-modal.modal .modal-dialog .modal-content {
    padding: 70px 60px 30px;
    border-radius: 1rem; }
    .moshi-modal.modal .modal-dialog .modal-content .modal-close-button {
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 4px; }
      .moshi-modal.modal .modal-dialog .modal-content .modal-close-button i {
        font-weight: bold; }
    .moshi-modal.modal .modal-dialog .modal-content .modal-header,
    .moshi-modal.modal .modal-dialog .modal-content .modal-footer {
      border: 0; }
    .moshi-modal.modal .modal-dialog .modal-content h3 {
      margin-top: 37px;
      margin-bottom: 30px; }
    .moshi-modal.modal .modal-dialog .modal-content .modal-header {
      display: block;
      padding-left: 0;
      padding-right: 0; }
      .moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title {
        height: auto; }
      .moshi-modal.modal .modal-dialog .modal-content .modal-header ol {
        margin: 70px 0 0;
        padding: 0;
        list-style-type: none;
        counter-reset: customlistcounter; }
        .moshi-modal.modal .modal-dialog .modal-content .modal-header ol li {
          display: inline-block;
          font-size: 24px;
          margin-right: 35px;
          counter-increment: customlistcounter;
          cursor: pointer; }
          .moshi-modal.modal .modal-dialog .modal-content .modal-header ol li::before {
            content: counter(customlistcounter) " "; }
          .moshi-modal.modal .modal-dialog .modal-content .modal-header ol li.active {
            color: #000; }
    .moshi-modal.modal .modal-dialog .modal-content .modal-body {
      padding-left: 0;
      padding-right: 0; }
    .moshi-modal.modal .modal-dialog .modal-content .modal-footer {
      padding: 40px 0 10px;
      display: block;
      margin: 30px -30px 0; }
      .moshi-modal.modal .modal-dialog .modal-content .modal-footer.modal-footer-actions-align-right {
        justify-content: flex-end;
        display: flex; }
      .moshi-modal.modal .modal-dialog .modal-content .modal-footer .btn {
        max-height: 40px; }
        .moshi-modal.modal .modal-dialog .modal-content .modal-footer .btn:last-child {
          margin-right: 0; }

.moshi-modal.modal .medium-width-modal .modal-content {
  margin: auto;
  max-width: 820px; }

.moshi-modal.modal .small-width-modal .modal-content {
  margin: auto;
  max-width: 580px; }

.moshi-modal.modal .small-width-modal .modal-content {
  padding-top: 30px; }

.moshi-modal.modal .small-padding-modal .modal-content {
  padding: 20px; }
  .moshi-modal.modal .small-padding-modal .modal-content .modal-footer {
    margin: 0;
    padding-bottom: 0; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

label.btn {
  display: flex;
  align-items: center;
  justify-content: center; }

.icon {
  min-height: 30px;
  min-width: 30px; }
  .icon[disabled] path {
    opacity: 0.3; }
  .icon:hover:not([disabled]) svg g,
  .icon:hover:not([disabled]) svg circle,
  .icon:hover:not([disabled]) svg path {
    fill: #0a83bf; }

.btn-group:not(.moshi-dropdown-button) .btn {
  margin: 0; }
  .btn-group:not(.moshi-dropdown-button) .btn.hover, .btn-group:not(.moshi-dropdown-button) .btn:hover {
    z-index: initial; }
  .btn-group:not(.moshi-dropdown-button) .btn:last-of-type {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important; }

.btn-group:not(.moshi-dropdown-button) > *:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important; }
  .btn-group:not(.moshi-dropdown-button) > *:first-child > .btn {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important; }

.btn-group:not(.moshi-dropdown-button) > *:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important; }
  .btn-group:not(.moshi-dropdown-button) > *:last-child > .btn {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; }
  .btn-group:not(.moshi-dropdown-button) > *:last-child:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.moshi-link {
  background-color: transparent;
  font-weight: 600;
  border: 0 !important; }
  .moshi-link.btn-link, .moshi-link.btn-link-primary {
    font-size: 14px;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    font-weight: 600;
    color: #0a83bf;
    border: 0; }
    .moshi-link.btn-link.hover:not(.no-hover), .moshi-link.btn-link:hover:not(.no-hover), .moshi-link.btn-link-primary.hover:not(.no-hover), .moshi-link.btn-link-primary:hover:not(.no-hover) {
      color: #0ca3ed;
      text-decoration: none; }
      .moshi-link.btn-link.hover:not(.no-hover) svg g,
      .moshi-link.btn-link.hover:not(.no-hover) svg circle,
      .moshi-link.btn-link.hover:not(.no-hover) svg path, .moshi-link.btn-link:hover:not(.no-hover) svg g,
      .moshi-link.btn-link:hover:not(.no-hover) svg circle,
      .moshi-link.btn-link:hover:not(.no-hover) svg path, .moshi-link.btn-link-primary.hover:not(.no-hover) svg g,
      .moshi-link.btn-link-primary.hover:not(.no-hover) svg circle,
      .moshi-link.btn-link-primary.hover:not(.no-hover) svg path, .moshi-link.btn-link-primary:hover:not(.no-hover) svg g,
      .moshi-link.btn-link-primary:hover:not(.no-hover) svg circle,
      .moshi-link.btn-link-primary:hover:not(.no-hover) svg path {
        fill: #0ca3ed; }
    .moshi-link.btn-link.focus:not(.no-hover), .moshi-link.btn-link:focus:not(.no-hover), .moshi-link.btn-link-primary.focus:not(.no-hover), .moshi-link.btn-link-primary:focus:not(.no-hover) {
      color: #0ca3ed;
      text-decoration: none;
      box-shadow: none; }
      .moshi-link.btn-link.focus:not(.no-hover) svg g,
      .moshi-link.btn-link.focus:not(.no-hover) svg circle,
      .moshi-link.btn-link.focus:not(.no-hover) svg path, .moshi-link.btn-link:focus:not(.no-hover) svg g,
      .moshi-link.btn-link:focus:not(.no-hover) svg circle,
      .moshi-link.btn-link:focus:not(.no-hover) svg path, .moshi-link.btn-link-primary.focus:not(.no-hover) svg g,
      .moshi-link.btn-link-primary.focus:not(.no-hover) svg circle,
      .moshi-link.btn-link-primary.focus:not(.no-hover) svg path, .moshi-link.btn-link-primary:focus:not(.no-hover) svg g,
      .moshi-link.btn-link-primary:focus:not(.no-hover) svg circle,
      .moshi-link.btn-link-primary:focus:not(.no-hover) svg path {
        fill: #0ca3ed; }
  .moshi-link.btn-link-danger {
    font-size: 14px;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    font-weight: 600;
    color: #cf3a1b;
    border: 0; }
    .moshi-link.btn-link-danger.hover:not(.no-hover), .moshi-link.btn-link-danger:hover:not(.no-hover) {
      color: #e55639;
      text-decoration: none; }
      .moshi-link.btn-link-danger.hover:not(.no-hover) svg g,
      .moshi-link.btn-link-danger.hover:not(.no-hover) svg circle,
      .moshi-link.btn-link-danger.hover:not(.no-hover) svg path, .moshi-link.btn-link-danger:hover:not(.no-hover) svg g,
      .moshi-link.btn-link-danger:hover:not(.no-hover) svg circle,
      .moshi-link.btn-link-danger:hover:not(.no-hover) svg path {
        fill: #e55639; }
    .moshi-link.btn-link-danger.focus:not(.no-hover), .moshi-link.btn-link-danger:focus:not(.no-hover) {
      color: #e55639;
      text-decoration: none;
      box-shadow: none; }
      .moshi-link.btn-link-danger.focus:not(.no-hover) svg g,
      .moshi-link.btn-link-danger.focus:not(.no-hover) svg circle,
      .moshi-link.btn-link-danger.focus:not(.no-hover) svg path, .moshi-link.btn-link-danger:focus:not(.no-hover) svg g,
      .moshi-link.btn-link-danger:focus:not(.no-hover) svg circle,
      .moshi-link.btn-link-danger:focus:not(.no-hover) svg path {
        fill: #e55639; }
  .moshi-link.btn-link-black {
    font-size: 14px;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    font-weight: 600;
    color: #000;
    border: 0; }
    .moshi-link.btn-link-black.hover:not(.no-hover), .moshi-link.btn-link-black:hover:not(.no-hover) {
      color: #000;
      text-decoration: none; }
      .moshi-link.btn-link-black.hover:not(.no-hover) svg g,
      .moshi-link.btn-link-black.hover:not(.no-hover) svg circle,
      .moshi-link.btn-link-black.hover:not(.no-hover) svg path, .moshi-link.btn-link-black:hover:not(.no-hover) svg g,
      .moshi-link.btn-link-black:hover:not(.no-hover) svg circle,
      .moshi-link.btn-link-black:hover:not(.no-hover) svg path {
        fill: #000; }
    .moshi-link.btn-link-black.focus:not(.no-hover), .moshi-link.btn-link-black:focus:not(.no-hover) {
      color: #000;
      text-decoration: none;
      box-shadow: none; }
      .moshi-link.btn-link-black.focus:not(.no-hover) svg g,
      .moshi-link.btn-link-black.focus:not(.no-hover) svg circle,
      .moshi-link.btn-link-black.focus:not(.no-hover) svg path, .moshi-link.btn-link-black:focus:not(.no-hover) svg g,
      .moshi-link.btn-link-black:focus:not(.no-hover) svg circle,
      .moshi-link.btn-link-black:focus:not(.no-hover) svg path {
        fill: #000; }
  .moshi-link.btn-link-white {
    font-size: 14px;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    font-weight: 600;
    color: #fff;
    border: 0; }
    .moshi-link.btn-link-white.hover:not(.no-hover), .moshi-link.btn-link-white:hover:not(.no-hover) {
      color: #fff;
      text-decoration: none; }
      .moshi-link.btn-link-white.hover:not(.no-hover) svg g,
      .moshi-link.btn-link-white.hover:not(.no-hover) svg circle,
      .moshi-link.btn-link-white.hover:not(.no-hover) svg path, .moshi-link.btn-link-white:hover:not(.no-hover) svg g,
      .moshi-link.btn-link-white:hover:not(.no-hover) svg circle,
      .moshi-link.btn-link-white:hover:not(.no-hover) svg path {
        fill: #fff; }
    .moshi-link.btn-link-white.focus:not(.no-hover), .moshi-link.btn-link-white:focus:not(.no-hover) {
      color: #fff;
      text-decoration: none;
      box-shadow: none; }
      .moshi-link.btn-link-white.focus:not(.no-hover) svg g,
      .moshi-link.btn-link-white.focus:not(.no-hover) svg circle,
      .moshi-link.btn-link-white.focus:not(.no-hover) svg path, .moshi-link.btn-link-white:focus:not(.no-hover) svg g,
      .moshi-link.btn-link-white:focus:not(.no-hover) svg circle,
      .moshi-link.btn-link-white:focus:not(.no-hover) svg path {
        fill: #fff; }
  .moshi-link.btn-link-lg {
    font-size: 12px; }
  .moshi-link.btn-link-xl {
    font-size: 14px; }
  .moshi-link.btn-link-xxl {
    font-size: 15px; }
  .moshi-link.btn-link-sm {
    font-size: 10px; }

.moshi-button {
  border-radius: 10px;
  font-weight: 600;
  color: #000 !important;
  border: 0 !important;
  text-transform: none;
  width: auto;
  min-height: 30px;
  min-width: 140px; }
  .moshi-button:focus {
    outline: none !important; }
  .moshi-button.icon {
    padding: 0; }
  .moshi-button .no-padding {
    padding-left: 0;
    padding-right: 0; }
  .moshi-button.btn-box-shadow {
    box-shadow: 0 2px 2px 0 rgba(18, 47, 76, 0.28) !important; }
  .moshi-button.btn-primary {
    border-radius: 10px;
    font-weight: 600;
    color: #fff !important;
    border: 0 !important;
    background-color: #0a83bf !important;
    box-shadow: none;
    outline: none !important; }
    .moshi-button.btn-primary svg g,
    .moshi-button.btn-primary svg circle,
    .moshi-button.btn-primary svg path {
      fill: #fff; }
    .moshi-button.btn-primary.focus, .moshi-button.btn-primary:focus {
      background-color: #0a83bf !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.2) !important;
      color: #fff !important; }
    .moshi-button.btn-primary.hover, .moshi-button.btn-primary:hover {
      background-color: #0a83bf !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.2) !important;
      color: #fff !important; }
    .moshi-button.btn-primary.active, .moshi-button.btn-primary:active {
      background-color: #0a83bf !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.2) !important;
      color: #fff !important; }
    .moshi-button.btn-primary:disabled {
      opacity: 0.3; }
      .moshi-button.btn-primary:disabled:hover {
        box-shadow: none !important;
        color: #fff; }
  .moshi-button.btn-secondary {
    border-radius: 10px;
    font-weight: 600;
    color: #0a83bf !important;
    border: 0 !important;
    background-color: #caebfc !important;
    box-shadow: none;
    outline: none !important; }
    .moshi-button.btn-secondary svg g,
    .moshi-button.btn-secondary svg circle,
    .moshi-button.btn-secondary svg path {
      fill: #0a83bf; }
    .moshi-button.btn-secondary.focus, .moshi-button.btn-secondary:focus {
      background-color: #caebfc !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.1) !important;
      color: #0a83bf !important; }
    .moshi-button.btn-secondary.hover, .moshi-button.btn-secondary:hover {
      background-color: #caebfc !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.1) !important;
      color: #0a83bf !important; }
    .moshi-button.btn-secondary.active, .moshi-button.btn-secondary:active {
      background-color: #caebfc !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.1) !important;
      color: #0a83bf !important; }
    .moshi-button.btn-secondary:disabled {
      opacity: 0.3; }
      .moshi-button.btn-secondary:disabled:hover {
        box-shadow: none !important;
        color: #0a83bf; }
  .moshi-button.btn-light {
    border-radius: 10px;
    font-weight: 600;
    color: #0a83bf !important;
    border: 0 !important;
    background-color: #fff !important;
    box-shadow: none;
    outline: none !important;
    border: solid 1px #f3f7fc !important; }
    .moshi-button.btn-light svg g,
    .moshi-button.btn-light svg circle,
    .moshi-button.btn-light svg path {
      fill: #0a83bf; }
    .moshi-button.btn-light.focus, .moshi-button.btn-light:focus {
      background-color: #fff !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.1) !important;
      color: #0a83bf !important; }
    .moshi-button.btn-light.hover, .moshi-button.btn-light:hover {
      background-color: #fff !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.1) !important;
      color: #0a83bf !important; }
    .moshi-button.btn-light.active, .moshi-button.btn-light:active {
      background-color: #fff !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.1) !important;
      color: #0a83bf !important; }
    .moshi-button.btn-light:disabled {
      opacity: 0.3; }
      .moshi-button.btn-light:disabled:hover {
        box-shadow: none !important;
        color: #0a83bf; }
  .moshi-button.btn-danger {
    border-radius: 10px;
    font-weight: 600;
    color: #cf3a1b !important;
    border: 0 !important;
    background-color: #f7c9c0 !important;
    box-shadow: none;
    outline: none !important; }
    .moshi-button.btn-danger svg g,
    .moshi-button.btn-danger svg circle,
    .moshi-button.btn-danger svg path {
      fill: #cf3a1b; }
    .moshi-button.btn-danger.focus, .moshi-button.btn-danger:focus {
      background-color: #f7c9c0 !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.1) !important;
      color: #cf3a1b !important; }
    .moshi-button.btn-danger.hover, .moshi-button.btn-danger:hover {
      background-color: #f7c9c0 !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.1) !important;
      color: #cf3a1b !important; }
    .moshi-button.btn-danger.active, .moshi-button.btn-danger:active {
      background-color: #f7c9c0 !important;
      box-shadow: 0 1px 5px 0 rgba(18, 47, 76, 0.1) !important;
      color: #cf3a1b !important; }
    .moshi-button.btn-danger:disabled {
      opacity: 0.3; }
      .moshi-button.btn-danger:disabled:hover {
        box-shadow: none !important;
        color: #cf3a1b; }
  .moshi-button.btn-gray {
    color: #212529;
    background-color: #f7f9fa;
    border-color: #f7f9fa; }
    .moshi-button.btn-gray.hover, .moshi-button.btn-gray:hover {
      color: #212529;
      background-color: #fff;
      border-color: #fff; }
    .moshi-button.btn-gray:focus, .moshi-button.btn-gray.focus {
      color: #212529;
      background-color: #fff;
      border-color: #fff;
      box-shadow: 0 0 0 0.2rem rgba(215, 217, 219, 0.5); }
    .moshi-button.btn-gray.disabled, .moshi-button.btn-gray:disabled {
      color: #212529;
      background-color: #f7f9fa;
      border-color: #f7f9fa; }
    .moshi-button.btn-gray:not(:disabled):not(.disabled):active, .moshi-button.btn-gray:not(:disabled):not(.disabled).active,
    .show > .moshi-button.btn-gray.dropdown-toggle {
      color: #212529;
      background-color: #f5f7f8;
      border-color: #f5f7f8; }
      .moshi-button.btn-gray:not(:disabled):not(.disabled):active:focus, .moshi-button.btn-gray:not(:disabled):not(.disabled).active:focus,
      .show > .moshi-button.btn-gray.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgba(215, 217, 219, 0.5); }
  .moshi-button.btn-transparent {
    border-radius: 10px;
    font-weight: 600;
    color: #000 !important;
    border: 0 !important;
    background-color: transparent !important;
    box-shadow: none;
    outline: none !important; }
    .moshi-button.btn-transparent svg g,
    .moshi-button.btn-transparent svg circle,
    .moshi-button.btn-transparent svg path {
      fill: #000; }
    .moshi-button.btn-transparent.focus, .moshi-button.btn-transparent:focus {
      background-color: transparent !important;
      box-shadow: none !important;
      color: #000 !important; }
    .moshi-button.btn-transparent.hover, .moshi-button.btn-transparent:hover {
      background-color: transparent !important;
      box-shadow: none !important;
      color: #000 !important; }
    .moshi-button.btn-transparent.active, .moshi-button.btn-transparent:active {
      background-color: transparent !important;
      box-shadow: none !important;
      color: #000 !important; }
    .moshi-button.btn-transparent:disabled {
      opacity: 0.3; }
      .moshi-button.btn-transparent:disabled:hover {
        box-shadow: none !important;
        color: #000; }
    .moshi-button.btn-transparent:hover {
      color: #0a83bf; }
  .moshi-button.btn-xs {
    min-height: 0;
    height: 20px;
    min-width: 20px;
    font-size: 10px;
    line-height: 1;
    padding: 0 15.5px;
    border-radius: 10px; }
  .moshi-button.btn-sm, .btn-group-sm > .moshi-button.btn {
    min-height: 0;
    height: 25px;
    min-width: 25px;
    font-size: 10px;
    line-height: 1;
    padding: 0 19.5px;
    border-radius: 13px; }
  .moshi-button.btn-md {
    min-height: 0;
    height: 32px;
    min-width: 32px;
    font-size: 12px;
    line-height: 1;
    padding: 0 23px;
    border-radius: 16px; }
  .moshi-button.btn-lg, .btn-group-lg > .moshi-button.btn {
    min-height: 0;
    height: 42px;
    min-width: 42px;
    font-size: 15px;
    line-height: 1;
    padding: 0 20.5px;
    border-radius: 10px; }
  .moshi-button.btn-capital-first {
    text-transform: lowercase; }
    .moshi-button.btn-capital-first::first-letter {
      text-transform: uppercase; }
  .moshi-button.btn-with-shadow:hover {
    box-shadow: 0 0 4px 0 #f1f1f9, 0 8px 30px 0 #c4c4cc !important; }
  .moshi-button.btn-regular-font {
    font-weight: 400; }

.moshi-dropdown-button .dropdown-toggle::after {
  float: right;
  width: 6px;
  height: 6px;
  margin-left: 15px;
  vertical-align: 0.1em;
  transform: rotate(135deg);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: inherit;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: inherit;
  border-left: 0;
  margin-top: 3px; }

.moshi-dropdown-button .dropdown-toggle.btn-light::after {
  border-top-color: #0a83bf;
  border-right-color: #0a83bf; }

.moshi-dropdown-button.split > .btn:not(:first-child) {
  margin-left: 0;
  border-left: 1px solid #fff !important; }
  .moshi-dropdown-button.split > .btn:not(:first-child)::after {
    margin-top: 0;
    float: unset;
    margin-left: 0; }

.moshi-dropdown-button.split > .btn:first-child {
  margin-right: 0; }

.moshi-dropdown-button .btn-dropdown-menu {
  border-radius: 14px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
  margin: 5px 0;
  padding: 0;
  overflow: hidden;
  border: 1px solid #f1f1f9;
  max-height: 300px;
  overflow-y: auto; }
  .moshi-dropdown-button .btn-dropdown-menu .btn-dropdown-item {
    height: 40px;
    margin-left: 0;
    padding: 5px 14px;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    border-bottom: 1px solid #f1f1f9;
    background-color: #f7f9fa; }
    .moshi-dropdown-button .btn-dropdown-menu .btn-dropdown-item.danger {
      color: #cf3a1b; }
      .moshi-dropdown-button .btn-dropdown-menu .btn-dropdown-item.danger path {
        fill: #cf3a1b; }
    .moshi-dropdown-button .btn-dropdown-menu .btn-dropdown-item:active {
      background-color: initial;
      color: initial; }
    .moshi-dropdown-button .btn-dropdown-menu .btn-dropdown-item:hover {
      background-color: #ebf1f5; }
    .moshi-dropdown-button .btn-dropdown-menu .btn-dropdown-item:last-child {
      border-bottom: 0; }
    .moshi-dropdown-button .btn-dropdown-menu .btn-dropdown-item i {
      margin-right: 7px; }

.moshi-more-actions-button .rs-more-actions-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  height: 38px;
  width: 38px;
  border: 1px solid #e4e4ee;
  transition: background-color 200ms ease-out; }
  .moshi-more-actions-button .rs-more-actions-toggle:hover {
    background-color: #caebfc; }

.moshi-more-actions-button .card-more-actions-icon {
  color: #0a83bf;
  margin: 0 !important; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.circular-badge {
  width: 40px;
  height: 40px;
  background-color: #f7f9fa;
  color: #c4c4cc;
  border: 2px solid #f1f1f9;
  font-size: 14px;
  line-height: 1.79;
  text-align: center;
  font-weight: 600;
  border-radius: 50%;
  display: inline-block; }
  .circular-badge .initials-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }

.badge {
  height: 16px;
  font-size: 8px;
  text-transform: uppercase;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  letter-spacing: 0.5px;
  line-height: 1.7;
  color: #fff;
  font-weight: 700; }
  .badge.badge-primary {
    background-color: #0a83bf; }
  .badge.badge-danger {
    background-color: #cf3a1b; }
  .badge.badge-warning {
    background-color: #fb6b32; }
  .badge.badge-success {
    background-color: #b5dece; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.alert {
  font-size: 14px;
  margin: 10px 5px 20px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  color: #fff;
  min-height: 40px;
  transition: height 0.1s, margin 0.1s, padding 0.1s;
  transition-timing-function: ease-in-out;
  border-radius: 10px;
  border: 0;
  height: auto; }
  .alert .close {
    padding: 0 1.25rem 0 0;
    margin-top: 5px; }
    .alert .close:hover {
      color: #fff; }
  .alert.alert-primary {
    background-color: #caebfc;
    color: #0a83bf; }
  .alert.alert-success {
    background-color: #d8eee6;
    color: #6fbea0; }
  .alert.alert-warning {
    background-color: #fed7c8;
    color: #fb6b32; }
  .alert.alert-danger {
    background-color: #f7c9c0;
    color: #cf3a1b; }

.toggle-switch-container {
  display: flex;
  justify-content: flex-start;
  padding-top: 35px; }
  .toggle-switch-container .toggle-switch-label {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 600; }

.toggle-switch {
  position: relative;
  display: inline-block;
  min-width: 35px;
  max-width: 35px; }
  .toggle-switch.form-control {
    border: 0;
    height: 20px !important; }
  .toggle-switch.disabled {
    opacity: 0.2;
    cursor: not-allowed; }
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0; }
    .toggle-switch input:checked + .slider {
      background-color: #6fbea0; }
      .toggle-switch input:checked + .slider::before {
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px); }
    .toggle-switch input:focus + .slider {
      box-shadow: 0 0 1px #6fbea0; }
  .toggle-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #8e8e93;
    -webkit-transition: 0.4s;
    transition: 0.4s; }
    .toggle-switch .slider::before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 2px;
      bottom: 2px;
      background-color: #fff;
      -webkit-transition: 0.4s;
      transition: 0.4s; }
    .toggle-switch .slider.round {
      border-radius: 34px; }
      .toggle-switch .slider.round::before {
        border-radius: 50%; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.navigation .tab-container {
  margin-bottom: 20px;
  display: flex; }
  .navigation .tab-container .navigation-tab {
    position: relative;
    overflow: hidden;
    margin-right: 5px;
    padding: 8px 15px 8px 14px;
    color: #13304d;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    white-space: nowrap;
    display: flex;
    line-height: 20px;
    border-radius: 11px;
    transition: background-color 200ms ease-out; }
    .navigation .tab-container .navigation-tab i {
      font-size: 20px;
      align-self: center;
      margin-top: -2px;
      margin-right: 5px; }
    .navigation .tab-container .navigation-tab.active {
      box-shadow: 0 4px 15px 0 rgba(23, 31, 38, 0.1);
      opacity: 1;
      color: #0a83bf;
      background-color: #fff; }
      .navigation .tab-container .navigation-tab.active .icon-tab-number {
        background-color: #0a83bf; }
    .navigation .tab-container .navigation-tab:hover {
      color: #0a83bf;
      background-color: #caebfc;
      text-decoration: none;
      opacity: 1; }
      .navigation .tab-container .navigation-tab:hover .icon-tab-number {
        background-color: #0a83bf; }
    .navigation .tab-container .navigation-tab:active {
      background-color: #99dafa;
      transition-duration: 100ms; }
    .navigation .tab-container .navigation-tab .icon-tab-number {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      text-align: center;
      font-size: 10px;
      color: #e6eded;
      background-color: #171f26;
      align-self: center;
      font-style: normal;
      padding-top: 2px;
      padding-right: 0.5px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-tooltip {
  font-weight: 400;
  transition: opacity 0s; }
  .moshi-tooltip .tooltip {
    pointer-events: none; }
  .moshi-tooltip.large .tooltip-inner {
    max-width: 50vw; }
  .moshi-tooltip.medium .tooltip-inner {
    max-width: 30vw; }
  .moshi-tooltip .tooltip-inner {
    border-radius: 14px;
    font-size: 14px;
    padding: 10px 13px; }
  .moshi-tooltip.show {
    opacity: 1; }
  .moshi-tooltip.tooltip-danger.bs-tooltip-top .arrow::before, .moshi-tooltip.tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow::before, .moshi-tooltip.tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #cf3a1b; }
  .moshi-tooltip.tooltip-danger.bs-tooltip-bottom .arrow::before, .moshi-tooltip.tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .moshi-tooltip.tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #cf3a1b; }
  .moshi-tooltip.tooltip-danger.bs-tooltip-right .arrow::before, .moshi-tooltip.tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow::before, .moshi-tooltip.tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #cf3a1b; }
  .moshi-tooltip.tooltip-danger.bs-tooltip-left .arrow::before, .moshi-tooltip.tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow::before, .moshi-tooltip.tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #cf3a1b; }
  .moshi-tooltip.tooltip-danger .tooltip-inner {
    background-color: #cf3a1b;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.36) !important; }
  .moshi-tooltip.tooltip-warning.bs-tooltip-top .arrow::before, .moshi-tooltip.tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow::before, .moshi-tooltip.tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #fee895; }
  .moshi-tooltip.tooltip-warning.bs-tooltip-bottom .arrow::before, .moshi-tooltip.tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .moshi-tooltip.tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #fee895; }
  .moshi-tooltip.tooltip-warning.bs-tooltip-right .arrow::before, .moshi-tooltip.tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow::before, .moshi-tooltip.tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #fee895; }
  .moshi-tooltip.tooltip-warning.bs-tooltip-left .arrow::before, .moshi-tooltip.tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow::before, .moshi-tooltip.tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #fee895; }
  .moshi-tooltip.tooltip-warning .tooltip-inner {
    background-color: #fee895;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.36) !important; }
  .moshi-tooltip.tooltip-success.bs-tooltip-top .arrow::before, .moshi-tooltip.tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow::before, .moshi-tooltip.tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #b5dece; }
  .moshi-tooltip.tooltip-success.bs-tooltip-bottom .arrow::before, .moshi-tooltip.tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .moshi-tooltip.tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #b5dece; }
  .moshi-tooltip.tooltip-success.bs-tooltip-right .arrow::before, .moshi-tooltip.tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow::before, .moshi-tooltip.tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #b5dece; }
  .moshi-tooltip.tooltip-success.bs-tooltip-left .arrow::before, .moshi-tooltip.tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow::before, .moshi-tooltip.tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #b5dece; }
  .moshi-tooltip.tooltip-success .tooltip-inner {
    background-color: #b5dece;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.36) !important; }
  .moshi-tooltip.tooltip-info.bs-tooltip-top .arrow::before, .moshi-tooltip.tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow::before, .moshi-tooltip.tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #171f26; }
  .moshi-tooltip.tooltip-info.bs-tooltip-bottom .arrow::before, .moshi-tooltip.tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .moshi-tooltip.tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #171f26; }
  .moshi-tooltip.tooltip-info.bs-tooltip-right .arrow::before, .moshi-tooltip.tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow::before, .moshi-tooltip.tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #171f26; }
  .moshi-tooltip.tooltip-info.bs-tooltip-left .arrow::before, .moshi-tooltip.tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow::before, .moshi-tooltip.tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #171f26; }
  .moshi-tooltip.tooltip-info .tooltip-inner {
    background-color: #171f26;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.36) !important; }
  .moshi-tooltip.tooltip-white.bs-tooltip-top .arrow::before, .moshi-tooltip.tooltip-white.bs-tooltip-auto[x-placement^="top"] .arrow::before, .moshi-tooltip.tooltip-white.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #fff; }
  .moshi-tooltip.tooltip-white.bs-tooltip-bottom .arrow::before, .moshi-tooltip.tooltip-white.bs-tooltip-auto[x-placement^="bottom"] .arrow::before, .moshi-tooltip.tooltip-white.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #fff; }
  .moshi-tooltip.tooltip-white.bs-tooltip-right .arrow::before, .moshi-tooltip.tooltip-white.bs-tooltip-auto[x-placement^="right"] .arrow::before, .moshi-tooltip.tooltip-white.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #fff; }
  .moshi-tooltip.tooltip-white.bs-tooltip-left .arrow::before, .moshi-tooltip.tooltip-white.bs-tooltip-auto[x-placement^="left"] .arrow::before, .moshi-tooltip.tooltip-white.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #fff; }
  .moshi-tooltip.tooltip-white .tooltip-inner {
    background-color: #fff;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.36) !important; }

.google-map > div > div {
  border-radius: 0.75rem; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.table-actions {
  margin-top: 30px;
  align-items: center;
  margin-left: 0;
  margin-right: 0; }
  .table-actions:has(.align-right) {
    align-items: flex-end; }
  .table-actions label {
    color: #8e8e93;
    width: initial;
    margin: 0;
    font-weight: 600;
    font-size: 11px; }
  .table-actions .btn {
    margin: 0 10px; }
  .table-actions > div:last-of-type {
    margin-left: auto; }
  .table-actions .align-right .dropdown-toggle {
    margin-left: 5px;
    color: #8e8e93 !important;
    text-transform: uppercase; }
    .table-actions .align-right .dropdown-toggle:hover, .table-actions .align-right .dropdown-toggle:focus, .table-actions .align-right .dropdown-toggle:active {
      color: #8e8e93 !important; }

.table-form-buttons {
  position: absolute;
  right: 15px;
  top: 15px; }

.table {
  margin-top: 45px; }
  .table > .row {
    margin-left: 0;
    margin-right: 0; }
  .table .row {
    border: 0;
    border-bottom: 1px solid #e6eded; }
    .table .row .table-row-icon {
      margin-top: -5px; }
    .table .row .card {
      min-height: 80px;
      justify-content: flex-start;
      border: 0;
      padding-top: 20px;
      padding-left: 15px;
      padding-bottom: 20px;
      margin-bottom: 10px; }
      .table .row .card.table-row.hover, .table .row .card.table-row:hover {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.36); }
      .table .row .card.small-table-card {
        min-height: 60px;
        padding-top: 10px;
        padding-bottom: 10px; }
        .table .row .card.small-table-card .card-more-actions {
          top: 15px; }
      .table .row .card .column-cell {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 0;
        padding-right: 0; }
        .table .row .card .column-cell .cell-with-icon {
          padding: 0;
          min-width: 35px;
          max-width: 35px; }
          .table .row .card .column-cell .cell-with-icon + .col-11 {
            flex: 1 0; }
        .table .row .card .column-cell:first-child {
          padding-left: 15px;
          padding-right: 15px; }
        .table .row .card .column-cell > a {
          display: table; }
        .table .row .card .column-cell .badge {
          position: absolute;
          margin-top: 0; }
        .table .row .card .column-cell .cell-wrapper {
          padding-top: 10px; }
          .table .row .card .column-cell .cell-wrapper .row {
            margin-left: 0;
            margin-right: 0;
            border: 0; }
          .table .row .card .column-cell .cell-wrapper:last-child {
            padding-right: 15px; }
    .table .row.table-header {
      border-bottom: 0; }
      .table .row.table-header .column-header {
        padding: 0; }
      .table .row.table-header label {
        text-transform: none;
        opacity: 0.3;
        font-size: 14px; }
    .table .row:last-child {
      border-bottom: 0; }
    .table .row form .row {
      border: 0; }
      .table .row form .row .no-left-padding {
        padding-left: 0; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-table {
  position: relative;
  padding: 10px 0; }
  .moshi-table .table-header,
  .moshi-table .table-row {
    display: grid;
    width: auto; }
    .moshi-table .table-header > div:first-child,
    .moshi-table .table-row > div:first-child {
      padding-left: 15px; }
    .moshi-table .table-header > div:last-child,
    .moshi-table .table-row > div:last-child {
      padding-right: 15px; }
  .moshi-table .table-row > div {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent; }
  .moshi-table .table-row:hover > div {
    border-top: 1px solid #e4e4ee;
    border-bottom: 1px solid #e4e4ee;
    background-color: #f7f9fa; }
  .moshi-table .table-row.highlight {
    animation: highlight-fade-orange-to-white 5s ease-in-out 0s; }
  .moshi-table .table-row.edit-row {
    position: relative;
    display: inherit;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f1f1f9; }
  .moshi-table .table-row .card-more-actions {
    position: initial;
    display: flex;
    justify-content: center;
    align-items: center; }
    .moshi-table .table-row .card-more-actions .row-action-btn {
      display: inline-block;
      height: inherit;
      min-width: auto;
      margin-right: 5px;
      font-size: 16px;
      text-decoration: none;
      padding: 0 5px;
      min-height: auto;
      outline: none; }
      .moshi-table .table-row .card-more-actions .row-action-btn:hover {
        text-decoration: none; }
      .moshi-table .table-row .card-more-actions .row-action-btn:last-child {
        margin-right: 0; }
    .moshi-table .table-row .card-more-actions .more-toggle:hover .card-more-actions-icon {
      cursor: pointer;
      border-radius: 50%;
      color: #0a83bf;
      background-color: #caebfc; }
    .moshi-table .table-row .card-more-actions .card-more-actions-icon {
      color: #122f4c;
      padding: 2px; }
  .moshi-table .table-header {
    text-align: left;
    font-size: 11px;
    border-top: 1px solid #e4e4ee;
    border-bottom: 1px solid #e4e4ee; }
    .moshi-table .table-header .header-cell {
      display: flex;
      align-content: center;
      align-items: center;
      color: #13304d;
      cursor: default; }
      .moshi-table .table-header .header-cell.sortable:hover {
        color: #0a83bf;
        background-color: rgba(202, 235, 252, 0.3); }
      .moshi-table .table-header .header-cell label {
        font-size: 14px;
        font-weight: 600;
        color: #406080;
        margin-bottom: 0;
        width: auto; }
      .moshi-table .table-header .header-cell .sortable-icon {
        display: inline-flex;
        flex-direction: column;
        width: 13px;
        margin-left: 10px; }
        .moshi-table .table-header .header-cell .sortable-icon .chevron.up {
          position: relative;
          display: block;
          height: 8px;
          margin: 1px 3px; }
          .moshi-table .table-header .header-cell .sortable-icon .chevron.up::before, .moshi-table .table-header .header-cell .sortable-icon .chevron.up::after {
            position: absolute;
            display: block;
            content: "";
            border: 4px solid transparent; }
          .moshi-table .table-header .header-cell .sortable-icon .chevron.up::before {
            bottom: 0;
            border-bottom-color: #7ea5b9; }
          .moshi-table .table-header .header-cell .sortable-icon .chevron.up::after {
            bottom: -2px;
            border-bottom-color: #fff; }
          .moshi-table .table-header .header-cell .sortable-icon .chevron.up.active::before {
            border-bottom-color: #0a83bf; }
        .moshi-table .table-header .header-cell .sortable-icon .chevron.down {
          position: relative;
          display: block;
          height: 8px;
          margin: 1px 3px; }
          .moshi-table .table-header .header-cell .sortable-icon .chevron.down::before, .moshi-table .table-header .header-cell .sortable-icon .chevron.down::after {
            position: absolute;
            display: block;
            content: "";
            border: 4px solid transparent; }
          .moshi-table .table-header .header-cell .sortable-icon .chevron.down::before {
            top: 0;
            border-top-color: #7ea5b9; }
          .moshi-table .table-header .header-cell .sortable-icon .chevron.down::after {
            top: -2px;
            border-top-color: #fff; }
          .moshi-table .table-header .header-cell .sortable-icon .chevron.down.active::before {
            border-top-color: #0a83bf; }
  .moshi-table .header-cell,
  .moshi-table .column-cell {
    padding: 15px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    outline: none; }
    .moshi-table .header-cell.align-right,
    .moshi-table .column-cell.align-right {
      justify-content: flex-end;
      text-align: right; }
    .moshi-table .header-cell.align-center,
    .moshi-table .column-cell.align-center {
      justify-content: center;
      text-align: center; }
    .moshi-table .header-cell .cell-wrapper,
    .moshi-table .column-cell .cell-wrapper {
      color: #171f26; }
    .moshi-table .header-cell.selectable-row-field .checkbox,
    .moshi-table .column-cell.selectable-row-field .checkbox {
      margin: 0;
      padding: 0; }
    .moshi-table .header-cell.moshi-table-column-container,
    .moshi-table .column-cell.moshi-table-column-container {
      padding-left: 3px; }
  .moshi-table .moshi-table-link {
    color: #0a83bf; }
  .moshi-table .moshi-table-default-link {
    font-weight: 400;
    color: #171f26; }
    .moshi-table .moshi-table-default-link:hover {
      color: #0ca3ed; }
  .moshi-table .moshi-table-date {
    color: #406080; }
  .moshi-table .bulk-actions-panel {
    position: absolute;
    top: 0;
    z-index: 1;
    background-color: #caebfc;
    width: 100%;
    height: 50px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between; }
    .moshi-table .bulk-actions-panel .checkbox-wrapper {
      width: 150px; }
      .moshi-table .bulk-actions-panel .checkbox-wrapper .checkbox {
        margin-bottom: 0;
        margin-top: 0; }
        .moshi-table .bulk-actions-panel .checkbox-wrapper .checkbox .checkbox-label {
          color: #0a83bf; }
  .moshi-table .header-cell .checkbox input ~ .checkbox-icon,
  .moshi-table .table-row .checkbox input ~ .checkbox-icon,
  .moshi-table .bulk-actions-panel .checkbox input ~ .checkbox-icon {
    background-color: #fff;
    border: 2px solid #e4e4ee; }
  .moshi-table .header-cell .checkbox input:checked ~ .checkbox-icon,
  .moshi-table .table-row .checkbox input:checked ~ .checkbox-icon,
  .moshi-table .bulk-actions-panel .checkbox input:checked ~ .checkbox-icon {
    background-color: #0a83bf;
    border: 0; }
  .moshi-table:not(.no-header) .table-row:first-child:hover .column-cell,
  .moshi-table:not(.no-header) .table-row:first-child:hover .card-more-actions {
    border-top-color: transparent; }
  .moshi-table:not(.no-pagination) .table-row:last-child:hover .column-cell,
  .moshi-table:not(.no-pagination) .table-row:last-child:hover .card-more-actions {
    border-bottom-color: transparent; }

.pagination-container {
  display: flex;
  justify-content: center;
  position: relative;
  user-select: none;
  padding: 5px 0;
  border-top: 1px solid #e4e4ee;
  background-color: #fff;
  height: 49px; }
  .pagination-container .navigation-page-number {
    padding-left: 24px;
    align-self: center;
    position: absolute;
    left: 0; }
  .pagination-container .navigation-button {
    display: flex; }
    .pagination-container .navigation-button .pagination-icon {
      padding: 8px;
      border: 1px solid #e4e4ee;
      border-radius: 50%;
      font-size: 20px;
      color: #000;
      opacity: 0.9;
      margin: 0 5px; }
      .pagination-container .navigation-button .pagination-icon.disabled {
        opacity: 0.7;
        pointer-events: none; }
      .pagination-container .navigation-button .pagination-icon:hover {
        cursor: not-allowed; }
        .pagination-container .navigation-button .pagination-icon:hover:not(:disabled) {
          background-color: #f7f9fa; }
  .pagination-container .pagination-list {
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin-bottom: 0; }
    .pagination-container .pagination-list .pagination-page-placeholder {
      color: #5c758e;
      font-size: 16px; }
    .pagination-container .pagination-list .pagination-page {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 0 8px;
      min-width: 27px;
      height: 27px;
      font-size: 11px;
      border-radius: 14px; }
      .pagination-container .pagination-list .pagination-page.active span {
        color: #0a83bf; }
      .pagination-container .pagination-list .pagination-page:hover {
        background-color: #f7f9fa; }
      .pagination-container .pagination-list .pagination-page span {
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 5px;
        color: #5c758e;
        outline: none !important; }

.moshi-table-column-picker .grey-label {
  text-transform: capitalize;
  color: #406080; }

.moshi-table-column-picker.moshi-popover .popover {
  min-width: unset; }
  .moshi-table-column-picker.moshi-popover .popover .popover-body {
    padding-top: 5px !important;
    padding-bottom: 0 !important; }

.moshi-table-minimal {
  padding: 0; }
  .moshi-table-minimal .column-cell {
    padding-top: 4px;
    padding-bottom: 4px; }

.moshi-table-no-padding > div > div:first-child {
  padding-left: 0 !important; }

.moshi-table-no-padding > div > div:last-child {
  padding-right: 0 !important; }

.common-table-header-padding {
  padding: 10px 12px; }

.card.table-card {
  padding: 0;
  margin-bottom: 0; }
  .card.table-card .header-cell {
    height: 48px; }
  .card.table-card .table-body {
    overflow-y: auto;
    max-height: 100%; }
  .card.table-card:not(.table-only) .bulk-actions-panel {
    top: 10px; }
  .card.table-card.table-only {
    padding: 0; }
    .card.table-card.table-only.scrollable-table .bulk-actions-panel {
      border-top-right-radius: 0;
      top: 0; }
    .card.table-card.table-only .table-header .header-cell:first-child {
      border-top-left-radius: 14px; }
    .card.table-card.table-only .table-header .header-cell:last-child {
      border-top-right-radius: 14px; }
    .card.table-card.table-only .moshi-table {
      padding-top: 0;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px; }
      .card.table-card.table-only .moshi-table .bulk-actions-panel {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px; }
    .card.table-card.table-only :not(.pagination-container).moshi-table {
      padding-bottom: 0; }
  .card.table-card .pagination-container {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px; }
  .card.table-card .moshi-table {
    overflow: hidden;
    display: flex;
    flex-flow: column;
    height: 100%; }
    .card.table-card .moshi-table:not(.no-pagination) {
      padding-bottom: 0; }
    .card.table-card .moshi-table.no-pagination {
      padding-bottom: 0; }
      .card.table-card .moshi-table.no-pagination .table-row:last-child:hover .column-cell,
      .card.table-card .moshi-table.no-pagination .table-row:last-child:hover .card-more-actions {
        border-bottom-color: transparent; }
      .card.table-card .moshi-table.no-pagination .table-row:last-child:hover :first-child.column-cell {
        border-bottom-left-radius: 14px; }
      .card.table-card .moshi-table.no-pagination .table-row:last-child:hover :last-child.column-cell, .card.table-card .moshi-table.no-pagination .table-row:last-child:hover :last-child.card-more-actions {
        border-bottom-right-radius: 14px; }

.image-component.image-placeholder {
  border: solid 2px #f1f1f9;
  background-color: #f7f9fa;
  border-radius: 50%; }

.patient-info-mini-card {
  display: flex; }
  .patient-info-mini-card .circular-badge {
    margin-right: 10px;
    min-width: 32px;
    min-height: 32px; }
    .patient-info-mini-card .circular-badge.horizontal {
      width: 40px;
      height: 40px;
      align-self: center; }
  .patient-info-mini-card .full-name-line:not(.horizontal) {
    margin-top: 5px;
    margin-bottom: -5px;
    color: #13304d;
    font-size: 20px;
    letter-spacing: 0.5px; }
  .patient-info-mini-card .one-line-data {
    margin-right: 5px; }
  .patient-info-mini-card .link-to-profile {
    text-align: center;
    margin-left: auto;
    align-self: center;
    line-height: 14px;
    color: #0a83bf;
    visibility: hidden;
    white-space: nowrap; }
    .patient-info-mini-card .link-to-profile span {
      font-size: 11px;
      font-weight: 500; }
  .patient-info-mini-card:hover .link-to-profile {
    display: block; }

.patient-info-mini-tooltip .tooltip {
  opacity: 1; }
  .patient-info-mini-tooltip .tooltip .tooltip-inner {
    min-width: 150px;
    max-width: 400px;
    text-align: left;
    min-height: 60px;
    padding: 16px 34px 16px 18px;
    border: 1px solid #e4e4ee; }
    .patient-info-mini-tooltip .tooltip .tooltip-inner .patient-info-mini-card {
      display: flex;
      align-items: center; }
      .patient-info-mini-tooltip .tooltip .tooltip-inner .patient-info-mini-card .circular-badge {
        height: 32px;
        width: 32px;
        margin-right: 15px;
        font-size: 12px; }
  .patient-info-mini-tooltip .tooltip .patient-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1px; }
    .patient-info-mini-tooltip .tooltip .patient-details .full-name-line {
      color: #000;
      font-size: 16px;
      font-weight: 400 !important;
      margin-top: 0;
      margin-bottom: 1px; }
    .patient-info-mini-tooltip .tooltip .patient-details .one-line-data {
      font-size: 12px;
      color: #000;
      font-weight: 400 !important; }
  .patient-info-mini-tooltip .tooltip .arrow {
    display: none; }

.loading {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%; }
  .loading svg {
    height: 60px;
    width: 60px; }

#nprogress .bar {
  background: #0a83bf !important; }

#nprogress .spinner-icon {
  display: none;
  border-top-color: #0a83bf !important;
  border-left-color: #0a83bf !important; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields {
  justify-content: space-between; }
  @media (max-width: 1327px) {
    .card-with-map .card-body > .row .map-fields .btn-xxl,
    .address-card .card-body > .row .map-fields .btn-xxl {
      min-width: auto;
      width: 100%;
      margin-right: 0; } }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.react-autosuggest__container {
  position: relative; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  border-radius: 14px;
  min-width: 100%;
  display: block;
  position: absolute;
  top: 51px;
  border: 2px solid #e4e4ee;
  background-color: #fff;
  z-index: 1000;
  font-size: 14px; }
  .react-autosuggest__container--open .react-autosuggest__suggestions-container .autosuggest-search-results-container {
    max-height: 500px;
    overflow: auto; }
  .react-autosuggest__container--open .react-autosuggest__suggestions-container .autosuggest-show-all-container {
    text-align: center;
    padding: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.15); }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 19px 24px;
  font-weight: 600;
  border-bottom: 1px solid #e4e4ee; }
  .react-autosuggest__suggestion:hover {
    background-color: #f7f9fa; }
    .react-autosuggest__suggestion:hover .patient-info-mini-card .link-to-profile {
      visibility: visible; }
  .react-autosuggest__suggestion:first-child {
    border-radius: 14px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .react-autosuggest__suggestion:last-child {
    border-radius: 14px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: 0; }
    .react-autosuggest__suggestion:last-child:first-child {
      border-radius: 14px; }
  .react-autosuggest__suggestion span {
    display: block;
    font-weight: 400; }
  .react-autosuggest__suggestion .patient-info-mini-card {
    font-size: 13px; }
    .react-autosuggest__suggestion .patient-info-mini-card .circular-badge {
      width: 32px;
      height: 32px; }
    .react-autosuggest__suggestion .patient-info-mini-card .full-name-line {
      font-weight: 600; }
    .react-autosuggest__suggestion .patient-info-mini-card .one-line-data {
      font-weight: 400;
      font-size: 12px; }

.react-autosuggest__suggestion--highlighted,
.react-autosuggest__suggestion--focused {
  background-color: #ebf1f5; }

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat; }

.autosuggest-prepended-icon {
  position: relative; }
  .autosuggest-prepended-icon > i {
    position: absolute;
    z-index: 1;
    top: 50%;
    margin-top: -11px;
    left: 6px;
    color: #406080; }
  .autosuggest-prepended-icon .form-control-prepended-icon {
    padding-left: 45px; }
  .autosuggest-prepended-icon .form-control-clear-button {
    padding-right: 28px; }

.clear-button-wrapper {
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 12px;
  z-index: 3;
  height: 100%; }
  .clear-button-wrapper .clear-button {
    line-height: 1.4;
    padding-top: 0;
    padding-bottom: 0; }

.no-hover-style:hover {
  background-color: #f7f9fa !important;
  border-color: #f1f1f9 !important; }

.calendar-search .react-autosuggest__suggestions-container {
  min-width: 414px; }
  .calendar-search .react-autosuggest__suggestions-container .react-autosuggest__suggestion {
    padding: 13px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-popover .popover {
  border-radius: 14px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.36);
  font-family: "IBM Plex Sans", "sans-serif";
  min-width: 460px;
  max-height: 460px;
  background-color: #f7f9fa;
  border-width: 0;
  outline: none; }
  .moshi-popover .popover > .arrow::before {
    border-bottom-color: transparent; }
  .moshi-popover .popover > .arrow::after {
    border-bottom-color: #f7f9fa; }

.moshi-popover .popover-body {
  padding: 15px;
  overflow: auto;
  max-height: 460px; }
  .moshi-popover .popover-body h4 {
    font-weight: 600;
    font-size: 14px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.custom-datepicker:disabled {
  padding: 0 !important; }

.moshi-popover.date-picker-popover .row {
  margin-bottom: 15px; }
  .moshi-popover.date-picker-popover .row > div > button {
    margin: auto;
    display: flex;
    justify-content: center; }

.moshi-popover.date-picker-popover .date-picker-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px; }
  .moshi-popover.date-picker-popover .date-picker-actions .btn-icon svg g,
  .moshi-popover.date-picker-popover .date-picker-actions .btn-icon svg path {
    fill: #0a83bf; }

.moshi-popover.date-picker-popover .date-picker-values button {
  color: #000;
  border-color: #000;
  border-radius: 10px;
  font-weight: 600;
  color: #000 !important;
  border: 0 !important;
  background-color: transparent;
  color: #000;
  background-color: transparent;
  border: 0;
  padding-top: 6px; }
  .moshi-popover.date-picker-popover .date-picker-values button.hover, .moshi-popover.date-picker-popover .date-picker-values button:hover {
    color: #000;
    background-color: #dce3e3;
    border-color: #000; }
  .moshi-popover.date-picker-popover .date-picker-values button:focus, .moshi-popover.date-picker-popover .date-picker-values button.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .moshi-popover.date-picker-popover .date-picker-values button.disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
    color: #000;
    background-color: transparent; }
  .moshi-popover.date-picker-popover .date-picker-values button:not(:disabled):not(.disabled):active, .moshi-popover.date-picker-popover .date-picker-values button:not(:disabled):not(.disabled).active,
  .show > .moshi-popover.date-picker-popover .date-picker-values button.dropdown-toggle {
    color: #212529;
    background-color: #dce3e3;
    border-color: #000; }
    .moshi-popover.date-picker-popover .date-picker-values button:not(:disabled):not(.disabled):active:focus, .moshi-popover.date-picker-popover .date-picker-values button:not(:disabled):not(.disabled).active:focus,
    .show > .moshi-popover.date-picker-popover .date-picker-values button.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .moshi-popover.date-picker-popover .date-picker-values button:not(:disabled):not(.disabled).active, .moshi-popover.date-picker-popover .date-picker-values button:not(:disabled):not(.disabled):active {
    color: #000;
    background-color: #dce3e3;
    border-color: #000; }
  .show > .moshi-popover.date-picker-popover .date-picker-values button.dropdown-toggle {
    color: #000;
    background-color: #dce3e3;
    border-color: #000; }
  .moshi-popover.date-picker-popover .date-picker-values button.hover, .moshi-popover.date-picker-popover .date-picker-values button:hover {
    color: #000;
    background-color: #d7e2e2;
    border-color: #000; }
  .moshi-popover.date-picker-popover .date-picker-values button.focus, .moshi-popover.date-picker-popover .date-picker-values button:focus {
    color: #000;
    background-color: #dce3e3; }

.moshi-popover.date-picker-popover .search-field {
  padding-left: 25px;
  padding-right: 25px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.files-list .files-item {
  border-radius: 14px;
  background-color: #f7f9fa;
  height: 60px;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
  margin-right: 15px;
  margin-left: 15px; }
  .files-list .files-item .files-item-name {
    font-weight: 600; }
    .files-list .files-item .files-item-name i {
      margin-top: -3px;
      margin-right: 5px; }
  .files-list .files-item > div {
    align-items: center;
    display: flex; }
    .files-list .files-item > div:last-child {
      justify-content: flex-end; }
      .files-list .files-item > div:last-child button {
        text-align: right; }

.moshi-image-preview {
  position: relative;
  border-radius: 15px;
  border: 2px #f1f1f9 solid;
  width: 350px;
  height: 250px; }
  .moshi-image-preview img {
    border-radius: 14px;
    width: 100%;
    height: 100%;
    object-fit: contain; }
  .moshi-image-preview.no-src {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .moshi-image-preview.no-src > span {
      display: inline-block;
      vertical-align: middle; }
  .moshi-image-preview .moshi-file-preview-overlay {
    width: 100%;
    max-width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(23, 31, 38, 0.6);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden; }
    .moshi-image-preview .moshi-file-preview-overlay .row {
      height: 100%; }
      .moshi-image-preview .moshi-file-preview-overlay .row > * {
        border-top: 1px solid #f1f1f9;
        height: 100%; }
        .moshi-image-preview .moshi-file-preview-overlay .row > *:first-child {
          border-right: 1px solid #f1f1f9; }
    .moshi-image-preview .moshi-file-preview-overlay .btn {
      color: #fff;
      border-radius: unset;
      background-color: transparent;
      font-size: 11px;
      width: 100%;
      height: 100%;
      margin: 0;
      border: 0;
      display: block; }
      .moshi-image-preview .moshi-file-preview-overlay .btn:hover {
        color: #13304d;
        background-color: #fff; }

.form-group .moshi-files .files-dropzone {
  margin-bottom: 0; }

.moshi-files .files-dropzone {
  border-radius: 14px;
  margin-bottom: 10px;
  background-color: #f7f9fa;
  height: 100px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  display: flex;
  border: 2px dashed #f1f1f9;
  color: #c4c4cc; }
  .moshi-files .files-dropzone:hover {
    cursor: pointer; }
  .moshi-files .files-dropzone.files-dropzone-active {
    display: flex;
    border-color: #0a83bf; }

.moshi-files .upload-progressbar {
  margin-top: 20px;
  margin-bottom: 10px; }
  .moshi-files .upload-progressbar .upload-status-text {
    margin-bottom: 15px; }

.moshi-files > .btn {
  margin-left: 0; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.progress .progress-bar {
  border-radius: 14px; }

.progress.xs {
  height: 6px; }

.notification-card {
  height: 60px !important;
  min-height: 60px !important;
  justify-content: center;
  margin-bottom: 0;
  background-color: #ebf1f5; }
  .notification-card .row > div {
    display: flex;
    align-items: center; }
    .notification-card .row > div i,
    .notification-card .row > div span {
      font-size: 14px; }
    .notification-card .row > div .toggle-switch {
      margin: 0; }
  .notification-card.inverted {
    box-shadow: none;
    border: 2px solid #f1f1f9;
    background-color: #f7f9fa;
    height: 50px !important;
    min-height: 50px !important;
    margin-bottom: 10px !important; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.container-description {
  max-width: 440px; }

.logo-placeholder {
  border-radius: 14px;
  width: 200px;
  height: 200px;
  border: 1px solid #d8d7ef; }

.signature-widget img {
  max-width: 200px;
  display: block; }

.signature-widget .signature-placeholder {
  display: flex;
  align-items: flex-end; }
  .signature-widget .signature-placeholder .signature-placeholder-text {
    border: 1px solid #ececec;
    border-radius: 10px;
    padding: 5px;
    min-width: 300px;
    min-height: 120px;
    color: #c4c4cc; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ck-editor .ck-content {
  min-height: 100px;
  border-radius: 0 0 14px 14px !important; }

.ck-editor button {
  border-radius: 6px !important; }

.ck-editor .ck-dropdown__panel button {
  border-radius: 0 !important; }

.ck-editor .ck-toolbar {
  border-radius: 14px 14px 0 0 !important; }

.react-markdown-renderer h2,
.ck-editor h2 {
  font-size: 17px; }

.react-markdown-renderer h3,
.ck-editor h3 {
  font-size: 16px; }

.react-markdown-renderer h4,
.ck-editor h4 {
  font-size: 15px;
  font-weight: 600; }

.react-markdown-renderer h1,
.react-markdown-renderer h2,
.react-markdown-renderer h3,
.react-markdown-renderer h4,
.react-markdown-renderer h5,
.react-markdown-renderer h6,
.ck-editor h1,
.ck-editor h2,
.ck-editor h3,
.ck-editor h4,
.ck-editor h5,
.ck-editor h6 {
  height: auto;
  margin: 10px 0 !important; }

.document-editor {
  display: flex;
  flex-flow: column nowrap;
  min-width: calc(210mm + 40px); }
  .document-editor .document-editor-toolbar.sticky-toolbar {
    position: sticky;
    top: -26px;
    z-index: 10; }
  .document-editor .document-editor-editable-container {
    background-color: #c4c4cc; }
    .document-editor .document-editor-editable-container .ck-content {
      /* A4 size */
      width: calc(210mm + 2px);
      padding: 20mm 12mm;
      box-sizing: border-box;
      min-height: 297mm;
      border: 1px solid #b4c2c9;
      background: #fff;
      margin: 40px auto;
      overflow: hidden; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.color-picker {
  height: 40px; }
  .color-picker .color-picker-hidden-input {
    visibility: hidden !important;
    height: 0;
    width: 0;
    margin-left: 90px; }
  .color-picker .color-picker-button {
    width: 180px !important;
    height: 40px !important; }
    .color-picker .color-picker-button.btn {
      margin-left: 0; }
    .color-picker .color-picker-button:disabled {
      opacity: 1; }

.category-colors-container {
  max-width: 300px; }
  .category-colors-container .color-circle,
  .category-colors-container .color-picker {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    position: relative;
    float: left;
    margin: 0 10px 10px 0; }
    .category-colors-container .color-circle:hover,
    .category-colors-container .color-picker:hover {
      box-shadow: 0 0 0 2px #c4c4cc !important;
      cursor: pointer; }
    .category-colors-container .color-circle:focus,
    .category-colors-container .color-picker:focus {
      outline: none; }
    .category-colors-container .color-circle .icon-ok,
    .category-colors-container .color-picker .icon-ok {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -7px;
      margin-left: -7px;
      font-weight: 600; }
    .category-colors-container .color-circle.color-picker,
    .category-colors-container .color-picker.color-picker {
      border: 1px solid #c4c4cc;
      text-align: center;
      color: #c4c4cc;
      font-weight: 600; }
      .category-colors-container .color-circle.color-picker:hover,
      .category-colors-container .color-picker.color-picker:hover {
        box-shadow: none !important;
        background-color: #e6eded; }
      .category-colors-container .color-circle.color-picker .custom-color-circle:focus,
      .category-colors-container .color-picker.color-picker .custom-color-circle:focus {
        outline: none; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.custom-calendar .fc {
  background-color: #fff; }
  .custom-calendar .fc .fc-timegrid-body,
  .custom-calendar .fc .fc-col-header,
  .custom-calendar .fc .fc-timegrid-body {
    width: 100% !important; }
  .custom-calendar .fc .fc-timegrid-slots table,
  .custom-calendar .fc .fc-timegrid-cols table {
    width: 100% !important; }
  .custom-calendar .fc .fc-event.fc-bg-event .moshi-fc-title {
    margin: 5px; }
  .custom-calendar .fc .fc-timegrid .fc-col-header .fc-timegrid-axis,
  .custom-calendar .fc .fc-resource-timeline .fc-col-header .fc-timegrid-axis {
    border-right: 0; }
  .custom-calendar .fc .fc-timegrid .fc-col-header .fc-col-header-cell,
  .custom-calendar .fc .fc-resource-timeline .fc-col-header .fc-col-header-cell {
    font-size: 11px;
    text-transform: uppercase;
    color: #8e8e93;
    letter-spacing: 0.46px;
    font-weight: 400;
    padding-bottom: 10px;
    padding-top: 10px;
    border-right: 0;
    border-left: 0; }
    .custom-calendar .fc .fc-timegrid .fc-col-header .fc-col-header-cell .fc-col-header-cell-cushion,
    .custom-calendar .fc .fc-resource-timeline .fc-col-header .fc-col-header-cell .fc-col-header-cell-cushion {
      width: 100%; }
      .custom-calendar .fc .fc-timegrid .fc-col-header .fc-col-header-cell .fc-col-header-cell-cushion:hover,
      .custom-calendar .fc .fc-resource-timeline .fc-col-header .fc-col-header-cell .fc-col-header-cell-cushion:hover {
        text-decoration: none; }
    .custom-calendar .fc .fc-timegrid .fc-col-header .fc-col-header-cell:hover,
    .custom-calendar .fc .fc-resource-timeline .fc-col-header .fc-col-header-cell:hover {
      background-color: rgba(202, 235, 252, 0.3); }
    .custom-calendar .fc .fc-timegrid .fc-col-header .fc-col-header-cell a,
    .custom-calendar .fc .fc-resource-timeline .fc-col-header .fc-col-header-cell a {
      text-align: center;
      padding: 0; }
      .custom-calendar .fc .fc-timegrid .fc-col-header .fc-col-header-cell a span,
      .custom-calendar .fc .fc-resource-timeline .fc-col-header .fc-col-header-cell a span {
        font-size: 10px;
        color: #171f26; }
      .custom-calendar .fc .fc-timegrid .fc-col-header .fc-col-header-cell a .moshi-fc-header-day,
      .custom-calendar .fc .fc-resource-timeline .fc-col-header .fc-col-header-cell a .moshi-fc-header-day {
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 500; }
      .custom-calendar .fc .fc-timegrid .fc-col-header .fc-col-header-cell a .moshi-fc-header-day-number,
      .custom-calendar .fc .fc-resource-timeline .fc-col-header .fc-col-header-cell a .moshi-fc-header-day-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        margin: auto;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        font-size: 24px;
        color: #171f26; }
  .custom-calendar .fc .fc-timegrid .fc-col-header .fc-day-today,
  .custom-calendar .fc .fc-resource-timeline .fc-col-header .fc-day-today {
    background-color: #fff; }
    .custom-calendar .fc .fc-timegrid .fc-col-header .fc-day-today a span,
    .custom-calendar .fc .fc-resource-timeline .fc-col-header .fc-day-today a span {
      color: #0a83bf; }
    .custom-calendar .fc .fc-timegrid .fc-col-header .fc-day-today a .moshi-fc-header-day-number,
    .custom-calendar .fc .fc-resource-timeline .fc-col-header .fc-day-today a .moshi-fc-header-day-number {
      background-color: #0a83bf;
      color: #fff;
      font-size: 20px; }
  .custom-calendar .fc .fc-timegrid .moshi-resource-header-cell,
  .custom-calendar .fc .fc-resource-timeline .moshi-resource-header-cell {
    text-align: center;
    font-size: 14px; }
    .custom-calendar .fc .fc-timegrid .moshi-resource-header-cell .resource-name,
    .custom-calendar .fc .fc-resource-timeline .moshi-resource-header-cell .resource-name {
      color: #000;
      text-transform: none;
      margin-bottom: 3px;
      letter-spacing: 0; }
    .custom-calendar .fc .fc-timegrid .moshi-resource-header-cell .resource-badge,
    .custom-calendar .fc .fc-resource-timeline .moshi-resource-header-cell .resource-badge {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-transform: uppercase;
      display: inline-block;
      line-height: 30px;
      font-weight: 600; }
  .custom-calendar .fc .fc-timegrid .moshi-now-indicator,
  .custom-calendar .fc .fc-resource-timeline .moshi-now-indicator {
    position: absolute;
    background-color: #0a83bf;
    height: 2px;
    border: 0; }
    .custom-calendar .fc .fc-timegrid .moshi-now-indicator.fc-now-indicator-arrow,
    .custom-calendar .fc .fc-resource-timeline .moshi-now-indicator.fc-now-indicator-arrow {
      border-width: 1px 0; }
  .custom-calendar .fc .fc-timegrid .fc-timegrid-slots .fc-timegrid-slot-label,
  .custom-calendar .fc .fc-resource-timeline .fc-timegrid-slots .fc-timegrid-slot-label {
    border-top: 0;
    padding-top: 0;
    vertical-align: top;
    font-size: 12px;
    color: #406080;
    padding-bottom: 0; }
  .custom-calendar .fc .fc-timegrid .fc-timegrid-slots .moshi-fc-lane,
  .custom-calendar .fc .fc-resource-timeline .fc-timegrid-slots .moshi-fc-lane {
    height: 25px;
    opacity: 0.5; }
    .custom-calendar .fc .fc-timegrid .fc-timegrid-slots .moshi-fc-lane .moshi-fc-lane-time,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-slots .moshi-fc-lane .moshi-fc-lane-time {
      font-size: 12px;
      color: #0a83bf;
      visibility: hidden; }
    .custom-calendar .fc .fc-timegrid .fc-timegrid-slots .moshi-fc-lane:hover,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-slots .moshi-fc-lane:hover {
      background-color: #f2f2ed; }
      .custom-calendar .fc .fc-timegrid .fc-timegrid-slots .moshi-fc-lane:hover .moshi-fc-lane-time,
      .custom-calendar .fc .fc-resource-timeline .fc-timegrid-slots .moshi-fc-lane:hover .moshi-fc-lane-time {
        visibility: visible; }
  .custom-calendar .fc .fc-timegrid .fc-timeline-slot-lane,
  .custom-calendar .fc .fc-resource-timeline .fc-timeline-slot-lane {
    height: 25px; }
    .custom-calendar .fc .fc-timegrid .fc-timeline-slot-lane .moshi-fc-lane-time,
    .custom-calendar .fc .fc-resource-timeline .fc-timeline-slot-lane .moshi-fc-lane-time {
      display: none; }
  .custom-calendar .fc .fc-timegrid .fc-timegrid-event,
  .custom-calendar .fc .fc-timegrid .fc-timeline-event,
  .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event,
  .custom-calendar .fc .fc-resource-timeline .fc-timeline-event {
    min-height: 26px;
    border-width: 4px;
    border-radius: 0 6px 6px 0;
    border-top-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
    box-shadow: 0 0 0 1px #fff;
    margin-right: 10px; }
    .custom-calendar .fc .fc-timegrid .fc-timegrid-event.fc-timegrid-event-condensed .moshi-fc-event-status,
    .custom-calendar .fc .fc-timegrid .fc-timeline-event.fc-timegrid-event-condensed .moshi-fc-event-status,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event.fc-timegrid-event-condensed .moshi-fc-event-status,
    .custom-calendar .fc .fc-resource-timeline .fc-timeline-event.fc-timegrid-event-condensed .moshi-fc-event-status {
      display: none; }
    .custom-calendar .fc .fc-timegrid .fc-timegrid-event.fc-timegrid-event-condensed .moshi-fc-title,
    .custom-calendar .fc .fc-timegrid .fc-timeline-event.fc-timegrid-event-condensed .moshi-fc-title,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event.fc-timegrid-event-condensed .moshi-fc-title,
    .custom-calendar .fc .fc-resource-timeline .fc-timeline-event.fc-timegrid-event-condensed .moshi-fc-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
    .custom-calendar .fc .fc-timegrid .fc-timegrid-event.fc-timegrid-event-condensed .moshi-fc-event-time,
    .custom-calendar .fc .fc-timegrid .fc-timeline-event.fc-timegrid-event-condensed .moshi-fc-event-time,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event.fc-timegrid-event-condensed .moshi-fc-event-time,
    .custom-calendar .fc .fc-resource-timeline .fc-timeline-event.fc-timegrid-event-condensed .moshi-fc-event-time {
      overflow: hidden; }
    .custom-calendar .fc .fc-timegrid .fc-timegrid-event.fc-timegrid-event-condensed .moshi-fc-event-note,
    .custom-calendar .fc .fc-timegrid .fc-timeline-event.fc-timegrid-event-condensed .moshi-fc-event-note,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event.fc-timegrid-event-condensed .moshi-fc-event-note,
    .custom-calendar .fc .fc-resource-timeline .fc-timeline-event.fc-timegrid-event-condensed .moshi-fc-event-note {
      display: none; }
    .custom-calendar .fc .fc-timegrid .fc-timegrid-event.moshi-fc-dim-event,
    .custom-calendar .fc .fc-timegrid .fc-timeline-event.moshi-fc-dim-event,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event.moshi-fc-dim-event,
    .custom-calendar .fc .fc-resource-timeline .fc-timeline-event.moshi-fc-dim-event {
      opacity: 0.5; }
    .custom-calendar .fc .fc-timegrid .fc-timegrid-event.fc-event-today,
    .custom-calendar .fc .fc-timegrid .fc-timeline-event.fc-event-today,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event.fc-event-today,
    .custom-calendar .fc .fc-resource-timeline .fc-timeline-event.fc-event-today {
      opacity: 1; }
    .custom-calendar .fc .fc-timegrid .fc-timegrid-event .fc-event-main .moshi-fc-event-content,
    .custom-calendar .fc .fc-timegrid .fc-timeline-event .fc-event-main .moshi-fc-event-content,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event .fc-event-main .moshi-fc-event-content,
    .custom-calendar .fc .fc-resource-timeline .fc-timeline-event .fc-event-main .moshi-fc-event-content {
      overflow: hidden;
      padding: 5px;
      font-weight: 500;
      font-size: 12px;
      white-space: nowrap; }
      .custom-calendar .fc .fc-timegrid .fc-timegrid-event .fc-event-main .moshi-fc-event-content *,
      .custom-calendar .fc .fc-timegrid .fc-timeline-event .fc-event-main .moshi-fc-event-content *,
      .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event .fc-event-main .moshi-fc-event-content *,
      .custom-calendar .fc .fc-resource-timeline .fc-timeline-event .fc-event-main .moshi-fc-event-content * {
        line-height: 15px; }
      .custom-calendar .fc .fc-timegrid .fc-timegrid-event .fc-event-main .moshi-fc-event-content .moshi-fc-title,
      .custom-calendar .fc .fc-timegrid .fc-timeline-event .fc-event-main .moshi-fc-event-content .moshi-fc-title,
      .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event .fc-event-main .moshi-fc-event-content .moshi-fc-title,
      .custom-calendar .fc .fc-resource-timeline .fc-timeline-event .fc-event-main .moshi-fc-event-content .moshi-fc-title {
        margin-right: 5px;
        width: 100%;
        flex-grow: 0; }
      .custom-calendar .fc .fc-timegrid .fc-timegrid-event .fc-event-main .moshi-fc-event-content .moshi-fc-event-time,
      .custom-calendar .fc .fc-timegrid .fc-timeline-event .fc-event-main .moshi-fc-event-content .moshi-fc-event-time,
      .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event .fc-event-main .moshi-fc-event-content .moshi-fc-event-time,
      .custom-calendar .fc .fc-resource-timeline .fc-timeline-event .fc-event-main .moshi-fc-event-content .moshi-fc-event-time {
        width: 100%; }
      .custom-calendar .fc .fc-timegrid .fc-timegrid-event .fc-event-main .moshi-fc-event-content .moshi-fc-event-status,
      .custom-calendar .fc .fc-timegrid .fc-timeline-event .fc-event-main .moshi-fc-event-content .moshi-fc-event-status,
      .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event .fc-event-main .moshi-fc-event-content .moshi-fc-event-status,
      .custom-calendar .fc .fc-resource-timeline .fc-timeline-event .fc-event-main .moshi-fc-event-content .moshi-fc-event-status {
        font-style: italic; }
      .custom-calendar .fc .fc-timegrid .fc-timegrid-event .fc-event-main .moshi-fc-event-content .moshi-fc-event-note,
      .custom-calendar .fc .fc-timegrid .fc-timeline-event .fc-event-main .moshi-fc-event-content .moshi-fc-event-note,
      .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event .fc-event-main .moshi-fc-event-content .moshi-fc-event-note,
      .custom-calendar .fc .fc-resource-timeline .fc-timeline-event .fc-event-main .moshi-fc-event-content .moshi-fc-event-note {
        font-size: 12px;
        overflow: hidden; }
    .custom-calendar .fc .fc-timegrid .fc-timegrid-event.event-status-no-show,
    .custom-calendar .fc .fc-timegrid .fc-timeline-event.event-status-no-show,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event.event-status-no-show,
    .custom-calendar .fc .fc-resource-timeline .fc-timeline-event.event-status-no-show {
      background-image: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(0, 0, 0, 0.4) 11px, rgba(0, 0, 0, 0.4) 12px); }
    .custom-calendar .fc .fc-timegrid .fc-timegrid-event.event-status-reserved, .custom-calendar .fc .fc-timegrid .fc-timegrid-event.outlined-event,
    .custom-calendar .fc .fc-timegrid .fc-timeline-event.event-status-reserved,
    .custom-calendar .fc .fc-timegrid .fc-timeline-event.outlined-event,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event.event-status-reserved,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event.outlined-event,
    .custom-calendar .fc .fc-resource-timeline .fc-timeline-event.event-status-reserved,
    .custom-calendar .fc .fc-resource-timeline .fc-timeline-event.outlined-event {
      border-top: 1px solid;
      border-right: 1px solid;
      border-bottom: 1px solid; }
    .custom-calendar .fc .fc-timegrid .fc-timegrid-event:hover,
    .custom-calendar .fc .fc-timegrid .fc-timeline-event:hover,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event:hover,
    .custom-calendar .fc .fc-resource-timeline .fc-timeline-event:hover {
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.4); }
    .custom-calendar .fc .fc-timegrid .fc-timegrid-event.filtered-event,
    .custom-calendar .fc .fc-timegrid .fc-timeline-event.filtered-event,
    .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event.filtered-event,
    .custom-calendar .fc .fc-resource-timeline .fc-timeline-event.filtered-event {
      opacity: 0.18; }
      .custom-calendar .fc .fc-timegrid .fc-timegrid-event.filtered-event .moshi-fc-title,
      .custom-calendar .fc .fc-timegrid .fc-timegrid-event.filtered-event .moshi-fc-event-time,
      .custom-calendar .fc .fc-timegrid .fc-timegrid-event.filtered-event .moshi-fc-event-note,
      .custom-calendar .fc .fc-timegrid .fc-timeline-event.filtered-event .moshi-fc-title,
      .custom-calendar .fc .fc-timegrid .fc-timeline-event.filtered-event .moshi-fc-event-time,
      .custom-calendar .fc .fc-timegrid .fc-timeline-event.filtered-event .moshi-fc-event-note,
      .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event.filtered-event .moshi-fc-title,
      .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event.filtered-event .moshi-fc-event-time,
      .custom-calendar .fc .fc-resource-timeline .fc-timegrid-event.filtered-event .moshi-fc-event-note,
      .custom-calendar .fc .fc-resource-timeline .fc-timeline-event.filtered-event .moshi-fc-title,
      .custom-calendar .fc .fc-resource-timeline .fc-timeline-event.filtered-event .moshi-fc-event-time,
      .custom-calendar .fc .fc-resource-timeline .fc-timeline-event.filtered-event .moshi-fc-event-note {
        display: none; }
  .custom-calendar .fc .fc-timegrid .fc-timeline-event,
  .custom-calendar .fc .fc-resource-timeline .fc-timeline-event {
    overflow: hidden; }
  .custom-calendar .fc .fc-resourceTimeGridDay-view .fc-scrollgrid-section td:first-child .fc-scroller-harness .fc-scrollgrid-sync-table {
    border-bottom-style: hidden; }
  .custom-calendar .fc .fc-resourceTimeGridDay-view .fc-timegrid-axis-chunk .fc-timegrid-slot-label {
    border-top-color: transparent;
    padding-top: 0;
    vertical-align: top;
    font-size: 12px;
    color: #406080;
    padding-bottom: 0;
    line-height: 25px; }
  .custom-calendar .fc .fc-resourceTimeGridDay-view .moshi-fc-lane .moshi-fc-lane-time {
    font-size: unset !important;
    color: #0a83bf;
    visibility: hidden; }
  .custom-calendar .fc .fc-resourceTimeGridDay-view .moshi-fc-lane:hover {
    background-color: #f2f2ed; }
    .custom-calendar .fc .fc-resourceTimeGridDay-view .moshi-fc-lane:hover .moshi-fc-lane-time {
      visibility: visible; }
  .custom-calendar .fc .fc-resource-timeline .fc-datagrid-body {
    border-bottom: 1px solid #ddd; }
  .custom-calendar .fc .fc-resource-timeline .moshi-resource-timeline-header .fc-datagrid-cell-main {
    visibility: hidden; }
  .custom-calendar .fc .fc-resource-timeline .fc-timeline-lane .moshi-fc-event-content {
    flex-direction: column; }
  .custom-calendar .fc .fc-resource-timeline .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot.fc-day-mon .fc-timeline-slot-frame, .custom-calendar .fc .fc-resource-timeline .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot.fc-day-wed .fc-timeline-slot-frame, .custom-calendar .fc .fc-resource-timeline .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot.fc-day-fri .fc-timeline-slot-frame {
    background-color: #ececec; }
  .custom-calendar .fc .fc-resource-timeline .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot.fc-day-sat .fc-timeline-slot-frame, .custom-calendar .fc .fc-resource-timeline .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot.fc-day-sun .fc-timeline-slot-frame {
    background-color: #fdf0ed; }
  .custom-calendar .fc .fc-resource-timeline .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot .fc-timeline-slot-frame .fc-timeline-slot-cushion {
    font-size: 12px;
    color: #406080;
    font-weight: 500;
    pointer-events: none; }
  .custom-calendar .fc .fc-resource-timeline .fc-timeline-slot.fc-slot-mon .fc-timeline-slot-frame, .custom-calendar .fc .fc-resource-timeline .fc-timeline-slot.fc-slot-wed .fc-timeline-slot-frame, .custom-calendar .fc .fc-resource-timeline .fc-timeline-slot.fc-slot-fri .fc-timeline-slot-frame {
    background-color: #ececec !important; }
  .custom-calendar .fc .fc-resource-timeline .fc-timeline-slot.fc-slot-sat .fc-timeline-slot-frame, .custom-calendar .fc .fc-resource-timeline .fc-timeline-slot.fc-slot-sun .fc-timeline-slot-frame {
    background-color: #fdf0ed !important; }
  .custom-calendar .fc .fc-resource-timeline .moshi-now-indicator {
    position: relative;
    height: 100%;
    width: 1px; }
    .custom-calendar .fc .fc-resource-timeline .moshi-now-indicator.fc-timeline-now-indicator-arrow {
      display: none; }
  .custom-calendar .fc .fc-resource-timeline .moshi-resource-header-cell {
    text-align: left; }
    .custom-calendar .fc .fc-resource-timeline .moshi-resource-header-cell .resource-name {
      color: #000;
      text-transform: none;
      display: inline-block; }
    .custom-calendar .fc .fc-resource-timeline .moshi-resource-header-cell .resource-badge {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      float: left;
      color: transparent !important;
      margin-top: 2px;
      margin-right: 8px; }

.working-hours-calendar-wrapper,
.booking-calendar-wrapper {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1); }
  .working-hours-calendar-wrapper .working-hours-calendar .working-hours-fc-wrapper,
  .working-hours-calendar-wrapper .booking-calendar .working-hours-fc-wrapper,
  .booking-calendar-wrapper .working-hours-calendar .working-hours-fc-wrapper,
  .booking-calendar-wrapper .booking-calendar .working-hours-fc-wrapper {
    max-height: calc(100vh - 270px);
    overflow: auto; }
  .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-scrollgrid,
  .working-hours-calendar-wrapper .booking-calendar .fc .fc-scrollgrid,
  .booking-calendar-wrapper .working-hours-calendar .fc .fc-scrollgrid,
  .booking-calendar-wrapper .booking-calendar .fc .fc-scrollgrid {
    border-radius: 14px;
    background-color: #fff;
    overflow: hidden;
    border: 1px solid #ddd; }
  .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resource-timeline-divider,
  .working-hours-calendar-wrapper .booking-calendar .fc .fc-resource-timeline-divider,
  .booking-calendar-wrapper .working-hours-calendar .fc .fc-resource-timeline-divider,
  .booking-calendar-wrapper .booking-calendar .fc .fc-resource-timeline-divider {
    width: 1px;
    border: 0; }
  .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot,
  .working-hours-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot,
  .booking-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot,
  .booking-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot {
    background-color: #fff;
    font-size: 11px;
    text-transform: uppercase;
    color: #8e8e93;
    letter-spacing: 0.46px;
    font-weight: 400;
    padding-bottom: 10px;
    padding-top: 10px;
    border-right: 0;
    border-left: 0; }
    .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot .fc-col-header-cell-cushion,
    .working-hours-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot .fc-col-header-cell-cushion,
    .booking-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot .fc-col-header-cell-cushion,
    .booking-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot .fc-col-header-cell-cushion {
      width: 100%; }
      .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot .fc-col-header-cell-cushion:hover,
      .working-hours-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot .fc-col-header-cell-cushion:hover,
      .booking-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot .fc-col-header-cell-cushion:hover,
      .booking-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot .fc-col-header-cell-cushion:hover {
        text-decoration: none; }
    .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a,
    .working-hours-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a,
    .booking-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a,
    .booking-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a {
      text-align: center;
      padding: 0; }
      .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a span,
      .working-hours-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a span,
      .booking-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a span,
      .booking-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a span {
        font-size: 10px;
        color: #171f26; }
      .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a .moshi-fc-header-day,
      .working-hours-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a .moshi-fc-header-day,
      .booking-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a .moshi-fc-header-day,
      .booking-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a .moshi-fc-header-day {
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 500; }
      .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a .moshi-fc-header-day-number,
      .working-hours-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a .moshi-fc-header-day-number,
      .booking-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a .moshi-fc-header-day-number,
      .booking-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-timeline-slot a .moshi-fc-header-day-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        margin: auto;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        font-size: 24px;
        color: #171f26; }
  .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-day-today,
  .working-hours-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-day-today,
  .booking-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-day-today,
  .booking-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-day-today {
    background-color: #fff; }
    .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-day-today a span,
    .working-hours-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-day-today a span,
    .booking-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-day-today a span,
    .booking-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-day-today a span {
      color: #0a83bf; }
    .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-day-today a .moshi-fc-header-day-number,
    .working-hours-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-day-today a .moshi-fc-header-day-number,
    .booking-calendar-wrapper .working-hours-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-day-today a .moshi-fc-header-day-number,
    .booking-calendar-wrapper .booking-calendar .fc .fc-resourceTimeline-view .fc-timeline-header .fc-timeline-header-row .fc-day-today a .moshi-fc-header-day-number {
      background-color: #0a83bf;
      color: #fff;
      font-size: 20px; }
  .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resource .fc-datagrid-cell-frame,
  .working-hours-calendar-wrapper .booking-calendar .fc .fc-resource .fc-datagrid-cell-frame,
  .booking-calendar-wrapper .working-hours-calendar .fc .fc-resource .fc-datagrid-cell-frame,
  .booking-calendar-wrapper .booking-calendar .fc .fc-resource .fc-datagrid-cell-frame {
    display: flex;
    align-items: center; }
    .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .fc-icon,
    .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .fc-datagrid-expander,
    .working-hours-calendar-wrapper .booking-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .fc-icon,
    .working-hours-calendar-wrapper .booking-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .fc-datagrid-expander,
    .booking-calendar-wrapper .working-hours-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .fc-icon,
    .booking-calendar-wrapper .working-hours-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .fc-datagrid-expander,
    .booking-calendar-wrapper .booking-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .fc-icon,
    .booking-calendar-wrapper .booking-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .fc-datagrid-expander {
      display: none; }
    .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .moshi-resource-header-cell,
    .working-hours-calendar-wrapper .booking-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .moshi-resource-header-cell,
    .booking-calendar-wrapper .working-hours-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .moshi-resource-header-cell,
    .booking-calendar-wrapper .booking-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .moshi-resource-header-cell {
      cursor: default !important;
      display: flex;
      align-items: center;
      flex-direction: row-reverse; }
      .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .moshi-resource-header-cell .resource-name,
      .working-hours-calendar-wrapper .booking-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .moshi-resource-header-cell .resource-name,
      .booking-calendar-wrapper .working-hours-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .moshi-resource-header-cell .resource-name,
      .booking-calendar-wrapper .booking-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .moshi-resource-header-cell .resource-name {
        color: #000;
        text-transform: none;
        display: inline-block;
        margin-bottom: 0; }
      .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .moshi-resource-header-cell .resource-badge,
      .working-hours-calendar-wrapper .booking-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .moshi-resource-header-cell .resource-badge,
      .booking-calendar-wrapper .working-hours-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .moshi-resource-header-cell .resource-badge,
      .booking-calendar-wrapper .booking-calendar .fc .fc-resource .fc-datagrid-cell-frame .fc-datagrid-cell-cushion .moshi-resource-header-cell .resource-badge {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        float: left;
        color: transparent !important;
        margin-right: 8px; }
  .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-timeline-lane-frame,
  .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-datagrid-cell-frame,
  .working-hours-calendar-wrapper .booking-calendar .fc .fc-timeline-lane-frame,
  .working-hours-calendar-wrapper .booking-calendar .fc .fc-datagrid-cell-frame,
  .booking-calendar-wrapper .working-hours-calendar .fc .fc-timeline-lane-frame,
  .booking-calendar-wrapper .working-hours-calendar .fc .fc-datagrid-cell-frame,
  .booking-calendar-wrapper .booking-calendar .fc .fc-timeline-lane-frame,
  .booking-calendar-wrapper .booking-calendar .fc .fc-datagrid-cell-frame {
    min-height: 3rem; }
  .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-resource-group .fc-datagrid-cell-frame,
  .working-hours-calendar-wrapper .booking-calendar .fc .fc-resource-group .fc-datagrid-cell-frame,
  .booking-calendar-wrapper .working-hours-calendar .fc .fc-resource-group .fc-datagrid-cell-frame,
  .booking-calendar-wrapper .booking-calendar .fc .fc-resource-group .fc-datagrid-cell-frame {
    min-height: 37px; }
  .working-hours-calendar-wrapper .working-hours-calendar .fc .fc-scrollgrid-section-header td:first-child,
  .working-hours-calendar-wrapper .booking-calendar .fc .fc-scrollgrid-section-header td:first-child,
  .booking-calendar-wrapper .working-hours-calendar .fc .fc-scrollgrid-section-header td:first-child,
  .booking-calendar-wrapper .booking-calendar .fc .fc-scrollgrid-section-header td:first-child {
    border-bottom: 1px solid #ddd; }
  .working-hours-calendar-wrapper .working-hours-calendar .fc .moshi-wh-calendar-event,
  .working-hours-calendar-wrapper .booking-calendar .fc .moshi-wh-calendar-event,
  .booking-calendar-wrapper .working-hours-calendar .fc .moshi-wh-calendar-event,
  .booking-calendar-wrapper .booking-calendar .fc .moshi-wh-calendar-event {
    padding: 0.3rem 0.85rem;
    margin: 0.3rem;
    min-height: 45px;
    font-size: 13px;
    border-radius: 0 6px 6px 0; }
    .working-hours-calendar-wrapper .working-hours-calendar .fc .moshi-wh-calendar-event.fc-bg-event,
    .working-hours-calendar-wrapper .booking-calendar .fc .moshi-wh-calendar-event.fc-bg-event,
    .booking-calendar-wrapper .working-hours-calendar .fc .moshi-wh-calendar-event.fc-bg-event,
    .booking-calendar-wrapper .booking-calendar .fc .moshi-wh-calendar-event.fc-bg-event {
      margin: 38px 0 0;
      border-radius: 0; }
      .working-hours-calendar-wrapper .working-hours-calendar .fc .moshi-wh-calendar-event.fc-bg-event .moshi-fc-title,
      .working-hours-calendar-wrapper .booking-calendar .fc .moshi-wh-calendar-event.fc-bg-event .moshi-fc-title,
      .booking-calendar-wrapper .working-hours-calendar .fc .moshi-wh-calendar-event.fc-bg-event .moshi-fc-title,
      .booking-calendar-wrapper .booking-calendar .fc .moshi-wh-calendar-event.fc-bg-event .moshi-fc-title {
        margin: 5px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.custom-daypicker .react-calendar {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  overflow: hidden;
  background-color: #fff;
  padding: 0 10px 5px;
  min-height: 261px;
  border: 0;
  /* sass-lint:disable class-name-format */ }
  .custom-daypicker .react-calendar.moshi-custom-date-picker {
    max-width: 255px; }
  .custom-daypicker .react-calendar .react-calendar__navigation {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between; }
    .custom-daypicker .react-calendar .react-calendar__navigation button {
      min-width: auto; }
      .custom-daypicker .react-calendar .react-calendar__navigation button:hover {
        background-color: transparent; }
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__prev2-button,
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__next2-button {
      display: none; }
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__label {
      font-family: "IBM Plex Sans", "sans-serif";
      font-weight: 600;
      font-size: 18px;
      min-width: auto;
      flex-grow: unset !important; }
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow {
      font-size: 18px; }
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__label:hover,
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow:hover {
      color: #0a83bf; }
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__label:focus,
    .custom-daypicker .react-calendar .react-calendar__navigation .react-calendar__navigation__arrow:focus {
      background-color: transparent; }
  .custom-daypicker .react-calendar .react-calendar__tile {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1; }
  .custom-daypicker .react-calendar .react-calendar__month-view__weekdays__weekday {
    text-align: center;
    font-size: 11px;
    color: #8e8e93;
    font-family: "IBM Plex Sans", "sans-serif";
    font-weight: 400; }
    .custom-daypicker .react-calendar .react-calendar__month-view__weekdays__weekday abbr {
      border-bottom: 0 !important;
      cursor: inherit !important;
      text-decoration: none !important; }
  .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day {
    border-radius: 50%;
    background-color: #f0fafe;
    font-size: 10px;
    height: 31px;
    color: #8e8e93;
    border: 3px solid #fff;
    padding: 0;
    position: relative;
    overflow: visible !important;
    font-family: "IBM Plex Sans", "sans-serif";
    font-weight: 600;
    margin-bottom: 5px; }
    .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day:hover {
      box-shadow: 0 4px 15px 0 #f1f1f9;
      border: 0; }
    .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__month-view__days__day--neighboringMonth, .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__month-view__days__day--weekend {
      color: #c4c4cc;
      background-color: #f7f9fa; }
    .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__tile--now {
      box-shadow: inset 0 0 0 1px #0a83bf;
      background-color: #fff;
      color: #0a83bf; }
    .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day.react-calendar__tile--rangeBothEnds {
      color: #fff;
      background-color: #0a83bf; }
    .custom-daypicker .react-calendar .react-calendar__month-view .moshi-daypicker-day:disabled {
      color: #c4c4cc;
      background-color: #f7f9fa;
      cursor: not-allowed; }
  .custom-daypicker .react-calendar button {
    background-color: transparent;
    border: 0;
    text-align: center; }
    .custom-daypicker .react-calendar button:focus {
      outline: none !important; }

.custom-daypicker .react-date-picker__wrapper {
  width: 100%;
  border: 0;
  margin-top: -4px; }

.custom-daypicker .react-date-picker__button {
  padding: 2px 6px; }
  .custom-daypicker .react-date-picker__button:focus {
    outline: none; }

.react-date-picker__calendar {
  width: 100% !important;
  z-index: 2 !important; }
  .react-date-picker__calendar .moshi-custom-date-picker {
    border: 1px solid #d8d7ef; }

.circle-container {
  position: absolute;
  top: -2px;
  left: -2px; }
  .circle-container .circle-background,
  .circle-container .circle-progress {
    fill: none; }
  .circle-container .circle-background {
    stroke: transparent; }
  .circle-container .circle-progress {
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.dental-chart-entry-sidepanel-container {
  min-width: auto !important;
  width: 830px; }
  .dental-chart-entry-sidepanel-container .dental-chart-form-container {
    padding: 30px; }
    .dental-chart-entry-sidepanel-container .dental-chart-form-container .dental-chart-entry-form-title {
      font-size: 20px; }
    .dental-chart-entry-sidepanel-container .dental-chart-form-container .dental-chart-grids-container {
      display: flex; }
      .dental-chart-entry-sidepanel-container .dental-chart-form-container .dental-chart-grids-container .dental-chart-grid-container {
        margin-right: 20px; }
    .dental-chart-entry-sidepanel-container .dental-chart-form-container .bill-items {
      margin-top: 0;
      padding: 15px; }

.dental-position-grid-wrapper {
  border-radius: 14px;
  flex-grow: 1;
  margin: 5px; }
  .dental-position-grid-wrapper:hover {
    box-shadow: 0 8px 30px 0 rgba(18, 47, 76, 0.28) !important;
    background-color: #fff;
    cursor: pointer; }
    .dental-position-grid-wrapper:hover.basic-grid {
      background-color: unset; }
  .dental-position-grid-wrapper.active {
    background-color: #ebf1f5;
    box-shadow: inset 0 0 2px #f1f1f9; }
    .dental-position-grid-wrapper.active:hover {
      box-shadow: 0 8px 30px 0 transparent !important; }
  .dental-position-grid-wrapper:focus {
    outline: none; }
  .dental-position-grid-wrapper .dental-position-grid-container {
    height: 63px;
    width: 37px;
    position: relative;
    margin: auto; }
    .dental-position-grid-wrapper .dental-position-grid-container.bottom-grid {
      margin-top: 5px; }
    .dental-position-grid-wrapper .dental-position-grid-container .tooth-number-position {
      text-align: center;
      width: 21px;
      height: 21px;
      margin-bottom: 2px;
      line-height: 20px;
      margin-left: 1px;
      font-size: 11px;
      border: 1px solid transparent; }
      .dental-position-grid-wrapper .dental-position-grid-container .tooth-number-position.active {
        border-radius: 50px;
        background-color: #f0fafe;
        border: 1px solid #0a83bf; }
      .dental-position-grid-wrapper .dental-position-grid-container .tooth-number-position.bottom-number {
        position: absolute;
        bottom: 0;
        margin-bottom: 0; }
    .dental-position-grid-wrapper .dental-position-grid-container .tooth-wrapper {
      display: inline-grid;
      position: absolute; }
      .dental-position-grid-wrapper .dental-position-grid-container .tooth-wrapper .servical-buccal-position,
      .dental-position-grid-wrapper .dental-position-grid-container .tooth-wrapper .servical-palatal-position {
        display: inline-block;
        width: 25px;
        height: 7px;
        position: relative;
        margin-left: -1px; }
        .dental-position-grid-wrapper .dental-position-grid-container .tooth-wrapper .servical-buccal-position svg,
        .dental-position-grid-wrapper .dental-position-grid-container .tooth-wrapper .servical-palatal-position svg {
          position: absolute;
          left: 0; }
      .dental-position-grid-wrapper .dental-position-grid-container .tooth-wrapper .tooth-container {
        width: 24px;
        height: 26px;
        position: relative; }
        .dental-position-grid-wrapper .dental-position-grid-container .tooth-wrapper .tooth-container svg {
          position: absolute;
          z-index: 2; }
        .dental-position-grid-wrapper .dental-position-grid-container .tooth-wrapper .tooth-container .buccal-position {
          position: absolute;
          left: 50%;
          margin-left: -9px;
          width: 18px;
          height: 10px;
          transform: rotate(180deg);
          z-index: 2; }
        .dental-position-grid-wrapper .dental-position-grid-container .tooth-wrapper .tooth-container .mesial-position {
          position: absolute;
          left: -5px;
          top: 8px;
          width: 18px;
          height: 10px;
          transform: rotate(90deg);
          z-index: 2; }
        .dental-position-grid-wrapper .dental-position-grid-container .tooth-wrapper .tooth-container .distal-position {
          width: 18px;
          height: 10px;
          position: absolute;
          transform: rotate(-90deg);
          right: -5px;
          top: 8px;
          z-index: 2; }
        .dental-position-grid-wrapper .dental-position-grid-container .tooth-wrapper .tooth-container .palatal-position {
          position: absolute;
          left: 50%;
          margin-left: -9px;
          bottom: 0;
          width: 18px;
          height: 10px;
          z-index: 2; }
        .dental-position-grid-wrapper .dental-position-grid-container .tooth-wrapper .tooth-container .incisal-position {
          position: absolute;
          width: 14px;
          height: 14px;
          top: 50%;
          left: 50%;
          margin-top: -7px;
          margin-left: -7px;
          background-color: #fff;
          z-index: 1; }
          .dental-position-grid-wrapper .dental-position-grid-container .tooth-wrapper .tooth-container .incisal-position.active {
            background-color: #f0fafe;
            border: 1px solid #0a83bf;
            border-radius: 50%;
            z-index: 4 !important; }
    .dental-position-grid-wrapper .dental-position-grid-container .roots-container {
      float: right;
      width: 8px;
      height: 30px;
      margin-top: 5px; }
      .dental-position-grid-wrapper .dental-position-grid-container .roots-container .root-position {
        width: 8px;
        height: 8px;
        position: relative;
        margin-bottom: 3px; }
        .dental-position-grid-wrapper .dental-position-grid-container .roots-container .root-position svg {
          position: absolute; }
    .dental-position-grid-wrapper .dental-position-grid-container .active {
      z-index: 3 !important; }
      .dental-position-grid-wrapper .dental-position-grid-container .active svg g {
        fill: #f0fafe;
        stroke: #0a83bf; }
  .dental-position-grid-wrapper.basic-grid {
    margin: 5px 0; }
    .dental-position-grid-wrapper.basic-grid .dental-position-grid-container {
      height: 41px;
      width: 37px;
      margin: 0; }
    .dental-position-grid-wrapper.basic-grid:hover {
      box-shadow: none !important;
      cursor: default; }

.dental-chart-grid-container {
  border-radius: 14px;
  box-shadow: 0 0 4px 0 #f1f1f9 !important;
  display: inline-grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  width: 1170px;
  height: 161px;
  background-color: #f7f9fa; }
  .dental-chart-grid-container.basic-grid {
    background-color: #fff;
    box-shadow: none !important;
    width: 472px;
    height: 68px; }
  .dental-chart-grid-container .dental-chart-clear-button {
    border-radius: 14px;
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 3px 10px;
    height: unset;
    line-height: 1.4;
    font-size: 12px;
    display: none; }
  .dental-chart-grid-container:hover .dental-chart-clear-button {
    display: block;
    outline: none;
    cursor: pointer; }
  .dental-chart-grid-container .teeth-container {
    display: flex;
    width: 585px;
    height: 80px;
    justify-content: space-around; }
  .dental-chart-grid-container .small-teeth-container {
    width: 232px;
    height: 30px;
    justify-content: normal;
    border: 0 !important; }
    .dental-chart-grid-container .small-teeth-container .small-tooth-number-container {
      width: 29px;
      height: 30px;
      text-align: center;
      font-size: 11px;
      color: #406080;
      line-height: 30px;
      border: 1px solid #f1f1f9;
      border-left: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: #fff;
      cursor: pointer; }
      .dental-chart-grid-container .small-teeth-container .small-tooth-number-container:first-child {
        border-left: 1px solid #f1f1f9; }
        .dental-chart-grid-container .small-teeth-container .small-tooth-number-container:first-child.active {
          border-left: 0; }
      .dental-chart-grid-container .small-teeth-container .small-tooth-number-container.bottom-row {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px; }
      .dental-chart-grid-container .small-teeth-container .small-tooth-number-container:hover {
        background-color: #f1f1f9; }
      .dental-chart-grid-container .small-teeth-container .small-tooth-number-container.active {
        border-color: #0a83bf;
        color: #0a83bf;
        background-color: #f1f1f9;
        box-shadow: inset 1px 0 0 0 #0a83bf; }
      .dental-chart-grid-container .small-teeth-container .small-tooth-number-container:focus {
        outline: none; }
  .dental-chart-grid-container .top-left {
    border-right: 1px solid #e6eded;
    border-bottom: 1px solid #e6eded; }
  .dental-chart-grid-container .top-right {
    border-bottom: 1px solid #e6eded; }
  .dental-chart-grid-container .bottom-left {
    border-right: 1px solid #e6eded; }
  .dental-chart-grid-container.small-basic-teeth-grid {
    background-color: unset;
    width: 370px;
    height: 52px;
    cursor: default;
    pointer-events: none; }
    .dental-chart-grid-container.small-basic-teeth-grid .small-teeth-container {
      width: 182px;
      height: 23px; }
      .dental-chart-grid-container.small-basic-teeth-grid .small-teeth-container .small-tooth-number-container {
        height: 23px;
        line-height: 23px;
        font-size: 80%; }

.advanced-tooth-position-grid-container {
  width: 235px;
  display: flex;
  justify-content: space-between; }
  .advanced-tooth-position-grid-container .tooth-position-wrapper .tooth-container {
    position: relative;
    width: 102px;
    height: 170px; }
    .advanced-tooth-position-grid-container .tooth-position-wrapper .tooth-container .servical-buccal-position {
      position: absolute;
      top: 0; }
    .advanced-tooth-position-grid-container .tooth-position-wrapper .tooth-container .tooth-positions-container {
      position: absolute;
      top: 35px;
      width: 102px;
      height: 102px; }
      .advanced-tooth-position-grid-container .tooth-position-wrapper .tooth-container .tooth-positions-container .buccal-position {
        position: absolute;
        left: 50%;
        margin-left: -35px; }
      .advanced-tooth-position-grid-container .tooth-position-wrapper .tooth-container .tooth-positions-container .mesial-position {
        position: absolute;
        left: 1px;
        width: 34px;
        height: 72px;
        top: 14px; }
      .advanced-tooth-position-grid-container .tooth-position-wrapper .tooth-container .tooth-positions-container .incisal-position {
        width: 47px;
        height: 46px;
        position: absolute;
        top: 28px;
        left: 50%;
        margin-left: -23px;
        z-index: 2; }
      .advanced-tooth-position-grid-container .tooth-position-wrapper .tooth-container .tooth-positions-container .distal-position {
        position: absolute;
        right: 0;
        width: 34px;
        height: 72px;
        top: 14px; }
      .advanced-tooth-position-grid-container .tooth-position-wrapper .tooth-container .tooth-positions-container .palatal-position {
        position: absolute;
        bottom: 2px;
        left: 50%;
        margin-left: -35px; }
    .advanced-tooth-position-grid-container .tooth-position-wrapper .tooth-container .servical-palatal-position {
      position: absolute;
      bottom: 0; }
  .advanced-tooth-position-grid-container .roots-position-container .root-position {
    position: relative;
    margin-bottom: 8px; }
  .advanced-tooth-position-grid-container .tooth-position-wrapper .position.active,
  .advanced-tooth-position-grid-container .roots-position-container .position.active {
    color: #0a83bf;
    z-index: 1; }
    .advanced-tooth-position-grid-container .tooth-position-wrapper .position.active svg g,
    .advanced-tooth-position-grid-container .roots-position-container .position.active svg g {
      fill: #f0fafe;
      stroke: #0a83bf; }
  .advanced-tooth-position-grid-container .tooth-position-wrapper .position:focus,
  .advanced-tooth-position-grid-container .roots-position-container .position:focus {
    outline: none; }
  .advanced-tooth-position-grid-container .tooth-position-wrapper .position .position-text,
  .advanced-tooth-position-grid-container .roots-position-container .position .position-text {
    position: absolute;
    text-transform: uppercase;
    font-size: 8px; }
    .advanced-tooth-position-grid-container .tooth-position-wrapper .position .position-text.root-text-position,
    .advanced-tooth-position-grid-container .roots-position-container .position .position-text.root-text-position {
      font-size: 11px;
      position: absolute;
      width: 100%;
      text-align: center;
      top: 7px; }
  .advanced-tooth-position-grid-container .tooth-position-wrapper .position:hover,
  .advanced-tooth-position-grid-container .roots-position-container .position:hover {
    cursor: pointer; }
    .advanced-tooth-position-grid-container .tooth-position-wrapper .position:hover svg g,
    .advanced-tooth-position-grid-container .roots-position-container .position:hover svg g {
      fill: #f1f1f9; }

.patient-dental-chart-list {
  margin-top: 12px;
  min-width: 1130px; }

.dental-chart-list-container .services-list {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 83px; }

.dental-chart-list-container .dental-chart-notes > span {
  white-space: normal;
  max-height: 100px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-tag {
  border-radius: 14px;
  font-size: 10px;
  font-weight: 600;
  padding: 0 10px;
  text-transform: uppercase;
  height: 20px;
  line-height: 10px;
  display: inline-flex;
  align-items: center;
  align-content: center;
  cursor: default; }
  .moshi-tag.moshi-tag-sm {
    height: 16px; }
  .moshi-tag:focus {
    outline: none; }
  .moshi-tag.moshi-tag-clickable {
    cursor: pointer; }
    .moshi-tag.moshi-tag-clickable:hover {
      box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-primary {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #0a83bf;
    color: #fff; }
    .moshi-tag.moshi-tag-primary.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-primary:focus {
      outline: none; }
    .moshi-tag.moshi-tag-primary.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-primary.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-primary-outline {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #99dafa;
    color: #0a83bf; }
    .moshi-tag.moshi-tag-primary-outline.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-primary-outline:focus {
      outline: none; }
    .moshi-tag.moshi-tag-primary-outline.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-primary-outline.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-secondary {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #13304d;
    color: #fff; }
    .moshi-tag.moshi-tag-secondary.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-secondary:focus {
      outline: none; }
    .moshi-tag.moshi-tag-secondary.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-secondary.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-secondary-outline {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #dce3e3;
    color: #13304d; }
    .moshi-tag.moshi-tag-secondary-outline.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-secondary-outline:focus {
      outline: none; }
    .moshi-tag.moshi-tag-secondary-outline.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-secondary-outline.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-info {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #171f26;
    color: #fff; }
    .moshi-tag.moshi-tag-info.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-info:focus {
      outline: none; }
    .moshi-tag.moshi-tag-info.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-info.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-info-outline {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #f7f9fa;
    color: #13304d; }
    .moshi-tag.moshi-tag-info-outline.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-info-outline:focus {
      outline: none; }
    .moshi-tag.moshi-tag-info-outline.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-info-outline.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-success {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #6fbea0;
    color: #fff; }
    .moshi-tag.moshi-tag-success.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-success:focus {
      outline: none; }
    .moshi-tag.moshi-tag-success.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-success.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-success-outline {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #d8eee6;
    color: #2e6651; }
    .moshi-tag.moshi-tag-success-outline.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-success-outline:focus {
      outline: none; }
    .moshi-tag.moshi-tag-success-outline.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-success-outline.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-warning {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #fc8f64;
    color: #fff; }
    .moshi-tag.moshi-tag-warning.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-warning:focus {
      outline: none; }
    .moshi-tag.moshi-tag-warning.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-warning.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-warning-outline {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #fed7c8;
    color: #b43503; }
    .moshi-tag.moshi-tag-warning-outline.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-warning-outline:focus {
      outline: none; }
    .moshi-tag.moshi-tag-warning-outline.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-warning-outline.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-default-warning {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #fb6b32;
    color: #fff; }
    .moshi-tag.moshi-tag-default-warning.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-default-warning:focus {
      outline: none; }
    .moshi-tag.moshi-tag-default-warning.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-default-warning.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-default-danger {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #cf3a1b;
    color: #fff; }
    .moshi-tag.moshi-tag-default-danger.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-default-danger:focus {
      outline: none; }
    .moshi-tag.moshi-tag-default-danger.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-default-danger.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-danger {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #e55639;
    color: #fff; }
    .moshi-tag.moshi-tag-danger.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-danger:focus {
      outline: none; }
    .moshi-tag.moshi-tag-danger.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-danger.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-danger-outline {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #f7c9c0;
    color: #a22d15; }
    .moshi-tag.moshi-tag-danger-outline.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-danger-outline:focus {
      outline: none; }
    .moshi-tag.moshi-tag-danger-outline.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-danger-outline.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-yellow {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #f7c403;
    color: #fff; }
    .moshi-tag.moshi-tag-yellow.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-yellow:focus {
      outline: none; }
    .moshi-tag.moshi-tag-yellow.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-yellow.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-yellow-outline {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #fef3c8;
    color: #927402; }
    .moshi-tag.moshi-tag-yellow-outline.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-yellow-outline:focus {
      outline: none; }
    .moshi-tag.moshi-tag-yellow-outline.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-yellow-outline.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-white {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #fff;
    color: #171f26;
    box-shadow: 0 2px 2px 0 rgba(18, 47, 76, 0.28) !important;
    border: 1px solid #f1f1f9; }
    .moshi-tag.moshi-tag-white.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-white:focus {
      outline: none; }
    .moshi-tag.moshi-tag-white.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-white.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-purple {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #4e49b0;
    color: #fff; }
    .moshi-tag.moshi-tag-purple.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-purple:focus {
      outline: none; }
    .moshi-tag.moshi-tag-purple.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-purple.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-purple-outline {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #d8d7ef;
    color: #3e3a8c; }
    .moshi-tag.moshi-tag-purple-outline.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-purple-outline:focus {
      outline: none; }
    .moshi-tag.moshi-tag-purple-outline.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-purple-outline.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-link {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #fff;
    color: #0a83bf; }
    .moshi-tag.moshi-tag-link.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-link:focus {
      outline: none; }
    .moshi-tag.moshi-tag-link.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-link.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }
  .moshi-tag.moshi-tag-link-outline {
    border-radius: 14px;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px;
    text-transform: uppercase;
    height: 20px;
    line-height: 10px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    cursor: default;
    background-color: #0a83bf;
    color: #fff; }
    .moshi-tag.moshi-tag-link-outline.moshi-tag-sm {
      height: 16px; }
    .moshi-tag.moshi-tag-link-outline:focus {
      outline: none; }
    .moshi-tag.moshi-tag-link-outline.moshi-tag-clickable {
      cursor: pointer; }
      .moshi-tag.moshi-tag-link-outline.moshi-tag-clickable:hover {
        box-shadow: 0 0 5px 0 rgba(23, 31, 38, 0.2); }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-timepicker {
  max-width: 115px; }
  .moshi-timepicker.disabled:hover {
    cursor: not-allowed; }
  .moshi-timepicker .rc-time-picker-input {
    border-radius: 14px;
    background-color: #f7f9fa;
    position: relative;
    height: 40px;
    border-width: 2px;
    border-color: #f1f1f9;
    padding: 11px 15px 10px; }
    .moshi-timepicker .rc-time-picker-input:focus {
      outline: none; }
    .moshi-timepicker .rc-time-picker-input:disabled {
      border-radius: 14px !important;
      cursor: not-allowed; }

.moshi-timepicker-popup .rc-time-picker-panel-inner {
  border-radius: 14px;
  overflow: hidden;
  border: 2px solid #0a83bf;
  box-shadow: none; }
  .moshi-timepicker-popup .rc-time-picker-panel-inner .rc-time-picker-panel-input-wrap {
    background-color: #f7f9fa;
    padding-left: 14px;
    padding-top: 10px; }
    .moshi-timepicker-popup .rc-time-picker-panel-inner .rc-time-picker-panel-input-wrap input {
      background-color: #f7f9fa;
      padding: 0;
      font-size: 14px;
      width: 100%; }

.moshi-timepicker-popup.rc-time-picker-panel-narrow {
  max-width: 115px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.patient-event-card {
  cursor: pointer;
  background-color: #f7f9fa;
  margin-bottom: 10px;
  box-shadow: none;
  margin-right: 10px; }
  .patient-event-card.active {
    background-color: #fff; }
  .patient-event-card .event-date {
    font-size: 11px;
    color: #8e8e93;
    text-transform: uppercase;
    line-height: 24px;
    margin-bottom: 15px;
    letter-spacing: 0.46px; }
    .patient-event-card .event-date .event-day {
      background-color: #d8d7ef;
      font-weight: 600;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: inline-block;
      text-align: center;
      margin-right: 8px; }
  .patient-event-card .event-time {
    font-size: 11px;
    letter-spacing: 0.46px;
    line-height: 10px;
    margin-left: 7px; }
    .patient-event-card .event-time .category-indicator {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      float: left;
      margin-top: -1px;
      margin-right: 14px; }
      .patient-event-card .event-time .category-indicator.moshi-calendar-event-primary {
        background-color: #0a83bf;
        border-color: #0a83bf;
        color: #171f26; }
      .patient-event-card .event-time .category-indicator.moshi-calendar-event-secondary {
        background-color: #13304d;
        border-color: #13304d;
        color: #171f26; }
      .patient-event-card .event-time .category-indicator.moshi-calendar-event-info {
        background-color: #171f26;
        border-color: #171f26;
        color: #171f26; }
      .patient-event-card .event-time .category-indicator.moshi-calendar-event-success {
        background-color: #d8eee6;
        border-color: #d8eee6;
        color: #171f26; }
      .patient-event-card .event-time .category-indicator.moshi-calendar-event-warning {
        background-color: #fed7c8;
        border-color: #fed7c8;
        color: #171f26; }
      .patient-event-card .event-time .category-indicator.moshi-calendar-event-danger {
        background-color: #f7c9c0;
        border-color: #f7c9c0;
        color: #171f26; }
      .patient-event-card .event-time .category-indicator.moshi-calendar-event-yellow {
        background-color: #fef3c8;
        border-color: #fef3c8;
        color: #171f26; }
      .patient-event-card .event-time .category-indicator.moshi-calendar-event-purple {
        background-color: #928fd1;
        border-color: #928fd1;
        color: #171f26; }
  .patient-event-card .event-name {
    font-size: 11px;
    font-weight: 600;
    padding-left: 31px;
    margin-top: 5px; }
  .patient-event-card .doctor-name {
    font-size: 11px;
    padding-left: 31px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.sidepanel-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999; }
  .sidepanel-overlay .sidepanel-container {
    height: 100%;
    max-width: 90%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff; }
    .sidepanel-overlay .sidepanel-container .sidepanel-common-padding {
      padding: 24px 40px; }
    .sidepanel-overlay .sidepanel-container.sidepanel-xl {
      min-width: 1200px; }
      .sidepanel-overlay .sidepanel-container.sidepanel-xl.fixed-width {
        max-width: 1200px; }
    .sidepanel-overlay .sidepanel-container.sidepanel-lg {
      min-width: 1100px; }
      .sidepanel-overlay .sidepanel-container.sidepanel-lg.fixed-width {
        max-width: 1100px; }
    .sidepanel-overlay .sidepanel-container.sidepanel-md {
      min-width: 770px; }
      .sidepanel-overlay .sidepanel-container.sidepanel-md.fixed-width {
        max-width: 770px; }
    .sidepanel-overlay .sidepanel-container.sidepanel-sm {
      width: 550px; }
      .sidepanel-overlay .sidepanel-container.sidepanel-sm.fixed-width {
        max-width: 550px; }
    .sidepanel-overlay .sidepanel-container .sidepanel-content {
      overflow: auto;
      height: 100%;
      position: relative; }
      .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 1000; }
        .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container .sidepanel-close-btn {
          padding: 4px; }
          .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container .sidepanel-close-btn i {
            font-weight: bold; }
      .sidepanel-overlay .sidepanel-container .sidepanel-content header {
        margin-bottom: 40px;
        padding-bottom: 5px; }
        .sidepanel-overlay .sidepanel-container .sidepanel-content header h2 {
          margin-right: 70px; }
        .sidepanel-overlay .sidepanel-container .sidepanel-content header > div {
          white-space: nowrap;
          float: right; }
          .sidepanel-overlay .sidepanel-container .sidepanel-content header > div .btn {
            margin-right: 20px; }
            .sidepanel-overlay .sidepanel-container .sidepanel-content header > div .btn:last-child {
              margin-left: 0;
              margin-right: 0; }
      .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-group-title {
        margin-bottom: 25px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.Toastify__toast-container {
  z-index: 9999999999; }
  .Toastify__toast-container .Toastify__toast {
    border-radius: 8px;
    font-family: "IBM Plex Sans", "sans-serif";
    min-height: 40px;
    margin-bottom: 10px; }
    .Toastify__toast-container .Toastify__toast.toast-unclickable {
      cursor: unset; }
  .Toastify__toast-container .Toastify__toast-body {
    font-weight: 400;
    margin-left: 10px; }
  .Toastify__toast-container .icon-close {
    display: flex;
    align-items: center; }
  .Toastify__toast-container .Toastify__toast--info {
    background-color: #122f4c; }
  .Toastify__toast-container .Toastify__toast--success {
    background-color: #4eac88; }
  .Toastify__toast-container .Toastify__toast--warning {
    background-color: #fb6b32; }
  .Toastify__toast-container .Toastify__toast--error {
    background-color: #e55639; }
  .Toastify__toast-container .Toastify__toast--default {
    background-color: #fff;
    color: #0a83bf; }
  .Toastify__toast-container .Toastify__progress-bar--default {
    background: #0a83bf; }
  .Toastify__toast-container.Toastify__toast-container--bottom-center.moshi-full-width-toast-container {
    bottom: 15px; }
  .Toastify__toast-container.Toastify__toast-container--top-center.moshi-full-width-toast-container {
    top: 0; }
  .Toastify__toast-container.moshi-full-width-toast-container {
    width: 100%;
    padding: 0; }
    .Toastify__toast-container.moshi-full-width-toast-container.default-z-index {
      z-index: 100; }
    .Toastify__toast-container.moshi-full-width-toast-container .moshi-full-width-toast {
      border-radius: 0;
      margin-bottom: 0;
      min-height: auto;
      padding: 10px !important;
      text-align: center;
      font-size: 20px;
      font-weight: 600; }
      .Toastify__toast-container.moshi-full-width-toast-container .moshi-full-width-toast .Toastify__toast-body {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.save-bar {
  box-shadow: 0 0 30px 0 rgba(18, 47, 76, 0.28);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 78px;
  background-color: #fff;
  z-index: 999; }
  .save-bar .left-action-buttons {
    float: left;
    margin-top: 19px; }
  .save-bar .action-buttons {
    float: right;
    margin-top: 19px; }

.sidepanel-overlay .sidepanel-document-container {
  min-width: auto !important;
  width: 700px !important; }
  @media print {
    .sidepanel-overlay .sidepanel-document-container {
      -webkit-print-color-adjust: exact !important;
      width: 100% !important;
      max-width: 100% !important; }
      .sidepanel-overlay .sidepanel-document-container button,
      .sidepanel-overlay .sidepanel-document-container .icon-close {
        display: none; } }
  .sidepanel-overlay .sidepanel-document-container .sidepanel-document-form > button:not(:first-child) {
    margin-right: 16px; }
  .sidepanel-overlay .sidepanel-document-container .sidepanel-document-form .document-edit-button,
  .sidepanel-overlay .sidepanel-document-container .sidepanel-document-form .document-save-button {
    float: right; }
  .sidepanel-overlay .sidepanel-document-container .sidepanel-document-form .document-save-button {
    margin-right: 60px;
    margin-bottom: 27px; }

.document-list-file-name {
  margin: 1px 0 0 5px;
  font-size: 14px; }

.tile-layout > header {
  min-height: 57px; }
  .tile-layout > header > h1 {
    height: auto; }

.tile-layout .tile-column-layout {
  display: flex;
  flex-direction: row; }
  .tile-layout .tile-column-layout.default-layout {
    min-width: 900px;
    max-width: 1400px; }
  .tile-layout .tile-column-layout .tile-column-center {
    flex-grow: 1; }
  .tile-layout .tile-column-layout .tile-column-left {
    min-width: 220px;
    max-width: 220px;
    margin-right: 20px; }
  .tile-layout .tile-column-layout .tile-column-right {
    min-width: 220px;
    max-width: 220px;
    margin-left: 20px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.form-group label .required-red-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #eb7c66;
  position: absolute;
  right: 1px;
  top: 3px;
  width: 7px;
  height: 7px;
  position: initial;
  display: inline-block;
  margin-right: 5px; }

.form-group .rsjf-invalid-feedback {
  margin-top: 0.25rem; }
  .form-group .rsjf-invalid-feedback ul {
    list-style: none;
    padding-left: 15px;
    margin-bottom: 0; }
    .form-group .rsjf-invalid-feedback ul li {
      color: #cf3a1b !important;
      font-size: 14px;
      font-weight: 400; }

.rjsf p {
  white-space: pre-wrap; }

.rjsf .control-label,
.rjsf .radio-label {
  color: #13304d !important; }

.rjsf .control-label {
  font-size: 14px !important;
  padding: 10px 0; }

.rjsf > .field-object > fieldset .react-date-picker--disabled .react-date-picker__wrapper {
  margin-top: -3px;
  margin-left: 2px; }

.rjsf > .field-object > fieldset p {
  margin-top: 20px; }

.rjsf > .field-object > fieldset > .field {
  border-bottom: 2px solid #e6eded; }
  .rjsf > .field-object > fieldset > .field:last-child {
    border-bottom: 0; }
  .rjsf > .field-object > fieldset > .field.form-group.field-object.field-section {
    border-radius: 14px;
    background-color: #f1f1f9;
    padding: 15px; }

.rjsf > .field-object > fieldset .field-string,
.rjsf > .field-object > fieldset .field-array,
.rjsf > .field-object > fieldset .field-number {
  padding: 25px 0; }
  .rjsf > .field-object > fieldset .field-string .custom-daypicker,
  .rjsf > .field-object > fieldset .field-array .custom-daypicker,
  .rjsf > .field-object > fieldset .field-number .custom-daypicker {
    width: 25%; }
  .rjsf > .field-object > fieldset .field-string > div > div .form-control,
  .rjsf > .field-object > fieldset .field-array > div > div .form-control,
  .rjsf > .field-object > fieldset .field-number > div > div .form-control {
    width: initial;
    margin-left: 40px; }
  .rjsf > .field-object > fieldset .field-string > div > div input,
  .rjsf > .field-object > fieldset .field-string > div > div textarea,
  .rjsf > .field-object > fieldset .field-array > div > div input,
  .rjsf > .field-object > fieldset .field-array > div > div textarea,
  .rjsf > .field-object > fieldset .field-number > div > div input,
  .rjsf > .field-object > fieldset .field-number > div > div textarea {
    box-shadow: none; }
  .rjsf > .field-object > fieldset .field-string > div > div .field-radio-group,
  .rjsf > .field-object > fieldset .field-string > div > div .checkboxes,
  .rjsf > .field-object > fieldset .field-array > div > div .field-radio-group,
  .rjsf > .field-object > fieldset .field-array > div > div .checkboxes,
  .rjsf > .field-object > fieldset .field-number > div > div .field-radio-group,
  .rjsf > .field-object > fieldset .field-number > div > div .checkboxes {
    padding-left: 40px; }
    .rjsf > .field-object > fieldset .field-string > div > div .field-radio-group .radio label > span,
    .rjsf > .field-object > fieldset .field-string > div > div .field-radio-group .checkbox label > span,
    .rjsf > .field-object > fieldset .field-string > div > div .checkboxes .radio label > span,
    .rjsf > .field-object > fieldset .field-string > div > div .checkboxes .checkbox label > span,
    .rjsf > .field-object > fieldset .field-array > div > div .field-radio-group .radio label > span,
    .rjsf > .field-object > fieldset .field-array > div > div .field-radio-group .checkbox label > span,
    .rjsf > .field-object > fieldset .field-array > div > div .checkboxes .radio label > span,
    .rjsf > .field-object > fieldset .field-array > div > div .checkboxes .checkbox label > span,
    .rjsf > .field-object > fieldset .field-number > div > div .field-radio-group .radio label > span,
    .rjsf > .field-object > fieldset .field-number > div > div .field-radio-group .checkbox label > span,
    .rjsf > .field-object > fieldset .field-number > div > div .checkboxes .radio label > span,
    .rjsf > .field-object > fieldset .field-number > div > div .checkboxes .checkbox label > span {
      display: flex;
      justify-content: flex-start; }
      .rjsf > .field-object > fieldset .field-string > div > div .field-radio-group .radio label > span span,
      .rjsf > .field-object > fieldset .field-string > div > div .field-radio-group .checkbox label > span span,
      .rjsf > .field-object > fieldset .field-string > div > div .checkboxes .radio label > span span,
      .rjsf > .field-object > fieldset .field-string > div > div .checkboxes .checkbox label > span span,
      .rjsf > .field-object > fieldset .field-array > div > div .field-radio-group .radio label > span span,
      .rjsf > .field-object > fieldset .field-array > div > div .field-radio-group .checkbox label > span span,
      .rjsf > .field-object > fieldset .field-array > div > div .checkboxes .radio label > span span,
      .rjsf > .field-object > fieldset .field-array > div > div .checkboxes .checkbox label > span span,
      .rjsf > .field-object > fieldset .field-number > div > div .field-radio-group .radio label > span span,
      .rjsf > .field-object > fieldset .field-number > div > div .field-radio-group .checkbox label > span span,
      .rjsf > .field-object > fieldset .field-number > div > div .checkboxes .radio label > span span,
      .rjsf > .field-object > fieldset .field-number > div > div .checkboxes .checkbox label > span span {
        padding: 10px;
        color: #000;
        font-size: 14px; }

.feedback-form-container {
  flex-direction: column;
  width: 100%; }
  .feedback-form-container .feedback-form-header {
    width: 100%;
    height: 94px;
    align-items: center;
    justify-content: center;
    border-bottom: 4px solid #f5f7f8;
    padding-bottom: 0; }
    .feedback-form-container .feedback-form-header img,
    .feedback-form-container .feedback-form-header svg {
      max-width: 110px; }
  .feedback-form-container .feedback-form-content {
    width: 100%;
    padding: 25px; }
    .feedback-form-container .feedback-form-content .rjsf {
      max-width: 400px;
      margin: auto; }
      .feedback-form-container .feedback-form-content .rjsf .form-group {
        margin-bottom: 0; }
        .feedback-form-container .feedback-form-content .rjsf .form-group label {
          color: #000; }
      .feedback-form-container .feedback-form-content .rjsf .submit-button-container {
        display: flex;
        justify-content: center; }

.overlay-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 999; }
  .overlay-container .overlay-header {
    justify-content: flex-end;
    padding: 10px 15px;
    border-bottom: 1px solid #e6eded;
    margin-bottom: 0;
    height: 65px; }
    .overlay-container .overlay-header #overlay-title {
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      width: 100%; }
      .overlay-container .overlay-header #overlay-title > * {
        vertical-align: middle; }
    .overlay-container .overlay-header .close-button {
      cursor: pointer;
      display: flex; }
      .overlay-container .overlay-header .close-button:focus {
        outline: none; }
      .overlay-container .overlay-header .close-button i {
        font-size: 36px;
        color: #171f26; }
        .overlay-container .overlay-header .close-button i:hover {
          color: #0a83bf; }
  .overlay-container .overlay-content-container {
    height: calc(100% - 65px);
    overflow-x: auto; }
    .overlay-container .overlay-content-container .overlay-content {
      display: flex;
      height: 100%;
      flex: 1; }
      .overlay-container .overlay-content-container .overlay-content > form {
        display: flex;
        flex: 1; }
      .overlay-container .overlay-content-container .overlay-content .overlay-left-side-container {
        flex-grow: 1;
        border-right: 1px solid #e6eded;
        padding: 25px;
        overflow: auto; }
        .overlay-container .overlay-content-container .overlay-content .overlay-left-side-container .overlay-section {
          padding: 25px;
          border-bottom: 1px solid #f1f1f9; }
        .overlay-container .overlay-content-container .overlay-content .overlay-left-side-container.no-padding {
          padding: 0; }
      .overlay-container .overlay-content-container .overlay-content .overlay-right-side-container {
        display: flex;
        flex-direction: column;
        position: relative; }
        .overlay-container .overlay-content-container .overlay-content .overlay-right-side-container .overlay-section {
          padding: 20px 34px;
          border-bottom: 1px solid #f1f1f9; }
          .overlay-container .overlay-content-container .overlay-content .overlay-right-side-container .overlay-section .form-group {
            padding-left: 0;
            padding-right: 0; }
        .overlay-container .overlay-content-container .overlay-content .overlay-right-side-container .overlay-right-bottom-container {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 88px; }
          .overlay-container .overlay-content-container .overlay-content .overlay-right-side-container .overlay-right-bottom-container .view-in-calendar-link {
            position: absolute;
            margin-top: -30px;
            top: 0;
            width: 100%;
            text-align: center;
            left: 0; }
      .overlay-container .overlay-content-container .overlay-content .button-container {
        background-color: #ebf1f5;
        padding: 24px 14px;
        text-align: center;
        border-top: 1px solid #dce3e3;
        border-bottom: 1px solid #dce3e3; }
        .overlay-container .overlay-content-container .overlay-content .button-container > *:not(:last-child) {
          margin-right: 10px; }

.overlay-right-side-patient-details {
  width: 440px; }
  .overlay-right-side-patient-details.patient-details-scrollable {
    height: calc( 100vh - 153px);
    overflow: auto; }
  .overlay-right-side-patient-details .selected-patient-container .patient-card-container {
    position: relative;
    padding: 18px 15px 15px;
    border-bottom: 1px solid #f1f1f9; }
    .overlay-right-side-patient-details .selected-patient-container .patient-card-container .patient-name {
      margin-bottom: 0;
      margin-left: 15px;
      color: #171f26; }
      .overlay-right-side-patient-details .selected-patient-container .patient-card-container .patient-name > a {
        font-size: 20px; }
    .overlay-right-side-patient-details .selected-patient-container .patient-card-container .patient-basic-info {
      color: #171f26;
      margin-bottom: 20px;
      margin-left: 15px; }
    .overlay-right-side-patient-details .selected-patient-container .patient-card-container .view-profile {
      text-align: center; }
  .overlay-right-side-patient-details .patient-stats-container {
    display: flex;
    padding: 10px;
    min-height: 88px;
    margin-bottom: 10px; }
    .overlay-right-side-patient-details .patient-stats-container .totals-container {
      flex-grow: 1;
      text-align: center; }
      .overlay-right-side-patient-details .patient-stats-container .totals-container h3 {
        margin-bottom: 7px; }
      .overlay-right-side-patient-details .patient-stats-container .totals-container p {
        margin-bottom: 5px;
        font-size: 11px;
        color: #b4c2c9;
        text-transform: uppercase; }
  .overlay-right-side-patient-details .patient-tabs-container {
    padding: 15px;
    border-bottom: 1px solid #f1f1f9; }
    .overlay-right-side-patient-details .patient-tabs-container .tabs .tab {
      text-transform: uppercase;
      color: #0a83bf;
      font-size: 11px;
      font-weight: 600; }
    .overlay-right-side-patient-details .patient-tabs-container .tab-content .patient-contact .grey-label {
      margin-bottom: 0;
      margin-top: 10px;
      display: block; }
  .overlay-right-side-patient-details .patient-autosuggest-container {
    padding: 15px;
    background-color: #ebf1f5; }
    .overlay-right-side-patient-details .patient-autosuggest-container .form-group {
      padding: 0; }
    .overlay-right-side-patient-details .patient-autosuggest-container .react-autosuggest__suggestions-container--open {
      top: 84px;
      width: 440px;
      height: calc(100vh - 278px);
      left: -15px;
      border-radius: 0;
      border: 0;
      overflow: auto; }
      .overlay-right-side-patient-details .patient-autosuggest-container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion {
        border: 0;
        border-bottom: 1px solid #e5e5e1;
        border-radius: 0;
        padding: 8px 15px 7px; }
        .overlay-right-side-patient-details .patient-autosuggest-container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion .patient-suggestion .patient-name {
          font-weight: 600; }
        .overlay-right-side-patient-details .patient-autosuggest-container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion .patient-suggestion .patient-info {
          font-size: 12px;
          color: #8e8e93; }
          .overlay-right-side-patient-details .patient-autosuggest-container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion .patient-suggestion .patient-info > span {
            display: inline; }
            .overlay-right-side-patient-details .patient-autosuggest-container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion .patient-suggestion .patient-info > span:not(:last-child)::after {
              margin-left: 5px;
              content: "\2022";
              margin-right: 5px; }
        .overlay-right-side-patient-details .patient-autosuggest-container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion:hover {
          color: #0a83bf;
          background-color: #f0fafe;
          cursor: pointer; }
          .overlay-right-side-patient-details .patient-autosuggest-container .react-autosuggest__suggestions-container--open .react-autosuggest__suggestion:hover .patient-info {
            color: #0a83bf; }
    .overlay-right-side-patient-details .patient-autosuggest-container .create-patient-btn-container {
      text-align: center; }
      .overlay-right-side-patient-details .patient-autosuggest-container .create-patient-btn-container .btn-link {
        font-size: 12px;
        display: inline-block; }
  .overlay-right-side-patient-details .empty-patient-container {
    width: 100%;
    background-color: #ebf1f5;
    height: 129px; }
  .overlay-right-side-patient-details .no-patient-msg {
    font-size: 20px;
    text-align: center;
    color: #b4c2c9;
    line-height: 1.22; }

.overlay-doctor-container {
  padding-top: 20px !important;
  padding-bottom: 3px !important; }

.overlay-patient-more-actions-container {
  position: absolute;
  right: 16px;
  top: 16px; }

.overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container {
  max-width: 660px;
  margin: auto;
  padding-bottom: 40px; }
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .right-side {
    flex: 1; }
    .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .right-side .staff-dropdown-container {
      margin-bottom: 15px; }
      .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .right-side .staff-dropdown-container .form-group {
        flex: 1;
        margin-bottom: 0; }
      .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .right-side .staff-dropdown-container i {
        align-self: center;
        margin-left: 7px; }
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .left-side {
    margin-bottom: 15px; }
    .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .left-side .add-staff-button {
      margin-right: 15px; }
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .event-custom-reminder-fields .grey-label {
    margin-right: 10px; }
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .event-custom-reminder-fields .moshi-button {
    margin-left: 0; }
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .event-custom-reminder-fields .event-custom-reminder-row-container {
    display: flex; }
    .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .event-custom-reminder-fields .event-custom-reminder-row-container .reminder-icon {
      border-radius: 14px;
      background-color: #ebf1f5;
      width: 40px;
      height: 40px;
      padding: 5px;
      text-align: center;
      line-height: 33px;
      margin-right: 5px; }
    .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .event-custom-reminder-fields .event-custom-reminder-row-container .icon-close {
      padding: 13px 5px; }
      .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .event-custom-reminder-fields .event-custom-reminder-row-container .icon-close:hover {
        cursor: pointer; }
    .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .event-custom-reminder-fields .event-custom-reminder-row-container .num-before-input,
    .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .event-custom-reminder-fields .event-custom-reminder-row-container .time-unit-select {
      flex-grow: 1; }
    .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-form-container .event-custom-reminder-fields .event-custom-reminder-row-container .before-at {
      padding-top: 10px;
      margin: 0 15px; }
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-types-container .radio-as-button {
    margin-top: 0; }
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-date-and-time-text {
    font-size: 24px;
    display: inline-block; }
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .moshi-tag {
    margin-top: 7px;
    margin-left: 15px; }
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-date-input,
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-from-time-dropdown {
    max-width: 160px; }
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-categories-dropdown {
    flex: 1; }
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .event-duration-dropdown {
    width: 125px; }
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .doctor-field,
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .category-field,
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .priority-field {
    margin-left: 125px; }
  .overlay-content-container .overlay-content .overlay-left-side-container .event-form-left-side-container .priority-field .radio {
    margin-top: 0; }

.overlay-content-container .overlay-content .overlay-left-side-container .category-and-doctor-container .category-indicator {
  margin-right: 5px; }

.overlay-content-container .overlay-content .overlay-left-side-container .category-and-doctor-container .duration-with-doctor {
  color: #13304d; }

.overlay-content-container .overlay-content .overlay-left-side-container hr {
  margin-top: 10px;
  margin-bottom: 20px; }

.overlay-content-container .overlay-content .overlay-left-side-container .event-details-group .detail-title {
  color: #13304d;
  font-size: 11px; }

.overlay-content-container .overlay-content .overlay-left-side-container .event-details-group .column-cell {
  padding-left: 0; }

.overlay-content-container .overlay-content .overlay-left-side-container .event-details-group .empty-notification-text {
  margin-bottom: 10px;
  display: block; }

.overlay-content-container .overlay-content .overlay-right-side-container.event-right-side-container {
  min-width: 440px; }
  .overlay-content-container .overlay-content .overlay-right-side-container.event-right-side-container .event-status-container {
    padding: 24px 15px;
    background-color: #ebf1f5;
    border-bottom: 1px solid #f1f1f9; }
    .overlay-content-container .overlay-content .overlay-right-side-container.event-right-side-container .event-status-container .form-group {
      margin-bottom: 0; }

.event-queue-overlay {
  z-index: 1000; }

.event-status-tooltip .tooltip-inner {
  min-width: 500px; }

.moshi-expand-list-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(241, 241, 249, 0.3);
  height: 50px; }

.tile {
  width: 100%;
  background-color: #fff;
  font-weight: 400; }
  .tile.collapsable {
    background-color: transparent; }
    .tile.collapsable .open-tile {
      padding-bottom: 10px; }
    .tile.collapsable .collapsible-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 18px;
      color: #000;
      margin-bottom: 14px; }
    .tile.collapsable:hover {
      background-color: transparent; }
    .tile.collapsable .select-button {
      font-size: 14px; }
  .tile .grey-label {
    margin-bottom: 7px;
    margin-top: 14px; }
  .tile .tile-header {
    margin-bottom: 5px;
    font-weight: 400; }
  .tile .tile-hover-edit {
    position: absolute;
    top: 11px;
    right: 11px;
    padding: 3px 10px;
    border-radius: 100px;
    font-size: 12px;
    font-weight: 600;
    color: #0a83bf;
    background-color: #caebfc;
    display: none; }
    .tile .tile-hover-edit.inline {
      position: initial; }
    .tile .tile-hover-edit.icon-container {
      display: none;
      background-color: transparent;
      font-size: 20px;
      border: 0;
      padding-right: 0; }
  .tile:hover .tile-hover-edit {
    display: block;
    outline: none;
    cursor: pointer; }
    .tile:hover .tile-hover-edit.icon-container {
      display: flex; }
  .tile .tile-send-sms {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 11px;
    left: 11px;
    padding: 3px 10px;
    border-radius: 100px; }
    .tile .tile-send-sms i {
      margin-top: -1px;
      margin-right: 7px; }
  .tile.tile-cave {
    min-height: 300px; }
    .tile.tile-cave .tile-cave-no-file-text {
      display: block;
      margin-top: 16px;
      color: #8e8e93;
      font-size: 13px; }
    .tile.tile-cave.tile-multiline {
      white-space: pre-line; }
  .tile.tile-basic-info {
    margin-bottom: 10px; }
  .tile.tile-reminder {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    margin-bottom: 10px; }
    .tile.tile-reminder .reminder-button {
      flex-basis: 49%;
      padding: 0 13.5px; }
  .tile .tile-row {
    padding: 2px;
    border-radius: 10px; }
    .tile .tile-row:hover {
      background-color: #f5f7f8; }
    .tile .tile-row .tile-text {
      font-size: 15px;
      flex-grow: 1; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.patient-waiting-list-card {
  background-color: #f7f9fa;
  margin-bottom: 10px;
  box-shadow: none;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #f1f1f9; }
  .patient-waiting-list-card:hover {
    cursor: pointer; }
  .patient-waiting-list-card .event-time {
    font-size: 11px;
    letter-spacing: 0.46px;
    line-height: 10px;
    margin-left: 7px; }
  .patient-waiting-list-card .event-name {
    font-size: 11px;
    font-weight: 600;
    padding-left: 29px;
    margin-top: 5px; }
  .patient-waiting-list-card .doctor-name {
    font-size: 11px;
    padding-left: 29px; }
  .patient-waiting-list-card .event-notes {
    font-size: 11px;
    padding-left: 29px;
    color: #8e8e93; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-item-finder {
  height: 85vh; }
  .moshi-item-finder .search-box {
    padding: 19px 15px 25px 19px;
    background-color: #e6eded; }
  .moshi-item-finder .moshi-item-finder-results {
    max-height: calc(85vh - 105px);
    border-radius: 0 0 14px 14px;
    position: relative;
    overflow-y: auto; }
    .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-category,
    .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-row {
      min-height: 62px;
      border-top: 1px solid #f1f1f9;
      background-color: #fff; }
      .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-category:hover, .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-category:active, .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-category:focus,
      .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-row:hover,
      .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-row:active,
      .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-row:focus {
        cursor: pointer;
        text-decoration: none !important; }
      .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-category .disabled,
      .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-row .disabled {
        color: #5c758e; }
        .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-category .disabled:hover, .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-category .disabled:active, .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-category .disabled:focus,
        .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-row .disabled:hover,
        .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-row .disabled:active,
        .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-row .disabled:focus {
          cursor: not-allowed;
          color: #5c758e;
          text-decoration: none !important; }
      .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-category.no-hover,
      .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-row.no-hover {
        color: #171f26; }
    .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-category .moshi-item-finder-row {
      padding-left: 40px; }
    .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-category-name {
      font-weight: 400;
      font-size: 20px;
      display: flex;
      align-items: center; }
    .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-row {
      color: #0a83bf;
      font-weight: 500;
      font-size: 14px; }
    .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-row,
    .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-category-name {
      padding: 20px;
      background-color: #fff;
      outline: none !important; }
      .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-row:hover,
      .moshi-item-finder .moshi-item-finder-results .moshi-item-finder-category-name:hover {
        background-color: #f5f7f8; }

.moshi-phone-input {
  border-radius: 14px;
  border: 1px solid #f1f1f9;
  background-color: #fff;
  display: flex;
  cursor: text; }
  .moshi-phone-input input {
    font-size: 14px;
    border: 0;
    background: none;
    padding-left: 0;
    height: 30px;
    color: #000; }
    .moshi-phone-input input:focus {
      outline: none; }
  .moshi-phone-input.PhoneInput--focus {
    background-color: #fff !important;
    border: 1px solid #0a83bf !important;
    outline: 0; }
  .moshi-phone-input.disabled {
    cursor: default;
    padding-left: 4px !important; }
    .moshi-phone-input.disabled .PhoneInputCountrySelectArrow {
      display: none; }
    .moshi-phone-input.disabled input {
      margin-top: 2px; }
  .moshi-phone-input .PhoneInputCountrySelect {
    outline: none; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.patient-appointment-event-card {
  border-radius: 14px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
  max-width: 930px; }
  .patient-appointment-event-card:focus {
    outline: none; }
  .patient-appointment-event-card:hover {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.06);
    cursor: pointer; }
  .patient-appointment-event-card .event-date {
    text-align: center;
    display: inline-block;
    color: #13304d;
    margin-right: 30px; }
    .patient-appointment-event-card .event-date .day {
      font-size: 20px;
      letter-spacing: 0.75px;
      margin-bottom: 5px; }
    .patient-appointment-event-card .event-date .date {
      font-size: 11px;
      text-transform: uppercase;
      color: #406080;
      letter-spacing: 0.46px;
      line-height: 1.09; }
  .patient-appointment-event-card .details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .patient-appointment-event-card .details-container .category-indicator-container {
      margin-left: 10px; }
    .patient-appointment-event-card .details-container .duration-with-doctor {
      color: #13304d;
      margin-top: 3px; }
    .patient-appointment-event-card .details-container .event-status-tag {
      margin-top: 5px; }
  .patient-appointment-event-card .event-remove-tag {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 100; }

.moshi-toggle-tabs {
  display: flex;
  overflow: hidden; }
  .moshi-toggle-tabs .moshi-toggle-tabs-wrapper {
    display: flex;
    height: 40px;
    border-radius: 10px;
    border: solid 1px #e5e5e1;
    align-items: center; }
    .moshi-toggle-tabs .moshi-toggle-tabs-wrapper .moshi-toggle-tab {
      color: #171f26;
      font-weight: 400;
      font-size: 14px;
      padding: 0 12px;
      transition: all 0.1s ease-in-out;
      height: 24px;
      margin: 0 8px;
      border-radius: 6px;
      display: flex;
      align-items: center; }
      .moshi-toggle-tabs .moshi-toggle-tabs-wrapper .moshi-toggle-tab > span {
        line-height: 10px; }
      .moshi-toggle-tabs .moshi-toggle-tabs-wrapper .moshi-toggle-tab.active {
        background-color: #0a83bf;
        color: #fff; }
      .moshi-toggle-tabs .moshi-toggle-tabs-wrapper .moshi-toggle-tab.moshi-toggle-tab-icon {
        height: 30px;
        width: 30px;
        margin: 0 4px;
        text-align: center;
        padding: 0; }
        .moshi-toggle-tabs .moshi-toggle-tabs-wrapper .moshi-toggle-tab.moshi-toggle-tab-icon > span {
          margin: auto; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.waiting-list-container .waiting-list-filters {
  display: flex;
  max-width: 800px;
  margin: 25px auto; }
  .waiting-list-container .waiting-list-filters .search-field-inverted {
    flex-grow: 1;
    margin-right: 10px; }
  .waiting-list-container .waiting-list-filters .dropdown {
    margin-left: 10px; }

.waiting-list-event-card {
  border-radius: 14px;
  background-color: #f7f9fa;
  padding: 20px;
  position: relative;
  max-width: 800px;
  margin: 10px auto; }
  .waiting-list-event-card:focus {
    outline: none; }
  .waiting-list-event-card:hover {
    box-shadow: 0 0 30px 0 #b4c2c9;
    cursor: pointer; }
  .waiting-list-event-card .grid-wrapper {
    display: grid;
    grid-template-columns: auto max-content max-content auto 1fr max-content;
    grid-template-rows: 1fr;
    grid-template-areas: "event-title category event-time assignees event-priority add-to-calendar";
    align-items: center; }
    .waiting-list-event-card .grid-wrapper .event-title {
      grid-area: event-title;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-right: 1px solid #c4c4cc;
      padding-right: 7px;
      margin-right: 7px; }
    .waiting-list-event-card .grid-wrapper .event-time {
      grid-area: event-time;
      border-right: 1px solid #c4c4cc;
      padding-right: 7px;
      margin-right: 7px; }
    .waiting-list-event-card .grid-wrapper .category {
      grid-area: category;
      margin-right: 7px;
      border-right: 1px solid #c4c4cc;
      padding-right: 7px; }
    .waiting-list-event-card .grid-wrapper .event-priority {
      grid-area: event-priority;
      margin-right: 25px; }
    .waiting-list-event-card .grid-wrapper .add-to-calendar-btn-container {
      grid-area: add-to-calendar; }
    .waiting-list-event-card .grid-wrapper .assignees {
      grid-area: assignees;
      margin-right: 7px;
      border-right: 1px solid #c4c4cc;
      padding-right: 7px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
  .waiting-list-event-card .note {
    margin-top: 10px;
    width: 100%; }

.custom-widget-history-modal.moshi-basic-modal .modal-content .modal-body {
  padding: 15px; }

.custom-widget-history-modal .empty-state {
  padding: 10px; }

.custom-widget-history-modal .card {
  margin-bottom: 10px;
  padding: 10px 20px; }
  .custom-widget-history-modal .card .custom-widget-history-line-title {
    font-size: 14px;
    font-weight: 500; }
  .custom-widget-history-modal .card .card-body {
    margin-top: 10px; }
  .custom-widget-history-modal .card:last-child {
    margin-bottom: 0; }

.custom-widget-history-modal .fields-wrapper .widget-value {
  font-weight: 600;
  font-size: 24px;
  display: inline-block; }

.custom-widget-history-modal .fields-wrapper .measurement {
  font-weight: 600;
  margin-left: 8px;
  display: inline-block; }

.custom-widget-history-modal .fields-wrapper .custom-numeric-label-widget {
  margin-top: 15px;
  margin-bottom: 15px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.select-patient-card {
  border-radius: 14px;
  border: 1px solid #dce3e3;
  padding: 20px 50px 20px 15px;
  position: relative;
  width: 300px;
  height: 170px;
  margin: 5px;
  display: flex;
  float: left;
  overflow: hidden; }
  .select-patient-card.selected {
    background-color: #f0fafe; }
  .select-patient-card .icon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #8e8e93;
    cursor: pointer; }
  .select-patient-card .card-patient-info-container {
    max-width: 200px;
    margin-left: 20px; }
    .select-patient-card .card-patient-info-container .patient-name {
      font-weight: 600; }
    .select-patient-card .card-patient-info-container .patient-basic-info,
    .select-patient-card .card-patient-info-container .patient-address,
    .select-patient-card .card-patient-info-container .patient-doctor {
      font-size: 13px;
      margin-bottom: 10px; }
  .select-patient-card .card-button-container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column; }
    .select-patient-card .card-button-container .readonly-radio-btn {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid #dce3e3;
      background-color: #fff; }
      .select-patient-card .card-button-container .readonly-radio-btn.selected {
        border: 4px solid #0a83bf; }
  .select-patient-card:hover {
    box-shadow: 0 8px 30px 0 rgba(18, 47, 76, 0.28) !important;
    cursor: pointer;
    background-color: #f0fafe; }

.merge-patients-overlay {
  font-size: initial;
  font-weight: initial; }
  .merge-patients-overlay .merge-patients-container {
    margin-left: 10%; }
    .merge-patients-overlay .merge-patients-container .merge-patients-title {
      margin-bottom: 40px; }
    .merge-patients-overlay .merge-patients-container .merge-form-container {
      max-width: 460px;
      margin: auto;
      padding-bottom: 10%; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.select-patient-card {
  border-radius: 14px;
  border: 1px solid #dce3e3;
  padding: 20px 50px 20px 15px;
  position: relative;
  width: 300px;
  height: 170px;
  margin: 5px;
  display: flex;
  float: left;
  overflow: hidden; }
  .select-patient-card.selected {
    background-color: #f0fafe; }
  .select-patient-card .icon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #8e8e93;
    cursor: pointer; }
  .select-patient-card .card-patient-info-container {
    max-width: 200px;
    margin-left: 20px; }
    .select-patient-card .card-patient-info-container .patient-name {
      font-weight: 600; }
    .select-patient-card .card-patient-info-container .patient-basic-info,
    .select-patient-card .card-patient-info-container .patient-address,
    .select-patient-card .card-patient-info-container .patient-doctor {
      font-size: 13px;
      margin-bottom: 10px; }
  .select-patient-card .card-button-container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column; }
    .select-patient-card .card-button-container .readonly-radio-btn {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid #dce3e3;
      background-color: #fff; }
      .select-patient-card .card-button-container .readonly-radio-btn.selected {
        border: 4px solid #0a83bf; }
  .select-patient-card:hover {
    box-shadow: 0 8px 30px 0 rgba(18, 47, 76, 0.28) !important;
    cursor: pointer;
    background-color: #f0fafe; }

.merge-patients-overlay {
  font-size: initial;
  font-weight: initial; }
  .merge-patients-overlay .merge-patients-container {
    margin-left: 10%; }
    .merge-patients-overlay .merge-patients-container .merge-patients-title {
      margin-bottom: 40px; }
    .merge-patients-overlay .merge-patients-container .merge-form-container {
      max-width: 460px;
      margin: auto;
      padding-bottom: 10%; }

.bill-type-icon-container {
  display: inline-block;
  border-radius: 10px;
  padding-top: 3px;
  text-align: center;
  width: 38px;
  height: 38px; }
  .bill-type-icon-container .bill-type-icon {
    color: #fff; }
  .bill-type-icon-container.invoice {
    background-color: #4eac88; }
  .bill-type-icon-container.estimate {
    background-color: #f7c403; }
  .bill-type-icon-container.advance {
    background-color: #fb6b32; }
  .bill-type-icon-container.credit-note {
    background-color: #4e49b0; }
  .bill-type-icon-container.bill-type-cell {
    width: 23px;
    height: 23px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 5px;
    margin-right: 10px;
    padding: 0;
    vertical-align: bottom; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-vertical-radio-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  justify-content: center;
  align-items: end; }
  .moshi-vertical-radio-group label.moshi-vertical-radio {
    height: auto; }

.moshi-vertical-radio {
  height: auto; }
  .moshi-vertical-radio .radio-wrapper {
    margin-left: -18px; }

.moshi-horizontal-radio-group {
  display: flex;
  flex-direction: row; }
  .moshi-horizontal-radio-group .moshi-horizontal-radio {
    border-radius: 14px;
    height: 40px;
    border: 1px solid #e4e4ee;
    padding-left: 15px;
    margin-right: 15px; }
    .moshi-horizontal-radio-group .moshi-horizontal-radio .radio-label {
      color: #122f4c; }
    .moshi-horizontal-radio-group .moshi-horizontal-radio.selected {
      background-color: #caebfc; }

.draggable-list {
  margin-bottom: 28px;
  min-height: 50px; }
  .draggable-list .draggable-list-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 11px; }
    .draggable-list .draggable-list-title.medium-list-title {
      font-size: 16px;
      font-weight: 400; }
  .draggable-list .draggable-card {
    display: flex;
    flex-direction: row;
    padding: 14px;
    margin-bottom: 10px; }
    .draggable-list .draggable-card .draggable-handle {
      flex-grow: 0;
      font-size: 18px;
      height: 22px;
      margin-right: 20px; }
      .draggable-list .draggable-card .draggable-handle i {
        position: relative;
        top: -1px; }
    .draggable-list .draggable-card .draggable-content {
      flex-grow: 1; }
    .draggable-list .draggable-card .actions {
      flex-grow: 0;
      display: inline-flex; }

.remove-icon-button {
  padding: 0 !important;
  font-size: 12px !important; }

.remove-icon-link {
  padding: 2px;
  color: #0a83bf; }
  .remove-icon-link:hover {
    border-radius: 50%;
    background-color: #caebfc; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.event-auto-suggest-result-card {
  display: flex; }
  .event-auto-suggest-result-card .icon-container {
    display: flex;
    align-items: center;
    margin-right: 18px;
    margin-left: 10px; }
  .event-auto-suggest-result-card .details-container {
    display: flex;
    flex-direction: column;
    font-size: 12px; }
    .event-auto-suggest-result-card .details-container .date-time-location {
      font-weight: 400; }
    .event-auto-suggest-result-card .details-container .name-title {
      font-weight: 500; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.tooltip-label {
  color: #5c758e !important;
  font-weight: 500; }

.modal .reminder-modal hr {
  margin: 2rem 0; }

.modal .reminder-modal .reminder-header {
  font-size: 20px;
  font-weight: 500;
  color: #000; }

.modal .reminder-modal .reminder-radio-container label {
  font-size: 20px;
  font-weight: 500;
  text-transform: none;
  color: #000; }

.modal .reminder-modal .reminder-radio-container .reminder-radio-options {
  margin-left: 25%; }

.modal .reminder-modal .modal-footer {
  display: flex;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.event-details-popover {
  max-width: 340px;
  min-width: auto; }
  .event-details-popover .popover {
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    overflow: auto;
    min-height: 325px;
    max-height: calc(100% - 70px);
    min-width: 520px; }
    .event-details-popover .popover .popover-body {
      padding: 0;
      overflow: visible;
      max-height: none; }
      .event-details-popover .popover .popover-body .event-status-container .form-group {
        margin-bottom: 0;
        padding-left: 0; }
      .event-details-popover .popover .popover-body .moshi-fc-event-popover-content .event-notifications {
        border-top: 0;
        border-bottom: 0; }
        .event-details-popover .popover .popover-body .moshi-fc-event-popover-content .event-notifications span {
          font-weight: 400; }
      .event-details-popover .popover .popover-body .moshi-fc-event-popover-content .event-popover-actions-container {
        height: 38px; }
        .event-details-popover .popover .popover-body .moshi-fc-event-popover-content .event-popover-actions-container .icon-wrapper i {
          font-size: 20px; }
        .event-details-popover .popover .popover-body .moshi-fc-event-popover-content .event-popover-actions-container .event-popover-actions {
          position: inherit;
          display: inline-block;
          height: 20px;
          font-family: "IBM Plex Sans", "sans-serif"; }
          .event-details-popover .popover .popover-body .moshi-fc-event-popover-content .event-popover-actions-container .event-popover-actions i {
            height: revert; }
          .event-details-popover .popover .popover-body .moshi-fc-event-popover-content .event-popover-actions-container .event-popover-actions .icon-ellypsis {
            color: #171f26;
            margin: 0 5px; }
            .event-details-popover .popover .popover-body .moshi-fc-event-popover-content .event-popover-actions-container .event-popover-actions .icon-ellypsis:hover {
              color: #0a83bf; }
      .event-details-popover .popover .popover-body .moshi-fc-event-popover-content .status-container .form-group {
        padding: 0;
        margin-bottom: 0; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-rs-dropdown .moshi-rs-dropdown-toggle {
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e4e4ee;
  color: #000;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  padding: 8px 24px 8px 13px;
  min-width: 100%;
  text-align: left;
  text-transform: none;
  font-size: 14px;
  height: 42px; }
  .moshi-rs-dropdown .moshi-rs-dropdown-toggle:active {
    background-color: #fff !important;
    color: #171f26 !important;
    border: 1px solid #317dc8 !important;
    box-shadow: none; }
  .moshi-rs-dropdown .moshi-rs-dropdown-toggle:hover {
    background-color: #f7f9fa;
    border: 1px solid #317dc8 !important; }
  .moshi-rs-dropdown .moshi-rs-dropdown-toggle:focus {
    box-shadow: none !important; }
  .moshi-rs-dropdown .moshi-rs-dropdown-toggle::after {
    display: inline-block;
    position: absolute;
    right: 17px;
    top: calc(50% - 2px);
    -ms-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    padding: 3px;
    border: solid #8e8e93;
    border-width: 0 2px 2px 0; }

.moshi-rs-dropdown.show .moshi-rs-dropdown-toggle {
  background-color: #fff !important;
  color: #171f26 !important;
  border: 1px solid #317dc8 !important;
  box-shadow: none; }
  .moshi-rs-dropdown.show .moshi-rs-dropdown-toggle:focus {
    box-shadow: none !important; }
  .moshi-rs-dropdown.show .moshi-rs-dropdown-toggle::after {
    top: calc(50% + 2px);
    transform: translateY(-50%) rotate(-135deg);
    -webkit-transform: translateY(-50%) rotate(-135deg); }

.moshi-rs-dropdown .moshi-rs-dropdown-menu {
  border-radius: 14px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
  margin: 5px 0;
  padding: 0;
  overflow: hidden;
  border: 1px solid #e4e4ee;
  max-height: 300px;
  overflow-y: auto;
  min-width: 100%;
  font-size: 14px; }
  .moshi-rs-dropdown .moshi-rs-dropdown-menu .dropdown-item {
    padding: 0; }
  .moshi-rs-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item {
    font-size: 12px;
    font-weight: 400;
    height: 42px;
    text-decoration: none;
    margin-left: 0;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent; }
    .moshi-rs-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item .selected {
      position: relative;
      padding-right: 30px;
      font-weight: 600; }
      .moshi-rs-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item .selected span {
        color: #0a83bf !important; }
      .moshi-rs-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item .selected .checkmark {
        font-weight: 800;
        position: absolute;
        right: -5px;
        top: 1px;
        color: #0a83bf; }
        .moshi-rs-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item .selected .checkmark path {
          fill: #0a83bf; }
    .moshi-rs-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item > li > i:first-child {
      position: relative;
      top: 2px;
      margin-right: 7px; }
    .moshi-rs-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item:hover, .moshi-rs-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item:active {
      background-color: #f7f9fa;
      border-bottom: 1px solid #e4e4ee;
      border-top: 1px solid #e4e4ee; }
    .moshi-rs-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item:first-child {
      border-top: 1px solid transparent; }
    .moshi-rs-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item:last-child {
      border-bottom: 1px solid transparent; }
    .moshi-rs-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item:disabled {
      background-color: #ebf1f5;
      color: #7ea5b9; }
    .moshi-rs-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item.dropdown-header {
      padding-bottom: 0;
      height: 25px;
      color: #5c758e;
      font-style: italic; }
      .moshi-rs-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item.dropdown-header:hover {
        background-color: transparent;
        border-bottom: 0;
        border-top: 0; }

.rs-more-actions-container .rs-more-actions-toggle {
  cursor: pointer; }

.rs-more-actions-container .rs-more-actions-menu {
  max-height: unset !important;
  min-width: unset !important; }

.rs-more-actions-container .rs-more-actions-item {
  display: flex;
  align-items: center;
  padding: 10px 15px; }
  .rs-more-actions-container .rs-more-actions-item i {
    margin-right: 5px;
    color: inherit !important;
    font-size: 14px !important; }
  .rs-more-actions-container .rs-more-actions-item:active {
    color: #000; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mosh-rs-event-status-dropdown .moshi-rs-dropdown-toggle {
  box-shadow: 0 2px 2px 0 rgba(18, 47, 76, 0.28) !important;
  border-radius: 10px;
  border-color: #171f26;
  text-align: center; }

.mosh-rs-event-status-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item {
  padding-left: 50px; }
  .mosh-rs-event-status-dropdown .moshi-rs-dropdown-menu .moshi-rs-dropdown-item .status-indicator {
    margin-top: 1px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.calendar-search-overlay-container {
  font-size: 14px;
  color: #000;
  font-weight: initial; }
  .calendar-search-overlay-container .overlay-content {
    display: grid !important;
    grid-template-columns: auto max-content; }
  .calendar-search-overlay-container .overlay-header {
    justify-content: space-between; }
    .calendar-search-overlay-container .overlay-header .calendar-search {
      width: 645px;
      margin-left: 50px; }
  .calendar-search-overlay-container .overlay-content-container .overlay-content {
    max-width: 100%;
    min-width: 1400px; }
    .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container {
      padding: 20px 50px 20px 75px; }
      .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .results-text {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 17px; }
      .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container {
        max-width: 100%; }
        .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group {
          padding: 18px 7px;
          border-top: 1px solid #e4e4ee;
          display: flex; }
          .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .date-container {
            min-width: 140px; }
            .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .date-container .day-number {
              width: 34px;
              height: 34px;
              border-radius: 50%;
              line-height: 34px;
              text-align: center;
              font-size: 18px;
              font-weight: 500;
              display: inline-block;
              margin-right: 10px;
              align-self: center; }
              .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .date-container .day-number.today {
                background-color: #0a83bf;
                color: #fff; }
            .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .date-container .month-and-date {
              text-transform: uppercase;
              font-size: 11px;
              font-weight: 500;
              align-self: center;
              margin-top: 1px; }
            .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .date-container:hover {
              cursor: pointer; }
              .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .date-container:hover .day-number {
                background-color: #caebfc; }
                .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .date-container:hover .day-number.today {
                  background-color: #0a83bf;
                  opacity: 0.7; }
          .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list {
            flex: 1; }
            .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container {
              border-radius: 14px;
              height: 37px;
              padding: 0 11px;
              display: grid;
              grid-template-columns: max-content max-content 1fr 1fr max-content max-content;
              grid-template-rows: 1fr;
              grid-template-areas: "category-indicator from-to-time event-title duration-with-doctor status-and-upcoming-container more-actions-container";
              align-items: center; }
              .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container .category-indicator {
                grid-area: category-indicator;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                display: inline-block; }
              .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container .from-to-time {
                grid-area: from-to-time;
                margin-right: 28px; }
              .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container .event-title {
                grid-area: event-title;
                font-weight: 500;
                margin-right: 28px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }
              .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container .duration-with-doctor {
                grid-area: duration-with-doctor;
                color: #5c758e;
                margin-right: 30px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap; }
              .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container .status-and-upcoming-container {
                grid-area: status-and-upcoming-container;
                display: flex;
                justify-content: center;
                margin-right: 10px; }
                .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container .status-and-upcoming-container .upcoming-passed,
                .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container .status-and-upcoming-container .event-status-tag {
                  margin-left: 15px;
                  margin-right: 15px; }
                .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container .status-and-upcoming-container .tag-container {
                  min-width: 130px;
                  display: flex;
                  justify-content: center; }
              .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container .more-actions-container {
                grid-area: more-actions-container; }
                .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container .more-actions-container .event-search-result-actions {
                  position: relative;
                  right: auto;
                  top: 3px; }
                  .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container .more-actions-container .event-search-result-actions .card-more-actions-icon {
                    color: #000; }
                    .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container .more-actions-container .event-search-result-actions .card-more-actions-icon:hover {
                      color: #0a83bf; }
              .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-list-container .search-results-container .day-group .events-list .search-result-container:hover {
                box-shadow: 0 8px 30px 0 rgba(18, 47, 76, 0.3);
                cursor: pointer; }
    .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-filters-container {
      min-width: 400px;
      padding: 20px 30px;
      border-left: 1px solid #e6eded; }
      .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-filters-container h3 {
        margin-bottom: 30px; }
      .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-filters-container .filters-container {
        display: block; }
        .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-filters-container .filters-container .calendar-search-filter-container {
          margin-bottom: 30px; }
          .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-filters-container .filters-container .calendar-search-filter-container .list-title {
            font-size: 12px;
            margin-bottom: 14px;
            text-transform: uppercase;
            letter-spacing: 0.46px;
            color: #7ea5b9; }
          .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-filters-container .filters-container .calendar-search-filter-container .checkbox-list {
            margin-bottom: 15px; }
            .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-filters-container .filters-container .calendar-search-filter-container .checkbox-list .checkbox-label {
              color: #000;
              text-transform: none;
              font-size: 15px;
              font-weight: 400; }
          .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-filters-container .filters-container .calendar-search-filter-container .select-text {
            font-size: 15px;
            color: #0a83bf; }
            .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-filters-container .filters-container .calendar-search-filter-container .select-text:hover {
              cursor: pointer;
              color: #0ca3ed; }
          .calendar-search-overlay-container .overlay-content-container .overlay-content .calendar-search-filters-container .filters-container .calendar-search-filter-container .calendar-search-filter-datepicker .moshi-date-range-popup-calendar {
            right: 15px; }

.sidebar-icon-relative-position {
  position: relative; }

.delete-animation {
  opacity: 0; }

.bounce-in {
  transform-origin: center center;
  animation-name: bounce-in;
  animation-duration: 0.2s; }

.magicbell-popover.moshi-popover .arrow::before {
  border-right-color: transparent; }

.magicbell-popover.moshi-popover .arrow::after {
  border-bottom-color: transparent;
  border-right-color: #fff; }

.magicbell-popover.moshi-popover .popover-body {
  background-color: #fff;
  border-radius: 12px;
  padding: 0; }
  .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container {
    height: 415px; }
    .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-inbox-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 10px 25px 10px 20px; }
      .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-inbox-header .magicbell-inbox-title {
        cursor: default;
        font-size: 18px; }
      .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-inbox-header .magicbell-inbox-mark-all-read {
        font-size: 14px;
        color: #0a83bf;
        font-weight: 400; }
        .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-inbox-header .magicbell-inbox-mark-all-read:hover {
          color: #07608d; }
    .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .infinite-scroll-component {
      scrollbar-width: none;
      -ms-overflow-style: none; }
      .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .infinite-scroll-component::-webkit-scrollbar {
        width: 0;
        height: 0; }
    .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-notification-container {
      display: flex;
      justify-content: space-between;
      margin: 0 5px 5px;
      padding: 0 5px;
      border: transparent 1px solid;
      border-radius: 10px;
      transition: all 0.4s ease-out; }
      .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-notification-container.unread {
        background-color: #f5f7f8;
        border-color: #e4e4ee; }
      .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-notification-container:hover {
        background-color: #ebf1f5;
        border-color: #e4e4ee; }
      .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-notification-container .magicbell-notification {
        display: flex;
        flex-direction: column;
        min-height: 50px;
        padding: 10px;
        width: 360px; }
        .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-notification-container .magicbell-notification .magicbell-notification-title {
          font-size: 15px;
          margin-bottom: 5px; }
        .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-notification-container .magicbell-notification .magicbell-notification-content {
          font-size: 14px;
          color: #5c758e;
          line-height: 1.4; }
      .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-notification-container .magicbell-notification-sent-at {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 30px;
        font-size: 14px; }
      .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-notification-container .magicbell-notification-actions-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px; }
        .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-notification-container .magicbell-notification-actions-container .magicbell-notification-actions {
          position: relative; }
          .magicbell-popover.moshi-popover .popover-body .magicbell-inbox-container .magicbell-notification-container .magicbell-notification-actions-container .magicbell-notification-actions > div {
            right: 0;
            top: -7px; }

@keyframes bounce-out {
  0% {
    transform: scale(1, 1); }
  50% {
    transform: scale(1.4, 1.4); }
  100% {
    transform: scale(1, 1); } }

@keyframes bounce-in {
  0% {
    transform: scale(1, 1); }
  50% {
    transform: scale(0.98, 0.98); }
  100% {
    transform: scale(1, 1); } }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.checkbox-list .filter-checkbox-row .checkbox-wrapper input[type="checkbox"] {
  display: none; }

.checkbox-list .filter-checkbox-row .checkbox-label {
  font-size: 15px; }
  .checkbox-list .filter-checkbox-row .checkbox-label:hover {
    color: #000 !important; }

.checkbox-list .filter-checkbox-row .moshi-tag:hover {
  box-shadow: 0 4px 15px 0 #f1f1f9; }

.checkbox-list .filter-checkbox-row .checkbox-row-icon {
  color: #5c758e;
  opacity: 0.8;
  visibility: hidden;
  margin-right: 3px; }
  .checkbox-list .filter-checkbox-row .checkbox-row-icon:hover {
    cursor: pointer;
    color: #0a83bf; }

.checkbox-list .filter-checkbox-row:hover {
  background-color: #f7f9fa; }
  .checkbox-list .filter-checkbox-row:hover .checkbox-row-icon {
    visibility: visible; }

.patient-notes-tile-container .patient-notes-form-card {
  margin-bottom: 0; }
  .patient-notes-tile-container .patient-notes-form-card form {
    display: flex;
    align-items: flex-end; }
    .patient-notes-tile-container .patient-notes-form-card form .form-group {
      margin: 0; }
    .patient-notes-tile-container .patient-notes-form-card form .note-field .ck-content {
      transition: min-height 0.1s ease-out; }
    .patient-notes-tile-container .patient-notes-form-card form .note-field.filled-field:not(.hide-toolbar) .ck-sticky-panel {
      display: none; }
    .patient-notes-tile-container .patient-notes-form-card form .note-field.filled-field:not(.hide-toolbar) .ck-content:not(.ck-focused) {
      min-height: 42px;
      border-radius: 10px !important; }
    .patient-notes-tile-container .patient-notes-form-card form .moshi-button {
      min-width: auto;
      height: 48px; }

.patient-notes-tile-container .patient-note-card {
  border: 1px solid #e4e4ee; }
  .patient-notes-tile-container .patient-note-card.pinned {
    background-color: #fffbeb; }
  .patient-notes-tile-container .patient-note-card .patient-note-header {
    font-size: 12px;
    color: #406080;
    min-height: 19px; }
    .patient-notes-tile-container .patient-note-card .patient-note-header .note-action-buttons .pin {
      height: 16px;
      width: 16px;
      vertical-align: text-top; }
    .patient-notes-tile-container .patient-note-card .patient-note-header .note-action-buttons .card-more-actions .card-more-actions-icon {
      color: #406080; }
  .patient-notes-tile-container .patient-note-card form {
    margin: 0 -5px; }
    .patient-notes-tile-container .patient-note-card form .note-field {
      margin-top: -2px; }
      .patient-notes-tile-container .patient-note-card form .note-field.disabled .form-group {
        margin-bottom: 0; }
      .patient-notes-tile-container .patient-note-card form .note-field.disabled .ck-sticky-panel {
        display: none; }
      .patient-notes-tile-container .patient-note-card form .note-field.disabled .ck-content {
        background-color: transparent;
        min-height: 42px;
        padding: 0;
        border: 0; }

.patient-notes-tile-container .note-field.focused .ck-toolbar {
  border: 1px solid #0a83bf;
  border-bottom-color: #c4c4cc !important; }

.patient-notes-tile-container .note-field.focused .ck-content {
  border: 1px solid #0a83bf !important;
  border-top-color: #c4c4cc !important; }

.patient-notes-tile-container .ck-toolbar {
  border-radius: 10px 10px 0 0 !important; }

.patient-notes-tile-container .ck-content {
  border-radius: 0 0 10px 10px !important;
  font-size: 15px; }
  .patient-notes-tile-container .ck-content .ck-placeholder {
    font-size: 14px; }

.sidebarCollapsibleTitle, .sidebarCollapsibleSubtitle, .sidebarCollapsibleItem {
  padding-left: 6px; }

.sidebarCollapsible {
  margin: 20px 0; }

.sidebarCollapsibleTitle {
  font-size: 18px;
  color: #000;
  letter-spacing: 0.46px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px; }
  .sidebarCollapsibleTitle:hover {
    background-color: #f7f9fa; }

.sidebarCollapsibleSubtitle {
  font-style: italic;
  color: #8e8e93;
  margin-top: 5px;
  margin-bottom: 6px; }

.sidebarCollapsibleItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  border-radius: 14px; }
  .sidebarCollapsibleItem:hover {
    background-color: #f7f9fa; }
    .sidebarCollapsibleItem:hover i {
      visibility: visible; }

.sidebarCollapsibleItemIcon {
  color: #5c758e;
  opacity: 0.8;
  visibility: hidden;
  margin-right: 9px; }
  .sidebarCollapsibleItemIcon:hover {
    cursor: pointer;
    color: #0a83bf; }

.sidebarCollapsibleItemLabel {
  font-size: 15px;
  margin-bottom: 0; }
  .sidebarCollapsibleItemLabel:hover {
    color: #000 !important; }

.form-builder .react-date-picker {
  border-width: 0; }

.form-builder .metadata {
  margin-bottom: 25px; }
  .form-builder .metadata label {
    font-size: 14px;
    font-weight: 500;
    text-transform: none; }

.form-builder .navigation :first-child {
  margin-right: 10px; }

.form-builder .navigation :not(.active) {
  color: #13304d; }

.form-builder .card {
  background-color: #f1f1f9;
  padding: 15px;
  border: 2px solid transparent;
  box-shadow: none; }
  .form-builder .card .card {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.06);
    background-color: #fff; }
  .form-builder .card .title .field-type-label.form-group-label {
    margin-bottom: 9px; }
  .form-builder .card .field-title-row {
    height: 40px;
    margin-bottom: 5px; }
  .form-builder .card .field-type {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .form-builder .card .field-type label {
      white-space: nowrap; }
  .form-builder .card .field-type-label.form-group-label {
    margin: 0;
    font-size: 14px;
    height: 14px;
    font-weight: 600;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center; }
  .form-builder .card.field-group {
    margin-top: 20px; }
  .form-builder .card.highlight {
    animation: highlight-fade 1.5s ease-in-out 0s; }
  .form-builder .card.highlight-odd {
    animation: highlight-fade-odd 1.5s ease-in-out 0s; }
  .form-builder .card input {
    font-weight: 500; }
    .form-builder .card input.title-input {
      padding: 20px;
      height: 60px;
      font-size: 20px;
      font-weight: 600; }
  .form-builder .card .questionnaire-list-actions {
    padding-left: 20px;
    align-items: center; }
    .form-builder .card .questionnaire-list-actions .btn-link,
    .form-builder .card .questionnaire-list-actions .btn-link-danger {
      padding: 0 10px;
      font-size: 12px;
      min-width: 70px;
      width: auto; }
    .form-builder .card .questionnaire-list-actions svg.move-down {
      margin-left: -10px; }
    .form-builder .card .questionnaire-list-actions svg.remove {
      margin-left: 20px; }
    .form-builder .card .questionnaire-list-actions .checkbox input ~ .checkbox-icon {
      background-color: #fff;
      border: 1px solid #8e8e93; }
    .form-builder .card .questionnaire-list-actions .checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf;
      border: 0; }
  .form-builder .card .disabled {
    color: #bababa !important;
    background-color: transparent !important;
    border-color: #f1f1f9 !important; }
  .form-builder .card > div:not(.field-title-row) .btn.btn-link {
    text-transform: none; }
  .form-builder .card .option {
    margin: 10px 0; }
    .form-builder .card .option .questionnaire-list-actions {
      padding-left: 0; }
    .form-builder .card .option .option-icon {
      height: 20px;
      width: 20px;
      border: 2px solid #dce3e3;
      background-color: #e6eded; }
      .form-builder .card .option .option-icon.circle {
        border-radius: 26px; }
    .form-builder .card .option .conditional {
      margin: 10px 0; }
      .form-builder .card .option .conditional .disabled {
        margin-top: 0; }
  .form-builder .card .option-number {
    text-align: center;
    line-height: 1.79;
    padding-top: 7px; }

.external-documents .overlay-header.external {
  background-color: #f7c9c0; }
  .external-documents .overlay-header.external .external-document-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 24px;
    font-weight: 400; }
    .external-documents .overlay-header.external .external-document-header .external-document-icon {
      background-color: #fb6b32;
      color: #fff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      margin-right: 15px;
      text-align: center;
      width: 38px;
      height: 38px; }
      .external-documents .overlay-header.external .external-document-header .external-document-icon .pdf {
        font-size: 14px; }

.external-documents .external-documents-right-side {
  height: 100%; }
  .external-documents .external-documents-right-side .external-document-edit-form .document-form-field {
    padding: 15px 15px 0; }
    .external-documents .external-documents-right-side .external-document-edit-form .document-form-field .form-group-label {
      color: #0a83bf;
      font-size: 11px;
      font-weight: 600; }

.view-image-modal {
  max-width: unset; }
  .view-image-modal .modal-header {
    display: none; }
  .view-image-modal .modal-content {
    justify-content: center;
    align-items: center;
    background: transparent;
    border: unset; }
    .view-image-modal .modal-content img {
      max-width: 90vw;
      max-height: 90vh;
      transition: all 0.2s ease-in-out;
      cursor: zoom-in;
      object-fit: contain; }
      .view-image-modal .modal-content img.zoom {
        transform: scale(2);
        cursor: zoom-out; }

.overlay-content-container {
  display: flex;
  justify-content: space-between; }
  .overlay-content-container .overlay-documents-left {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    overflow: auto;
    border-right: 1px solid #e6eded; }
    .overlay-content-container .overlay-documents-left .view-pdf-container .view-pdf-page {
      margin-bottom: 100px; }
    .overlay-content-container .overlay-documents-left .view-dicom-container {
      border-radius: 14px;
      display: flex;
      width: 80%;
      height: 80%;
      object-fit: contain;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .overlay-content-container .overlay-documents-left .view-dicom-container .view-dicom-toolbar {
        margin: 10px 0; }
      .overlay-content-container .overlay-documents-left .view-dicom-container .viewport-wrapper {
        object-fit: contain;
        display: block;
        margin-left: auto;
        margin-right: auto; }
    .overlay-content-container .overlay-documents-left .view-image-container {
      border-radius: 14px;
      display: flex;
      width: 900px;
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
      justify-content: center;
      align-items: center; }
      .overlay-content-container .overlay-documents-left .view-image-container img {
        object-fit: contain;
        cursor: zoom-in;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: 100%; }
    .overlay-content-container .overlay-documents-left .view-xlsx-container {
      box-shadow: 0 8px 30px 0 rgba(18, 47, 76, 0.3);
      min-width: 700px;
      margin-bottom: 100px;
      padding: 40px; }
      .overlay-content-container .overlay-documents-left .view-xlsx-container table {
        width: 100%; }
      .overlay-content-container .overlay-documents-left .view-xlsx-container td {
        min-width: 90px;
        text-align: center;
        border: 1px solid #c4c4cc;
        height: 17px; }
        .overlay-content-container .overlay-documents-left .view-xlsx-container td:hover {
          border: 1px double #317dc8;
          background-color: #d2e3f4; }
    .overlay-content-container .overlay-documents-left .view-docx-container {
      box-shadow: 0 8px 30px 0 rgba(18, 47, 76, 0.3);
      min-width: 700px;
      max-width: 900px;
      margin-bottom: 100px;
      padding: 40px; }
      .overlay-content-container .overlay-documents-left .view-docx-container .view-docx {
        overflow: hidden; }
        .overlay-content-container .overlay-documents-left .view-docx-container .view-docx h1,
        .overlay-content-container .overlay-documents-left .view-docx-container .view-docx h2,
        .overlay-content-container .overlay-documents-left .view-docx-container .view-docx h3,
        .overlay-content-container .overlay-documents-left .view-docx-container .view-docx h4,
        .overlay-content-container .overlay-documents-left .view-docx-container .view-docx h5 {
          height: auto; }
        .overlay-content-container .overlay-documents-left .view-docx-container .view-docx td {
          min-width: 90px;
          text-align: center;
          border: 1px solid #c4c4cc;
          height: 17px; }
        .overlay-content-container .overlay-documents-left .view-docx-container .view-docx img {
          max-height: 350px; }
    .overlay-content-container .overlay-documents-left .action-buttons-container {
      position: fixed;
      bottom: 0;
      left: 0;
      width: calc(100% - 440px);
      height: 250px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      transition: all 200ms ease-out;
      opacity: 0; }
      .overlay-content-container .overlay-documents-left .action-buttons-container:hover {
        opacity: 1; }
      .overlay-content-container .overlay-documents-left .action-buttons-container .action-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        height: 100px; }
        .overlay-content-container .overlay-documents-left .action-buttons-container .action-buttons .action-group {
          justify-content: center;
          display: flex;
          padding: 10px;
          color: #dce3e3;
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.75); }
        .overlay-content-container .overlay-documents-left .action-buttons-container .action-buttons i.disabled {
          color: #8e8e93;
          cursor: not-allowed; }
  .overlay-content-container .external-documents-right-side .overlay-right-side-container {
    height: 100%; }
    .overlay-content-container .external-documents-right-side .overlay-right-side-container .document-overlay-description {
      padding: 15px;
      border-bottom: 1px solid #f1f1f9; }
      .overlay-content-container .external-documents-right-side .overlay-right-side-container .document-overlay-description .title {
        text-transform: uppercase;
        color: #0a83bf;
        font-size: 11px;
        font-weight: 600; }
      .overlay-content-container .external-documents-right-side .overlay-right-side-container .document-overlay-description .content {
        margin-bottom: 3px;
        margin-top: 15px;
        display: block; }

.document-editor {
  display: flex;
  flex-flow: column nowrap;
  min-width: calc(210mm + 40px);
  /* sass-lint:disable class-name-format */ }
  .document-editor .document-editor-editable-container {
    background-color: #c4c4cc; }
    .document-editor .document-editor-editable-container.header .ck-content, .document-editor .document-editor-editable-container.footer .ck-content {
      width: auto;
      min-height: 30mm;
      box-sizing: border-box;
      margin: 0;
      padding: 0 20px; }
    .document-editor .document-editor-editable-container .ck-content {
      /* A4 size */
      width: calc(210mm + 2px);
      padding: 20mm 12mm;
      box-sizing: border-box;
      min-height: 297mm;
      border: 1px solid #b4c2c9;
      background: #fff;
      margin: 40px auto;
      overflow: hidden; }
  .document-editor .ck-dropdown .smartcode-dropdown ~ .ck-dropdown__panel {
    max-height: 300px;
    overflow: auto; }

.tags-popover .popover {
  min-width: unset;
  max-width: unset;
  max-height: unset; }
  .tags-popover .popover .popover-body {
    padding: 0;
    border-radius: 10px;
    overflow: visible;
    max-height: unset;
    background-color: #fff; }

/* PAGES */
.wrapper-aside-banner {
  background-color: #ebf1f5;
  flex: 1; }

.container-aside-banner {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-top: 20px;
  height: 100%;
  overflow-y: auto;
  max-width: 1680px;
  margin: 0 auto; }
  .container-aside-banner .main-aside {
    flex-grow: 1;
    flex-direction: column;
    margin: 0 20px; }
    @media (min-width: 768px) {
      .container-aside-banner .main-aside {
        margin: 40px 100px 0;
        max-width: 400px; } }
    .container-aside-banner .main-aside .logo {
      max-width: 250px; }
      .container-aside-banner .main-aside .logo img {
        display: block;
        max-width: 100%; }
    .container-aside-banner .main-aside .form-main-aside {
      max-width: 340px; }
      .container-aside-banner .main-aside .form-main-aside .btn {
        margin: 0; }
      .container-aside-banner .main-aside .form-main-aside .form-group {
        padding: 0;
        margin-bottom: 25px; }
        .container-aside-banner .main-aside .form-main-aside .form-group:last-of-type {
          margin-bottom: 54px; }
        .container-aside-banner .main-aside .form-main-aside .form-group input {
          background-color: #fff; }
          .container-aside-banner .main-aside .form-main-aside .form-group input::placeholder {
            color: #8e8e93; }
          .container-aside-banner .main-aside .form-main-aside .form-group input:hover {
            background-color: #fff !important;
            border-color: #f1f1f9 !important; }
    .container-aside-banner .main-aside .greeting-main-aside {
      margin-top: 89px;
      margin-bottom: 66px;
      width: 100%;
      text-align: left; }
      .container-aside-banner .main-aside .greeting-main-aside h2 {
        margin-bottom: 15px;
        height: auto;
        padding-bottom: 0;
        letter-spacing: normal; }
      .container-aside-banner .main-aside .greeting-main-aside span.title {
        font-size: 28px;
        font-weight: 600; }
      .container-aside-banner .main-aside .greeting-main-aside span.text {
        font-size: 15px; }
    .container-aside-banner .main-aside .footer-main-aside {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin: 50px 0 20px; }
      .container-aside-banner .main-aside .footer-main-aside .footer-link {
        margin-bottom: 10px; }
        .container-aside-banner .main-aside .footer-main-aside .footer-link span {
          margin-right: 10px; }
      .container-aside-banner .main-aside .footer-main-aside a {
        margin-top: 10px;
        margin-bottom: 10px; }
  .container-aside-banner .banner-aside {
    display: none;
    align-items: center; }
    @media (min-width: 1000px) {
      .container-aside-banner .banner-aside {
        display: flex;
        flex-grow: 1;
        align-items: stretch;
        justify-content: stretch;
        background: url("/img/login/login-aside.svg") no-repeat 50% 5%;
        background-clip: border-box;
        background-size: contain;
        height: 60%;
        margin-top: 40px;
        margin-right: 100px;
        max-height: 75%; } }

.app-version {
  color: #c4c4cc; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

nav.sidebar {
  z-index: 2;
  height: 100%;
  position: fixed;
  transition: all 0.3s;
  width: 250px;
  background-color: rgba(255, 255, 255, 0.73);
  padding: 0 10px 20px;
  top: 0;
  bottom: 0;
  overflow: auto;
  box-shadow: inset -2px 0 0 0 #f1f1f9; }
  nav.sidebar > hr {
    width: 33%;
    border-color: #f1f1f9;
    margin: 16px 0 16px -10px; }
  nav.sidebar div,
  nav.sidebar a {
    outline: none; }
    nav.sidebar div.navigation,
    nav.sidebar a.navigation {
      border-radius: 14px;
      color: #171f26;
      line-height: 40px;
      padding: 0 10px;
      display: block;
      text-decoration: none;
      position: relative;
      white-space: nowrap; }
      nav.sidebar div.navigation svg g,
      nav.sidebar div.navigation svg circle,
      nav.sidebar div.navigation svg path,
      nav.sidebar a.navigation svg g,
      nav.sidebar a.navigation svg circle,
      nav.sidebar a.navigation svg path {
        fill: #171f26; }
      nav.sidebar div.navigation span,
      nav.sidebar a.navigation span {
        vertical-align: middle;
        height: 30px; }
      nav.sidebar div.navigation svg,
      nav.sidebar a.navigation svg {
        width: 30px;
        margin-top: -3px; }
      nav.sidebar div.navigation > .circle,
      nav.sidebar a.navigation > .circle {
        top: 17px;
        left: 7px;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        background-color: #13304d; }
      nav.sidebar div.navigation.navigation-no-hover,
      nav.sidebar a.navigation.navigation-no-hover {
        padding: 0 17px;
        display: flex;
        align-items: center;
        min-height: 20px;
        max-height: 20px;
        transition: all 0.3s; }
        nav.sidebar div.navigation.navigation-no-hover .location-name,
        nav.sidebar a.navigation.navigation-no-hover .location-name {
          display: flex;
          min-height: 15px;
          max-width: 200px; }
          nav.sidebar div.navigation.navigation-no-hover .location-name .dropdown-toggle-label,
          nav.sidebar a.navigation.navigation-no-hover .location-name .dropdown-toggle-label {
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
            nav.sidebar div.navigation.navigation-no-hover .location-name .dropdown-toggle-label .dropdown-value,
            nav.sidebar a.navigation.navigation-no-hover .location-name .dropdown-toggle-label .dropdown-value {
              color: #13304d;
              letter-spacing: 0.46px; }
        nav.sidebar div.navigation.navigation-no-hover .title,
        nav.sidebar a.navigation.navigation-no-hover .title {
          color: #13304d;
          font-size: 12px;
          font-weight: 500;
          height: 11px;
          min-height: 11px;
          text-transform: uppercase;
          line-height: 1;
          margin-bottom: 9px; }
      nav.sidebar div.navigation.active,
      nav.sidebar a.navigation.active {
        background-color: #ebf1f5; }
      nav.sidebar div.navigation:hover,
      nav.sidebar a.navigation:hover {
        background-color: #f7f9fa; }
  nav.sidebar #locations-dropdown .dropdown-toggle-label {
    min-height: 15px; }
  nav.sidebar #locations-dropdown .dropdown-value {
    text-transform: initial;
    color: #0a83bf;
    letter-spacing: normal;
    font-size: 14px;
    font-weight: 600; }
  nav.sidebar #locations-dropdown .dropdown-caret i::before {
    border-top-color: #0a83bf; }
  nav.sidebar #locations-dropdown .dropdown-caret i::after {
    border-top-color: #0a83bf; }
  nav.sidebar #locations-dropdown .dropdown-caret .open::before {
    border-top-color: transparent;
    border-bottom-color: #0a83bf; }
  nav.sidebar #locations-dropdown .dropdown-caret .open::after {
    border-top-color: transparent;
    border-bottom-color: #0a83bf; }
  nav.sidebar .sub-navigation {
    border-radius: 14px; }
    nav.sidebar .sub-navigation .navigation-parent {
      border-radius: 14px;
      color: #171f26;
      line-height: 40px;
      padding: 0 0 0 10px;
      display: block;
      font-weight: 500;
      text-decoration: none;
      margin: auto;
      color: #171f26; }
      nav.sidebar .sub-navigation .navigation-parent svg g,
      nav.sidebar .sub-navigation .navigation-parent svg circle,
      nav.sidebar .sub-navigation .navigation-parent svg path {
        fill: #171f26; }
      nav.sidebar .sub-navigation .navigation-parent span {
        vertical-align: middle; }
      nav.sidebar .sub-navigation .navigation-parent:hover {
        background-color: #f7f9fa; }
      nav.sidebar .sub-navigation .navigation-parent svg {
        width: 30px;
        margin-top: -3px; }
    nav.sidebar .sub-navigation .navigation {
      margin: 0 9px;
      font-weight: 400 !important; }
      nav.sidebar .sub-navigation .navigation.active {
        background-color: #fff; }
    nav.sidebar .sub-navigation.active {
      background-color: #ebf1f5;
      padding-bottom: 10px; }
      nav.sidebar .sub-navigation.active .navigation-parent {
        margin-bottom: 10px; }
        nav.sidebar .sub-navigation.active .navigation-parent:hover {
          background-color: transparent; }
  nav.sidebar .collapse-button {
    background-color: #f1f1f9;
    position: absolute;
    right: 0;
    top: 32px;
    width: 21px;
    height: 21px;
    text-align: center;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    padding-top: 1px;
    padding-left: 3px; }
    nav.sidebar .collapse-button i {
      font-size: 14px;
      margin-right: 2px; }
    nav.sidebar .collapse-button:hover {
      color: #0a83bf;
      cursor: pointer; }
  nav.sidebar .sidebar-link-text {
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s;
    opacity: 1;
    white-space: nowrap; }
  nav.sidebar .logo {
    transition: all 0.3s; }

nav.collapsed {
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 70px;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 1; }
  nav.collapsed::-webkit-scrollbar {
    width: 0;
    height: 0; }
  nav.collapsed .locations-dropdown {
    width: 75px; }
  nav.collapsed .sidebar-link-text {
    display: none; }
  nav.collapsed .sidebar-icon {
    height: 19px; }
  nav.collapsed .navigation {
    padding: 0 9px !important;
    width: 50px; }
    nav.collapsed .navigation.navigation-no-hover {
      padding: 0 !important;
      border-radius: 0 !important; }
      nav.collapsed .navigation.navigation-no-hover .location-name .dropdown-toggle-label {
        max-width: 50px !important; }
  nav.collapsed .app-version {
    opacity: 0; }
  nav.collapsed hr {
    width: 100%; }
  nav.collapsed .logo {
    margin-left: 5px; }
    @media screen and (max-height: 720px) {
      nav.collapsed .logo {
        margin-bottom: 0; } }
  nav.collapsed .sub-navigation .navigation {
    text-align: center;
    width: 80%;
    margin: auto;
    text-transform: lowercase; }

nav.content-sidebar {
  width: 220px;
  min-width: 220px;
  margin-left: -20px;
  position: fixed;
  background-color: transparent;
  padding: 42px 10px 0 20px;
  top: 0;
  bottom: 0;
  overflow: auto; }
  nav.content-sidebar header {
    padding-top: 30px; }
    nav.content-sidebar header .back:focus {
      outline: none; }
  nav.content-sidebar nav {
    margin: 29px 0 0; }
    nav.content-sidebar nav a {
      outline: none; }
      nav.content-sidebar nav a.navigation {
        border-radius: 14px;
        color: #171f26;
        line-height: 40px;
        padding: 0 0 0 10px;
        display: block;
        font-weight: 500;
        text-decoration: none; }
        nav.content-sidebar nav a.navigation svg g,
        nav.content-sidebar nav a.navigation svg circle,
        nav.content-sidebar nav a.navigation svg path {
          fill: #171f26; }
        nav.content-sidebar nav a.navigation span {
          vertical-align: middle; }
        nav.content-sidebar nav a.navigation.active {
          background-color: #fff; }
        nav.content-sidebar nav a.navigation:hover {
          background-color: #f7f9fa; }
  nav.content-sidebar ul {
    list-style: none;
    padding: 0; }
    nav.content-sidebar ul li svg {
      margin-top: -3px; }
  nav.content-sidebar + .custom-container {
    padding-right: 10px; }
  nav.content-sidebar hr {
    width: 33%;
    border-color: #f1f1f9;
    margin: 16px 0 16px -20px;
    transition: all 0.3s; }
  nav.content-sidebar .title {
    color: #13304d;
    font-size: 12px;
    font-weight: 500;
    height: 11px;
    min-height: 11px;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 9px;
    margin-top: 30px; }

nav .numeric-badge {
  color: #fff;
  background-color: #e55639;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  padding-top: 1px;
  top: -3px;
  right: -3px;
  font-size: 9px;
  width: 12px;
  height: 12px;
  transform-origin: center center;
  animation-name: bounce-out;
  animation-duration: 0.5s; }
  nav .numeric-badge.font-7 {
    font-size: 7px; }
  nav .numeric-badge.font-9 {
    font-size: 9px; }

.active-encounters-popover.moshi-popover .popover {
  top: -5px !important; }

.active-encounters-popover.moshi-popover .arrow {
  top: 5px !important; }
  .active-encounters-popover.moshi-popover .arrow::before {
    border-right-color: transparent; }
  .active-encounters-popover.moshi-popover .arrow::after {
    border-bottom-color: transparent;
    border-right-color: #fff; }

.active-encounters-popover.moshi-popover .popover-body {
  background-color: #fff;
  border-radius: 15px;
  padding: 0; }
  .active-encounters-popover.moshi-popover .popover-body .active-encounters-container {
    background-color: #fff; }
    .active-encounters-popover.moshi-popover .popover-body .active-encounters-container .active-encounters-header {
      padding: 10px 0 10px 15px;
      font-size: 18px; }
    .active-encounters-popover.moshi-popover .popover-body .active-encounters-container .patient-name {
      font-weight: 700;
      font-size: 14px; }
    .active-encounters-popover.moshi-popover .popover-body .active-encounters-container .check-in-at {
      font-weight: 600;
      font-size: 13px; }
    .active-encounters-popover.moshi-popover .popover-body .active-encounters-container .active-encounters-body {
      scrollbar-width: none;
      -ms-overflow-style: none;
      background-color: #fff !important;
      margin-bottom: 10px;
      padding: 0 5px;
      overflow: auto;
      max-height: 380px; }
      .active-encounters-popover.moshi-popover .popover-body .active-encounters-container .active-encounters-body::-webkit-scrollbar {
        width: 0;
        height: 0; }
      .active-encounters-popover.moshi-popover .popover-body .active-encounters-container .active-encounters-body .card {
        margin-bottom: 5px;
        box-shadow: none !important;
        padding: 10px !important;
        transition: all 0.4s ease-out; }
        .active-encounters-popover.moshi-popover .popover-body .active-encounters-container .active-encounters-body .card:hover {
          background-color: #ebf1f5; }
        .active-encounters-popover.moshi-popover .popover-body .active-encounters-container .active-encounters-body .card .doctor-circle-icon {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          margin-right: 5px;
          display: inline-block; }

.active-encounters-popover.moshi-popover .active-encounter .icon-chevron-right {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -9px;
  font-size: 18px; }

.sidebar-icon {
  margin: 0 7px;
  font-size: 18px;
  vertical-align: middle;
  padding-bottom: 1px; }

.sidebar-support-modal .modal-body {
  display: flex;
  justify-content: center; }
  .sidebar-support-modal .modal-body a {
    margin-left: 5px; }

.organization-name {
  margin: 15px 10px 5px 5px;
  padding: 0 10px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.logo a {
  height: 50px; }
  .logo a img {
    max-width: 100%; }

nav .logo {
  margin: 16px 15px 50px 18px; }
  nav .logo svg {
    height: 30px; }

.logo svg {
  overflow: visible; }

.user-detail-layout {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto; }
  .user-detail-layout .loading svg {
    height: 100px;
    width: 100px; }
  .user-detail-layout header h1 {
    flex-grow: initial; }
    .user-detail-layout header h1 .badge {
      vertical-align: middle;
      margin-left: 15px;
      margin-top: 0; }

.add-user-modal .card {
  margin-bottom: 0; }
  .add-user-modal .card .image-component {
    width: 100px !important;
    height: 100px !important;
    margin-right: 15px; }
  .add-user-modal .card .upload-file-form {
    margin: 40px 0 !important; }

.add-user-modal .remove-button-container {
  display: flex;
  justify-content: center; }
  .add-user-modal .remove-button-container .btn-link-danger {
    display: flex;
    align-self: center;
    margin-top: 10px; }

.add-user-success-modal .user-name {
  margin-top: 10px;
  color: #13304d;
  font-size: 18px;
  font-weight: 600; }

.add-user-success-modal .instructions {
  margin-top: 60px;
  font-weight: 600;
  width: 350px;
  text-align: center;
  margin-bottom: 21px; }

.add-user-success-modal .card {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 36px;
  background-color: #f7f9fa;
  border: 0;
  box-shadow: none;
  max-width: 520px;
  margin: auto; }
  .add-user-success-modal .card .card-title {
    padding-left: 30px;
    margin-bottom: 24px; }
    .add-user-success-modal .card .card-title h3 {
      margin-bottom: 17px; }
  .add-user-success-modal .card .card-body {
    padding-left: 30px; }
    .add-user-success-modal .card .card-body .form-group label {
      margin-bottom: 0; }

.device-content {
  padding: 20px 0 40px; }

.dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  padding: 5px;
  margin-right: 5px; }
  .dot.online {
    background-color: #b5dece; }
  .dot.offline {
    background-color: #cf3a1b; }

.device-card {
  height: auto;
  width: 340px;
  padding: 20px;
  border: 0;
  min-height: 316px; }
  .device-card .card-title {
    margin-bottom: 20px; }
    .device-card .card-title .device-card-title {
      font-size: 18px;
      margin-bottom: 5px;
      margin-top: 17px;
      color: #13304d;
      letter-spacing: 0.5px;
      height: auto; }
  .device-card .card-body {
    padding: 0;
    line-height: 1.43; }
  .device-card .card-footer {
    background-color: #fff;
    border: 0;
    padding: 0; }
    .device-card .card-footer .btn-link-danger {
      margin-bottom: -10px; }
  .device-card.add-device-card {
    background-color: #f7f9fa;
    box-shadow: unset;
    padding: 0;
    height: 316px; }
    .device-card.add-device-card .card-body {
      width: 100%;
      height: 100%; }
    .device-card.add-device-card button {
      height: 100%;
      width: 100%;
      text-align: center;
      padding: 0 !important;
      font-size: 12px; }
  @media screen and (max-width: 1400px) {
    .device-card {
      width: 320px; } }

.modal-dialog.confirm-remove-device-modal h5 .modal-text-title, .modal-dialog.add-device-success-modal h5 .modal-text-title, .modal-dialog.device-modal h5 .modal-text-title {
  font-size: 18px !important; }

.modal-dialog.confirm-remove-device-modal h5.modal-title, .modal-dialog.add-device-success-modal h5.modal-title, .modal-dialog.device-modal h5.modal-title {
  font-size: 28px;
  letter-spacing: 0.3px;
  text-align: center; }
  .modal-dialog.confirm-remove-device-modal h5.modal-title img, .modal-dialog.add-device-success-modal h5.modal-title img, .modal-dialog.device-modal h5.modal-title img {
    width: 200px; }

.modal-dialog.confirm-remove-device-modal .card, .modal-dialog.add-device-success-modal .card, .modal-dialog.device-modal .card {
  background-color: #f7f9fa;
  border-color: #e6eded;
  padding: 55px 0 45px 40px; }
  .modal-dialog.confirm-remove-device-modal .card .property, .modal-dialog.add-device-success-modal .card .property, .modal-dialog.device-modal .card .property {
    font-size: 11px;
    line-height: 1.09;
    letter-spacing: 0.5px;
    color: #8e8e93; }
  .modal-dialog.confirm-remove-device-modal .card .value, .modal-dialog.add-device-success-modal .card .value, .modal-dialog.device-modal .card .value {
    font-size: 14px;
    line-height: 1.79;
    color: #000; }

.modal-dialog.confirm-remove-device-modal .modal-footer .justify-content-center .btn, .modal-dialog.add-device-success-modal .modal-footer .justify-content-center .btn, .modal-dialog.device-modal .modal-footer .justify-content-center .btn {
  width: 100%;
  height: 40px; }

.modal-dialog.add-device-modal {
  min-width: 960px; }
  .modal-dialog.add-device-modal h5.modal-title {
    font-size: 28px;
    letter-spacing: 0.3px; }
  .modal-dialog.add-device-modal .modal-content .modal-body h5 {
    font-size: 18px;
    line-height: 1;
    letter-spacing: 0.5px; }
    .modal-dialog.add-device-modal .modal-content .modal-body h5.step-one-title {
      margin-top: 45px; }
  .modal-dialog.add-device-modal .modal-content .row {
    margin-top: 40px;
    margin-bottom: 40px; }
    .modal-dialog.add-device-modal .modal-content .row:first-of-type {
      margin-top: 0; }
    .modal-dialog.add-device-modal .modal-content .row:last-of-type {
      margin-bottom: 0; }
  .modal-dialog.add-device-modal .modal-content .enter-code-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 35px; }
    .modal-dialog.add-device-modal .modal-content .enter-code-container .device-code {
      margin-top: auto;
      margin-bottom: 0; }
      .modal-dialog.add-device-modal .modal-content .enter-code-container .device-code input {
        width: 100%;
        height: 100px;
        border-radius: 14px;
        background-color: #f7f9fa;
        padding: 11px 15px;
        font-size: 24px;
        letter-spacing: 18px;
        text-align: center;
        color: #000; }
        .modal-dialog.add-device-modal .modal-content .enter-code-container .device-code input::placeholder {
          letter-spacing: 0.3px;
          color: #8e8e93; }
      .modal-dialog.add-device-modal .modal-content .enter-code-container .device-code .invalid-feedback {
        position: absolute;
        bottom: -25px; }
  .modal-dialog.add-device-modal .modal-content .centered-image {
    display: flex;
    justify-content: center; }
    .modal-dialog.add-device-modal .modal-content .centered-image.custom-drop-shadow-image img {
      box-shadow: 30px 30px 40px -25px rgba(0, 0, 0, 0.4);
      border-radius: 11px; }
  .modal-dialog.add-device-modal .modal-content .step-three-container {
    margin-top: 37px; }
  .modal-dialog.add-device-modal .modal-footer {
    margin-top: 0 !important; }

.table .column-cell .first-last-name-cell > div {
  padding-right: 0; }
  .table .column-cell .first-last-name-cell > div:last-child {
    padding-left: 10px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.modal .remove-patient-modal .modal-content,
.modal .close-encounter-modal .modal-content,
.modal .close-encounter-success-modal .modal-content {
  padding: 31px 60px 30px;
  margin: auto;
  max-width: 580px; }
  .modal .remove-patient-modal .modal-content .modal-body.remove-patient-body,
  .modal .close-encounter-modal .modal-content .modal-body.remove-patient-body,
  .modal .close-encounter-success-modal .modal-content .modal-body.remove-patient-body {
    padding-top: 0; }
    .modal .remove-patient-modal .modal-content .modal-body.remove-patient-body .patient-info-mini-card,
    .modal .close-encounter-modal .modal-content .modal-body.remove-patient-body .patient-info-mini-card,
    .modal .close-encounter-success-modal .modal-content .modal-body.remove-patient-body .patient-info-mini-card {
      margin-bottom: 37px;
      padding-bottom: 31px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .modal .remove-patient-modal .modal-content .modal-body.remove-patient-body .doctor-location-time,
    .modal .close-encounter-modal .modal-content .modal-body.remove-patient-body .doctor-location-time,
    .modal .close-encounter-success-modal .modal-content .modal-body.remove-patient-body .doctor-location-time {
      margin-top: 17px; }
    .modal .remove-patient-modal .modal-content .modal-body.remove-patient-body .reason-section,
    .modal .close-encounter-modal .modal-content .modal-body.remove-patient-body .reason-section,
    .modal .close-encounter-success-modal .modal-content .modal-body.remove-patient-body .reason-section {
      padding-top: 44px;
      color: #8e8e93; }
  .modal .remove-patient-modal .modal-content .modal-body .dropdown button,
  .modal .close-encounter-modal .modal-content .modal-body .dropdown button,
  .modal .close-encounter-success-modal .modal-content .modal-body .dropdown button {
    width: 100%; }
  .modal .remove-patient-modal .modal-content .modal-body.close-encounter-body,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-body,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-body {
    padding-bottom: 0; }
    .modal .remove-patient-modal .modal-content .modal-body.close-encounter-body .doctor-location-time,
    .modal .close-encounter-modal .modal-content .modal-body.close-encounter-body .doctor-location-time,
    .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-body .doctor-location-time {
      margin-top: 17px;
      margin-bottom: 44px; }
    .modal .remove-patient-modal .modal-content .modal-body.close-encounter-body .radio,
    .modal .close-encounter-modal .modal-content .modal-body.close-encounter-body .radio,
    .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-body .radio {
      background: 0;
      border: 0; }
    .modal .remove-patient-modal .modal-content .modal-body.close-encounter-body hr,
    .modal .close-encounter-modal .modal-content .modal-body.close-encounter-body hr,
    .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-body hr {
      margin-top: 34px;
      margin-bottom: 30px; }
    .modal .remove-patient-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group,
    .modal .close-encounter-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group,
    .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group {
      flex-direction: row; }
      .modal .remove-patient-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group .form-group-label,
      .modal .close-encounter-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group .form-group-label,
      .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group .form-group-label {
        flex-basis: 50%;
        color: #000;
        text-transform: none;
        font-weight: 400;
        font-size: 14px; }
      .modal .remove-patient-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group > div,
      .modal .close-encounter-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group > div,
      .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group > div {
        flex-basis: 50%; }
        .modal .remove-patient-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group > div label .radio-label,
        .modal .close-encounter-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group > div label .radio-label,
        .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group > div label .radio-label {
          color: #000;
          text-transform: none;
          font-weight: 400;
          font-size: 14px; }
        .modal .remove-patient-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group > div label:first-of-type,
        .modal .close-encounter-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group > div label:first-of-type,
        .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-body .appointment-needed .form-group > div label:first-of-type {
          margin-top: 0; }
    .modal .remove-patient-modal .modal-content .modal-body.close-encounter-body .memo-for-next-appointment.disabled,
    .modal .close-encounter-modal .modal-content .modal-body.close-encounter-body .memo-for-next-appointment.disabled,
    .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-body .memo-for-next-appointment.disabled {
      opacity: 0.2; }
      .modal .remove-patient-modal .modal-content .modal-body.close-encounter-body .memo-for-next-appointment.disabled .form-group,
      .modal .close-encounter-modal .modal-content .modal-body.close-encounter-body .memo-for-next-appointment.disabled .form-group,
      .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-body .memo-for-next-appointment.disabled .form-group {
        margin-bottom: 0; }
        .modal .remove-patient-modal .modal-content .modal-body.close-encounter-body .memo-for-next-appointment.disabled .form-group textarea,
        .modal .close-encounter-modal .modal-content .modal-body.close-encounter-body .memo-for-next-appointment.disabled .form-group textarea,
        .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-body .memo-for-next-appointment.disabled .form-group textarea {
          border-radius: 14px !important;
          height: 80px;
          min-height: 80px;
          border-width: 2px;
          border-color: #f1f1f9;
          background-color: #f7f9fa; }
  .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body {
    margin-top: 20px; }
    .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .patient-info-mini-card,
    .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .patient-info-mini-card,
    .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .patient-info-mini-card {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .close-success-title,
    .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .close-success-title,
    .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .close-success-title {
      text-align: center;
      margin-top: 60px;
      margin-bottom: 60px; }
    .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card,
    .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card,
    .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card {
      padding: 36px 30px 20px;
      background-color: #f7f9fa;
      border: 0;
      box-shadow: none; }
      .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card h3,
      .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card h3,
      .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card h3 {
        margin: 0; }
      .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
      .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
      .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label {
        margin-top: 29px;
        margin-bottom: 0; }
      .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card span,
      .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card span,
      .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card span {
        white-space: pre; }
      .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card .doctor-location-time,
      .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card .doctor-location-time,
      .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card .doctor-location-time {
        margin-top: 17px; }
  .modal .remove-patient-modal .modal-content .remove-patient-footer,
  .modal .close-encounter-modal .modal-content .remove-patient-footer,
  .modal .close-encounter-success-modal .modal-content .remove-patient-footer {
    border-top-width: 1px; }

.modal .close-encounter-success-modal .modal-content .modal-footer {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  padding-top: 0;
  border: 0; }
  .modal .close-encounter-success-modal .modal-content .modal-footer .btn {
    margin: 5px 0; }

.modal .add-patient-modal .card:not(.notification-card) {
  border: 0;
  padding: 0;
  box-shadow: none; }

.modal .add-patient-preview-modal .user-name {
  margin-top: 10px;
  color: #13304d;
  font-size: 18px;
  font-weight: 600; }

.modal .add-patient-preview-modal .card {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 36px;
  background-color: #f7f9fa;
  border: 0;
  box-shadow: none;
  max-width: 520px;
  margin: 60px auto auto; }
  .modal .add-patient-preview-modal .card .card-title {
    padding-left: 30px;
    margin-bottom: 24px; }
    .modal .add-patient-preview-modal .card .card-title h3 {
      margin-bottom: 17px; }
  .modal .add-patient-preview-modal .card .card-body {
    padding-left: 30px; }
    .modal .add-patient-preview-modal .card .card-body .form-group label {
      margin-bottom: 0; }

.patient-personal-info header > button {
  margin: 3px; }

.patient-personal-info header h1 {
  flex-grow: 0;
  margin-right: 20px; }

.patient-personal-info .user-profile-title {
  font-weight: 600;
  margin-bottom: 22px; }

.patient-personal-info .form-group {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0;
  padding-right: 0; }

.patient-personal-info .horizontal-orientation {
  flex-direction: row; }
  .patient-personal-info .horizontal-orientation > .form-group {
    width: 33.33333%; }
    .patient-personal-info .horizontal-orientation > .form-group:nth-child(1) {
      padding-right: 15px; }
    .patient-personal-info .horizontal-orientation > .form-group:nth-child(2) {
      padding-left: 15px;
      padding-right: 15px; }
    .patient-personal-info .horizontal-orientation > .form-group:nth-child(3) {
      padding-left: 15px; }

.patient-detail-past-encounters .table {
  margin-top: 0; }
  .patient-detail-past-encounters .table .row .card {
    min-height: 100px; }

.patient-detail-past-encounters .past-encounter-flow-name-cell {
  margin-top: 7px; }
  .patient-detail-past-encounters .past-encounter-flow-name-cell .flow-name h3 {
    line-height: 1.6;
    margin-left: 5px; }
  .patient-detail-past-encounters .past-encounter-flow-name-cell .started-at {
    padding-left: 35px; }

.patient-detail-past-encounters .table-row-indicator-icon {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -10px; }

.patient-document-list h2 {
  margin-top: 50px;
  margin-bottom: 60px; }

.patient-detail-encounter {
  margin-bottom: 75px; }
  .patient-detail-encounter header h1 {
    height: auto;
    padding-right: 15px;
    margin-top: 2px; }
  .patient-detail-encounter header .btn,
  .patient-detail-encounter header .custom-react-dropdown {
    align-self: start;
    /* sass-lint:disable class-name-format */ }
    .patient-detail-encounter header .btn .dropdown---menu-item---1LjoL::first-letter,
    .patient-detail-encounter header .custom-react-dropdown .dropdown---menu-item---1LjoL::first-letter {
      text-transform: capitalize; }
  .patient-detail-encounter .comment-section {
    margin-bottom: 45px; }
    .patient-detail-encounter .comment-section .card {
      padding: 30px;
      margin-bottom: 10px;
      border: 0; }
      .patient-detail-encounter .comment-section .card h3 {
        margin-top: 8px;
        margin-bottom: 17px; }
  .patient-detail-encounter .add-comment-button {
    margin-top: 30px;
    margin-bottom: 14px; }
  .patient-detail-encounter .add-comment-card {
    padding: 30px 30px 20px;
    margin-bottom: 10px; }
    .patient-detail-encounter .add-comment-card h3 {
      margin-top: 7px;
      margin-bottom: 20px; }
    .patient-detail-encounter .add-comment-card .add-comment-actions {
      display: flex;
      justify-content: flex-end; }
      .patient-detail-encounter .add-comment-card .add-comment-actions .btn-primary {
        margin-right: 0; }
  .patient-detail-encounter .doctor-location-time {
    margin-top: 20px;
    margin-bottom: 49px; }

.doctor-location-time {
  font-size: 14px;
  color: #8e8e93; }

.patient-detail-dental-chart header {
  margin-bottom: 6px; }
  .patient-detail-dental-chart header h1 {
    height: auto;
    padding-right: 15px; }
  .patient-detail-dental-chart header .btn {
    align-self: start; }

.document-sidepanel-container {
  min-width: auto !important;
  width: 730px; }
  .document-sidepanel-container .document-sidepanel-form {
    padding: 20px; }

.add-patient-sidepanel {
  z-index: 1051; }
  .add-patient-sidepanel .sidepanel-container {
    max-width: 770px; }

.sidepanel-container .cave-sidepanel-header h2 {
  margin-right: 30px !important; }

.rjsf-patient i.glyphicon {
  display: none; }

.rjsf-patient .field-array .array-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .rjsf-patient .field-array .array-item > div:first-child {
    padding-left: 15px;
    width: 90%; }
    .rjsf-patient .field-array .array-item > div:first-child .form-group > label:first-child {
      position: absolute;
      left: 0;
      margin-top: 12px; }

.rjsf-patient .field-array .array-item-remove {
  margin-top: 5px; }

.patient-full-name {
  vertical-align: middle; }

.patient-header-more-actions {
  display: inline-flex;
  position: unset; }

.patient-deleted-status {
  border-radius: 14px;
  margin-left: 15px;
  padding: 0 25px;
  background-color: #cf3a1b;
  color: #fff;
  font-size: 18px;
  vertical-align: middle;
  display: inline-flex;
  text-transform: uppercase; }

.medical-record-toolbar {
  max-width: 1160px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.comment-red-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #cf3a1b;
  position: absolute;
  right: 1px;
  top: 3px; }

.waiting-list {
  width: 100%;
  margin-top: 0;
  padding-bottom: 0; }
  .waiting-list header {
    padding-bottom: 15px;
    margin-bottom: 5px; }
  .waiting-list > .waiting-list-columns {
    width: max-content;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px; }
    .waiting-list > .waiting-list-columns .bucket-container > .row {
      margin-left: 0;
      margin-right: 0; }
    .waiting-list > .waiting-list-columns .bucket-container .bucket-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      padding-left: 10px;
      margin-bottom: 32px;
      letter-spacing: normal; }
      .waiting-list > .waiting-list-columns .bucket-container .bucket-title h3 {
        margin-bottom: 0;
        margin-right: 5px;
        padding-bottom: 0; }
        .waiting-list > .waiting-list-columns .bucket-container .bucket-title h3 .list-position-indicator {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          border-radius: 50%;
          background-color: #f7f9fa;
          display: inline-block;
          font-weight: 500;
          font-size: 16px; }
    .waiting-list > .waiting-list-columns .bucket-container .bucket-main-action {
      text-transform: initial;
      font-size: 12px;
      padding: 0;
      height: 12px;
      margin-bottom: -15px;
      width: auto;
      min-width: 40px; }
    .waiting-list > .waiting-list-columns .bucket-container:last-child .bucket {
      border-right: 0; }
    .waiting-list > .waiting-list-columns .bucket {
      height: calc(100vh - 65px - 105px);
      overflow-y: auto;
      overflow-x: hidden;
      border-right: 2px solid #fff; }
      .waiting-list > .waiting-list-columns .bucket .scrollbar-container {
        width: 359px !important; }
      .waiting-list > .waiting-list-columns .bucket .bucket-row {
        padding-top: 17px;
        height: 100%; }
      .waiting-list > .waiting-list-columns .bucket .bucket-droppable-area {
        padding: 0;
        height: 100%;
        display: table;
        align-self: start;
        overflow-x: hidden; }
        .waiting-list > .waiting-list-columns .bucket .bucket-droppable-area .drop-bucket-indicator {
          position: absolute;
          width: 340px;
          height: 110%;
          left: 10px;
          top: -17px;
          background-color: #dce3e3; }
        .waiting-list > .waiting-list-columns .bucket .bucket-droppable-area.dragging-over-column .drop-item-placeholder {
          display: none; }
      .waiting-list > .waiting-list-columns .bucket .bucket-title {
        display: flex;
        flex-direction: row;
        margin-left: 15px; }
      .waiting-list > .waiting-list-columns .bucket .drop-item-placeholder {
        border-radius: 14px;
        width: 340px;
        height: 140px;
        border: dashed 2px #c4c4cc;
        color: #c4c4cc;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 12px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center; }
      .waiting-list > .waiting-list-columns .bucket .card:not(.notification-card) {
        margin: 0 auto 20px;
        width: 340px;
        border-top-width: 0;
        border-right-width: 0;
        border-bottom-width: 0;
        border-left-width: 3px;
        height: 140px;
        padding-right: 10px;
        padding-left: 15px;
        padding-top: 15px;
        box-shadow: none; }
        .waiting-list > .waiting-list-columns .bucket .card:not(.notification-card).highlight {
          animation: highlight-fade-orange-to-white 5s ease-in-out 0s; }
        .waiting-list > .waiting-list-columns .bucket .card:not(.notification-card):focus {
          outline: #0a83bf dashed; }
          .waiting-list > .waiting-list-columns .bucket .card:not(.notification-card):focus:not(.focus-visible) {
            outline: none; }
      .waiting-list > .waiting-list-columns .bucket .card .btn.btn-link {
        width: initial; }
      .waiting-list > .waiting-list-columns .bucket .card:hover, .waiting-list > .waiting-list-columns .bucket .card.dragging {
        box-shadow: 0 0 22px -15px #000;
        background-color: #fafafa; }
      .waiting-list > .waiting-list-columns .bucket .card.full-border {
        border-width: 3px;
        padding-top: calc( 15px - 3px);
        padding-right: calc( 15px - 3px); }
      .waiting-list > .waiting-list-columns .bucket .card.dragging {
        background-color: #fcfcfc; }
      .waiting-list > .waiting-list-columns .bucket .card .patient-actions,
      .waiting-list > .waiting-list-columns .bucket .card .document-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 1px; }
      .waiting-list > .waiting-list-columns .bucket .card .patient-actions {
        margin-top: 0;
        padding-right: 8px;
        height: fit-content;
        position: absolute;
        top: 8px;
        right: 1px; }
        .waiting-list > .waiting-list-columns .bucket .card .patient-actions .no-comments svg path {
          fill: #c4c4cc; }
        .waiting-list > .waiting-list-columns .bucket .card .patient-actions > .btn-group {
          background-color: #f3f7fc;
          display: flex;
          align-items: center;
          border-radius: 50px;
          height: 32px;
          padding: 5px; }
          .waiting-list > .waiting-list-columns .bucket .card .patient-actions > .btn-group .finish-wrapper {
            display: flex;
            align-items: center;
            padding-left: 5px; }
          .waiting-list > .waiting-list-columns .bucket .card .patient-actions > .btn-group .btn-primary {
            margin-right: 5px; }
          .waiting-list > .waiting-list-columns .bucket .card .patient-actions > .btn-group .btn-transparent {
            min-width: 30px;
            width: 30px;
            height: 30px; }
          .waiting-list > .waiting-list-columns .bucket .card .patient-actions > .btn-group .dropdown .dropdown-menu {
            top: 37px; }
      .waiting-list > .waiting-list-columns .bucket .card .card-title {
        margin-bottom: 0;
        margin-top: -5px;
        flex-grow: 1; }
        .waiting-list > .waiting-list-columns .bucket .card .card-title .patient-info {
          display: flex;
          flex-direction: column; }
          .waiting-list > .waiting-list-columns .bucket .card .card-title .patient-info .patient-name {
            font-weight: 600;
            font-size: 16px;
            text-transform: none;
            width: auto;
            padding-top: 0;
            padding-bottom: 0;
            height: 21px;
            min-height: 21px;
            margin-top: 5px; }
          .waiting-list > .waiting-list-columns .bucket .card .card-title .patient-info .check-in-at {
            font-weight: 500;
            font-size: 14px;
            line-height: 1.25;
            align-items: flex-start;
            display: flex;
            margin-top: 5px; }
          .waiting-list > .waiting-list-columns .bucket .card .card-title .patient-info .flow-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 1.25;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: flex-end; }
          .waiting-list > .waiting-list-columns .bucket .card .card-title .patient-info .status-container {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            margin-bottom: 10px;
            white-space: nowrap;
            font-size: 14px;
            font-weight: 500; }
      .waiting-list > .waiting-list-columns .bucket .card .card-footer {
        background-color: transparent;
        padding: 0;
        border: 0;
        display: flex;
        align-items: flex-end;
        height: 20px; }
        .waiting-list > .waiting-list-columns .bucket .card .card-footer .doctor-name {
          display: flex;
          align-items: center;
          margin-top: 6px;
          color: #8f8f94;
          font-size: 14px; }
          .waiting-list > .waiting-list-columns .bucket .card .card-footer .doctor-name .category-name {
            max-width: 130px; }
          .waiting-list > .waiting-list-columns .bucket .card .card-footer .doctor-name .doctor-circle-icon {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            margin-right: 5px; }
          .waiting-list > .waiting-list-columns .bucket .card .card-footer .doctor-name .event-category {
            margin-right: 10px; }
        .waiting-list > .waiting-list-columns .bucket .card .card-footer .choose-doctor {
          font-size: 14px;
          padding: 0; }
        .waiting-list > .waiting-list-columns .bucket .card .card-footer.footer-buttons {
          padding: 0 15px; }
          .waiting-list > .waiting-list-columns .bucket .card .card-footer.footer-buttons :first-child {
            margin-right: 10px; }
      .waiting-list > .waiting-list-columns .bucket .card.assist-request {
        background-color: #13304d;
        color: #fff;
        border: 0;
        height: 70px; }
        .waiting-list > .waiting-list-columns .bucket .card.assist-request .transparent-actions-background.btn-group {
          background-color: transparent; }
        .waiting-list > .waiting-list-columns .bucket .card.assist-request .patient-name {
          font-size: 14px !important; }
      .waiting-list > .waiting-list-columns .bucket .upcoming-events-container {
        width: 340px;
        margin: auto;
        border-top: 2px solid #fff;
        margin-top: 19px;
        padding-top: 16px;
        padding-bottom: 70px; }
        .waiting-list > .waiting-list-columns .bucket .upcoming-events-container h3 {
          margin-bottom: 13px; }
        .waiting-list > .waiting-list-columns .bucket .upcoming-events-container .upcoming-event-container {
          border-radius: 14px;
          box-shadow: 0 0 4px 0 #f1f1f9 !important;
          background-color: #f7f9fa;
          padding: 15px;
          margin-bottom: 10px;
          color: #406080; }
          .waiting-list > .waiting-list-columns .bucket .upcoming-events-container .upcoming-event-container .upcoming-event-row {
            display: flex;
            justify-content: space-between;
            align-items: center; }
            .waiting-list > .waiting-list-columns .bucket .upcoming-events-container .upcoming-event-container .upcoming-event-row .category-name {
              max-width: 130px; }
            .waiting-list > .waiting-list-columns .bucket .upcoming-events-container .upcoming-event-container .upcoming-event-row:first-child {
              margin-bottom: 3px; }
            .waiting-list > .waiting-list-columns .bucket .upcoming-events-container .upcoming-event-container .upcoming-event-row.align-left {
              justify-content: flex-start; }
            .waiting-list > .waiting-list-columns .bucket .upcoming-events-container .upcoming-event-container .upcoming-event-row .event-category {
              margin-right: 10px; }
            .waiting-list > .waiting-list-columns .bucket .upcoming-events-container .upcoming-event-container .upcoming-event-row .hover-actions {
              height: 21px;
              display: flex;
              flex-direction: row; }
              .waiting-list > .waiting-list-columns .bucket .upcoming-events-container .upcoming-event-container .upcoming-event-row .hover-actions .card-more-actions {
                position: initial;
                right: initial;
                top: initial;
                margin-left: 10px; }
                .waiting-list > .waiting-list-columns .bucket .upcoming-events-container .upcoming-event-container .upcoming-event-row .hover-actions .card-more-actions .more-toggle i {
                  color: #000;
                  margin: 0; }
                .waiting-list > .waiting-list-columns .bucket .upcoming-events-container .upcoming-event-container .upcoming-event-row .hover-actions .card-more-actions .more-toggle .rs-more-actions-menu i {
                  margin-right: 5px; }
          .waiting-list > .waiting-list-columns .bucket .upcoming-events-container .upcoming-event-container .patient-name {
            max-width: 180px;
            color: #13304d; }
          .waiting-list > .waiting-list-columns .bucket .upcoming-events-container .upcoming-event-container .doctor-circle {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            margin-right: 5px;
            display: inline-block; }
          .waiting-list > .waiting-list-columns .bucket .upcoming-events-container .upcoming-event-container:hover {
            background-color: #fff; }

.comment-list-popover.moshi-popover .popover {
  overflow: hidden; }

.comment-list-popover.moshi-popover .popover-body {
  background-color: #fff;
  padding: 0; }
  .comment-list-popover.moshi-popover .popover-body .comment-list {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 260px;
    padding: 10px; }
    .comment-list-popover.moshi-popover .popover-body .comment-list .comment-item {
      display: inline-flex;
      flex-direction: column;
      padding: 6px 16px;
      background-color: #c4c4cc;
      border-radius: 14px;
      margin-bottom: 5px; }
      .comment-list-popover.moshi-popover .popover-body .comment-list .comment-item .comment-title {
        font-weight: 600;
        font-size: 14px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between; }
        .comment-list-popover.moshi-popover .popover-body .comment-list .comment-item .comment-title .card-more-actions {
          position: static;
          margin-left: 12px; }
          .comment-list-popover.moshi-popover .popover-body .comment-list .comment-item .comment-title .card-more-actions .dropdown-toggle i {
            color: #171f26; }
      .comment-list-popover.moshi-popover .popover-body .comment-list .comment-item.my-comments {
        background-color: #caebfc;
        align-self: flex-end; }
        .comment-list-popover.moshi-popover .popover-body .comment-list .comment-item.my-comments.editable {
          background-color: #fff;
          border: 1px solid #e4e4ee;
          padding: 7px 0;
          min-width: 300px; }
          .comment-list-popover.moshi-popover .popover-body .comment-list .comment-item.my-comments.editable .form-buttons {
            display: flex;
            justify-content: flex-end; }
          .comment-list-popover.moshi-popover .popover-body .comment-list .comment-item.my-comments.editable .moshi-button {
            height: 34px; }
          .comment-list-popover.moshi-popover .popover-body .comment-list .comment-item.my-comments.editable textarea {
            resize: none;
            border: 0; }
            .comment-list-popover.moshi-popover .popover-body .comment-list .comment-item.my-comments.editable textarea:hover {
              background: #fff; }
      .comment-list-popover.moshi-popover .popover-body .comment-list .comment-item:last-of-type {
        margin-bottom: 0; }
      .comment-list-popover.moshi-popover .popover-body .comment-list .comment-item .form-group {
        margin-bottom: 0;
        padding: 0; }
        .comment-list-popover.moshi-popover .popover-body .comment-list .comment-item .form-group textarea {
          width: 220px; }
  .comment-list-popover.moshi-popover .popover-body p {
    white-space: pre-wrap; }
  .comment-list-popover.moshi-popover .popover-body hr {
    clear: both;
    margin: 0 0 5px; }
  .comment-list-popover.moshi-popover .popover-body textarea {
    font-weight: 400 !important;
    min-height: 42px !important; }
  .comment-list-popover.moshi-popover .popover-body .comment-inline-form {
    padding: 16px 10px 0; }
    .comment-list-popover.moshi-popover .popover-body .comment-inline-form form {
      display: flex;
      flex-grow: 1; }
      .comment-list-popover.moshi-popover .popover-body .comment-inline-form form .form-group {
        flex-grow: 1; }
        .comment-list-popover.moshi-popover .popover-body .comment-inline-form form .form-group textarea::placeholder {
          color: #8e8e93; }
      .comment-list-popover.moshi-popover .popover-body .comment-inline-form form .moshi-button {
        display: flex;
        justify-content: center;
        align-items: center; }

.modal-body .custom-radio-label-container {
  margin-top: 15px; }
  .modal-body .custom-radio-label-container .form-group {
    flex-direction: row;
    margin-bottom: 0; }
    .modal-body .custom-radio-label-container .form-group .form-group-label {
      flex-basis: 30%;
      display: inline-block;
      text-transform: none;
      font-size: 14px;
      color: #000;
      font-weight: 400; }
    .modal-body .custom-radio-label-container .form-group > div {
      flex-basis: 70%; }
      .modal-body .custom-radio-label-container .form-group > div label {
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: 600; }
        .modal-body .custom-radio-label-container .form-group > div label:last-of-type {
          margin-bottom: 0; }
        .modal-body .custom-radio-label-container .form-group > div label span {
          text-transform: none;
          font-size: 14px;
          color: #000; }

.custom-remove-encounter-modal .modal-content h3 {
  margin-bottom: 1px !important; }

.custom-remove-encounter-modal .modal-content .modal-body {
  margin-bottom: 90px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.document-detail-modal .modal-content .modal-header {
  padding-top: 0;
  padding-bottom: 0; }
  .document-detail-modal .modal-content .modal-header .modal-title label {
    display: block; }

.document-detail-modal .modal-content .modal-body {
  padding-top: 0; }
  .document-detail-modal .modal-content .modal-body .metadata {
    margin-bottom: 29px; }
  .document-detail-modal .modal-content .modal-body .field-title-row {
    height: initial;
    margin-bottom: 0; }
  .document-detail-modal .modal-content .modal-body .card {
    padding: 0;
    border: 0;
    background: none;
    box-shadow: none; }
    .document-detail-modal .modal-content .modal-body .card .form-group-label {
      color: #8e8e93;
      margin-bottom: 7px;
      font-size: 12px; }

.document-questionnaire-submission-modal .modal-content {
  padding-top: 30px !important; }
  .document-questionnaire-submission-modal .modal-content .content {
    margin-top: 75px; }
    .document-questionnaire-submission-modal .modal-content .content .sidebar {
      width: 100%;
      position: relative;
      border: 0;
      box-shadow: none;
      padding: 0;
      margin-bottom: 50px; }
      .document-questionnaire-submission-modal .modal-content .content .sidebar h3 {
        margin-bottom: 17px; }
        .document-questionnaire-submission-modal .modal-content .content .sidebar h3:first-of-type {
          margin-top: 14px; }
      .document-questionnaire-submission-modal .modal-content .content .sidebar a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .document-questionnaire-submission-modal .modal-content .content .sidebar a.navigation {
          margin-top: 0;
          margin-bottom: 5px; }
        .document-questionnaire-submission-modal .modal-content .content .sidebar a.disabled:hover {
          cursor: not-allowed;
          background-color: initial; }
          .document-questionnaire-submission-modal .modal-content .content .sidebar a.disabled:hover.active {
            background-color: #e6eded; }
      .document-questionnaire-submission-modal .modal-content .content .sidebar svg path {
        fill: none;
        stroke: none; }
  .document-questionnaire-submission-modal .modal-content .title-row {
    align-items: flex-end; }
    .document-questionnaire-submission-modal .modal-content .title-row h1 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .document-questionnaire-submission-modal .modal-content .title-row .btn-link-primary {
      margin-bottom: 6px; }
  .document-questionnaire-submission-modal .modal-content .required {
    margin: 0 5px;
    color: #cf3a1b; }
  .document-questionnaire-submission-modal .modal-content .modal-body #root__title {
    display: none; }
  .document-questionnaire-submission-modal .modal-content .modal-body .form-group {
    margin-bottom: 0; }
  .document-questionnaire-submission-modal .modal-content .modal-footer {
    margin-top: 0 !important;
    display: flex !important;
    justify-content: center; }

.upload-documents-modal .title {
  display: flex !important;
  justify-content: center;
  font-weight: 600;
  line-height: 1.22;
  color: #13304d;
  padding: 0; }

.upload-documents-modal .form-control-file {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .upload-documents-modal .form-control-file .icon-bin {
    margin-left: auto; }

.upload-documents-modal .files-dropzone {
  display: flex !important;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  min-height: 180px; }

.upload-documents-modal .upload-file-description {
  padding: 0 5px 20px; }

.upload-documents-modal .modal-footer {
  display: flex;
  justify-content: center; }

.document-icon {
  border-radius: 15%;
  background-color: #0a83bf;
  color: #fff; }
  .document-icon.icon-size-lg {
    padding: 5px; }
  .document-icon.icon-size-md {
    padding: 3px; }
  .document-icon.icon-size-sm {
    padding: 2px; }
  .document-icon.inbound-submitted {
    background-color: #6fbea0; }
  .document-icon.inbound-request {
    background-color: #f7c403; }
  .document-icon.inbound-template {
    background-color: #317dc8; }
  .document-icon.outbound-template,
  .document-icon .outbound-document,
  .document-icon .outbound-document-icon {
    background-color: #6f6bc2; }
  .document-icon.external {
    background-color: #fb6b32; }
  .document-icon.pdf {
    background-color: #fb6b32;
    color: #fff;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    position: relative;
    top: 0;
    font-weight: 500;
    font-size: 10px;
    height: 24px;
    width: 24px; }

.view-document-container {
  box-shadow: 0 8px 30px 0 rgba(18, 47, 76, 0.3);
  width: 770px;
  margin: 0 auto 80px;
  padding: 40px; }

.new-document-popover .popover {
  min-width: unset;
  max-width: unset;
  max-height: unset; }
  .new-document-popover .popover .popover-body {
    overflow: visible;
    max-height: unset; }

.new-document-popover .document-type-container {
  border-radius: 14px;
  width: 270px;
  border: 1px solid #caebfc;
  padding: 20px 20px 29px;
  text-align: center; }
  .new-document-popover .document-type-container .document-type-title {
    font-size: 12px;
    font-weight: 600;
    color: #0a83bf;
    text-transform: uppercase; }
  .new-document-popover .document-type-container .document-type-description {
    margin-top: 10px;
    font-size: 12px;
    color: #8e8e93; }
  .new-document-popover .document-type-container .document-type-create-dropdown {
    margin-top: 22px; }

.form-template-finder .search-box {
  padding-bottom: 8px; }

.form-template-finder .template-finder-header .template-finder-create-new-link {
  background-color: #e6eded;
  text-align: center;
  padding-bottom: 8px; }

.form-template-finder .template-finder-header .template-finder-result {
  padding: 20px 30px 20px 19px;
  display: flex;
  justify-content: space-between; }
  .form-template-finder .template-finder-header .template-finder-result > span {
    text-transform: uppercase;
    font-size: 12px;
    color: #5c758e; }

.form-template-finder .template-finder-item {
  display: flex;
  justify-content: space-between;
  font-weight: 400; }
  .form-template-finder .template-finder-item .template-finder-item-name {
    color: #000; }
  .form-template-finder .template-finder-item .template-finder-item-date {
    color: #8e8e93; }

.flow-settings.custom-container {
  margin-left: 80px; }
  .flow-settings.custom-container h1 {
    margin-top: 19px; }
  .flow-settings.custom-container .navigation {
    margin-bottom: 0; }
  .flow-settings.custom-container .flow-detail-component {
    max-width: 580px;
    display: flex;
    flex-direction: column; }
    .flow-settings.custom-container .flow-detail-component .flow-detail-description {
      margin-bottom: 40px; }
    .flow-settings.custom-container .flow-detail-component .card .form-group {
      margin-bottom: 38px; }
    .flow-settings.custom-container .flow-detail-component .table {
      margin-top: 41px; }
      .flow-settings.custom-container .flow-detail-component .table.smaller-top-margin {
        margin-top: 15px; }
      .flow-settings.custom-container .flow-detail-component .table .table-header {
        margin-bottom: 9px; }
        .flow-settings.custom-container .flow-detail-component .table .table-header label {
          margin-bottom: 5px; }
      .flow-settings.custom-container .flow-detail-component .table .row .checkbox {
        margin-top: 0; }
      .flow-settings.custom-container .flow-detail-component .table .row .enable-disable-toggle {
        padding-top: 0;
        margin-top: 0;
        text-transform: uppercase; }
    .flow-settings.custom-container .flow-detail-component .add-document-button {
      margin-top: 24px;
      margin-bottom: 40px; }
    .flow-settings.custom-container .flow-detail-component .confirmation-section h2 {
      padding-top: 49px;
      margin-top: 40px;
      height: unset;
      border-top: 1px solid #f1f1f9; }
    .flow-settings.custom-container .flow-detail-component .confirmation-section .confirmation-subtext {
      margin-bottom: 20px; }
    .flow-settings.custom-container .flow-detail-component .confirmation-section .card {
      padding-top: 22px; }
      .flow-settings.custom-container .flow-detail-component .confirmation-section .card form > div:first-child .radio {
        margin-top: 0; }

.flow-settings.content-sidebar {
  width: 100px;
  min-width: 100px;
  margin-top: 0; }
  .flow-settings.content-sidebar header {
    margin-top: 22px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.widget-group .rjsf.card.widget.quarter-width-widget, .widget-group .rjsf.card.widget.vital-signs-widget, .widget-group .rjsf.card.widget.admin-data-widget {
  min-width: 220px;
  max-width: 250px;
  margin-right: 20px;
  display: inline-flex; }

.widget-group .widget-group-title {
  margin-bottom: 10px;
  font-size: 20px; }
  .widget-group .widget-group-title::first-letter {
    text-transform: capitalize; }
  .widget-group .widget-group-title.other-group-title {
    margin-bottom: 10px; }

.widget-group .rjsf.card {
  padding: 15px;
  min-height: 100px; }
  .widget-group .rjsf.card.hover, .widget-group .rjsf.card:hover {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.36); }
    .widget-group .rjsf.card.hover.widget .edit-widget, .widget-group .rjsf.card:hover.widget .edit-widget {
      display: block; }
  .widget-group .rjsf.card .card-more-actions {
    position: absolute;
    top: 15px;
    right: 10px;
    width: 30px; }
  .widget-group .rjsf.card.widget {
    max-width: 940px; }
    .widget-group .rjsf.card.widget.form-disabled .fields-wrapper .custom-datepicker {
      height: 16px !important; }
    .widget-group .rjsf.card.widget.form-disabled .fields-wrapper .toggle-switch.disabled {
      opacity: 1; }
      .widget-group .rjsf.card.widget.form-disabled .fields-wrapper .toggle-switch.disabled .slider:hover {
        cursor: not-allowed !important; }
    .widget-group .rjsf.card.widget.form-disabled .errors {
      padding-right: 20px; }
    .widget-group .rjsf.card.widget.highlight {
      animation: highlight-fade 1.5s ease-in-out 0s; }
    .widget-group .rjsf.card.widget .widget-title-wrapper {
      margin: 0 0 3px; }
      .widget-group .rjsf.card.widget .widget-title-wrapper .widget-title {
        font-weight: 600;
        color: #8e8e93;
        margin-left: 0;
        letter-spacing: 0.46px; }
    .widget-group .rjsf.card.widget .react-markdown-renderer {
      margin-top: 20px; }
    .widget-group .rjsf.card.widget .widget-created-at-label {
      position: absolute;
      left: 15px;
      bottom: 10px;
      font-size: 9px; }
    .widget-group .rjsf.card.widget .widget-version-label {
      position: absolute;
      right: 20px;
      bottom: 10px;
      text-transform: lowercase;
      font-size: 9px; }
    .widget-group .rjsf.card.widget fieldset {
      display: flex;
      flex-direction: column; }
    .widget-group .rjsf.card.widget .fields-wrapper .widget-value {
      display: inline-block;
      font-size: 36px;
      font-weight: 600;
      margin-right: 5px; }
      .widget-group .rjsf.card.widget .fields-wrapper .widget-value .custom-numeric-label-widget {
        display: inline-block; }
      .widget-group .rjsf.card.widget .fields-wrapper .widget-value .measurement {
        margin-left: 5px; }
      .widget-group .rjsf.card.widget .fields-wrapper .widget-value .big-value {
        font-size: 36px; }
    .widget-group .rjsf.card.widget .fields-wrapper .measurement {
      font-size: 14px;
      display: inline-block;
      font-weight: 600; }
    .widget-group .rjsf.card.widget .fields-wrapper .custom-react-dropdown {
      height: auto; }
    .widget-group .rjsf.card.widget .fields-wrapper .field-label {
      margin-bottom: 0; }
    .widget-group .rjsf.card.widget .fields-wrapper .input-group {
      border-radius: 14px;
      border: 2px solid #f1f1f9;
      background-color: #f7f9fa;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      height: 36px; }
      .widget-group .rjsf.card.widget .fields-wrapper .input-group input {
        border: 0;
        background-color: transparent;
        margin-top: -4px; }
        .widget-group .rjsf.card.widget .fields-wrapper .input-group input:nth-of-type(1n + 2) {
          height: 15px;
          border-left: 1px solid #f1f1f9;
          margin-left: 1px;
          padding-top: 0;
          padding-bottom: 0; }
        .widget-group .rjsf.card.widget .fields-wrapper .input-group input:hover {
          background-color: transparent; }
      .widget-group .rjsf.card.widget .fields-wrapper .input-group .input-group-text {
        border: 0;
        font-size: 14px;
        text-align: right;
        color: #c4c4cc;
        background-color: transparent;
        margin-top: -4px;
        margin-right: 10px; }
      .widget-group .rjsf.card.widget .fields-wrapper .input-group .form-control {
        padding-left: 10px;
        padding-right: 10px; }
    .widget-group .rjsf.card.widget .fields-wrapper textarea.form-control:disabled {
      font-weight: normal !important; }
    .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled {
      margin-top: 3px; }
    .widget-group .rjsf.card.widget .fields-wrapper .read-only-widget {
      font-weight: 600; }
    .widget-group .rjsf.card.widget .fields-wrapper .react-date-picker {
      width: 150px;
      height: 40px; }
      .widget-group .rjsf.card.widget .fields-wrapper .react-date-picker.react-date-picker--disabled {
        border: 0;
        padding: 0;
        /* sass-lint:disable class-name-format */
        /* sass-lint:enable class-name-format */ }
        .widget-group .rjsf.card.widget .fields-wrapper .react-date-picker.react-date-picker--disabled .react-date-picker__wrapper {
          margin-top: 0; }
        .widget-group .rjsf.card.widget .fields-wrapper .react-date-picker.react-date-picker--disabled input {
          color: #171f26; }
        .widget-group .rjsf.card.widget .fields-wrapper .react-date-picker.react-date-picker--disabled:hover {
          background-color: transparent; }
    .widget-group .rjsf.card.widget.half-width-widget {
      min-width: 480px;
      max-width: 480px;
      margin-right: 20px;
      display: inline-flex; }
    .widget-group .rjsf.card.widget .widget-icon-wrapper {
      display: inline-block;
      margin-left: -7px;
      margin-top: -8px; }
      .widget-group .rjsf.card.widget .widget-icon-wrapper .widget-icon {
        color: #8e8e93;
        margin: 7px 5px 0 7px; }
        .widget-group .rjsf.card.widget .widget-icon-wrapper .widget-icon path {
          fill: #8e8e93 !important; }
        .widget-group .rjsf.card.widget .widget-icon-wrapper .widget-icon.hover, .widget-group .rjsf.card.widget .widget-icon-wrapper .widget-icon:hover {
          color: #8e8e93 !important; }
          .widget-group .rjsf.card.widget .widget-icon-wrapper .widget-icon.hover path, .widget-group .rjsf.card.widget .widget-icon-wrapper .widget-icon:hover path {
            fill: #8e8e93 !important; }
        .widget-group .rjsf.card.widget .widget-icon-wrapper .widget-icon.focus, .widget-group .rjsf.card.widget .widget-icon-wrapper .widget-icon:focus {
          color: #8e8e93 !important; }
          .widget-group .rjsf.card.widget .widget-icon-wrapper .widget-icon.focus path, .widget-group .rjsf.card.widget .widget-icon-wrapper .widget-icon:focus path {
            fill: #8e8e93 !important; }
    .widget-group .rjsf.card.widget.vital-signs-widget {
      min-height: 136px; }
      .widget-group .rjsf.card.widget.vital-signs-widget .fields-wrapper {
        white-space: nowrap; }
      .widget-group .rjsf.card.widget.vital-signs-widget .widget-title {
        margin-left: 0; }
      .widget-group .rjsf.card.widget.vital-signs-widget .input-group input.form-control:disabled:first-child {
        max-width: 40%;
        width: 40%; }
      .widget-group .rjsf.card.widget.vital-signs-widget .input-group .widget-value-splitter {
        font-size: 30px;
        height: 30px;
        padding: 0;
        margin: 0 0 0 -5px;
        line-height: 1; }
      .widget-group .rjsf.card.widget.vital-signs-widget input {
        margin-top: 0; }
        .widget-group .rjsf.card.widget.vital-signs-widget input.form-control:disabled {
          font-size: 36px;
          height: 36px;
          padding: 0;
          font-weight: 600; }
          .widget-group .rjsf.card.widget.vital-signs-widget input.form-control:disabled + .input-group-append span {
            border: 0;
            background: none;
            color: #000; }
    .widget-group .rjsf.card.widget.problem-description-widget {
      padding: 10px 30px 20px 15px; }
      .widget-group .rjsf.card.widget.problem-description-widget div:first-of-type {
        line-height: 1; }
      .widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper {
        text-transform: lowercase; }
        .widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper::first-letter {
          text-transform: capitalize; }
        .widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title {
          font-weight: 600;
          color: #13304d;
          letter-spacing: 0.5px; }
      .widget-group .rjsf.card.widget.problem-description-widget .widget-created-at-label {
        left: 15px; }
    .widget-group .rjsf.card.widget.form-enabled .widget-title-wrapper {
      margin-bottom: 5px; }
    .widget-group .rjsf.card.widget.form-enabled.vital-signs-widget {
      padding-top: 17px;
      padding-bottom: 18px; }
    .widget-group .rjsf.card.widget.form-enabled .edit-button-toggle {
      margin-top: 12px;
      justify-content: space-between; }
      .widget-group .rjsf.card.widget.form-enabled .edit-button-toggle .btn {
        margin: 0;
        height: 30px;
        line-height: 14px; }
        .widget-group .rjsf.card.widget.form-enabled .edit-button-toggle .btn:first-of-type {
          margin-right: 20px; }
    .widget-group .rjsf.card.widget.form-enabled.problem-description-widget .files-dropzone,
    .widget-group .rjsf.card.widget.form-enabled.problem-description-widget .files-item {
      margin-top: 10px; }
    .widget-group .rjsf.card.widget.form-enabled.problem-description-widget .widget-title-wrapper {
      margin-bottom: 31px; }
    .widget-group .rjsf.card.widget.form-enabled.problem-description-widget .edit-button-toggle {
      justify-content: flex-end;
      margin-top: 14px; }
      .widget-group .rjsf.card.widget.form-enabled.problem-description-widget .edit-button-toggle .btn {
        margin-left: 20px; }
    .widget-group .rjsf.card.widget.draft-widget {
      border: 1px solid #cf3a1b;
      background: #fdf0ed; }
    .widget-group .rjsf.card.widget.multi-index-widget .widget-title-wrapper {
      padding-bottom: 10px; }
    .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
      font-weight: 600;
      color: #13304d;
      text-transform: none; }
    .widget-group .rjsf.card.widget.multi-index-widget .fields-wrapper {
      display: grid;
      grid-template-columns: minmax(240px, 2fr) 5fr;
      margin-top: 4px;
      margin-bottom: 10px; }
      .widget-group .rjsf.card.widget.multi-index-widget .fields-wrapper > :first-child {
        grid-row: 1 / span 10000; }

.add-questionnaire-widget-modal {
  min-width: 700px; }
  .add-questionnaire-widget-modal .widget-title-wrapper {
    margin: 0 0 3px; }
    .add-questionnaire-widget-modal .widget-title-wrapper .widget-title {
      font-weight: 600;
      color: #8e8e93;
      margin-left: 0;
      letter-spacing: 0.46px; }
  .add-questionnaire-widget-modal .modal-footer {
    padding: 15px 0 0; }

.field-radio-group {
  padding-bottom: 10px; }

.custom-numeric-label-widget {
  padding: 20px 25px; }
  .custom-numeric-label-widget.widget-label:not(.widget-label-no-style) {
    display: flex;
    align-items: center;
    min-height: 54px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    white-space: normal;
    overflow-wrap: anywhere; }
  .custom-numeric-label-widget.widget-label.include-value {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    font-size: 16px; }
    .custom-numeric-label-widget.widget-label.include-value .value {
      color: #000;
      font-size: 28px;
      margin-bottom: 4px; }
  .custom-numeric-label-widget.widget-label.colored-background {
    border-radius: 14px;
    box-shadow: 0 0 4px 0 #f1f1f9;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: calc(100% - 15px);
    margin-right: 20px;
    color: #fff !important;
    flex-direction: column-reverse; }
    .custom-numeric-label-widget.widget-label.colored-background .value {
      margin: 0;
      margin-top: 6px;
      color: #fff !important; }
    .custom-numeric-label-widget.widget-label.colored-background.color-success {
      background: #6fbea0; }
    .custom-numeric-label-widget.widget-label.colored-background.color-warning {
      background: #fb6b32; }
    .custom-numeric-label-widget.widget-label.colored-background.color-danger {
      background: #cf3a1b; }
    .custom-numeric-label-widget.widget-label.colored-background.color-default {
      background: #171f26; }

.widget-icon-label {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 6px;
  padding: 0;
  line-height: 1; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.automated-check-in {
  margin-right: 15px;
  min-width: 1000px; }
  .automated-check-in .navigation {
    margin-bottom: 33px; }
  .automated-check-in .card {
    margin-top: 17px; }
  .automated-check-in hr {
    margin-top: 0; }
  .automated-check-in .greeting-screen .welcome-graphics-card .welcome-graphics-image-card {
    width: 200px;
    height: 200px;
    border: solid 1px #d8d7ef;
    box-shadow: unset;
    margin-bottom: 0; }
  .automated-check-in .greeting-screen .welcome-graphics-card .col {
    align-items: center;
    display: flex; }
  .automated-check-in .greeting-screen .welcome-graphics-card button {
    width: 100%; }
  .automated-check-in .greeting-screen .title-comment-card label {
    margin-top: 20px; }
  .automated-check-in .final-screen button {
    margin-top: 17px; }
  .automated-check-in .sign-in-fields-screen .new-patient .card {
    margin-top: 0;
    margin-bottom: 10px; }
  .automated-check-in .sign-in-fields-screen .new-patient .table {
    margin-top: 17px;
    margin-bottom: 0; }
    .automated-check-in .sign-in-fields-screen .new-patient .table .row:last-of-type .card .row .column-cell.col-2 {
      display: block;
      padding-right: 15px;
      padding-top: 5px; }
      .automated-check-in .sign-in-fields-screen .new-patient .table .row:last-of-type .card .row .column-cell.col-2 button {
        float: right;
        min-width: inherit;
        width: auto !important; }
  .automated-check-in .sign-in-fields-screen hr {
    margin-top: 34px; }
  .automated-check-in .reason-for-visit {
    margin-top: 72px; }
    .automated-check-in .reason-for-visit .reason-for-visit-item {
      min-height: 60px;
      border: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 0;
      margin-bottom: 10px; }
      .automated-check-in .reason-for-visit .reason-for-visit-item:hover {
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.4); }
      .automated-check-in .reason-for-visit .reason-for-visit-item input:disabled {
        margin-top: 5px; }
      .automated-check-in .reason-for-visit .reason-for-visit-item .btn-primary {
        width: 100%; }
      .automated-check-in .reason-for-visit .reason-for-visit-item .btn-outline-primary {
        float: right; }
      .automated-check-in .reason-for-visit .reason-for-visit-item .cell-with-icon {
        padding: 8px 0 0 9px;
        min-width: 35px;
        max-width: 35px; }
        .automated-check-in .reason-for-visit .reason-for-visit-item .cell-with-icon i {
          fill: #f1f1f9; }
        .automated-check-in .reason-for-visit .reason-for-visit-item .cell-with-icon + .table-row-text {
          flex: 1 0;
          padding-left: 26px; }
      .automated-check-in .reason-for-visit .reason-for-visit-item .form-group {
        margin-bottom: 0; }
      .automated-check-in .reason-for-visit .reason-for-visit-item .toggle-container {
        text-align: right;
        margin-top: 10px; }
        .automated-check-in .reason-for-visit .reason-for-visit-item .toggle-container .toggle-switch {
          float: right;
          margin-left: 15px; }
    .automated-check-in .reason-for-visit .card-more-actions {
      top: 10px;
      right: 10px; }
    .automated-check-in .reason-for-visit .add-reason-card {
      width: 100%;
      height: 60px;
      border: 0;
      margin-top: 20px;
      background-color: #f7f9fa;
      text-align: center;
      font-size: 12px;
      cursor: pointer; }
      .automated-check-in .reason-for-visit .add-reason-card.disabled {
        cursor: not-allowed; }
        .automated-check-in .reason-for-visit .add-reason-card.disabled:hover {
          color: #0a83bf;
          text-decoration: none; }
  .automated-check-in .documents-and-questionnaires-screen .flow-dropdown-container hr {
    margin-top: 34px;
    margin-bottom: 37px; }
  .automated-check-in .documents-and-questionnaires-screen .flow-dropdown-container .dropdown-title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 17px; }
    .automated-check-in .documents-and-questionnaires-screen .flow-dropdown-container .dropdown-title span {
      font-weight: 600; }
  .automated-check-in .documents-and-questionnaires-screen .flow-dropdown-container .documents-or-questionnaires-table {
    margin-top: 0; }
    .automated-check-in .documents-and-questionnaires-screen .flow-dropdown-container .documents-or-questionnaires-table .table-row {
      margin-top: 0; }
      .automated-check-in .documents-and-questionnaires-screen .flow-dropdown-container .documents-or-questionnaires-table .table-row .cell-wrapper {
        padding-top: 0; }
        .automated-check-in .documents-and-questionnaires-screen .flow-dropdown-container .documents-or-questionnaires-table .table-row .cell-wrapper .font-weight-bold .type {
          font-weight: 400;
          font-size: 11px;
          text-transform: uppercase;
          color: #8e8e93; }
        .automated-check-in .documents-and-questionnaires-screen .flow-dropdown-container .documents-or-questionnaires-table .table-row .cell-wrapper .cell-with-icon {
          padding-top: 15px; }
        .automated-check-in .documents-and-questionnaires-screen .flow-dropdown-container .documents-or-questionnaires-table .table-row .cell-wrapper .version {
          padding-top: 10px; }
        .automated-check-in .documents-and-questionnaires-screen .flow-dropdown-container .documents-or-questionnaires-table .table-row .cell-wrapper .remove-btn {
          font-size: 12px;
          padding-top: 13px;
          color: #cf3a1b;
          border-radius: 0; }
      .automated-check-in .documents-and-questionnaires-screen .flow-dropdown-container .documents-or-questionnaires-table .table-row.highlight {
        animation: highlight-fade 1.5s ease-in-out 0s; }
      .automated-check-in .documents-and-questionnaires-screen .flow-dropdown-container .documents-or-questionnaires-table .table-row:last-of-type {
        margin-bottom: 0; }

.user-detail-layout .navigation {
  margin-bottom: 0; }

.user-detail-layout .user-detail .user-profile-title {
  margin: 57px 0 17px; }

.user-detail-layout .user-detail .card {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 100px; }
  .user-detail-layout .user-detail .card .form-group {
    margin: 18px 10px; }
  .user-detail-layout .user-detail .card.identification-wrapper-card {
    padding-left: 0; }
    .user-detail-layout .user-detail .card.identification-wrapper-card > .row {
      margin-left: 0;
      margin-right: 0; }
      .user-detail-layout .user-detail .card.identification-wrapper-card > .row .card-no-style {
        margin-bottom: 0; }
  .user-detail-layout .user-detail .card.avatar {
    padding-left: 20px;
    padding-right: 20px; }
    .user-detail-layout .user-detail .card.avatar .image-component {
      margin-top: 10px;
      margin-bottom: 10px; }

.user-detail-layout .user-detail .change-password-button {
  height: 56px; }

.user-detail-layout .user-detail .user-rights {
  padding-bottom: 20px; }
  .user-detail-layout .user-detail .user-rights .label-row-columns .form-group-label {
    margin-left: 10px;
    margin-top: 14px;
    margin-bottom: 0; }
  .user-detail-layout .user-detail .user-rights .remove-button-container {
    display: flex;
    justify-content: center; }
    .user-detail-layout .user-detail .user-rights .remove-button-container .btn-link-danger {
      display: flex;
      align-self: center;
      margin-top: 20px; }
  .user-detail-layout .user-detail .user-rights .btn-link:not(.btn-link-danger) {
    margin-left: 10px; }

.user-detail .card.color-picker-card {
  min-height: auto;
  padding-bottom: 20px; }
  .user-detail .card.color-picker-card .color-picker-form-group {
    margin-bottom: 0; }

.edit-documents-and-questionnaires-container hr {
  margin: 40px 0 30px; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.calendar-layout {
  max-height: 100%;
  max-width: 100%;
  padding-bottom: 0;
  font-family: "IBM Plex Sans", "sans-serif"; }
  .calendar-layout .calendar-main-container .calendar-header-container {
    width: 100%; }
    .calendar-layout .calendar-main-container .calendar-header-container .header-actions {
      width: 100%;
      min-width: 1200px; }
      .calendar-layout .calendar-main-container .calendar-header-container .header-actions .calendar-controls {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-left: 60px !important; }
        .calendar-layout .calendar-main-container .calendar-header-container .header-actions .calendar-controls .calendar-toolbar-left-side {
          display: flex;
          margin-left: 40px;
          min-width: 318px; }
          .calendar-layout .calendar-main-container .calendar-header-container .header-actions .calendar-controls .calendar-toolbar-left-side .date-title {
            font-size: 20px;
            margin-bottom: 0;
            white-space: nowrap;
            line-height: 40px;
            margin-right: 10px; }
          .calendar-layout .calendar-main-container .calendar-header-container .header-actions .calendar-controls .calendar-toolbar-left-side .moshi-toggle-tabs {
            min-width: 78px; }
        .calendar-layout .calendar-main-container .calendar-header-container .header-actions .calendar-controls .calendar-toolbar-right-side {
          display: flex; }
          .calendar-layout .calendar-main-container .calendar-header-container .header-actions .calendar-controls .calendar-toolbar-right-side .calendar-search {
            margin-right: 10px; }
            .calendar-layout .calendar-main-container .calendar-header-container .header-actions .calendar-controls .calendar-toolbar-right-side .calendar-search .calendar-search-input {
              margin-bottom: 0;
              min-width: 150px; }
          .calendar-layout .calendar-main-container .calendar-header-container .header-actions .calendar-controls .calendar-toolbar-right-side .calendar-dropdown-filter {
            margin-right: 10px; }
  .calendar-layout .calendar-main-container .calendar-sidebar {
    float: left;
    width: 250px;
    padding-right: 20px; }
    .calendar-layout .calendar-main-container .calendar-sidebar .checkbox-list-container {
      margin-bottom: 15px;
      border-bottom: 2px solid #e4e4ee;
      padding-bottom: 10px;
      margin-left: -4px;
      padding-left: 4px; }
      .calendar-layout .calendar-main-container .calendar-sidebar .checkbox-list-container .checkbox-list {
        margin-bottom: 11px; }
      .calendar-layout .calendar-main-container .calendar-sidebar .checkbox-list-container .list-title {
        font-size: 18px;
        color: #000;
        letter-spacing: 0.46px;
        margin-bottom: 5px;
        padding-left: 4px;
        padding-top: 10px;
        padding-bottom: 10px;
        align-items: center;
        display: flex;
        justify-content: space-between; }
        .calendar-layout .calendar-main-container .calendar-sidebar .checkbox-list-container .list-title .add-icon {
          float: left;
          margin-right: 5px; }
          .calendar-layout .calendar-main-container .calendar-sidebar .checkbox-list-container .list-title .add-icon:hover {
            background-color: inherit; }
        .calendar-layout .calendar-main-container .calendar-sidebar .checkbox-list-container .list-title:hover {
          background-color: #f7f9fa; }
      .calendar-layout .calendar-main-container .calendar-sidebar .checkbox-list-container .list-group-title {
        font-style: italic;
        color: #8e8e93;
        margin-bottom: 10px;
        padding-left: 4px; }
      .calendar-layout .calendar-main-container .calendar-sidebar .checkbox-list-container .add-icon {
        font-weight: 600;
        border-radius: 50%;
        width: 21px;
        height: 21px;
        text-align: center;
        line-height: 21px;
        font-size: 13px;
        margin-right: 1px;
        color: #13304d; }
        .calendar-layout .calendar-main-container .calendar-sidebar .checkbox-list-container .add-icon:hover {
          background-color: #fff; }
    .calendar-layout .calendar-main-container .calendar-sidebar .select-text {
      font-size: 15px;
      color: #0a83bf;
      padding-left: 4px; }
      .calendar-layout .calendar-main-container .calendar-sidebar .select-text:hover {
        cursor: pointer;
        color: #0ca3ed; }
  .calendar-layout .calendar-main-container .calendar-view {
    border-radius: 14px;
    box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.36) !important;
    margin-left: 250px;
    overflow: auto;
    max-height: calc(100vh - 115px); }
  .calendar-layout .calendar-main-container .calendar-filter-pills-container {
    margin-bottom: 23px; }
    .calendar-layout .calendar-main-container .calendar-filter-pills-container .moshi-tag {
      margin: 5px 10px 5px 0; }
      .calendar-layout .calendar-main-container .calendar-filter-pills-container .moshi-tag:hover {
        box-shadow: 0 4px 15px 0 #f1f1f9; }
  .calendar-layout .calendar-waiting-list-container label {
    text-transform: capitalize;
    font-size: 15px; }
  .calendar-layout .calendar-waiting-list-container .add-to-waiting-list {
    color: #0a83bf; }
  .calendar-layout .calendar-waiting-list-container .calendar-waiting-list-tabs .moshi-toggle-tabs-wrapper {
    width: 100%;
    border: 0;
    align-items: start;
    justify-content: space-between; }
    .calendar-layout .calendar-waiting-list-container .calendar-waiting-list-tabs .moshi-toggle-tabs-wrapper .moshi-toggle-tab {
      text-transform: capitalize;
      margin: 0; }

.category-indicator,
.color-indicator {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px; }
  .category-indicator.primary-background,
  .color-indicator.primary-background {
    background-color: #0a83bf; }
  .category-indicator.secondary-background,
  .color-indicator.secondary-background {
    background-color: #13304d; }
  .category-indicator.info-background,
  .color-indicator.info-background {
    background-color: #171f26; }
  .category-indicator.success-background,
  .color-indicator.success-background {
    background-color: #d8eee6; }
  .category-indicator.warning-background,
  .color-indicator.warning-background {
    background-color: #fed7c8; }
  .category-indicator.danger-background,
  .color-indicator.danger-background {
    background-color: #f7c9c0; }
  .category-indicator.yellow-background,
  .color-indicator.yellow-background {
    background-color: #fef3c8; }
  .category-indicator.purple-background,
  .color-indicator.purple-background {
    background-color: #928fd1; }

.event-notifications {
  margin-bottom: 20px;
  border-top: 1px solid #f1f1f9;
  border-bottom: 1px solid #f1f1f9; }
  .event-notifications .sent-row {
    font-family: "IBM Plex Sans", "sans-serif";
    font-weight: 500; }
  .event-notifications .scheduled-row .cell-wrapper {
    color: #5c758e !important; }

.calendar-date-picker-popover .popover {
  background-color: #fff;
  min-width: 460px; }
  .calendar-date-picker-popover .popover .popover-body {
    padding: 0;
    display: flex; }
    .calendar-date-picker-popover .popover .popover-body .calendar-daypicker-container .custom-daypicker {
      width: 240px;
      border-right: 1px solid #dce3e3; }
      .calendar-date-picker-popover .popover .popover-body .calendar-daypicker-container .custom-daypicker .react-calendar {
        box-shadow: none; }
    .calendar-date-picker-popover .popover .popover-body .calendar-popover-jump-to-container {
      font-family: "IBM Plex Sans", "sans-serif";
      padding: 15px; }
      .calendar-date-picker-popover .popover .popover-body .calendar-popover-jump-to-container .buttons-grid {
        display: grid;
        grid-template-columns: auto auto;
        grid-auto-rows: 30px;
        align-items: center;
        width: 100%; }
        .calendar-date-picker-popover .popover .popover-body .calendar-popover-jump-to-container .buttons-grid .moshi-button {
          margin: 0 3px; }

.calendar-sidebar-height {
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-height: calc(100vh - 107px);
  overflow: auto; }
  .calendar-sidebar-height::-webkit-scrollbar {
    width: 0;
    height: 0; }

.calendar-prev-next-container {
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 42px;
  margin-left: 10px;
  background-color: #fff;
  min-width: 275px;
  border: solid 1px #e5e5e1; }
  .calendar-prev-next-container .today,
  .calendar-prev-next-container .calendar-date {
    font-size: 15px;
    font-family: "IBM Plex Sans", "sans-serif";
    font-weight: 400; }
    .calendar-prev-next-container .today:hover,
    .calendar-prev-next-container .calendar-date:hover {
      cursor: pointer;
      color: #0a83bf; }
    .calendar-prev-next-container .today:focus,
    .calendar-prev-next-container .calendar-date:focus {
      outline: none; }
  .calendar-prev-next-container .calendar-date {
    margin-left: 15px;
    width: 160px;
    text-align: center; }
  .calendar-prev-next-container .calendar-prev-next-button {
    border-radius: 14px;
    align-items: center;
    display: flex;
    height: 30px;
    width: 30px;
    text-align: center; }
    .calendar-prev-next-container .calendar-prev-next-button i {
      margin: auto; }
    .calendar-prev-next-container .calendar-prev-next-button:hover {
      cursor: pointer;
      color: #0a83bf; }
    .calendar-prev-next-container .calendar-prev-next-button:focus {
      outline: none; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.billing {
  /* sass-lint:disable class-name-format */ }
  .billing .billing-list-header {
    display: flex;
    padding: 10px 12px;
    margin: 0;
    background: #fff;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px; }
    .billing .billing-list-header .billing-list-filters {
      display: flex;
      flex-grow: 1;
      margin-right: 10px; }
      .billing .billing-list-header .billing-list-filters .search-field-inverted {
        flex-grow: 1; }
      .billing .billing-list-header .billing-list-filters > div {
        margin-right: 10px; }
        .billing .billing-list-header .billing-list-filters > div:last-child {
          margin-right: 0; }
    .billing .billing-list-header > button {
      margin-left: auto; }
  .billing .billing-invoice-list {
    min-width: 1300px; }
  .billing .bill-section {
    max-width: 1250px;
    min-width: 900px;
    margin: 0 auto;
    padding-left: 60px !important;
    padding-right: 60px !important; }
  .billing .bill-form .bill-document-left-header-section {
    max-width: 400px; }
    .billing .bill-form .bill-document-left-header-section .bill-client-name-container .form-group {
      display: inline-block;
      width: calc(100% - 40px);
      vertical-align: top; }
    .billing .bill-form .bill-document-left-header-section .bill-client-name-container i {
      position: relative;
      top: 36px;
      margin-left: 10px; }
      .billing .bill-form .bill-document-left-header-section .bill-client-name-container i.disabled {
        color: #b4c2c9;
        cursor: not-allowed; }
  .billing .bill-form hr {
    margin-top: 6px;
    margin-bottom: 10px; }
  .billing .bill-form .inline-label-inputs-container .custom-daypicker,
  .billing .bill-form .inline-label-inputs-container .custom-react-dropdown {
    width: 200px; }
  .billing .bill-form .inline-label-inputs-container .due-date-container {
    display: flex;
    justify-content: flex-end; }
    .billing .bill-form .inline-label-inputs-container .due-date-container .due-date-dropdown {
      max-width: 140px;
      margin-right: 20px; }
      .billing .bill-form .inline-label-inputs-container .due-date-container .due-date-dropdown .dropdown-toggle {
        overflow: visible; }
  .billing .bill-form .number-input-group input {
    width: 200px; }
  .billing .bill-form .array-invalid {
    margin-top: 5px;
    margin-left: 10px;
    color: #cf3a1b;
    font-size: 14px; }
  .billing .bill-form .bill-item-section .bill-item-list-header {
    margin-top: 10px;
    margin-bottom: 10px; }
  .billing .bill-form .bill-item-section .array-invalid {
    text-align: center; }
  .billing .bill-form .bill-item-section .bill-totals-container {
    margin-top: 50px; }
  .billing .bill-form.header-text-only header {
    margin-bottom: 15px; }
  .billing .bill-preview .bill-items-preview {
    margin-top: 10px;
    margin-bottom: 60px; }
    .billing .bill-preview .bill-items-preview .bill-item-row {
      padding: 20px 0;
      border-top: 1px solid #f1f1f9; }
      .billing .bill-preview .bill-items-preview .bill-item-row .bill-item-description {
        margin-bottom: 10px;
        margin-top: 15px;
        margin-left: 22px;
        color: #5c758e; }
  .billing .bill-preview .invoice-signature-text {
    float: right;
    color: #406080; }
  .billing .bill-preview .invoice-footer {
    margin-top: 40px;
    margin-bottom: 10px;
    color: #406080;
    text-align: center; }
  .billing .bill-preview .bill-dates .bill-dates-container span {
    text-align: right;
    display: block; }
  .billing .bill-preview .bill-detail-fiscalization .not-fiscalized-warning > span {
    display: block;
    color: #cf3a1b;
    text-transform: uppercase;
    font-weight: 600; }
  .billing .bill-preview .bill-detail-fiscalization .not-fiscalized-warning > button {
    margin-top: 10px; }
  .billing .bill-preview .bill-detail-fiscalization .qr-code-container {
    display: inline-block;
    border: 1px solid #f1f1f9;
    padding: 15px;
    padding-bottom: 10px; }
  .billing .bill-sidebar .bill-status-section {
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px; }
  .billing .bill-sidebar .billing-invoice-payments-container .add-payment-container .add-payment-button {
    vertical-align: top;
    width: calc(50% - 5px);
    min-height: 51px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0; }
    .billing .bill-sidebar .billing-invoice-payments-container .add-payment-container .add-payment-button:nth-last-child(1), .billing .bill-sidebar .billing-invoice-payments-container .add-payment-container .add-payment-button:nth-last-child(2) {
      margin-bottom: 0; }
    .billing .bill-sidebar .billing-invoice-payments-container .add-payment-container .add-payment-button > button {
      margin: 0;
      min-height: 51px; }
  .billing .bill-sidebar .billing-invoice-payments-container .back-to-payments-container {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-align: center; }
  .billing .bill-sidebar .billing-invoice-payments-container .payment-list-container .payment-list-tag:not(:empty)::after {
    content: " - "; }
  .billing .bill-sidebar .billing-invoice-payments-container .payment-list-container .amount-due-container {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f1f1f9;
    padding-top: 12px;
    font-weight: 600;
    margin-bottom: 5px; }
  .billing .bill-sidebar .billing-invoice-payments-container .payment-list-container .payment-hover-container {
    visibility: visible; }
    .billing .bill-sidebar .billing-invoice-payments-container .payment-list-container .payment-hover-container:not(:hover) .visible-on-hover {
      visibility: hidden !important; }
  .billing .bill-sidebar .advance-usage-section {
    display: flex;
    justify-content: space-between;
    font-weight: 600; }
  .billing .bill-referenced-documents {
    margin-top: 50px; }
    .billing .bill-referenced-documents ul {
      margin-top: 8px;
      padding-inline-start: 20px; }
      .billing .bill-referenced-documents ul button {
        padding-top: 4px;
        padding-bottom: 4px; }
  .billing .invoice-custom-datepicker .react-date-picker__calendar--open,
  .billing .invoice-custom-due-datepicker .react-date-picker__calendar--open {
    left: -50px !important; }
  .billing .export-bill-document-form {
    width: 468px;
    padding: 15px 10px 5px; }
  .billing .billing-sidepanel {
    padding: 32px 40px 40px; }
  .billing .overlay-header .bill-type-icon-container {
    margin-right: 14px; }
  .billing .overlay-header.invoice-overlay-header {
    background-color: #d8eee6; }
  .billing .overlay-header.advance-overlay-header {
    background-color: #f7c9c0; }
  .billing .overlay-header.estimate-overlay-header {
    background-color: #fef3c8; }
  .billing .overlay-header.credit-note-overlay-header {
    background-color: #d8d7ef; }

.bill-items .bill-item-list-container {
  margin-bottom: 20px; }
  .bill-items .bill-item-list-container textarea:hover {
    background-color: #fff !important; }
  .bill-items .bill-item-list-container .bill-item {
    width: auto !important;
    margin: 0 -60px;
    padding: 0 60px; }
    .bill-items .bill-item-list-container .bill-item .remove-cancel-icon {
      margin-left: 8px;
      padding: 8px;
      outline: none; }
      .bill-items .bill-item-list-container .bill-item .remove-cancel-icon i {
        border-radius: 50%; }
    .bill-items .bill-item-list-container .bill-item .bill-item-name-container {
      display: flex;
      margin-bottom: 1rem; }
      .bill-items .bill-item-list-container .bill-item .bill-item-name-container .form-group {
        margin-bottom: 0;
        flex-grow: 1; }
      .bill-items .bill-item-list-container .bill-item .bill-item-name-container i {
        position: relative;
        top: 3px;
        padding: 5px;
        font-size: 20px;
        cursor: pointer; }
        .bill-items .bill-item-list-container .bill-item .bill-item-name-container i:focus {
          outline: none; }
  .bill-items .bill-item-list-container .bill-item-description-container .bill-item-description-textarea {
    margin-bottom: 15px; }

.bill-items .bill-item-buttons {
  text-align: center; }

.bill-totals-container {
  width: 100%;
  background-color: #fff;
  font-weight: 400; }
  .bill-totals-container > .row {
    padding: 10px 0; }
    .bill-totals-container > .row:last-child {
      border-top: 1px solid #f1f1f9; }

.invoice-sum-placeholder {
  height: 31px;
  background-color: #f5f7f8;
  display: block; }

.cash-register-general-info {
  margin-bottom: 20px;
  margin-top: 25px; }
  .cash-register-general-info > div {
    display: inline-block;
    margin-bottom: 10px; }
    .cash-register-general-info > div:not(:last-child) {
      margin-right: 40px; }
    .cash-register-general-info > div > .grey-label {
      display: block; }

.cash-register-create-card {
  padding: 10px !important; }
  .cash-register-create-card:hover {
    box-shadow: 0 0 30px 0 #b4c2c9;
    cursor: pointer; }
  .cash-register-create-card .cash-register-create-card-data {
    height: 100%;
    align-items: center; }

.cash-payments-tag {
  border-radius: 100px;
  padding: 3px 18px;
  font-weight: 600;
  border: 2px solid #f1f1f9; }

.cash-register-detail {
  margin: 30px auto;
  max-width: 1200px; }
  .cash-register-detail span,
  .cash-register-detail div {
    font-size: 16px; }

.cash-register-title {
  font-size: 24px; }

.cash-register-summary {
  margin-bottom: 20px; }
  .cash-register-summary .payment-type {
    padding-right: 0; }
    .cash-register-summary .payment-type .payment-tags {
      font-size: 11px;
      color: #406080;
      padding-left: 5px; }
  .cash-register-summary .total-revenue {
    padding-right: 0;
    padding-bottom: 16px; }
    .cash-register-summary .total-revenue div {
      display: flex;
      justify-content: space-between; }
  .cash-register-summary .darkened-form-area {
    padding: 18px 20px 20px 23px; }
    .cash-register-summary .darkened-form-area .row:first-child {
      margin-bottom: 22px; }
    .cash-register-summary .darkened-form-area .row:not(:first-child) {
      margin-top: 16px; }
    .cash-register-summary .darkened-form-area .row > div:not(:first-child) {
      text-align: right; }

.billing-unit-detail {
  margin-bottom: 78px; }
  .billing-unit-detail .billing-unit-fiscalization {
    width: 830px; }
    .billing-unit-detail .billing-unit-fiscalization .card-title h3 {
      font-weight: 400; }
    .billing-unit-detail .billing-unit-fiscalization .card-body {
      padding-bottom: 0; }
    .billing-unit-detail .billing-unit-fiscalization .fiscalization-status .toggle-switch-container {
      align-items: center; }
      .billing-unit-detail .billing-unit-fiscalization .fiscalization-status .toggle-switch-container .toggle-switch-label {
        font-size: 20px;
        font-weight: 400; }
    .billing-unit-detail .billing-unit-fiscalization .billing-unit-fiscalization-act {
      border-radius: 14px;
      margin-top: 30px;
      border: 1px solid #f1f1f9;
      height: 580px;
      overflow: hidden; }
      .billing-unit-detail .billing-unit-fiscalization .billing-unit-fiscalization-act .billing-unit-fiscalization-act-overflow {
        overflow-y: scroll;
        height: 100%; }
    .billing-unit-detail .billing-unit-fiscalization .fiscalization-section-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .billing-unit-detail .billing-unit-fiscalization .fiscalization-section-actions.center-align {
        justify-content: center; }
      .billing-unit-detail .billing-unit-fiscalization .fiscalization-section-actions .btn:first-child {
        margin-right: 10px; }
      .billing-unit-detail .billing-unit-fiscalization .fiscalization-section-actions .btn.btn-icon {
        display: block; }

.billing-unit-list .billing-unit-list-item h3 {
  height: initial;
  min-height: 18px; }

.billing-unit-list .billing-unit-list-item .billing-unit-list-item-actions {
  display: flex; }
  .billing-unit-list .billing-unit-list-item .billing-unit-list-item-actions .btn {
    margin-left: 40px;
    max-height: 35px; }
    .billing-unit-list .billing-unit-list-item .billing-unit-list-item-actions .btn:first-child {
      margin-left: 0; }

.bill-pdf-modal form {
  margin: 30px; }

.no-log-modal {
  min-width: unset;
  max-width: 650px;
  text-align: center; }
  .no-log-modal .modal-body {
    padding: 30px !important; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.therapy-detail-form,
.new-patient-active-therapy-form {
  padding-bottom: 100px;
  width: 550px; }

.therapy-widget {
  min-width: 900px; }
  .therapy-widget.past-therapy {
    color: #13304d !important;
    background-color: #f7f9fa; }
    .therapy-widget.past-therapy .intake-progress .progress-bar {
      background-color: rgba(10, 131, 191, 0.5) !important; }
    .therapy-widget.past-therapy .intake-progress .intake-target .current-intake-number {
      color: #8e8e93; }
  .therapy-widget hr {
    margin-top: 15px;
    margin-bottom: 0; }
  .therapy-widget .metadata {
    justify-content: space-between;
    color: #406080;
    font-size: 11px;
    text-transform: uppercase; }
    .therapy-widget .metadata .card-more-actions {
      top: 12px;
      right: 18px; }
  .therapy-widget .therapy-preview {
    font-size: 20px; }
  .therapy-widget .therapy-notification-preview .therapy-info {
    font-weight: 500;
    font-size: 14px -1; }
  .therapy-widget .therapy-notification-preview .therapy-instructions {
    font-weight: 500;
    font-size: 14px -1; }
    .therapy-widget .therapy-notification-preview .therapy-instructions .simple-gray-blue-card {
      padding: 10px 20px; }
      .therapy-widget .therapy-notification-preview .therapy-instructions .simple-gray-blue-card p {
        margin-bottom: 0;
        white-space: pre-wrap; }
  .therapy-widget .duration-info {
    font-weight: 600;
    font-size: 11px;
    color: #13304d;
    letter-spacing: 0.46px; }
  .therapy-widget .intake-progress {
    height: 44px;
    margin-top: 10px;
    align-items: center; }
    .therapy-widget .intake-progress .intake-target {
      color: #8e8e93;
      font-size: 11px;
      letter-spacing: 0.46px;
      font-weight: 500;
      margin-left: 30px; }
      .therapy-widget .intake-progress .intake-target .current-intake-number {
        letter-spacing: 0.46px;
        color: #0a83bf; }
  .therapy-widget .therapy-actions .moshi-tag-button {
    margin-left: 5px; }
    .therapy-widget .therapy-actions .moshi-tag-button:first-child {
      margin-left: 0; }
  .therapy-widget .intake-table .collapsible-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px; }
    .therapy-widget .intake-table .collapsible-title .intake-info {
      color: #000;
      font-size: 14px; }
    .therapy-widget .intake-table .collapsible-title .duration-info {
      margin: 0;
      color: #5c758e;
      font-size: 11px; }
  .therapy-widget .intake-table h3 {
    font-size: 14px;
    display: flex;
    height: 22px; }
    .therapy-widget .intake-table h3 i {
      color: #122f4c; }
  .therapy-widget .moshi-expand-list-panel {
    margin: -10px -20px -20px;
    width: calc(100% + 40px); }

.past-therapy-list h3 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 20px; }

.unbilled-tile {
  margin-bottom: 20px; }
  .unbilled-tile .unbilled-tile-title {
    display: inline;
    font-weight: 600; }
  .unbilled-tile .unbilled-tile-count {
    position: relative;
    top: -2px;
    float: right;
    font-weight: 400;
    color: #13304d; }
  .unbilled-tile .unbilled-tile-list-wrapper {
    position: relative; }
    .unbilled-tile .unbilled-tile-list-wrapper .unbilled-tile-list {
      scrollbar-width: none;
      -ms-overflow-style: none;
      max-height: 350px;
      overflow-y: scroll;
      padding-left: 15px;
      margin-left: -15px;
      padding-right: 20px;
      margin-right: -20px; }
      .unbilled-tile .unbilled-tile-list-wrapper .unbilled-tile-list::-webkit-scrollbar {
        width: 0;
        height: 0; }
      .unbilled-tile .unbilled-tile-list-wrapper .unbilled-tile-list .patient-unbilled-item {
        margin-bottom: 10px;
        padding: 12px;
        display: flex;
        flex-direction: row; }
        .unbilled-tile .unbilled-tile-list-wrapper .unbilled-tile-list .patient-unbilled-item .checkbox {
          padding: 0;
          margin: 0 8px 0 0;
          width: 20px; }
        .unbilled-tile .unbilled-tile-list-wrapper .unbilled-tile-list .patient-unbilled-item .item-quantity {
          display: block;
          color: #8e8e93; }
        .unbilled-tile .unbilled-tile-list-wrapper .unbilled-tile-list .patient-unbilled-item .item-price {
          display: block;
          float: right; }
        .unbilled-tile .unbilled-tile-list-wrapper .unbilled-tile-list .patient-unbilled-item:focus {
          outline: 0; }
        .unbilled-tile .unbilled-tile-list-wrapper .unbilled-tile-list .patient-unbilled-item:first-child {
          margin-top: 10px; }
    .unbilled-tile .unbilled-tile-list-wrapper .scroll-gradient {
      position: absolute;
      width: 100%;
      height: 10px;
      z-index: 2; }
      .unbilled-tile .unbilled-tile-list-wrapper .scroll-gradient.top {
        background: linear-gradient(to bottom, #e6eded, rgba(235, 241, 245, 0)); }
      .unbilled-tile .unbilled-tile-list-wrapper .scroll-gradient.bottom {
        bottom: 0;
        background: linear-gradient(to top, #e6eded, rgba(235, 241, 245, 0)); }
  .unbilled-tile .unbilled-tile-more-action {
    margin-top: 4px;
    margin-bottom: 2px;
    text-align: center; }
  .unbilled-tile .unbilled-tile-actions .btn-group {
    width: 100%; }

.unbilled-form form {
  max-width: 1400px;
  min-width: 1000px;
  margin: 0 auto; }
  .unbilled-form form .bill-items .bill-item-list-container .bill-item {
    margin: 0;
    padding: 0;
    width: 100% !important; }

.unbilled-form .unbilled-form-user-label {
  color: #406080;
  font-size: 12px;
  font-weight: 400;
  float: right;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  position: relative;
  top: -10px;
  margin-bottom: 5px; }

.unbilled-items-overlay-patient-details {
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column; }

.unbilled-items-overlay-totals {
  margin: 0 15px;
  overflow: hidden; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.analytics .filters-container {
  display: flex;
  align-items: flex-start; }

.analytics .select-location {
  min-width: 200px; }

.analytics .min-height-400 {
  min-height: 400px; }

.analytics .date-container {
  border-radius: 14px;
  border: 0;
  height: 40px;
  margin-right: 10px;
  padding: 6px 12px;
  background-color: #f7f9fa;
  display: flex;
  align-items: center; }
  .analytics .date-container:hover, .analytics .date-container:active {
    background-color: #fff;
    box-shadow: 0 0 4px 0 #f1f1f9, 0 8px 30px 0 #c4c4cc; }
  .analytics .date-container.danger {
    background-color: #f1a393 !important;
    box-shadow: 0 0 4px 0 #f1f1f9, 0 8px 30px 0 #c4c4cc; }
  .analytics .date-container .date-label {
    display: block;
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 400;
    color: #8e8e93; }
  .analytics .date-container .date-value {
    align-items: center;
    display: block;
    font-size: 14px;
    font-weight: 400; }
    .analytics .date-container .date-value .date-range {
      display: flex; }
      .analytics .date-container .date-value .date-range .range-separator {
        margin: 0 3px; }
    .analytics .date-container .date-value .date-icon {
      align-self: center; }
      .analytics .date-container .date-value .date-icon:hover {
        color: #0a83bf; }
  .analytics .date-container .invalid-date-range {
    display: inline;
    font-size: 12px;
    font-weight: 400;
    color: #e55639; }
  .analytics .date-container .custom-daypicker {
    margin: 0; }
  .analytics .date-container .date-pick {
    padding: 0;
    height: 20px;
    font-size: 14px;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer; }
    .analytics .date-container .date-pick input {
      cursor: pointer; }
      .analytics .date-container .date-pick input:hover {
        color: #0a83bf; }
    .analytics .date-container .date-pick :first-child :first-child {
      min-width: 0; }

.analytics .analytics-chart-container {
  border-radius: 14px;
  padding-top: 20px;
  background-color: #ebf1f5;
  height: calc(100% - 20px);
  max-height: 94vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column; }

.analytics .table-header label {
  overflow: hidden;
  text-overflow: ellipsis; }

.analytics .card-height-container {
  height: 100%;
  padding-bottom: 20px; }

.analytics .card-height-100 {
  height: 100%; }

.analytics .dashboard-date-picker {
  display: flex;
  flex-direction: row;
  align-items: baseline; }
  .analytics .dashboard-date-picker .date-text {
    display: flex;
    flex-direction: row;
    align-items: baseline; }
  .analytics .dashboard-date-picker .today:hover {
    cursor: pointer;
    color: #0a83bf; }
  .analytics .dashboard-date-picker .today:focus {
    outline: none; }

.billing-item-edit .overlay-section > * {
  margin: 0 auto;
  min-width: 800px;
  max-width: 1000px; }

.billing-item-edit label {
  font-weight: 600;
  color: #5c758e !important; }

.billing-item-edit .item-edit-translations {
  margin-bottom: 90px !important; }
  .billing-item-edit .item-edit-translations .required-red-dot,
  .billing-item-edit .item-edit-translations .invalid-feedback {
    display: none; }

.calendar-view-settings-container .form-title {
  font-size: 18px;
  color: #171f26; }

.calendar-view-settings-container .trigger-label,
.calendar-view-settings-container .checkbox-label,
.calendar-view-settings-container .radio-label {
  text-transform: none !important;
  color: #171f26 !important;
  font-size: 16px !important;
  font-weight: normal !important; }

.calendar-view-settings-container .day-of-week {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 1px solid #e4e4ee;
  color: #8e8e93;
  font-size: 12px;
  justify-content: center;
  align-items: center; }
  .calendar-view-settings-container .day-of-week.active {
    background-color: #0a83bf;
    color: #fff;
    border: 1px solid transparent; }

.calendar-view-settings-container .event-date-input .invalid-feedback {
  margin-left: 0; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.inbound-documents .inbound-document-list-header-wrapper .inbound-document-list-header {
  display: flex;
  margin: 10px; }
  .inbound-documents .inbound-document-list-header-wrapper .inbound-document-list-header > * {
    margin-right: 10px; }

.inbound-documents .inbound-document-list-header-wrapper .inbound-document-list-patient-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4ee;
  margin-bottom: 10px;
  padding: 10px; }

.inbound-documents .inbound-document-list-header-wrapper .view-dicom-toolbar {
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #f7f9fa;
  border: solid 1px #e4e4ee; }
  .inbound-documents .inbound-document-list-header-wrapper .view-dicom-toolbar button {
    font-size: 16px;
    padding: 4px 8px;
    margin: 0 5px; }
    .inbound-documents .inbound-document-list-header-wrapper .view-dicom-toolbar button.btn-light {
      color: #0a83bf;
      border: solid 1px #e4e4ee;
      background-color: #fff; }
      .inbound-documents .inbound-document-list-header-wrapper .view-dicom-toolbar button.btn-light:hover {
        background-color: #caebfc;
        border-color: #0a83bf; }
    .inbound-documents .inbound-document-list-header-wrapper .view-dicom-toolbar button:first-child {
      margin-left: 0; }
    .inbound-documents .inbound-document-list-header-wrapper .view-dicom-toolbar button:last-child {
      margin-right: 0; }

.inbound-documents .inbound-document-list-header-wrapper .inbound-document-tabs .active.inbound-document-submission-tab {
  background-color: #4eac88; }

.inbound-documents .inbound-document-list-header-wrapper .inbound-document-tabs .active.inbound-document-request-tab {
  background-color: #fdd230; }

.inbound-documents .inbound-document-list-header-wrapper .inbound-document-tabs .active.inbound-document-template-tab {
  background-color: #317dc8; }

.inbound-documents .inbound-document-list-header-wrapper .inbound-date-range-filter .moshi-date-range-picker-empty-state,
.inbound-documents .inbound-document-list-header-wrapper .inbound-date-range-filter .moshi-date-range-field {
  min-width: 297px; }

.inbound-documents .inbound-document-list-header-wrapper .inbound-creator-filter {
  min-width: 140px; }

.inbound-documents .inbound-documents-overlay .overlay-header {
  background-color: #caebfc; }
  .inbound-documents .inbound-documents-overlay .overlay-header.request {
    background-color: #fef3c8; }
  .inbound-documents .inbound-documents-overlay .overlay-header.submitted {
    background-color: #d8eee6; }
  .inbound-documents .inbound-documents-overlay .overlay-header .inbound-document-header {
    margin: 0 auto; }
    .inbound-documents .inbound-documents-overlay .overlay-header .inbound-document-header .title {
      position: relative;
      bottom: 2px;
      font-size: 24px;
      font-weight: 600; }

.inbound-documents .inbound-documents-overlay .overlay-left-side-container .form-preview-toggle {
  margin-bottom: 20px; }
  .inbound-documents .inbound-documents-overlay .overlay-left-side-container .form-preview-toggle .toggle-switch-container {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 0; }
    .inbound-documents .inbound-documents-overlay .overlay-left-side-container .form-preview-toggle .toggle-switch-container .toggle-switch {
      margin-left: 10px; }
    .inbound-documents .inbound-documents-overlay .overlay-left-side-container .form-preview-toggle .toggle-switch-container .toggle-switch-label {
      font-weight: 400; }

.inbound-documents .inbound-documents-overlay .overlay-left-side-container form {
  height: unset; }

.inbound-documents .inbound-documents-overlay .overlay-right-side-container {
  overflow-y: auto; }

.inbound-documents .inbound-documents-overlay .form-builder-left-side .form-builder {
  margin: 0 auto;
  max-width: 700px;
  padding-bottom: 78px; }

.inbound-documents .inbound-documents-overlay .form-builder-right-side {
  min-width: 360px;
  height: calc(100% - 75px);
  margin-bottom: 75px; }
  .inbound-documents .inbound-documents-overlay .form-builder-right-side .overlay-section {
    padding: 16px 24px !important;
    font-size: 16px; }
  .inbound-documents .inbound-documents-overlay .form-builder-right-side .add-form-field-section {
    color: #0a83bf; }
    .inbound-documents .inbound-documents-overlay .form-builder-right-side .add-form-field-section:hover {
      color: #0ca3ed; }
    .inbound-documents .inbound-documents-overlay .form-builder-right-side .add-form-field-section i {
      margin-right: 20px;
      position: relative;
      top: 1px; }
  .inbound-documents .inbound-documents-overlay .form-builder-right-side .document-collapsible :nth-child(2) {
    margin-top: 0; }
  .inbound-documents .inbound-documents-overlay .form-builder-right-side .document-is-clinical-field {
    display: flex; }
    .inbound-documents .inbound-documents-overlay .form-builder-right-side .document-is-clinical-field .is-clinical-label {
      flex-grow: 1; }

.inbound-documents .inbound-documents-overlay .form-preview-right-side {
  width: 440px; }
  .inbound-documents .inbound-documents-overlay .form-preview-right-side .form-status-section {
    font-size: 12px; }
    .inbound-documents .inbound-documents-overlay .form-preview-right-side .form-status-section .form-status {
      border-radius: 6px;
      padding: 4px 8px;
      margin-right: 9px;
      color: #fff; }
      .inbound-documents .inbound-documents-overlay .form-preview-right-side .form-status-section .form-status.submitted {
        background-color: #4eac88; }
      .inbound-documents .inbound-documents-overlay .form-preview-right-side .form-status-section .form-status.request {
        background-color: #f7c403; }

.moshi-form .form-control:disabled:not(:read-only), .moshi-form .form-control.disabled:not(:read-only), .moshi-form .form-control.readonly:not(:read-only),
form .form-control:disabled:not(:read-only),
form .form-control.disabled:not(:read-only),
form .form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input, .moshi-form .form-control:disabled:not(:read-only) input, .moshi-form .form-control.disabled:not(:read-only) input, .moshi-form .form-control.readonly:not(:read-only) input, form .form-control:disabled:not(:read-only) input, form .form-control.disabled:not(:read-only) input, form .form-control.readonly:not(:read-only) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input, .moshi-form .edit-mode-toggler-disabled:not(.checkbox),
form .edit-mode-toggler-disabled:not(.checkbox) {
  background: none;
  border: 0;
  padding-left: 0;
  height: 30px;
  font-weight: 600;
  color: #000; }
  .moshi-form .form-control:disabled:not(:read-only) + label, .moshi-form .form-control.disabled:not(:read-only) + label, .moshi-form .form-control.readonly:not(:read-only) + label, form .form-control:disabled:not(:read-only) + label, form .form-control.disabled:not(:read-only) + label, form .form-control.readonly:not(:read-only) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) + label, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input + label, .moshi-form .form-control:disabled:not(:read-only) input + label, .moshi-form .form-control.disabled:not(:read-only) input + label, .moshi-form .form-control.readonly:not(:read-only) input + label, form .form-control:disabled:not(:read-only) input + label, form .form-control.disabled:not(:read-only) input + label, form .form-control.readonly:not(:read-only) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input + label, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input + label, .moshi-form .edit-mode-toggler-disabled:not(.checkbox) + label, form .edit-mode-toggler-disabled:not(.checkbox) + label {
    margin: 0; }
  .moshi-form .form-control:not(:read-only):disabled::placeholder, .moshi-form .form-control.disabled:not(:read-only)::placeholder, .moshi-form .form-control.readonly:not(:read-only)::placeholder,
  form .form-control:not(:read-only):disabled::placeholder,
  form .form-control.disabled:not(:read-only)::placeholder,
  form .form-control.readonly:not(:read-only)::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(textarea):disabled::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:not(.readonly-look):disabled::placeholder, .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input::placeholder, .moshi-form .form-control:disabled:not(:read-only) input::placeholder, .moshi-form .form-control.disabled:not(:read-only) input::placeholder, .moshi-form .form-control.readonly:not(:read-only) input::placeholder, form .form-control:disabled:not(:read-only) input::placeholder, form .form-control.disabled:not(:read-only) input::placeholder, form .form-control.readonly:not(:read-only) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input::placeholder, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input::placeholder, .moshi-form .edit-mode-toggler-disabled:not(.checkbox)::placeholder,
  form .edit-mode-toggler-disabled:not(.checkbox)::placeholder {
    color: #fff; }
  .moshi-form .form-group.disabled-no-style.form-control:disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.disabled:not(:read-only), .moshi-form .form-group.disabled-no-style.form-control.readonly:not(:read-only),
  form .form-group.disabled-no-style.form-control:disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.disabled:not(:read-only),
  form .form-group.disabled-no-style.form-control.readonly:not(:read-only), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(textarea), .widget-group .rjsf.card.widget .fields-wrapper .form-group.disabled-no-style.form-control:disabled:not(.readonly-look), .moshi-form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, form .form-control.edit-mode-toggler-disabled:not(.checkbox) input.form-group.disabled-no-style, .moshi-form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, .moshi-form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, form .form-control:disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.disabled:not(:read-only) input.form-group.disabled-no-style, form .form-control.readonly:not(:read-only) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(textarea) input.form-group.disabled-no-style, .widget-group .rjsf.card.widget .fields-wrapper .form-control:disabled:not(.readonly-look) input.form-group.disabled-no-style, .moshi-form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox),
  form .form-group.disabled-no-style.edit-mode-toggler-disabled:not(.checkbox) {
    margin-top: 0;
    margin-bottom: 0; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  color: #000; }

.moshi-modal.modal .modal-dialog .modal-content .modal-header .modal-title .modal-text-title {
  line-height: 40px;
  font-size: 36px;
  height: 36px;
  font-weight: 600; }

.widget-group .rjsf.card.widget.problem-description-widget .widget-title-wrapper .widget-title, .widget-group .rjsf.card.widget.multi-index-widget .widget-title {
  font-size: 20px;
  height: 20px; }

.custom-container header, nav.content-sidebar header {
  font-weight: 600;
  font-size: 36px;
  align-items: center; }

a:disabled, .dropdown---dropdown---1yvIZ .dropdown-toggle.button-look:disabled, .moshi-link.btn-link:disabled, .moshi-link.btn-link-primary:disabled, .moshi-link.btn-link-danger:disabled, .moshi-link.btn-link-black:disabled, .moshi-link.btn-link-white:disabled, .moshi-button:disabled, .moshi-popover.date-picker-popover .date-picker-values button:disabled {
  opacity: 0.1; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.moshi-form .invalid-feedback.array-invalid,
.moshi-form .invalid-tooltip.array-invalid,
form .invalid-feedback.array-invalid,
form .invalid-tooltip.array-invalid {
  display: block; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div, .flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row, .moshi-date-range-picker-empty-state, .flex-justify-align-middle-row, .flex-center-all-row-important, .therapy-widget .metadata {
  display: flex !important; }

.flex-align-middle-column, .flex-justify-middle-column, .flex-column, .card-with-map .card-body > .row .map-fields,
.address-card .card-body > .row .map-fields, .rjsf > .field-object > fieldset, .rjsf > .field-object > fieldset .field-string > div > div,
.rjsf > .field-object > fieldset .field-array > div > div,
.rjsf > .field-object > fieldset .field-number > div > div {
  flex-direction: column; }

.flex-align-middle-row, .flex-justify-end-row, .flex-justify-start-row, .flex-space-between, .flex-row {
  flex-direction: row; }

.moshi-date-range-picker-empty-state {
  align-items: center;
  align-content: center; }

a.hover, a:hover, a.focus, a:focus, .cursor-pointer:hover, .clickable-text.hover, .clickable-text:hover, .icon:hover:not([disabled]), .moshi-link.btn-link.hover, .moshi-link.btn-link:hover, .moshi-link.btn-link-primary.hover, .moshi-link.btn-link-primary:hover, .moshi-link.btn-link.focus, .moshi-link.btn-link:focus, .moshi-link.btn-link-primary.focus, .moshi-link.btn-link-primary:focus, .moshi-link.btn-link-danger.hover, .moshi-link.btn-link-danger:hover, .moshi-link.btn-link-danger.focus, .moshi-link.btn-link-danger:focus, .moshi-link.btn-link-black.hover, .moshi-link.btn-link-black:hover, .moshi-link.btn-link-black.focus, .moshi-link.btn-link-black:focus, .moshi-link.btn-link-white.hover, .moshi-link.btn-link-white:hover, .moshi-link.btn-link-white.focus, .moshi-link.btn-link-white:focus, .moshi-table .table-row .card-more-actions .row-action-btn, .sidepanel-overlay .sidepanel-container .sidepanel-content .sidepanel-close-btn-container, .patient-detail-past-encounters .table .row:hover {
  cursor: pointer !important; }

.moshi-form .form-control,
form .form-control, .geosuggest__input, .moshi-timepicker .rc-time-picker-input {
  color: #000;
  font-weight: 400;
  font-size: 15px; }

.grey-label, .moshi-form .form-group label:not(.btn),
form .form-group label:not(.btn), .moshi-form .form-group-label,
form .form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label,
.modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label, .document-detail-modal .modal-content .modal-header .modal-title label {
  text-transform: none;
  color: #8e8e93;
  font-size: 12px;
  font-weight: 500; }
  .form-group-label-disabled.grey-label, .moshi-form .form-group label.form-group-label-disabled:not(.btn),
  form .form-group label.form-group-label-disabled:not(.btn), .moshi-form .form-group-label-disabled.form-group-label,
  form .form-group-label-disabled.form-group-label, .modal .remove-patient-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled,
  .modal .close-encounter-success-modal .modal-content .modal-body.close-encounter-success-body .card label.form-group-label-disabled, .document-detail-modal .modal-content .modal-header .modal-title label.form-group-label-disabled {
    margin-bottom: 0; }

.metadata-label {
  text-transform: none;
  color: #406080;
  font-size: 12px;
  font-weight: 400; }

.no-hover, nav.sidebar div.navigation.navigation-no-hover,
nav.sidebar a.navigation.navigation-no-hover {
  cursor: initial !important; }
  .no-hover:hover, nav.sidebar div.navigation.navigation-no-hover:hover,
  nav.sidebar a.navigation.navigation-no-hover:hover {
    background-color: initial !important;
    color: initial !important; }

.moshi-form label.checkbox,
form label.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  user-select: none;
  height: 20px; }
  .moshi-form label.checkbox input, form label.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .moshi-form label.checkbox input:disabled, form label.checkbox input:disabled {
      cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-label, form label.checkbox input:disabled ~ .checkbox-label {
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled ~ .checkbox-icon, form label.checkbox input:disabled ~ .checkbox-icon {
        background-color: #e6eded !important;
        cursor: not-allowed; }
      .moshi-form label.checkbox input:disabled:checked ~ .checkbox-icon, form label.checkbox input:disabled:checked ~ .checkbox-icon {
        background-color: #0a83bf !important; }
    .moshi-form label.checkbox input:checked ~ .checkbox-icon, form label.checkbox input:checked ~ .checkbox-icon {
      background-color: #0a83bf; }
      .moshi-form label.checkbox input:checked ~ .checkbox-icon::after, form label.checkbox input:checked ~ .checkbox-icon::after {
        display: block;
        border-width: 0 2px 2px 0; }
  .moshi-form label.checkbox .checkbox-icon, form label.checkbox .checkbox-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 4px; }
    .moshi-form label.checkbox .checkbox-icon::after, form label.checkbox .checkbox-icon::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg); }
  .moshi-form label.checkbox .checkbox-label, form label.checkbox .checkbox-label {
    text-transform: none;
    font-size: 14px;
    color: #000; }
  .moshi-form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled) ~ .checkbox-icon {
    background-color: #cdcdea; }
  .moshi-form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon, form label.checkbox:hover input:not(:disabled):checked ~ .checkbox-icon {
    background-color: #07628f; }

.moshi-form label.radio,
form label.radio {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer !important;
  user-select: none;
  height: 20px; }
  .moshi-form label.disabled.radio,
  form label.disabled.radio {
    cursor: not-allowed !important; }
  .moshi-form label.radio + .radio-note, form label.radio + .radio-note {
    color: #80b0e0;
    font-weight: bold;
    margin-left: 30px; }
  .moshi-form label.radio input, form label.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .moshi-form label.radio input:disabled ~ .radio-icon, form label.radio input:disabled ~ .radio-icon {
      background-color: #e6eded; }
    .moshi-form label.radio input:checked ~ .radio-icon, form label.radio input:checked ~ .radio-icon {
      background-color: #0a83bf; }
      .moshi-form label.radio input:checked ~ .radio-icon::after, form label.radio input:checked ~ .radio-icon::after {
        display: block; }
  .moshi-form label.radio .radio-icon, form label.radio .radio-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #dfdff1;
    border-radius: 50%; }
    .moshi-form label.radio .radio-icon::after, form label.radio .radio-icon::after {
      content: "";
      position: absolute;
      display: none;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #fff; }
  .moshi-form label.radio .radio-label, form label.radio .radio-label {
    text-transform: uppercase;
    font-size: 11px;
    color: #8e8e93; }
  .moshi-form label.radio:hover input:not(:disabled) ~ .radio-icon, form label.radio:hover input:not(:disabled) ~ .radio-icon {
    background-color: #cdcdea; }
  .moshi-form label.radio:hover input:not(:disabled):checked ~ .radio-icon, form label.radio:hover input:not(:disabled):checked ~ .radio-icon {
    background-color: #07628f; }
  .moshi-form label.radio-as-button.radio,
  form label.radio-as-button.radio {
    height: 40px;
    border: 1px solid #e5e5e1 !important;
    margin-right: 10px;
    padding-left: 40px; }
    .moshi-form label.radio-as-button.radio .radio-label, form label.radio-as-button.radio .radio-label {
      color: #171f26;
      text-transform: lowercase;
      font-weight: 400;
      font-size: 14px; }
      .moshi-form label.radio-as-button.radio .radio-label::first-letter, form label.radio-as-button.radio .radio-label::first-letter {
        text-transform: uppercase; }
    .moshi-form label.radio-as-button.radio .radio-icon, form label.radio-as-button.radio .radio-icon {
      background-color: #fff;
      border: 2px solid #dce3e3;
      height: 18px;
      width: 18px;
      top: 50%;
      margin-top: -9px;
      left: 10px; }
      .moshi-form label.radio-as-button.radio .radio-icon::after, form label.radio-as-button.radio .radio-icon::after {
        background: transparent; }
    .moshi-form label.radio-as-button.radio input:checked ~ .radio-icon, form label.radio-as-button.radio input:checked ~ .radio-icon {
      background-color: #fff;
      border: 5px solid #0a83bf !important; }
    .moshi-form label.radio-as-button.checked.radio,
    form label.radio-as-button.checked.radio {
      background-color: #e2f4fd !important; }
    .moshi-form label.radio-as-button.radio:hover,
    form label.radio-as-button.radio:hover {
      background-color: #e2f4fd !important; }
      .moshi-form label.radio-as-button.radio:hover input ~ .radio-icon, form label.radio-as-button.radio:hover input ~ .radio-icon {
        background-color: #fff; }
      .moshi-form label.radio-as-button.radio:hover input:checked ~ .radio-icon, form label.radio-as-button.radio:hover input:checked ~ .radio-icon {
        background-color: #fff; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Ikonate" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.outbound-documents .overlay-header {
  background-color: #d8d7ef; }
  .outbound-documents .overlay-header .outbound-document-header {
    margin: 0 auto; }
    .outbound-documents .overlay-header .outbound-document-header .title {
      position: relative;
      bottom: 2px;
      font-size: 24px;
      font-weight: 600; }

.outbound-documents .overlay-content-container .overlay-content {
  display: grid !important;
  grid-template-columns: auto max-content; }

.outbound-documents .overlay-content-container .form-preview-left-side .view-document-wrapper {
  min-width: 830px; }

.outbound-documents .overlay-content-container .form-preview-toggle {
  margin-bottom: 20px; }
  .outbound-documents .overlay-content-container .form-preview-toggle .toggle-switch-container .toggle-switch {
    margin-left: 10px; }
  .outbound-documents .overlay-content-container .form-preview-toggle .toggle-switch-container .toggle-switch-label {
    font-weight: 400; }

.outbound-documents .overlay-content-container .outbound-document-pdf-preview-wrapper .outbound-document-pdf-preview {
  padding: 60px 60px 15px;
  min-width: 1050px; }
  .outbound-documents .overlay-content-container .outbound-document-pdf-preview-wrapper .outbound-document-pdf-preview .form-preview-toggle {
    max-width: 920px;
    margin: 0 auto 20px; }

.outbound-documents .overlay-content-container .view-pdf-container {
  text-align: center;
  min-width: 1050px; }
  .outbound-documents .overlay-content-container .view-pdf-container .view-pdf-page {
    margin-bottom: 50px; }
    .outbound-documents .overlay-content-container .view-pdf-container .view-pdf-page .react-pdf__Page__canvas {
      box-shadow: 0 8px 30px 0 rgba(18, 47, 76, 0.3);
      margin: auto; }

.outbound-documents .overlay-content-container .outbound-overlay-action-buttons .pdf-action-button {
  vertical-align: top;
  width: calc(50% - 5px);
  min-height: 51px;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 10px; }
  .outbound-documents .overlay-content-container .outbound-overlay-action-buttons .pdf-action-button:nth-child(even) {
    margin-right: 0; }
  .outbound-documents .overlay-content-container .outbound-overlay-action-buttons .pdf-action-button:nth-last-child(1), .outbound-documents .overlay-content-container .outbound-overlay-action-buttons .pdf-action-button:nth-last-child(2) {
    margin-bottom: 0; }
  .outbound-documents .overlay-content-container .outbound-overlay-action-buttons .pdf-action-button > button {
    margin: 0;
    min-height: 51px; }

.outbound-documents .overlay-content-container .outbound-new-container {
  max-width: 1200px;
  margin: 20px auto 100px;
  padding-bottom: 100px; }

.outbound-document-settings .form-container,
.outbound-document-settings legend-container {
  flex: 0 0 100%;
  max-width: 100%; }

.outbound-document-settings .toggle-switch-label {
  margin-right: 5px;
  font-weight: 400; }

@media (min-width: 1300px) {
  .collapsed .outbound-document-settings .form-container {
    flex: 0 0 75%;
    max-width: 75%; }
  .collapsed .outbound-document-settings .legend-container {
    flex: 0 0 25%;
    max-width: 25%; } }

@media (min-width: 1475px) {
  :not(.collapsed) .outbound-document-settings .form-container {
    flex: 0 0 75%;
    max-width: 75%; }
  :not(.collapsed) .outbound-document-settings .legend-container {
    flex: 0 0 25%;
    max-width: 25%; } }

@keyframes highlight-fade {
  from {
    background: #dce3e3;
    border-color: #0a83bf;
    border-width: 2px; }
  to {
    background: #f1f1f9;
    border-color: transparent; } }

@keyframes highlight-fade-odd {
  from {
    background: #dce3e3;
    border-color: #0a83bf;
    border-width: 2px; }
  to {
    background: #f1f1f9;
    border-color: transparent; } }

@keyframes highlight-fade-orange-to-white {
  0% {
    background-color: #fff1eb; }
  75% {
    background-color: #fff; } }

@keyframes highlight-fade-blue-to-transparent {
  0% {
    background-color: #0a83bf; }
  75% {
    background-color: transparent; } }

.margin-0 {
  margin: 0px; }

.margin-0-important {
  margin: 0px !important; }

.margin-1 {
  margin: 1px; }

.margin-1-important {
  margin: 1px !important; }

.margin-2 {
  margin: 2px; }

.margin-2-important {
  margin: 2px !important; }

.margin-3 {
  margin: 3px; }

.margin-3-important {
  margin: 3px !important; }

.margin-4 {
  margin: 4px; }

.margin-4-important {
  margin: 4px !important; }

.margin-5 {
  margin: 5px; }

.margin-5-important {
  margin: 5px !important; }

.margin-6 {
  margin: 6px; }

.margin-6-important {
  margin: 6px !important; }

.margin-7 {
  margin: 7px; }

.margin-7-important {
  margin: 7px !important; }

.margin-8 {
  margin: 8px; }

.margin-8-important {
  margin: 8px !important; }

.margin-9 {
  margin: 9px; }

.margin-9-important {
  margin: 9px !important; }

.margin-10 {
  margin: 10px; }

.margin-10-important {
  margin: 10px !important; }

.margin-11 {
  margin: 11px; }

.margin-11-important {
  margin: 11px !important; }

.margin-12 {
  margin: 12px; }

.margin-12-important {
  margin: 12px !important; }

.margin-13 {
  margin: 13px; }

.margin-13-important {
  margin: 13px !important; }

.margin-14 {
  margin: 14px; }

.margin-14-important {
  margin: 14px !important; }

.margin-15 {
  margin: 15px; }

.margin-15-important {
  margin: 15px !important; }

.margin-16 {
  margin: 16px; }

.margin-16-important {
  margin: 16px !important; }

.margin-17 {
  margin: 17px; }

.margin-17-important {
  margin: 17px !important; }

.margin-18 {
  margin: 18px; }

.margin-18-important {
  margin: 18px !important; }

.margin-19 {
  margin: 19px; }

.margin-19-important {
  margin: 19px !important; }

.margin-20 {
  margin: 20px; }

.margin-20-important {
  margin: 20px !important; }

.margin-top-0 {
  margin-top: 0px; }

.margin-top-0-important {
  margin-top: 0px !important; }

.margin-top-1 {
  margin-top: 1px; }

.margin-top-1-important {
  margin-top: 1px !important; }

.margin-top-2 {
  margin-top: 2px; }

.margin-top-2-important {
  margin-top: 2px !important; }

.margin-top-3 {
  margin-top: 3px; }

.margin-top-3-important {
  margin-top: 3px !important; }

.margin-top-4 {
  margin-top: 4px; }

.margin-top-4-important {
  margin-top: 4px !important; }

.margin-top-5 {
  margin-top: 5px; }

.margin-top-5-important {
  margin-top: 5px !important; }

.margin-top-6 {
  margin-top: 6px; }

.margin-top-6-important {
  margin-top: 6px !important; }

.margin-top-7 {
  margin-top: 7px; }

.margin-top-7-important {
  margin-top: 7px !important; }

.margin-top-8 {
  margin-top: 8px; }

.margin-top-8-important {
  margin-top: 8px !important; }

.margin-top-9 {
  margin-top: 9px; }

.margin-top-9-important {
  margin-top: 9px !important; }

.margin-top-10 {
  margin-top: 10px; }

.margin-top-10-important {
  margin-top: 10px !important; }

.margin-top-11 {
  margin-top: 11px; }

.margin-top-11-important {
  margin-top: 11px !important; }

.margin-top-12 {
  margin-top: 12px; }

.margin-top-12-important {
  margin-top: 12px !important; }

.margin-top-13 {
  margin-top: 13px; }

.margin-top-13-important {
  margin-top: 13px !important; }

.margin-top-14 {
  margin-top: 14px; }

.margin-top-14-important {
  margin-top: 14px !important; }

.margin-top-15 {
  margin-top: 15px; }

.margin-top-15-important {
  margin-top: 15px !important; }

.margin-top-16 {
  margin-top: 16px; }

.margin-top-16-important {
  margin-top: 16px !important; }

.margin-top-17 {
  margin-top: 17px; }

.margin-top-17-important {
  margin-top: 17px !important; }

.margin-top-18 {
  margin-top: 18px; }

.margin-top-18-important {
  margin-top: 18px !important; }

.margin-top-19 {
  margin-top: 19px; }

.margin-top-19-important {
  margin-top: 19px !important; }

.margin-top-20 {
  margin-top: 20px; }

.margin-top-20-important {
  margin-top: 20px !important; }

.margin-top-21 {
  margin-top: 21px; }

.margin-top-21-important {
  margin-top: 21px !important; }

.margin-top-22 {
  margin-top: 22px; }

.margin-top-22-important {
  margin-top: 22px !important; }

.margin-top-23 {
  margin-top: 23px; }

.margin-top-23-important {
  margin-top: 23px !important; }

.margin-top-24 {
  margin-top: 24px; }

.margin-top-24-important {
  margin-top: 24px !important; }

.margin-top-25 {
  margin-top: 25px; }

.margin-top-25-important {
  margin-top: 25px !important; }

.margin-top-26 {
  margin-top: 26px; }

.margin-top-26-important {
  margin-top: 26px !important; }

.margin-top-27 {
  margin-top: 27px; }

.margin-top-27-important {
  margin-top: 27px !important; }

.margin-top-28 {
  margin-top: 28px; }

.margin-top-28-important {
  margin-top: 28px !important; }

.margin-top-29 {
  margin-top: 29px; }

.margin-top-29-important {
  margin-top: 29px !important; }

.margin-top-30 {
  margin-top: 30px; }

.margin-top-30-important {
  margin-top: 30px !important; }

.margin-top-31 {
  margin-top: 31px; }

.margin-top-31-important {
  margin-top: 31px !important; }

.margin-top-32 {
  margin-top: 32px; }

.margin-top-32-important {
  margin-top: 32px !important; }

.margin-top-33 {
  margin-top: 33px; }

.margin-top-33-important {
  margin-top: 33px !important; }

.margin-top-34 {
  margin-top: 34px; }

.margin-top-34-important {
  margin-top: 34px !important; }

.margin-top-35 {
  margin-top: 35px; }

.margin-top-35-important {
  margin-top: 35px !important; }

.margin-top-36 {
  margin-top: 36px; }

.margin-top-36-important {
  margin-top: 36px !important; }

.margin-top-37 {
  margin-top: 37px; }

.margin-top-37-important {
  margin-top: 37px !important; }

.margin-top-38 {
  margin-top: 38px; }

.margin-top-38-important {
  margin-top: 38px !important; }

.margin-top-39 {
  margin-top: 39px; }

.margin-top-39-important {
  margin-top: 39px !important; }

.margin-top-40 {
  margin-top: 40px; }

.margin-top-40-important {
  margin-top: 40px !important; }

.margin-top-41 {
  margin-top: 41px; }

.margin-top-41-important {
  margin-top: 41px !important; }

.margin-top-42 {
  margin-top: 42px; }

.margin-top-42-important {
  margin-top: 42px !important; }

.margin-top-43 {
  margin-top: 43px; }

.margin-top-43-important {
  margin-top: 43px !important; }

.margin-top-44 {
  margin-top: 44px; }

.margin-top-44-important {
  margin-top: 44px !important; }

.margin-top-45 {
  margin-top: 45px; }

.margin-top-45-important {
  margin-top: 45px !important; }

.margin-top-46 {
  margin-top: 46px; }

.margin-top-46-important {
  margin-top: 46px !important; }

.margin-top-47 {
  margin-top: 47px; }

.margin-top-47-important {
  margin-top: 47px !important; }

.margin-top-48 {
  margin-top: 48px; }

.margin-top-48-important {
  margin-top: 48px !important; }

.margin-top-49 {
  margin-top: 49px; }

.margin-top-49-important {
  margin-top: 49px !important; }

.margin-top-50 {
  margin-top: 50px; }

.margin-top-50-important {
  margin-top: 50px !important; }

.margin-top-51 {
  margin-top: 51px; }

.margin-top-51-important {
  margin-top: 51px !important; }

.margin-top-52 {
  margin-top: 52px; }

.margin-top-52-important {
  margin-top: 52px !important; }

.margin-top-53 {
  margin-top: 53px; }

.margin-top-53-important {
  margin-top: 53px !important; }

.margin-top-54 {
  margin-top: 54px; }

.margin-top-54-important {
  margin-top: 54px !important; }

.margin-top-55 {
  margin-top: 55px; }

.margin-top-55-important {
  margin-top: 55px !important; }

.margin-top-56 {
  margin-top: 56px; }

.margin-top-56-important {
  margin-top: 56px !important; }

.margin-top-57 {
  margin-top: 57px; }

.margin-top-57-important {
  margin-top: 57px !important; }

.margin-top-58 {
  margin-top: 58px; }

.margin-top-58-important {
  margin-top: 58px !important; }

.margin-top-59 {
  margin-top: 59px; }

.margin-top-59-important {
  margin-top: 59px !important; }

.margin-top-60 {
  margin-top: 60px; }

.margin-top-60-important {
  margin-top: 60px !important; }

.margin-top-61 {
  margin-top: 61px; }

.margin-top-61-important {
  margin-top: 61px !important; }

.margin-top-62 {
  margin-top: 62px; }

.margin-top-62-important {
  margin-top: 62px !important; }

.margin-top-63 {
  margin-top: 63px; }

.margin-top-63-important {
  margin-top: 63px !important; }

.margin-top-64 {
  margin-top: 64px; }

.margin-top-64-important {
  margin-top: 64px !important; }

.margin-top-65 {
  margin-top: 65px; }

.margin-top-65-important {
  margin-top: 65px !important; }

.margin-top-66 {
  margin-top: 66px; }

.margin-top-66-important {
  margin-top: 66px !important; }

.margin-top-67 {
  margin-top: 67px; }

.margin-top-67-important {
  margin-top: 67px !important; }

.margin-top-68 {
  margin-top: 68px; }

.margin-top-68-important {
  margin-top: 68px !important; }

.margin-top-69 {
  margin-top: 69px; }

.margin-top-69-important {
  margin-top: 69px !important; }

.margin-top-70 {
  margin-top: 70px; }

.margin-top-70-important {
  margin-top: 70px !important; }

.margin-top-71 {
  margin-top: 71px; }

.margin-top-71-important {
  margin-top: 71px !important; }

.margin-top-72 {
  margin-top: 72px; }

.margin-top-72-important {
  margin-top: 72px !important; }

.margin-top-73 {
  margin-top: 73px; }

.margin-top-73-important {
  margin-top: 73px !important; }

.margin-top-74 {
  margin-top: 74px; }

.margin-top-74-important {
  margin-top: 74px !important; }

.margin-top-75 {
  margin-top: 75px; }

.margin-top-75-important {
  margin-top: 75px !important; }

.margin-top-76 {
  margin-top: 76px; }

.margin-top-76-important {
  margin-top: 76px !important; }

.margin-top-77 {
  margin-top: 77px; }

.margin-top-77-important {
  margin-top: 77px !important; }

.margin-top-78 {
  margin-top: 78px; }

.margin-top-78-important {
  margin-top: 78px !important; }

.margin-top-79 {
  margin-top: 79px; }

.margin-top-79-important {
  margin-top: 79px !important; }

.margin-top-80 {
  margin-top: 80px; }

.margin-top-80-important {
  margin-top: 80px !important; }

.margin-top-81 {
  margin-top: 81px; }

.margin-top-81-important {
  margin-top: 81px !important; }

.margin-top-82 {
  margin-top: 82px; }

.margin-top-82-important {
  margin-top: 82px !important; }

.margin-top-83 {
  margin-top: 83px; }

.margin-top-83-important {
  margin-top: 83px !important; }

.margin-top-84 {
  margin-top: 84px; }

.margin-top-84-important {
  margin-top: 84px !important; }

.margin-top-85 {
  margin-top: 85px; }

.margin-top-85-important {
  margin-top: 85px !important; }

.margin-top-86 {
  margin-top: 86px; }

.margin-top-86-important {
  margin-top: 86px !important; }

.margin-top-87 {
  margin-top: 87px; }

.margin-top-87-important {
  margin-top: 87px !important; }

.margin-top-88 {
  margin-top: 88px; }

.margin-top-88-important {
  margin-top: 88px !important; }

.margin-top-89 {
  margin-top: 89px; }

.margin-top-89-important {
  margin-top: 89px !important; }

.margin-top-90 {
  margin-top: 90px; }

.margin-top-90-important {
  margin-top: 90px !important; }

.margin-right-0 {
  margin-right: 0px; }

.margin-right-0-important {
  margin-right: 0px !important; }

.margin-right-1 {
  margin-right: 1px; }

.margin-right-1-important {
  margin-right: 1px !important; }

.margin-right-2 {
  margin-right: 2px; }

.margin-right-2-important {
  margin-right: 2px !important; }

.margin-right-3 {
  margin-right: 3px; }

.margin-right-3-important {
  margin-right: 3px !important; }

.margin-right-4 {
  margin-right: 4px; }

.margin-right-4-important {
  margin-right: 4px !important; }

.margin-right-5 {
  margin-right: 5px; }

.margin-right-5-important {
  margin-right: 5px !important; }

.margin-right-6 {
  margin-right: 6px; }

.margin-right-6-important {
  margin-right: 6px !important; }

.margin-right-7 {
  margin-right: 7px; }

.margin-right-7-important {
  margin-right: 7px !important; }

.margin-right-8 {
  margin-right: 8px; }

.margin-right-8-important {
  margin-right: 8px !important; }

.margin-right-9 {
  margin-right: 9px; }

.margin-right-9-important {
  margin-right: 9px !important; }

.margin-right-10 {
  margin-right: 10px; }

.margin-right-10-important {
  margin-right: 10px !important; }

.margin-right-11 {
  margin-right: 11px; }

.margin-right-11-important {
  margin-right: 11px !important; }

.margin-right-12 {
  margin-right: 12px; }

.margin-right-12-important {
  margin-right: 12px !important; }

.margin-right-13 {
  margin-right: 13px; }

.margin-right-13-important {
  margin-right: 13px !important; }

.margin-right-14 {
  margin-right: 14px; }

.margin-right-14-important {
  margin-right: 14px !important; }

.margin-right-15 {
  margin-right: 15px; }

.margin-right-15-important {
  margin-right: 15px !important; }

.margin-right-16 {
  margin-right: 16px; }

.margin-right-16-important {
  margin-right: 16px !important; }

.margin-right-17 {
  margin-right: 17px; }

.margin-right-17-important {
  margin-right: 17px !important; }

.margin-right-18 {
  margin-right: 18px; }

.margin-right-18-important {
  margin-right: 18px !important; }

.margin-right-19 {
  margin-right: 19px; }

.margin-right-19-important {
  margin-right: 19px !important; }

.margin-right-20 {
  margin-right: 20px; }

.margin-right-20-important {
  margin-right: 20px !important; }

.margin-right-21 {
  margin-right: 21px; }

.margin-right-21-important {
  margin-right: 21px !important; }

.margin-right-22 {
  margin-right: 22px; }

.margin-right-22-important {
  margin-right: 22px !important; }

.margin-right-23 {
  margin-right: 23px; }

.margin-right-23-important {
  margin-right: 23px !important; }

.margin-right-24 {
  margin-right: 24px; }

.margin-right-24-important {
  margin-right: 24px !important; }

.margin-right-25 {
  margin-right: 25px; }

.margin-right-25-important {
  margin-right: 25px !important; }

.margin-right-26 {
  margin-right: 26px; }

.margin-right-26-important {
  margin-right: 26px !important; }

.margin-right-27 {
  margin-right: 27px; }

.margin-right-27-important {
  margin-right: 27px !important; }

.margin-right-28 {
  margin-right: 28px; }

.margin-right-28-important {
  margin-right: 28px !important; }

.margin-right-29 {
  margin-right: 29px; }

.margin-right-29-important {
  margin-right: 29px !important; }

.margin-right-30 {
  margin-right: 30px; }

.margin-right-30-important {
  margin-right: 30px !important; }

.margin-right-31 {
  margin-right: 31px; }

.margin-right-31-important {
  margin-right: 31px !important; }

.margin-right-32 {
  margin-right: 32px; }

.margin-right-32-important {
  margin-right: 32px !important; }

.margin-right-33 {
  margin-right: 33px; }

.margin-right-33-important {
  margin-right: 33px !important; }

.margin-right-34 {
  margin-right: 34px; }

.margin-right-34-important {
  margin-right: 34px !important; }

.margin-right-35 {
  margin-right: 35px; }

.margin-right-35-important {
  margin-right: 35px !important; }

.margin-right-36 {
  margin-right: 36px; }

.margin-right-36-important {
  margin-right: 36px !important; }

.margin-right-37 {
  margin-right: 37px; }

.margin-right-37-important {
  margin-right: 37px !important; }

.margin-right-38 {
  margin-right: 38px; }

.margin-right-38-important {
  margin-right: 38px !important; }

.margin-right-39 {
  margin-right: 39px; }

.margin-right-39-important {
  margin-right: 39px !important; }

.margin-right-40 {
  margin-right: 40px; }

.margin-right-40-important {
  margin-right: 40px !important; }

.margin-bottom-0 {
  margin-bottom: 0px; }

.margin-bottom-0-important {
  margin-bottom: 0px !important; }

.margin-bottom-1 {
  margin-bottom: 1px; }

.margin-bottom-1-important {
  margin-bottom: 1px !important; }

.margin-bottom-2 {
  margin-bottom: 2px; }

.margin-bottom-2-important {
  margin-bottom: 2px !important; }

.margin-bottom-3 {
  margin-bottom: 3px; }

.margin-bottom-3-important {
  margin-bottom: 3px !important; }

.margin-bottom-4 {
  margin-bottom: 4px; }

.margin-bottom-4-important {
  margin-bottom: 4px !important; }

.margin-bottom-5 {
  margin-bottom: 5px; }

.margin-bottom-5-important {
  margin-bottom: 5px !important; }

.margin-bottom-6 {
  margin-bottom: 6px; }

.margin-bottom-6-important {
  margin-bottom: 6px !important; }

.margin-bottom-7 {
  margin-bottom: 7px; }

.margin-bottom-7-important {
  margin-bottom: 7px !important; }

.margin-bottom-8 {
  margin-bottom: 8px; }

.margin-bottom-8-important {
  margin-bottom: 8px !important; }

.margin-bottom-9 {
  margin-bottom: 9px; }

.margin-bottom-9-important {
  margin-bottom: 9px !important; }

.margin-bottom-10 {
  margin-bottom: 10px; }

.margin-bottom-10-important {
  margin-bottom: 10px !important; }

.margin-bottom-11 {
  margin-bottom: 11px; }

.margin-bottom-11-important {
  margin-bottom: 11px !important; }

.margin-bottom-12 {
  margin-bottom: 12px; }

.margin-bottom-12-important {
  margin-bottom: 12px !important; }

.margin-bottom-13 {
  margin-bottom: 13px; }

.margin-bottom-13-important {
  margin-bottom: 13px !important; }

.margin-bottom-14 {
  margin-bottom: 14px; }

.margin-bottom-14-important {
  margin-bottom: 14px !important; }

.margin-bottom-15 {
  margin-bottom: 15px; }

.margin-bottom-15-important {
  margin-bottom: 15px !important; }

.margin-bottom-16 {
  margin-bottom: 16px; }

.margin-bottom-16-important {
  margin-bottom: 16px !important; }

.margin-bottom-17 {
  margin-bottom: 17px; }

.margin-bottom-17-important {
  margin-bottom: 17px !important; }

.margin-bottom-18 {
  margin-bottom: 18px; }

.margin-bottom-18-important {
  margin-bottom: 18px !important; }

.margin-bottom-19 {
  margin-bottom: 19px; }

.margin-bottom-19-important {
  margin-bottom: 19px !important; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.margin-bottom-20-important {
  margin-bottom: 20px !important; }

.margin-bottom-21 {
  margin-bottom: 21px; }

.margin-bottom-21-important {
  margin-bottom: 21px !important; }

.margin-bottom-22 {
  margin-bottom: 22px; }

.margin-bottom-22-important {
  margin-bottom: 22px !important; }

.margin-bottom-23 {
  margin-bottom: 23px; }

.margin-bottom-23-important {
  margin-bottom: 23px !important; }

.margin-bottom-24 {
  margin-bottom: 24px; }

.margin-bottom-24-important {
  margin-bottom: 24px !important; }

.margin-bottom-25 {
  margin-bottom: 25px; }

.margin-bottom-25-important {
  margin-bottom: 25px !important; }

.margin-bottom-26 {
  margin-bottom: 26px; }

.margin-bottom-26-important {
  margin-bottom: 26px !important; }

.margin-bottom-27 {
  margin-bottom: 27px; }

.margin-bottom-27-important {
  margin-bottom: 27px !important; }

.margin-bottom-28 {
  margin-bottom: 28px; }

.margin-bottom-28-important {
  margin-bottom: 28px !important; }

.margin-bottom-29 {
  margin-bottom: 29px; }

.margin-bottom-29-important {
  margin-bottom: 29px !important; }

.margin-bottom-30 {
  margin-bottom: 30px; }

.margin-bottom-30-important {
  margin-bottom: 30px !important; }

.margin-bottom-31 {
  margin-bottom: 31px; }

.margin-bottom-31-important {
  margin-bottom: 31px !important; }

.margin-bottom-32 {
  margin-bottom: 32px; }

.margin-bottom-32-important {
  margin-bottom: 32px !important; }

.margin-bottom-33 {
  margin-bottom: 33px; }

.margin-bottom-33-important {
  margin-bottom: 33px !important; }

.margin-bottom-34 {
  margin-bottom: 34px; }

.margin-bottom-34-important {
  margin-bottom: 34px !important; }

.margin-bottom-35 {
  margin-bottom: 35px; }

.margin-bottom-35-important {
  margin-bottom: 35px !important; }

.margin-bottom-36 {
  margin-bottom: 36px; }

.margin-bottom-36-important {
  margin-bottom: 36px !important; }

.margin-bottom-37 {
  margin-bottom: 37px; }

.margin-bottom-37-important {
  margin-bottom: 37px !important; }

.margin-bottom-38 {
  margin-bottom: 38px; }

.margin-bottom-38-important {
  margin-bottom: 38px !important; }

.margin-bottom-39 {
  margin-bottom: 39px; }

.margin-bottom-39-important {
  margin-bottom: 39px !important; }

.margin-bottom-40 {
  margin-bottom: 40px; }

.margin-bottom-40-important {
  margin-bottom: 40px !important; }

.margin-bottom-41 {
  margin-bottom: 41px; }

.margin-bottom-41-important {
  margin-bottom: 41px !important; }

.margin-bottom-42 {
  margin-bottom: 42px; }

.margin-bottom-42-important {
  margin-bottom: 42px !important; }

.margin-bottom-43 {
  margin-bottom: 43px; }

.margin-bottom-43-important {
  margin-bottom: 43px !important; }

.margin-bottom-44 {
  margin-bottom: 44px; }

.margin-bottom-44-important {
  margin-bottom: 44px !important; }

.margin-bottom-45 {
  margin-bottom: 45px; }

.margin-bottom-45-important {
  margin-bottom: 45px !important; }

.margin-bottom-46 {
  margin-bottom: 46px; }

.margin-bottom-46-important {
  margin-bottom: 46px !important; }

.margin-bottom-47 {
  margin-bottom: 47px; }

.margin-bottom-47-important {
  margin-bottom: 47px !important; }

.margin-bottom-48 {
  margin-bottom: 48px; }

.margin-bottom-48-important {
  margin-bottom: 48px !important; }

.margin-bottom-49 {
  margin-bottom: 49px; }

.margin-bottom-49-important {
  margin-bottom: 49px !important; }

.margin-bottom-50 {
  margin-bottom: 50px; }

.margin-bottom-50-important {
  margin-bottom: 50px !important; }

.margin-bottom-51 {
  margin-bottom: 51px; }

.margin-bottom-51-important {
  margin-bottom: 51px !important; }

.margin-bottom-52 {
  margin-bottom: 52px; }

.margin-bottom-52-important {
  margin-bottom: 52px !important; }

.margin-bottom-53 {
  margin-bottom: 53px; }

.margin-bottom-53-important {
  margin-bottom: 53px !important; }

.margin-bottom-54 {
  margin-bottom: 54px; }

.margin-bottom-54-important {
  margin-bottom: 54px !important; }

.margin-bottom-55 {
  margin-bottom: 55px; }

.margin-bottom-55-important {
  margin-bottom: 55px !important; }

.margin-bottom-56 {
  margin-bottom: 56px; }

.margin-bottom-56-important {
  margin-bottom: 56px !important; }

.margin-bottom-57 {
  margin-bottom: 57px; }

.margin-bottom-57-important {
  margin-bottom: 57px !important; }

.margin-bottom-58 {
  margin-bottom: 58px; }

.margin-bottom-58-important {
  margin-bottom: 58px !important; }

.margin-bottom-59 {
  margin-bottom: 59px; }

.margin-bottom-59-important {
  margin-bottom: 59px !important; }

.margin-bottom-60 {
  margin-bottom: 60px; }

.margin-bottom-60-important {
  margin-bottom: 60px !important; }

.margin-bottom-61 {
  margin-bottom: 61px; }

.margin-bottom-61-important {
  margin-bottom: 61px !important; }

.margin-bottom-62 {
  margin-bottom: 62px; }

.margin-bottom-62-important {
  margin-bottom: 62px !important; }

.margin-bottom-63 {
  margin-bottom: 63px; }

.margin-bottom-63-important {
  margin-bottom: 63px !important; }

.margin-bottom-64 {
  margin-bottom: 64px; }

.margin-bottom-64-important {
  margin-bottom: 64px !important; }

.margin-bottom-65 {
  margin-bottom: 65px; }

.margin-bottom-65-important {
  margin-bottom: 65px !important; }

.margin-bottom-66 {
  margin-bottom: 66px; }

.margin-bottom-66-important {
  margin-bottom: 66px !important; }

.margin-bottom-67 {
  margin-bottom: 67px; }

.margin-bottom-67-important {
  margin-bottom: 67px !important; }

.margin-bottom-68 {
  margin-bottom: 68px; }

.margin-bottom-68-important {
  margin-bottom: 68px !important; }

.margin-bottom-69 {
  margin-bottom: 69px; }

.margin-bottom-69-important {
  margin-bottom: 69px !important; }

.margin-bottom-70 {
  margin-bottom: 70px; }

.margin-bottom-70-important {
  margin-bottom: 70px !important; }

.margin-bottom-71 {
  margin-bottom: 71px; }

.margin-bottom-71-important {
  margin-bottom: 71px !important; }

.margin-bottom-72 {
  margin-bottom: 72px; }

.margin-bottom-72-important {
  margin-bottom: 72px !important; }

.margin-bottom-73 {
  margin-bottom: 73px; }

.margin-bottom-73-important {
  margin-bottom: 73px !important; }

.margin-bottom-74 {
  margin-bottom: 74px; }

.margin-bottom-74-important {
  margin-bottom: 74px !important; }

.margin-bottom-75 {
  margin-bottom: 75px; }

.margin-bottom-75-important {
  margin-bottom: 75px !important; }

.margin-bottom-76 {
  margin-bottom: 76px; }

.margin-bottom-76-important {
  margin-bottom: 76px !important; }

.margin-bottom-77 {
  margin-bottom: 77px; }

.margin-bottom-77-important {
  margin-bottom: 77px !important; }

.margin-bottom-78 {
  margin-bottom: 78px; }

.margin-bottom-78-important {
  margin-bottom: 78px !important; }

.margin-bottom-79 {
  margin-bottom: 79px; }

.margin-bottom-79-important {
  margin-bottom: 79px !important; }

.margin-bottom-80 {
  margin-bottom: 80px; }

.margin-bottom-80-important {
  margin-bottom: 80px !important; }

.margin-bottom-81 {
  margin-bottom: 81px; }

.margin-bottom-81-important {
  margin-bottom: 81px !important; }

.margin-bottom-82 {
  margin-bottom: 82px; }

.margin-bottom-82-important {
  margin-bottom: 82px !important; }

.margin-bottom-83 {
  margin-bottom: 83px; }

.margin-bottom-83-important {
  margin-bottom: 83px !important; }

.margin-bottom-84 {
  margin-bottom: 84px; }

.margin-bottom-84-important {
  margin-bottom: 84px !important; }

.margin-bottom-85 {
  margin-bottom: 85px; }

.margin-bottom-85-important {
  margin-bottom: 85px !important; }

.margin-bottom-86 {
  margin-bottom: 86px; }

.margin-bottom-86-important {
  margin-bottom: 86px !important; }

.margin-bottom-87 {
  margin-bottom: 87px; }

.margin-bottom-87-important {
  margin-bottom: 87px !important; }

.margin-bottom-88 {
  margin-bottom: 88px; }

.margin-bottom-88-important {
  margin-bottom: 88px !important; }

.margin-bottom-89 {
  margin-bottom: 89px; }

.margin-bottom-89-important {
  margin-bottom: 89px !important; }

.margin-bottom-90 {
  margin-bottom: 90px; }

.margin-bottom-90-important {
  margin-bottom: 90px !important; }

.margin-left-0 {
  margin-left: 0px; }

.margin-left-0-important {
  margin-left: 0px !important; }

.margin-left-1 {
  margin-left: 1px; }

.margin-left-1-important {
  margin-left: 1px !important; }

.margin-left-2 {
  margin-left: 2px; }

.margin-left-2-important {
  margin-left: 2px !important; }

.margin-left-3 {
  margin-left: 3px; }

.margin-left-3-important {
  margin-left: 3px !important; }

.margin-left-4 {
  margin-left: 4px; }

.margin-left-4-important {
  margin-left: 4px !important; }

.margin-left-5 {
  margin-left: 5px; }

.margin-left-5-important {
  margin-left: 5px !important; }

.margin-left-6 {
  margin-left: 6px; }

.margin-left-6-important {
  margin-left: 6px !important; }

.margin-left-7 {
  margin-left: 7px; }

.margin-left-7-important {
  margin-left: 7px !important; }

.margin-left-8 {
  margin-left: 8px; }

.margin-left-8-important {
  margin-left: 8px !important; }

.margin-left-9 {
  margin-left: 9px; }

.margin-left-9-important {
  margin-left: 9px !important; }

.margin-left-10 {
  margin-left: 10px; }

.margin-left-10-important {
  margin-left: 10px !important; }

.margin-left-11 {
  margin-left: 11px; }

.margin-left-11-important {
  margin-left: 11px !important; }

.margin-left-12 {
  margin-left: 12px; }

.margin-left-12-important {
  margin-left: 12px !important; }

.margin-left-13 {
  margin-left: 13px; }

.margin-left-13-important {
  margin-left: 13px !important; }

.margin-left-14 {
  margin-left: 14px; }

.margin-left-14-important {
  margin-left: 14px !important; }

.margin-left-15 {
  margin-left: 15px; }

.margin-left-15-important {
  margin-left: 15px !important; }

.margin-left-16 {
  margin-left: 16px; }

.margin-left-16-important {
  margin-left: 16px !important; }

.margin-left-17 {
  margin-left: 17px; }

.margin-left-17-important {
  margin-left: 17px !important; }

.margin-left-18 {
  margin-left: 18px; }

.margin-left-18-important {
  margin-left: 18px !important; }

.margin-left-19 {
  margin-left: 19px; }

.margin-left-19-important {
  margin-left: 19px !important; }

.margin-left-20 {
  margin-left: 20px; }

.margin-left-20-important {
  margin-left: 20px !important; }

.margin-left-21 {
  margin-left: 21px; }

.margin-left-21-important {
  margin-left: 21px !important; }

.margin-left-22 {
  margin-left: 22px; }

.margin-left-22-important {
  margin-left: 22px !important; }

.margin-left-23 {
  margin-left: 23px; }

.margin-left-23-important {
  margin-left: 23px !important; }

.margin-left-24 {
  margin-left: 24px; }

.margin-left-24-important {
  margin-left: 24px !important; }

.margin-left-25 {
  margin-left: 25px; }

.margin-left-25-important {
  margin-left: 25px !important; }

.margin-left-26 {
  margin-left: 26px; }

.margin-left-26-important {
  margin-left: 26px !important; }

.margin-left-27 {
  margin-left: 27px; }

.margin-left-27-important {
  margin-left: 27px !important; }

.margin-left-28 {
  margin-left: 28px; }

.margin-left-28-important {
  margin-left: 28px !important; }

.margin-left-29 {
  margin-left: 29px; }

.margin-left-29-important {
  margin-left: 29px !important; }

.margin-left-30 {
  margin-left: 30px; }

.margin-left-30-important {
  margin-left: 30px !important; }

.margin-left-31 {
  margin-left: 31px; }

.margin-left-31-important {
  margin-left: 31px !important; }

.margin-left-32 {
  margin-left: 32px; }

.margin-left-32-important {
  margin-left: 32px !important; }

.margin-left-33 {
  margin-left: 33px; }

.margin-left-33-important {
  margin-left: 33px !important; }

.margin-left-34 {
  margin-left: 34px; }

.margin-left-34-important {
  margin-left: 34px !important; }

.margin-left-35 {
  margin-left: 35px; }

.margin-left-35-important {
  margin-left: 35px !important; }

.margin-left-36 {
  margin-left: 36px; }

.margin-left-36-important {
  margin-left: 36px !important; }

.margin-left-37 {
  margin-left: 37px; }

.margin-left-37-important {
  margin-left: 37px !important; }

.margin-left-38 {
  margin-left: 38px; }

.margin-left-38-important {
  margin-left: 38px !important; }

.margin-left-39 {
  margin-left: 39px; }

.margin-left-39-important {
  margin-left: 39px !important; }

.margin-left-40 {
  margin-left: 40px; }

.margin-left-40-important {
  margin-left: 40px !important; }

.margin-left-41 {
  margin-left: 41px; }

.margin-left-41-important {
  margin-left: 41px !important; }

.margin-left-42 {
  margin-left: 42px; }

.margin-left-42-important {
  margin-left: 42px !important; }

.margin-left-43 {
  margin-left: 43px; }

.margin-left-43-important {
  margin-left: 43px !important; }

.margin-left-44 {
  margin-left: 44px; }

.margin-left-44-important {
  margin-left: 44px !important; }

.margin-left-45 {
  margin-left: 45px; }

.margin-left-45-important {
  margin-left: 45px !important; }

.margin-left-46 {
  margin-left: 46px; }

.margin-left-46-important {
  margin-left: 46px !important; }

.margin-left-47 {
  margin-left: 47px; }

.margin-left-47-important {
  margin-left: 47px !important; }

.margin-left-48 {
  margin-left: 48px; }

.margin-left-48-important {
  margin-left: 48px !important; }

.margin-left-49 {
  margin-left: 49px; }

.margin-left-49-important {
  margin-left: 49px !important; }

.margin-left-50 {
  margin-left: 50px; }

.margin-left-50-important {
  margin-left: 50px !important; }

.padding-0 {
  padding: 0px; }

.padding-0-important {
  padding: 0px !important; }

.padding-1 {
  padding: 1px; }

.padding-1-important {
  padding: 1px !important; }

.padding-2 {
  padding: 2px; }

.padding-2-important {
  padding: 2px !important; }

.padding-3 {
  padding: 3px; }

.padding-3-important {
  padding: 3px !important; }

.padding-4 {
  padding: 4px; }

.padding-4-important {
  padding: 4px !important; }

.padding-5 {
  padding: 5px; }

.padding-5-important {
  padding: 5px !important; }

.padding-6 {
  padding: 6px; }

.padding-6-important {
  padding: 6px !important; }

.padding-7 {
  padding: 7px; }

.padding-7-important {
  padding: 7px !important; }

.padding-8 {
  padding: 8px; }

.padding-8-important {
  padding: 8px !important; }

.padding-9 {
  padding: 9px; }

.padding-9-important {
  padding: 9px !important; }

.padding-10 {
  padding: 10px; }

.padding-10-important {
  padding: 10px !important; }

.padding-11 {
  padding: 11px; }

.padding-11-important {
  padding: 11px !important; }

.padding-12 {
  padding: 12px; }

.padding-12-important {
  padding: 12px !important; }

.padding-13 {
  padding: 13px; }

.padding-13-important {
  padding: 13px !important; }

.padding-14 {
  padding: 14px; }

.padding-14-important {
  padding: 14px !important; }

.padding-15 {
  padding: 15px; }

.padding-15-important {
  padding: 15px !important; }

.padding-16 {
  padding: 16px; }

.padding-16-important {
  padding: 16px !important; }

.padding-17 {
  padding: 17px; }

.padding-17-important {
  padding: 17px !important; }

.padding-18 {
  padding: 18px; }

.padding-18-important {
  padding: 18px !important; }

.padding-19 {
  padding: 19px; }

.padding-19-important {
  padding: 19px !important; }

.padding-20 {
  padding: 20px; }

.padding-20-important {
  padding: 20px !important; }

.padding-top-0 {
  padding-top: 0px; }

.padding-top-0-important {
  padding-top: 0px !important; }

.padding-top-1 {
  padding-top: 1px; }

.padding-top-1-important {
  padding-top: 1px !important; }

.padding-top-2 {
  padding-top: 2px; }

.padding-top-2-important {
  padding-top: 2px !important; }

.padding-top-3 {
  padding-top: 3px; }

.padding-top-3-important {
  padding-top: 3px !important; }

.padding-top-4 {
  padding-top: 4px; }

.padding-top-4-important {
  padding-top: 4px !important; }

.padding-top-5 {
  padding-top: 5px; }

.padding-top-5-important {
  padding-top: 5px !important; }

.padding-top-6 {
  padding-top: 6px; }

.padding-top-6-important {
  padding-top: 6px !important; }

.padding-top-7 {
  padding-top: 7px; }

.padding-top-7-important {
  padding-top: 7px !important; }

.padding-top-8 {
  padding-top: 8px; }

.padding-top-8-important {
  padding-top: 8px !important; }

.padding-top-9 {
  padding-top: 9px; }

.padding-top-9-important {
  padding-top: 9px !important; }

.padding-top-10 {
  padding-top: 10px; }

.padding-top-10-important {
  padding-top: 10px !important; }

.padding-top-11 {
  padding-top: 11px; }

.padding-top-11-important {
  padding-top: 11px !important; }

.padding-top-12 {
  padding-top: 12px; }

.padding-top-12-important {
  padding-top: 12px !important; }

.padding-top-13 {
  padding-top: 13px; }

.padding-top-13-important {
  padding-top: 13px !important; }

.padding-top-14 {
  padding-top: 14px; }

.padding-top-14-important {
  padding-top: 14px !important; }

.padding-top-15 {
  padding-top: 15px; }

.padding-top-15-important {
  padding-top: 15px !important; }

.padding-top-16 {
  padding-top: 16px; }

.padding-top-16-important {
  padding-top: 16px !important; }

.padding-top-17 {
  padding-top: 17px; }

.padding-top-17-important {
  padding-top: 17px !important; }

.padding-top-18 {
  padding-top: 18px; }

.padding-top-18-important {
  padding-top: 18px !important; }

.padding-top-19 {
  padding-top: 19px; }

.padding-top-19-important {
  padding-top: 19px !important; }

.padding-top-20 {
  padding-top: 20px; }

.padding-top-20-important {
  padding-top: 20px !important; }

.padding-top-21 {
  padding-top: 21px; }

.padding-top-21-important {
  padding-top: 21px !important; }

.padding-top-22 {
  padding-top: 22px; }

.padding-top-22-important {
  padding-top: 22px !important; }

.padding-top-23 {
  padding-top: 23px; }

.padding-top-23-important {
  padding-top: 23px !important; }

.padding-top-24 {
  padding-top: 24px; }

.padding-top-24-important {
  padding-top: 24px !important; }

.padding-top-25 {
  padding-top: 25px; }

.padding-top-25-important {
  padding-top: 25px !important; }

.padding-top-26 {
  padding-top: 26px; }

.padding-top-26-important {
  padding-top: 26px !important; }

.padding-top-27 {
  padding-top: 27px; }

.padding-top-27-important {
  padding-top: 27px !important; }

.padding-top-28 {
  padding-top: 28px; }

.padding-top-28-important {
  padding-top: 28px !important; }

.padding-top-29 {
  padding-top: 29px; }

.padding-top-29-important {
  padding-top: 29px !important; }

.padding-top-30 {
  padding-top: 30px; }

.padding-top-30-important {
  padding-top: 30px !important; }

.padding-top-31 {
  padding-top: 31px; }

.padding-top-31-important {
  padding-top: 31px !important; }

.padding-top-32 {
  padding-top: 32px; }

.padding-top-32-important {
  padding-top: 32px !important; }

.padding-top-33 {
  padding-top: 33px; }

.padding-top-33-important {
  padding-top: 33px !important; }

.padding-top-34 {
  padding-top: 34px; }

.padding-top-34-important {
  padding-top: 34px !important; }

.padding-top-35 {
  padding-top: 35px; }

.padding-top-35-important {
  padding-top: 35px !important; }

.padding-top-36 {
  padding-top: 36px; }

.padding-top-36-important {
  padding-top: 36px !important; }

.padding-top-37 {
  padding-top: 37px; }

.padding-top-37-important {
  padding-top: 37px !important; }

.padding-top-38 {
  padding-top: 38px; }

.padding-top-38-important {
  padding-top: 38px !important; }

.padding-top-39 {
  padding-top: 39px; }

.padding-top-39-important {
  padding-top: 39px !important; }

.padding-top-40 {
  padding-top: 40px; }

.padding-top-40-important {
  padding-top: 40px !important; }

.padding-top-41 {
  padding-top: 41px; }

.padding-top-41-important {
  padding-top: 41px !important; }

.padding-top-42 {
  padding-top: 42px; }

.padding-top-42-important {
  padding-top: 42px !important; }

.padding-top-43 {
  padding-top: 43px; }

.padding-top-43-important {
  padding-top: 43px !important; }

.padding-top-44 {
  padding-top: 44px; }

.padding-top-44-important {
  padding-top: 44px !important; }

.padding-top-45 {
  padding-top: 45px; }

.padding-top-45-important {
  padding-top: 45px !important; }

.padding-top-46 {
  padding-top: 46px; }

.padding-top-46-important {
  padding-top: 46px !important; }

.padding-top-47 {
  padding-top: 47px; }

.padding-top-47-important {
  padding-top: 47px !important; }

.padding-top-48 {
  padding-top: 48px; }

.padding-top-48-important {
  padding-top: 48px !important; }

.padding-top-49 {
  padding-top: 49px; }

.padding-top-49-important {
  padding-top: 49px !important; }

.padding-top-50 {
  padding-top: 50px; }

.padding-top-50-important {
  padding-top: 50px !important; }

.padding-right-0 {
  padding-right: 0px; }

.padding-right-0-important {
  padding-right: 0px !important; }

.padding-right-1 {
  padding-right: 1px; }

.padding-right-1-important {
  padding-right: 1px !important; }

.padding-right-2 {
  padding-right: 2px; }

.padding-right-2-important {
  padding-right: 2px !important; }

.padding-right-3 {
  padding-right: 3px; }

.padding-right-3-important {
  padding-right: 3px !important; }

.padding-right-4 {
  padding-right: 4px; }

.padding-right-4-important {
  padding-right: 4px !important; }

.padding-right-5 {
  padding-right: 5px; }

.padding-right-5-important {
  padding-right: 5px !important; }

.padding-right-6 {
  padding-right: 6px; }

.padding-right-6-important {
  padding-right: 6px !important; }

.padding-right-7 {
  padding-right: 7px; }

.padding-right-7-important {
  padding-right: 7px !important; }

.padding-right-8 {
  padding-right: 8px; }

.padding-right-8-important {
  padding-right: 8px !important; }

.padding-right-9 {
  padding-right: 9px; }

.padding-right-9-important {
  padding-right: 9px !important; }

.padding-right-10 {
  padding-right: 10px; }

.padding-right-10-important {
  padding-right: 10px !important; }

.padding-right-11 {
  padding-right: 11px; }

.padding-right-11-important {
  padding-right: 11px !important; }

.padding-right-12 {
  padding-right: 12px; }

.padding-right-12-important {
  padding-right: 12px !important; }

.padding-right-13 {
  padding-right: 13px; }

.padding-right-13-important {
  padding-right: 13px !important; }

.padding-right-14 {
  padding-right: 14px; }

.padding-right-14-important {
  padding-right: 14px !important; }

.padding-right-15 {
  padding-right: 15px; }

.padding-right-15-important {
  padding-right: 15px !important; }

.padding-right-16 {
  padding-right: 16px; }

.padding-right-16-important {
  padding-right: 16px !important; }

.padding-right-17 {
  padding-right: 17px; }

.padding-right-17-important {
  padding-right: 17px !important; }

.padding-right-18 {
  padding-right: 18px; }

.padding-right-18-important {
  padding-right: 18px !important; }

.padding-right-19 {
  padding-right: 19px; }

.padding-right-19-important {
  padding-right: 19px !important; }

.padding-right-20 {
  padding-right: 20px; }

.padding-right-20-important {
  padding-right: 20px !important; }

.padding-right-21 {
  padding-right: 21px; }

.padding-right-21-important {
  padding-right: 21px !important; }

.padding-right-22 {
  padding-right: 22px; }

.padding-right-22-important {
  padding-right: 22px !important; }

.padding-right-23 {
  padding-right: 23px; }

.padding-right-23-important {
  padding-right: 23px !important; }

.padding-right-24 {
  padding-right: 24px; }

.padding-right-24-important {
  padding-right: 24px !important; }

.padding-right-25 {
  padding-right: 25px; }

.padding-right-25-important {
  padding-right: 25px !important; }

.padding-right-26 {
  padding-right: 26px; }

.padding-right-26-important {
  padding-right: 26px !important; }

.padding-right-27 {
  padding-right: 27px; }

.padding-right-27-important {
  padding-right: 27px !important; }

.padding-right-28 {
  padding-right: 28px; }

.padding-right-28-important {
  padding-right: 28px !important; }

.padding-right-29 {
  padding-right: 29px; }

.padding-right-29-important {
  padding-right: 29px !important; }

.padding-right-30 {
  padding-right: 30px; }

.padding-right-30-important {
  padding-right: 30px !important; }

.padding-right-31 {
  padding-right: 31px; }

.padding-right-31-important {
  padding-right: 31px !important; }

.padding-right-32 {
  padding-right: 32px; }

.padding-right-32-important {
  padding-right: 32px !important; }

.padding-right-33 {
  padding-right: 33px; }

.padding-right-33-important {
  padding-right: 33px !important; }

.padding-right-34 {
  padding-right: 34px; }

.padding-right-34-important {
  padding-right: 34px !important; }

.padding-right-35 {
  padding-right: 35px; }

.padding-right-35-important {
  padding-right: 35px !important; }

.padding-right-36 {
  padding-right: 36px; }

.padding-right-36-important {
  padding-right: 36px !important; }

.padding-right-37 {
  padding-right: 37px; }

.padding-right-37-important {
  padding-right: 37px !important; }

.padding-right-38 {
  padding-right: 38px; }

.padding-right-38-important {
  padding-right: 38px !important; }

.padding-right-39 {
  padding-right: 39px; }

.padding-right-39-important {
  padding-right: 39px !important; }

.padding-right-40 {
  padding-right: 40px; }

.padding-right-40-important {
  padding-right: 40px !important; }

.padding-right-41 {
  padding-right: 41px; }

.padding-right-41-important {
  padding-right: 41px !important; }

.padding-right-42 {
  padding-right: 42px; }

.padding-right-42-important {
  padding-right: 42px !important; }

.padding-right-43 {
  padding-right: 43px; }

.padding-right-43-important {
  padding-right: 43px !important; }

.padding-right-44 {
  padding-right: 44px; }

.padding-right-44-important {
  padding-right: 44px !important; }

.padding-right-45 {
  padding-right: 45px; }

.padding-right-45-important {
  padding-right: 45px !important; }

.padding-right-46 {
  padding-right: 46px; }

.padding-right-46-important {
  padding-right: 46px !important; }

.padding-right-47 {
  padding-right: 47px; }

.padding-right-47-important {
  padding-right: 47px !important; }

.padding-right-48 {
  padding-right: 48px; }

.padding-right-48-important {
  padding-right: 48px !important; }

.padding-right-49 {
  padding-right: 49px; }

.padding-right-49-important {
  padding-right: 49px !important; }

.padding-right-50 {
  padding-right: 50px; }

.padding-right-50-important {
  padding-right: 50px !important; }

.padding-right-51 {
  padding-right: 51px; }

.padding-right-51-important {
  padding-right: 51px !important; }

.padding-right-52 {
  padding-right: 52px; }

.padding-right-52-important {
  padding-right: 52px !important; }

.padding-right-53 {
  padding-right: 53px; }

.padding-right-53-important {
  padding-right: 53px !important; }

.padding-right-54 {
  padding-right: 54px; }

.padding-right-54-important {
  padding-right: 54px !important; }

.padding-right-55 {
  padding-right: 55px; }

.padding-right-55-important {
  padding-right: 55px !important; }

.padding-right-56 {
  padding-right: 56px; }

.padding-right-56-important {
  padding-right: 56px !important; }

.padding-right-57 {
  padding-right: 57px; }

.padding-right-57-important {
  padding-right: 57px !important; }

.padding-right-58 {
  padding-right: 58px; }

.padding-right-58-important {
  padding-right: 58px !important; }

.padding-right-59 {
  padding-right: 59px; }

.padding-right-59-important {
  padding-right: 59px !important; }

.padding-right-60 {
  padding-right: 60px; }

.padding-right-60-important {
  padding-right: 60px !important; }

.padding-right-61 {
  padding-right: 61px; }

.padding-right-61-important {
  padding-right: 61px !important; }

.padding-right-62 {
  padding-right: 62px; }

.padding-right-62-important {
  padding-right: 62px !important; }

.padding-right-63 {
  padding-right: 63px; }

.padding-right-63-important {
  padding-right: 63px !important; }

.padding-right-64 {
  padding-right: 64px; }

.padding-right-64-important {
  padding-right: 64px !important; }

.padding-right-65 {
  padding-right: 65px; }

.padding-right-65-important {
  padding-right: 65px !important; }

.padding-bottom-0 {
  padding-bottom: 0px; }

.padding-bottom-0-important {
  padding-bottom: 0px !important; }

.padding-bottom-1 {
  padding-bottom: 1px; }

.padding-bottom-1-important {
  padding-bottom: 1px !important; }

.padding-bottom-2 {
  padding-bottom: 2px; }

.padding-bottom-2-important {
  padding-bottom: 2px !important; }

.padding-bottom-3 {
  padding-bottom: 3px; }

.padding-bottom-3-important {
  padding-bottom: 3px !important; }

.padding-bottom-4 {
  padding-bottom: 4px; }

.padding-bottom-4-important {
  padding-bottom: 4px !important; }

.padding-bottom-5 {
  padding-bottom: 5px; }

.padding-bottom-5-important {
  padding-bottom: 5px !important; }

.padding-bottom-6 {
  padding-bottom: 6px; }

.padding-bottom-6-important {
  padding-bottom: 6px !important; }

.padding-bottom-7 {
  padding-bottom: 7px; }

.padding-bottom-7-important {
  padding-bottom: 7px !important; }

.padding-bottom-8 {
  padding-bottom: 8px; }

.padding-bottom-8-important {
  padding-bottom: 8px !important; }

.padding-bottom-9 {
  padding-bottom: 9px; }

.padding-bottom-9-important {
  padding-bottom: 9px !important; }

.padding-bottom-10 {
  padding-bottom: 10px; }

.padding-bottom-10-important {
  padding-bottom: 10px !important; }

.padding-bottom-11 {
  padding-bottom: 11px; }

.padding-bottom-11-important {
  padding-bottom: 11px !important; }

.padding-bottom-12 {
  padding-bottom: 12px; }

.padding-bottom-12-important {
  padding-bottom: 12px !important; }

.padding-bottom-13 {
  padding-bottom: 13px; }

.padding-bottom-13-important {
  padding-bottom: 13px !important; }

.padding-bottom-14 {
  padding-bottom: 14px; }

.padding-bottom-14-important {
  padding-bottom: 14px !important; }

.padding-bottom-15 {
  padding-bottom: 15px; }

.padding-bottom-15-important {
  padding-bottom: 15px !important; }

.padding-bottom-16 {
  padding-bottom: 16px; }

.padding-bottom-16-important {
  padding-bottom: 16px !important; }

.padding-bottom-17 {
  padding-bottom: 17px; }

.padding-bottom-17-important {
  padding-bottom: 17px !important; }

.padding-bottom-18 {
  padding-bottom: 18px; }

.padding-bottom-18-important {
  padding-bottom: 18px !important; }

.padding-bottom-19 {
  padding-bottom: 19px; }

.padding-bottom-19-important {
  padding-bottom: 19px !important; }

.padding-bottom-20 {
  padding-bottom: 20px; }

.padding-bottom-20-important {
  padding-bottom: 20px !important; }

.padding-bottom-21 {
  padding-bottom: 21px; }

.padding-bottom-21-important {
  padding-bottom: 21px !important; }

.padding-bottom-22 {
  padding-bottom: 22px; }

.padding-bottom-22-important {
  padding-bottom: 22px !important; }

.padding-bottom-23 {
  padding-bottom: 23px; }

.padding-bottom-23-important {
  padding-bottom: 23px !important; }

.padding-bottom-24 {
  padding-bottom: 24px; }

.padding-bottom-24-important {
  padding-bottom: 24px !important; }

.padding-bottom-25 {
  padding-bottom: 25px; }

.padding-bottom-25-important {
  padding-bottom: 25px !important; }

.padding-bottom-26 {
  padding-bottom: 26px; }

.padding-bottom-26-important {
  padding-bottom: 26px !important; }

.padding-bottom-27 {
  padding-bottom: 27px; }

.padding-bottom-27-important {
  padding-bottom: 27px !important; }

.padding-bottom-28 {
  padding-bottom: 28px; }

.padding-bottom-28-important {
  padding-bottom: 28px !important; }

.padding-bottom-29 {
  padding-bottom: 29px; }

.padding-bottom-29-important {
  padding-bottom: 29px !important; }

.padding-bottom-30 {
  padding-bottom: 30px; }

.padding-bottom-30-important {
  padding-bottom: 30px !important; }

.padding-bottom-31 {
  padding-bottom: 31px; }

.padding-bottom-31-important {
  padding-bottom: 31px !important; }

.padding-bottom-32 {
  padding-bottom: 32px; }

.padding-bottom-32-important {
  padding-bottom: 32px !important; }

.padding-bottom-33 {
  padding-bottom: 33px; }

.padding-bottom-33-important {
  padding-bottom: 33px !important; }

.padding-bottom-34 {
  padding-bottom: 34px; }

.padding-bottom-34-important {
  padding-bottom: 34px !important; }

.padding-bottom-35 {
  padding-bottom: 35px; }

.padding-bottom-35-important {
  padding-bottom: 35px !important; }

.padding-bottom-36 {
  padding-bottom: 36px; }

.padding-bottom-36-important {
  padding-bottom: 36px !important; }

.padding-bottom-37 {
  padding-bottom: 37px; }

.padding-bottom-37-important {
  padding-bottom: 37px !important; }

.padding-bottom-38 {
  padding-bottom: 38px; }

.padding-bottom-38-important {
  padding-bottom: 38px !important; }

.padding-bottom-39 {
  padding-bottom: 39px; }

.padding-bottom-39-important {
  padding-bottom: 39px !important; }

.padding-bottom-40 {
  padding-bottom: 40px; }

.padding-bottom-40-important {
  padding-bottom: 40px !important; }

.padding-left-0 {
  padding-left: 0px; }

.padding-left-0-important {
  padding-left: 0px !important; }

.padding-left-1 {
  padding-left: 1px; }

.padding-left-1-important {
  padding-left: 1px !important; }

.padding-left-2 {
  padding-left: 2px; }

.padding-left-2-important {
  padding-left: 2px !important; }

.padding-left-3 {
  padding-left: 3px; }

.padding-left-3-important {
  padding-left: 3px !important; }

.padding-left-4 {
  padding-left: 4px; }

.padding-left-4-important {
  padding-left: 4px !important; }

.padding-left-5 {
  padding-left: 5px; }

.padding-left-5-important {
  padding-left: 5px !important; }

.padding-left-6 {
  padding-left: 6px; }

.padding-left-6-important {
  padding-left: 6px !important; }

.padding-left-7 {
  padding-left: 7px; }

.padding-left-7-important {
  padding-left: 7px !important; }

.padding-left-8 {
  padding-left: 8px; }

.padding-left-8-important {
  padding-left: 8px !important; }

.padding-left-9 {
  padding-left: 9px; }

.padding-left-9-important {
  padding-left: 9px !important; }

.padding-left-10 {
  padding-left: 10px; }

.padding-left-10-important {
  padding-left: 10px !important; }

.padding-left-11 {
  padding-left: 11px; }

.padding-left-11-important {
  padding-left: 11px !important; }

.padding-left-12 {
  padding-left: 12px; }

.padding-left-12-important {
  padding-left: 12px !important; }

.padding-left-13 {
  padding-left: 13px; }

.padding-left-13-important {
  padding-left: 13px !important; }

.padding-left-14 {
  padding-left: 14px; }

.padding-left-14-important {
  padding-left: 14px !important; }

.padding-left-15 {
  padding-left: 15px; }

.padding-left-15-important {
  padding-left: 15px !important; }

.padding-left-16 {
  padding-left: 16px; }

.padding-left-16-important {
  padding-left: 16px !important; }

.padding-left-17 {
  padding-left: 17px; }

.padding-left-17-important {
  padding-left: 17px !important; }

.padding-left-18 {
  padding-left: 18px; }

.padding-left-18-important {
  padding-left: 18px !important; }

.padding-left-19 {
  padding-left: 19px; }

.padding-left-19-important {
  padding-left: 19px !important; }

.padding-left-20 {
  padding-left: 20px; }

.padding-left-20-important {
  padding-left: 20px !important; }

.padding-left-21 {
  padding-left: 21px; }

.padding-left-21-important {
  padding-left: 21px !important; }

.padding-left-22 {
  padding-left: 22px; }

.padding-left-22-important {
  padding-left: 22px !important; }

.padding-left-23 {
  padding-left: 23px; }

.padding-left-23-important {
  padding-left: 23px !important; }

.padding-left-24 {
  padding-left: 24px; }

.padding-left-24-important {
  padding-left: 24px !important; }

.padding-left-25 {
  padding-left: 25px; }

.padding-left-25-important {
  padding-left: 25px !important; }

.padding-left-26 {
  padding-left: 26px; }

.padding-left-26-important {
  padding-left: 26px !important; }

.padding-left-27 {
  padding-left: 27px; }

.padding-left-27-important {
  padding-left: 27px !important; }

.padding-left-28 {
  padding-left: 28px; }

.padding-left-28-important {
  padding-left: 28px !important; }

.padding-left-29 {
  padding-left: 29px; }

.padding-left-29-important {
  padding-left: 29px !important; }

.padding-left-30 {
  padding-left: 30px; }

.padding-left-30-important {
  padding-left: 30px !important; }

.padding-left-31 {
  padding-left: 31px; }

.padding-left-31-important {
  padding-left: 31px !important; }

.padding-left-32 {
  padding-left: 32px; }

.padding-left-32-important {
  padding-left: 32px !important; }

.padding-left-33 {
  padding-left: 33px; }

.padding-left-33-important {
  padding-left: 33px !important; }

.padding-left-34 {
  padding-left: 34px; }

.padding-left-34-important {
  padding-left: 34px !important; }

.padding-left-35 {
  padding-left: 35px; }

.padding-left-35-important {
  padding-left: 35px !important; }

.padding-left-36 {
  padding-left: 36px; }

.padding-left-36-important {
  padding-left: 36px !important; }

.padding-left-37 {
  padding-left: 37px; }

.padding-left-37-important {
  padding-left: 37px !important; }

.padding-left-38 {
  padding-left: 38px; }

.padding-left-38-important {
  padding-left: 38px !important; }

.padding-left-39 {
  padding-left: 39px; }

.padding-left-39-important {
  padding-left: 39px !important; }

.padding-left-40 {
  padding-left: 40px; }

.padding-left-40-important {
  padding-left: 40px !important; }

.padding-left-41 {
  padding-left: 41px; }

.padding-left-41-important {
  padding-left: 41px !important; }

.padding-left-42 {
  padding-left: 42px; }

.padding-left-42-important {
  padding-left: 42px !important; }

.padding-left-43 {
  padding-left: 43px; }

.padding-left-43-important {
  padding-left: 43px !important; }

.padding-left-44 {
  padding-left: 44px; }

.padding-left-44-important {
  padding-left: 44px !important; }

.padding-left-45 {
  padding-left: 45px; }

.padding-left-45-important {
  padding-left: 45px !important; }

.padding-left-46 {
  padding-left: 46px; }

.padding-left-46-important {
  padding-left: 46px !important; }

.padding-left-47 {
  padding-left: 47px; }

.padding-left-47-important {
  padding-left: 47px !important; }

.padding-left-48 {
  padding-left: 48px; }

.padding-left-48-important {
  padding-left: 48px !important; }

.padding-left-49 {
  padding-left: 49px; }

.padding-left-49-important {
  padding-left: 49px !important; }

.padding-left-50 {
  padding-left: 50px; }

.padding-left-50-important {
  padding-left: 50px !important; }

.position-top-0 {
  position: relative;
  top: 0px; }

.position-top-1 {
  position: relative;
  top: 1px; }

.position-top-2 {
  position: relative;
  top: 2px; }

.position-top-3 {
  position: relative;
  top: 3px; }

.position-top-4 {
  position: relative;
  top: 4px; }

.position-top-5 {
  position: relative;
  top: 5px; }

.position-top-6 {
  position: relative;
  top: 6px; }

.position-top-7 {
  position: relative;
  top: 7px; }

.position-top-8 {
  position: relative;
  top: 8px; }

.position-top-9 {
  position: relative;
  top: 9px; }

.position-top-10 {
  position: relative;
  top: 10px; }

.position-right-0 {
  position: relative;
  right: 0px; }

.position-right-1 {
  position: relative;
  right: 1px; }

.position-right-2 {
  position: relative;
  right: 2px; }

.position-right-3 {
  position: relative;
  right: 3px; }

.position-right-4 {
  position: relative;
  right: 4px; }

.position-right-5 {
  position: relative;
  right: 5px; }

.position-right-6 {
  position: relative;
  right: 6px; }

.position-right-7 {
  position: relative;
  right: 7px; }

.position-right-8 {
  position: relative;
  right: 8px; }

.position-right-9 {
  position: relative;
  right: 9px; }

.position-right-10 {
  position: relative;
  right: 10px; }

.position-bottom-0 {
  position: relative;
  bottom: 0px; }

.position-bottom-1 {
  position: relative;
  bottom: 1px; }

.position-bottom-2 {
  position: relative;
  bottom: 2px; }

.position-bottom-3 {
  position: relative;
  bottom: 3px; }

.position-bottom-4 {
  position: relative;
  bottom: 4px; }

.position-bottom-5 {
  position: relative;
  bottom: 5px; }

.position-bottom-6 {
  position: relative;
  bottom: 6px; }

.position-bottom-7 {
  position: relative;
  bottom: 7px; }

.position-bottom-8 {
  position: relative;
  bottom: 8px; }

.position-bottom-9 {
  position: relative;
  bottom: 9px; }

.position-bottom-10 {
  position: relative;
  bottom: 10px; }

.position-left-0 {
  position: relative;
  left: 0px; }

.position-left-1 {
  position: relative;
  left: 1px; }

.position-left-2 {
  position: relative;
  left: 2px; }

.position-left-3 {
  position: relative;
  left: 3px; }

.position-left-4 {
  position: relative;
  left: 4px; }

.position-left-5 {
  position: relative;
  left: 5px; }

.position-left-6 {
  position: relative;
  left: 6px; }

.position-left-7 {
  position: relative;
  left: 7px; }

.position-left-8 {
  position: relative;
  left: 8px; }

.position-left-9 {
  position: relative;
  left: 9px; }

.position-left-10 {
  position: relative;
  left: 10px; }

.category-color-1 {
  background-color: #927402; }

.category-color-2 {
  background-color: #f7c403; }

.category-color-3 {
  background-color: #fee895; }

.category-color-4 {
  background-color: #fddd63; }

.category-color-5 {
  background-color: #b43503; }

.category-color-6 {
  background-color: #fb6b32; }

.category-color-7 {
  background-color: #fed7c8; }

.category-color-8 {
  background-color: #fdb396; }

.category-color-9 {
  background-color: #75210f; }

.category-color-10 {
  background-color: #cf3a1b; }

.category-color-11 {
  background-color: #eb7c66; }

.category-color-12 {
  background-color: #b40404; }

.category-color-13 {
  background-color: #fb3232; }

.category-color-14 {
  background-color: #fec8c8; }

.category-color-15 {
  background-color: #fd9696; }

.category-color-16 {
  background-color: #2e2b68; }

.category-color-17 {
  background-color: #4e49b0; }

.category-color-18 {
  background-color: #b5b3e0; }

.category-color-19 {
  background-color: #928fd1; }

.category-color-20 {
  background-color: #1d4a77; }

.category-color-21 {
  background-color: #317dc8; }

.category-color-22 {
  background-color: #a9c9ea; }

.category-color-23 {
  background-color: #80b0e0; }

.category-color-24 {
  background-color: #0a82bd; }

.category-color-25 {
  background-color: #0ca3ed; }

.category-color-26 {
  background-color: #99dafa; }

.category-color-27 {
  background-color: #69c8f7; }

.category-color-28 {
  background-color: #2e6651; }

.category-color-29 {
  background-color: #4eac88; }

.category-color-30 {
  background-color: #b5dece; }

.category-color-31 {
  background-color: #92ceb7; }
