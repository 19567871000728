.unbilled-tile {
  margin-bottom: 20px;

  .unbilled-tile-title {
    display: inline;
    font-weight: 600;
  }

  .unbilled-tile-count {
    position: relative;
    top: -2px;
    float: right;
    font-weight: 400;
    color: $light-font-color;
  }

  .unbilled-tile-list-wrapper {
    position: relative;

    .unbilled-tile-list {
      @include no-scrollbar;
      max-height: 350px;
      overflow-y: scroll;
      padding-left: 15px;
      margin-left: -15px;
      padding-right: 20px;
      margin-right: -20px;

      .patient-unbilled-item {
        margin-bottom: 10px;
        padding: 12px;
        display: flex;
        flex-direction: row;

        .checkbox {
          padding: 0;
          margin: 0 8px 0 0;
          width: 20px;
        }

        .item-quantity {
          display: block;
          color: $grey5;
        }

        .item-price {
          display: block;
          float: right;
        }

        &:focus {
          outline: 0;
        }

        &:first-child {
          margin-top: 10px;
        }
      }
    }

    .scroll-gradient {
      position: absolute;
      width: 100%;
      height: 10px;
      z-index: 2;

      &.top {
        background: linear-gradient(
          to bottom,
          $grey-blue,
          $grey-blue-transparent
        );
      }

      &.bottom {
        bottom: 0;
        background: linear-gradient(to top, $grey-blue, $grey-blue-transparent);
      }
    }
  }

  .unbilled-tile-more-action {
    margin-top: 4px;
    margin-bottom: 2px;
    text-align: center;
  }

  .unbilled-tile-actions {
    .btn-group {
      width: 100%;
    }
  }
}

.unbilled-form {
  form {
    max-width: 1400px;
    min-width: 1000px;
    margin: 0 auto;

    .bill-items {
      .bill-item-list-container {
        .bill-item {
          margin: 0;
          padding: 0;
          width: 100% !important;
        }
      }
    }
  }

  .unbilled-form-user-label {
    color: $lighter-font-color;
    font-size: $font-size-label;
    font-weight: 400;
    float: right;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    position: relative;
    top: -10px;
    margin-bottom: 5px;
  }
}

.unbilled-items-overlay-patient-details {
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column;
}

.unbilled-items-overlay-totals {
  margin: 0 15px;
  overflow: hidden;
}
