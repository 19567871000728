@keyframes bounce-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.4, 1.4);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.98, 0.98);
  }

  100% {
    transform: scale(1, 1);
  }
}
