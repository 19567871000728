.loading {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;

  svg {
    height: 60px;
    width: 60px;
  }
}

#nprogress {
  .bar {
    background: $default-primary !important;
  }

  .spinner-icon {
    display: none;
    border-top-color: $default-primary !important;
    border-left-color: $default-primary !important;
  }
}
